import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { Key } from 'react';
import { Operator } from '@/shared/types';
import { ElectricityAccountVo } from './electricity';
import { SaleClientMeterTags } from '../pages/Client/Meter/SaleElectricity/Device/MeterTags';
import { ThermalResponse } from './thermal';

export enum CreditLimitType {
  NONE,
  LIMITED,
  UNLIMITED,
}

export const CreditLimitTypeDisplay = {
  [CreditLimitType.NONE]: '无',
  [CreditLimitType.LIMITED]: '一定额度',
  [CreditLimitType.UNLIMITED]: '不限额度',
};

export enum ChargeTypeEnum {
  POWER = 0,
  THERMAL = 1,
}

export const ChargeTypeEnumDisplay: Record<ChargeTypeEnum, string> = {
  [ChargeTypeEnum.POWER]: '电费',
  [ChargeTypeEnum.THERMAL]: '蒸汽费',
};

export enum ElectricMeterTypeEnum {
  ILLUMINATION,
  PHOTOVOLTAIC,
  SPECIAL,
}

export const ElectricMeterTypeEnumDisplay = {
  [ElectricMeterTypeEnum.ILLUMINATION]: '照明计量',
  [ElectricMeterTypeEnum.PHOTOVOLTAIC]: '光伏计量',
  [ElectricMeterTypeEnum.SPECIAL]: '专用计量',
};

export enum SaleClientAccountStatus {
  NORMAL,
  FREEZE,
  ARREARS,
}

export const SaleClientAccountStatusDisplay = {
  [SaleClientAccountStatus.NORMAL]: '正常',
  [SaleClientAccountStatus.FREEZE]: '冻结',
  [SaleClientAccountStatus.ARREARS]: '欠费',
};

export enum TransactionType {
  RECHARGE,
  REFUND,
  BILLDEDUCTION,
  MANUAL_DEDYCTION,
  ANTI_BOOKKEEPING,
}

export const TransactionTypeDisplay = {
  [TransactionType.RECHARGE]: '充值',
  [TransactionType.REFUND]: '退款',
  [TransactionType.BILLDEDUCTION]: '对账单扣款',
  [TransactionType.MANUAL_DEDYCTION]: '手动扣款',
  [TransactionType.ANTI_BOOKKEEPING]: '反记账',
};

export const TransactionTypeOperator = {
  [TransactionType.RECHARGE]: '+',
  [TransactionType.REFUND]: '-',
  [TransactionType.BILLDEDUCTION]: '-',
  [TransactionType.MANUAL_DEDYCTION]: '-',
  [TransactionType.ANTI_BOOKKEEPING]: '+',
};

export interface SaleClientContactRequest {
  contactCellphone: string;
  contactName: string;
  description?: string;
}

export interface SaleClientContactInfo extends SaleClientContactRequest {
  id: number;
  saleClientId: number;
}

export interface SaleClientRequest {
  code: string;
  name: string;
  ouId: number;
  companyName: string;
  companyAddress: string;
  uscc: string;
  meterTypes: ChargeTypeEnum[];
  electricityAccountIds?: number[];
  contacts: SaleClientContactRequest[];
  // 客户重要等级
  importantLevel?: number;
  // 用汽账户ids
  thermalAccountIds: Array<number>;
  // 手动计算生效时间
  effectiveTimeOfAverPrice?: string;
  beneficialBank?: string; // 开户行
  bankAccount?: string; // 账号（银行账号）
}

export function createSaleClient(request: SaleClientRequest) {
  return fetch<void>(
    `/api/v2/sale/client/add`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function updateSaleClient(id: Key, request: SaleClientRequest) {
  return fetch<void>(
    `/api/v2/sale/client/update`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...request }),
    },
    true
  );
}

export function deleteSaleClient(id: Key) {
  return fetch<void>(
    `/api/v2/sale/client/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface SaleClientInfo extends Omit<SaleClientRequest, 'electricityAccountIds' | 'contact'> {
  id: number;
  code: string;
  ouName: string;
  createByUserId: string;
  updateByUserId: string;
  updateSource: Operator;
  createTime: string;
  updateTime: string;
  deleteFlag: boolean;
  electricityAccounts: ElectricityAccountVo[];
  contacts: SaleClientContactInfo[];
  thermalAccounts: Array<ThermalResponse>;
  importantLevelName?: string;
  averagePriceAuto?: number; // 度电均价系统生成
  averagePriceManual?: number; // 手工确认度电均价
  isManual?: boolean; // 是否手工确认
  // 手工确认日期
  averagePriceStartDate?: string;
  averagePriceEndDate?: string;
  beneficialBank?: string;
  bankAccount?: string;
}

export function getSaleClient(id: Key) {
  return fetch<SaleClientInfo>(
    `/api/v2/sale/client/info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface SaleClientListRequest extends Partial<Pick<SaleClientInfo, 'code' | 'name' | 'ouId'>>, PageRequest {}

export function getSaleClientPage(request: SaleClientListRequest) {
  return fetch<PageResponse<SaleClientInfo>>(
    `/api/v2/sale/client/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function getSaleClientList() {
  return fetch<{ list: Array<SaleClientInfo> }>(
    `/api/v2/sale/client/list`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list);
}

export function getSaleClientListById(ouId: number) {
  return fetch<{ list: Array<SaleClientInfo> }>(
    `/api/v2/sale/client/list`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list);
}

export interface ElectricAccountDevice {
  deviceId: number;
  deviceCode: string;
  deviceName: string;
  deviceSn: string;
  deviceTags?: string[];
  electricityAccountId: number;
  electricityAccountNumber: string;
}

export interface ElectricAccountDevicePageRequest extends PageRequest {
  saleClientId: Key;
  electricityAccountId?: number;
  codeOrName?: string;
  deviceTypeIds?: number[];
  deviceTag?: string;
}

export function getSaleMeterDevicePage(request: ElectricAccountDevicePageRequest) {
  return fetch<PageResponse<ElectricAccountDevice>>(
    `/api/v2/sale/client/device/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function getSaleClientTagList(id: Key) {
  return fetch<{ list: SaleClientMeterTags[] }>(
    `/api/v2/sale/client/tag/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface SaleMeterDevices {
  deviceId: number;
  electricityAccountId: number;
}
export interface AddSaleMeterDeviceRequest {
  saleClientId: number;
  type: ElectricMeterTypeEnum;
  devices: SaleMeterDevices[];
  saleClientTagNames: string[] | undefined;
}

export function addSaleMeterDevice(request: AddSaleMeterDeviceRequest) {
  return fetch<void>(
    `/api/v2/sale-electricity/meter/add`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function deleteSaleMeterDevice(id: number) {
  return fetch<void>(
    `/api/v2/sale-electricity/meter/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function getSaleClientElectricityAccountList(id: Key) {
  return fetch<{ list: ElectricityAccountVo[] }>(
    `/api/v2/sale/client/electricityAccount/listById`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list);
}

export interface SaleMeterDevice extends ElectricAccountDevice {
  id: number;
  type: ElectricMeterTypeEnum;
  code: string;
  saleClientId: number;
  createByUserId: string;
  updateByUserId: string;
  saleClientAccountId: number;
  saleClientAccountName: string;
  meterTags?: string[];
}

export function getSaleMeterDeviceList(id: Key) {
  return fetch<{ list: Array<SaleMeterDevice> }>(
    `/api/v2/sale-electricity/meter/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 客户账户

export interface SaleClientAccountAddRequest {
  name: string;
  saleClientId: number;
  creditLimitType: CreditLimitType;
  creditLimitAmount: number;
  description?: string;
}

export function createSaleClientAccount(request: SaleClientAccountAddRequest) {
  return fetch<void>(
    `/api/v2/sale/client/account/add`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function updateSaleClientAccount(id: Key, request: Omit<SaleClientAccountAddRequest, 'saleClientId'>) {
  return fetch<void>(
    `/api/v2/sale/client/account/update`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...request }),
    },
    true
  );
}

export function freezeSaleClientAccount(id: Key) {
  return fetch<void>(
    `/api/v2/sale/client/account/freeze`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function restoreSaleClientAccount(id: Key) {
  return fetch<void>(
    `/api/v2/sale/client/account/restore`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface SaleClientAccountInfo extends SaleClientAccountAddRequest {
  estimateBalance: number;
  id: number;
  code: string;
  saleClientName: string;
  companyName: string;
  uscc: string;
  voucherNumber: number;
  ouName: string;
  status: SaleClientAccountStatus;
  updateTime: string;
  updateSource: Operator;
  updateByUsername: string;
  balance: number;
  // 可用凭证数
  usableVoucherNumber: number;
  // 可用凭证列表(余额>0)
  usableVouchers: Array<SaleVoucherVo>;
}

export function getSaleClientAccount(id: Key) {
  return fetch<SaleClientAccountInfo>(
    `/api/v2/sale/client/account/info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface SaleClientAccountPageRequest extends PageRequest {
  saleClientId?: number;
  status?: SaleClientAccountStatus;
  ouId?: number;
}

export function getSaleClientAccountPage(request: SaleClientAccountPageRequest) {
  return fetch<PageResponse<SaleClientAccountInfo>>(
    `/api/v2/sale/client/account/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

// 充值

export interface VoucherFileRequest {
  fileName: string;
  fileKey: string;
}
export interface SaleClientAccountRechargeRequest {
  type: TransactionType;
  specifiedDate: string;
  amount: number;
  // voucherName: string;
  description: string;
  files: VoucherFileRequest[];
}

export function accountRecharge(id: Key, request: SaleClientAccountRechargeRequest) {
  return fetch<void>(
    `/api/v2/sale/client/account/recharge`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...request }),
    },
    true
  );
}

export interface VoucherRefundRequest {
  saleVoucherId: number;
  amount: number;
}

export interface SaleClientAccountRefundRequest extends Omit<SaleClientAccountRechargeRequest, 'amount'> {
  vouchers: VoucherRefundRequest[];
}

export function accountRefund(id: Key, request: SaleClientAccountRefundRequest) {
  return fetch<void>(
    `/api/v2/sale/client/account/refund`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...request }),
    },
    true
  );
}

// 账户流水
export interface SaleClientAccountTransactionVo {
  id: number;
  saleClientAccountId: number;
  description: string;
  type: TransactionType;
  amount: number;
  updateTime: string;
  updateByUsername: string;
}

export interface SaleClientAccountTransactionPageRequest extends PageRequest {
  saleClientAccountId: Key;
  type?: TransactionType;
  username?: string;
  startTime?: string;
  endTime?: string;
}

export function getSaleClientAccountTransactionPage(request: SaleClientAccountTransactionPageRequest) {
  return fetch<PageResponse<SaleClientAccountTransactionVo>>(
    `/api/v2/sale/client/account/transaction/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

// 销售凭证

export interface SaleAccountVoucherListRequest {
  saleClientAccountId: Key;
  type?: TransactionType;
  softField?: number; // 默认0：按创建时间排序，1:按收款凭证排序
}

export interface SaleVoucherVo {
  id: number;
  name: string;
  saleClientAccountId: number;
  specifiedDate: string;
  description: string;
  type: TransactionType;
  createByUserId: string;
  updateByUserId: string;
  updateSource: Operator;
  createTime: string;
  updateTime: string;
  balance: number;
  amount: number;
  files: (VoucherFileRequest & { id: number })[];
}

export function getSaleAccountVoucherList(request: SaleAccountVoucherListRequest) {
  return fetch<{ list: Array<SaleVoucherVo> }>(
    `/api/v2/sale/voucher/list`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface SaleAccountVoucherPageRequest extends PageRequest {
  saleClientAccountId: Key;
}

export function getSaleAccountVoucherPage(request: SaleAccountVoucherPageRequest) {
  return fetch<PageResponse<SaleVoucherVo>>(
    `/api/v2/sale/voucher/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function getSaleAccountVoucher(id: Key) {
  return fetch<SaleVoucherVo>(
    `/api/v2/sale/voucher/info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface SaleVoucherTransactionPageRequest extends PageRequest {
  saleVoucherId: Key;
}

export interface SaleVoucherTransactionVo {
  id: number;
  saleVoucherId: number;
  amount: number;
  type: TransactionType;
  version: number;
  createByUserId: string;
  updateByUserId: string;
  updateSource: Operator;
  createTime: string;
  updateTime: string;
  description: string;
}

export function getSaleVoucherTransactionPage(request: SaleVoucherTransactionPageRequest) {
  return fetch<PageResponse<SaleVoucherTransactionVo>>(
    `/api/v2/sale/voucher/transaction/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface ImportantLevel {
  id: number;
  desc: string;
}

export function getImportantLevel() {
  return fetch<{ list: Array<ImportantLevel> }>(
    `/api/v2/sale/client/importantLevel`,
    {
      method: 'POST',
    },
    true
  );
}

export function getSaleClientByCode(code: string) {
  return fetch<SaleClientInfo>(
    `/api/v2/sale/client/getByCode`,
    {
      method: 'POST',
      body: JSON.stringify({ code }),
    },
    true
  );
}

export function getDeviceBySaleClientId(id: Key) {
  return fetch<{ list: Array<{ id: Key; name: string }> }>(
    `/api/v2/sale/client/getDeviceById`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list);
}

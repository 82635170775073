import {
  Button,
  EllipsisSpan,
  FormTitle,
  Paging,
  SubContent,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
  Table,
  Tabs,
  PopConfirm,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import dayjs from 'dayjs';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BindOrEditMonitorDeviceFormModal from '../BindOrEditMonitorDeviceFormModal';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import {
  apiV2SupervisoryAccountDetailPost,
  apiV2SupervisoryAccountDeviceBindPost,
  apiV2SupervisoryAccountDeviceDeletePost,
  apiV2SupervisoryAccountDeviceEditPost,
  apiV2SupervisoryAccountDevicePagePost,
} from '@maxtropy/device-customer-apis-v2';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AccessMode, AccessModeDisplay } from '@/pages/VideoMonitoring/type';

export enum MonitorDeviceManageType {
  PV = 2,
  BSA = 3,
  MICROGRIDS = 4,
  NONELECENERGYMED = 5,
  OTHERS = 6,
}
export const MonitorDeviceManageTypeDisplay = {
  [MonitorDeviceManageType.PV]: '光伏站',
  [MonitorDeviceManageType.BSA]: '储能站',
  [MonitorDeviceManageType.MICROGRIDS]: '微电网',
  [MonitorDeviceManageType.NONELECENERGYMED]: '非电能源介质',
  [MonitorDeviceManageType.OTHERS]: '其他',
};
export enum MonitorDeviceSortType {
  NORMAL = 0,
  ASCEND,
  DESCEND,
}

export interface BindDeviceFormSubmitProps {
  supervisoryDeviceName: string;
  supervisoryTarget: string;
  ouId?: number;
  supervisoryUrl: string;
  weighting: number;
}

const columns = (accessMode?: AccessMode) => [
  {
    title: '监控设备名称',
    dataIndex: 'supervisoryDeviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '监控目标',
    dataIndex: 'supervisoryTarget',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '权重',
    dataIndex: 'weighting',
    ellipsis: { showTitle: true },
    sorter: true,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '监控地址(URL)-预览地址',
    hidden: accessMode === AccessMode.HK,
    dataIndex: 'supervisoryUrl',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

const { TabPane } = Tabs;

const MonitorDeviceManage: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const { id } = useParams<{ id?: string }>();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const navigate = useNavigate();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [monitorDeviceId, setMonitorDeviceId] = useState<number>();
  const [sortParams, setSortParams] = useState<MonitorDeviceSortType>();
  const [update, updateFn] = useUpdate();
  const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false);

  const hasPVTabPermission = useHasPermission(PermissionsType.B_SURVEILLANCEACCOUNTPVTAB);
  const hasBsaTabPermission = useHasPermission(PermissionsType.B_SURVEILLANCEACCOUNTBSATAB);
  const hasDcTabPermission = useHasPermission(PermissionsType.B_SURVEILLANCEACCOUNTDCTAB);
  const hasPcPermission = useHasPermission(PermissionsType.B_SURVEILLANCEACCOUNTPCTAB);
  const hasOtherPermission = useHasPermission(PermissionsType.B_SURVEILLANCEACCOUNTOTHERTAB);

  const defaultTabKey = useMemo(() => {
    if (hasPVTabPermission) {
      return MonitorDeviceManageType.PV.toString();
    }
    if (hasBsaTabPermission) {
      return MonitorDeviceManageType.BSA.toString();
    }
    if (hasDcTabPermission) {
      return MonitorDeviceManageType.MICROGRIDS.toString();
    }
    if (hasPcPermission) {
      return MonitorDeviceManageType.NONELECENERGYMED.toString();
    }
    if (hasOtherPermission) {
      return MonitorDeviceManageType.OTHERS.toString();
    }
    return undefined;
  }, [hasPVTabPermission, hasBsaTabPermission, hasDcTabPermission, hasPcPermission, hasOtherPermission]);

  const [deviceTabActiveKey, setDeviceTabActiveKey] = useState<string>();

  useEffect(() => {
    setDeviceTabActiveKey(defaultTabKey);
  }, [defaultTabKey]);
  // 查询监控账户详情
  const { data: accountDetail } = useRequest(
    () => {
      return apiV2SupervisoryAccountDetailPost({
        id: Number(id),
      });
    },
    {
      ready: !!id,
      refreshDeps: [id],
    }
  );

  const { data: pageList, loading } = useRequest(
    async () => {
      const res = await apiV2SupervisoryAccountDevicePagePost({
        accountId: Number(id),
        supervisoryType: Number(deviceTabActiveKey),
        weightType: sortParams,
        page: pageOffset,
        size: pageSize,
      });
      setTotalCount(res.total!);
      return res.list;
    },
    {
      ready: !!id && !!deviceTabActiveKey,
      refreshDeps: [pageOffset, pageSize, deviceTabActiveKey, update, id, sortParams],
    }
  );

  const onCancel = () => {
    setEditModalOpen(false);
    setMonitorDeviceId(undefined);
  };

  const onSubmit = async (values: BindDeviceFormSubmitProps) => {
    setSubmitBtnLoading(true);
    if (monitorDeviceId) {
      await apiV2SupervisoryAccountDeviceEditPost({
        id: monitorDeviceId,
        ...values,
      });
      onCancel();
      setSubmitBtnLoading(false);
      setPageOffset(1);
      updateFn();
    } else {
      await apiV2SupervisoryAccountDeviceBindPost({
        accountId: Number(id),
        supervisoryType: Number(deviceTabActiveKey),
        ...values,
      });
      onCancel();
      setSubmitBtnLoading(false);
      updateFn();
    }
  };

  const onDelete = (id: number) => {
    apiV2SupervisoryAccountDeviceDeletePost({
      ids: [id],
    }).then(_ => {
      updateFn();
    });
  };

  const buildColumns = [
    ...columns(accountDetail?.accessMode),
    {
      title: '操作',
      render: (_: any, record: any) => {
        return (
          <Space size={10}>
            <PopConfirm
              title="是否删除该监控设备？"
              onConfirm={() => {
                onDelete(record.id);
              }}
            >
              <Button type="link">删除</Button>
            </PopConfirm>

            <Button
              type="link"
              onClick={() => {
                setEditModalOpen(true);
                setMonitorDeviceId(record.id);
              }}
            >
              编辑
            </Button>

            <Button type="link" onClick={() => navigate(`/monitor/video/list/detail/${record.id}`)}>
              查看
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
      <FormTitle title="管理监控设备" />

      <SubContent>
        <Row style={{ margin: '12px 0', padding: '6px 0' }}>
          <Col span={6}>
            <span className={styles.label}>账户名称：</span>
            <span className={styles.value}>{accountDetail?.name}</span>
          </Col>
          <Col span={6}>
            <span className={styles.label}>接入方式：</span>
            <span className={styles.value}>
              {accountDetail?.accessMode ? AccessModeDisplay[accountDetail?.accessMode as AccessMode] : '--'}
            </span>
          </Col>
          {accountDetail?.accessMode === AccessMode.YSY && (
            <Col span={6}>
              <span className={styles.label}>AppKey：</span>
              <span className={styles.value}>{accountDetail?.appKey}</span>
            </Col>
          )}
        </Row>
        <Tabs onChange={(v: string) => setDeviceTabActiveKey(v)} activeKey={deviceTabActiveKey}>
          {hasPVTabPermission && (
            <TabPane
              tab={MonitorDeviceManageTypeDisplay[MonitorDeviceManageType.PV]}
              key={MonitorDeviceManageType.PV}
            />
          )}
          {hasBsaTabPermission && (
            <TabPane
              tab={MonitorDeviceManageTypeDisplay[MonitorDeviceManageType.BSA]}
              key={MonitorDeviceManageType.BSA}
            />
          )}
          {hasDcTabPermission && (
            <TabPane
              tab={MonitorDeviceManageTypeDisplay[MonitorDeviceManageType.MICROGRIDS]}
              key={MonitorDeviceManageType.MICROGRIDS}
            />
          )}
          {hasPcPermission && (
            <TabPane
              tab={MonitorDeviceManageTypeDisplay[MonitorDeviceManageType.NONELECENERGYMED]}
              key={MonitorDeviceManageType.NONELECENERGYMED}
            />
          )}
          {hasOtherPermission && (
            <TabPane
              tab={MonitorDeviceManageTypeDisplay[MonitorDeviceManageType.OTHERS]}
              key={MonitorDeviceManageType.OTHERS}
            />
          )}
        </Tabs>
        {defaultTabKey && (
          <>
            <Space size={16} direction="horizontal" className={styles.bindBtnSty}>
              <Button
                type="primary"
                onClick={() => {
                  setEditModalOpen(true);
                  setMonitorDeviceId(undefined);
                }}
              >
                绑定
              </Button>
              <span className={styles.tableTipSty}>
                <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', marginRight: 5 }} />
                权重越小，在视频监控列表页的展示位置越靠前
              </span>
            </Space>
            <Table
              rowKey="id"
              sticky
              scroll={{ x: 1300 }}
              loading={loading}
              columns={buildColumns}
              dataSource={pageList ?? []}
              onChange={(_, __, sorter: any) => {
                setSortParams(
                  sorter.order
                    ? sorter.order === 'ascend'
                      ? MonitorDeviceSortType.ASCEND
                      : sorter.order === 'descend'
                      ? MonitorDeviceSortType.DESCEND
                      : MonitorDeviceSortType.NORMAL
                    : MonitorDeviceSortType.NORMAL
                );
                setPageOffset(1);
              }}
              pagination={false}
            />
            <Paging pagingInfo={pagingInfo} />
            <BindOrEditMonitorDeviceFormModal
              submitBtnLoading={submitBtnLoading}
              deviceTabActiveKey={deviceTabActiveKey!}
              monitorDeviceId={monitorDeviceId}
              visible={editModalOpen}
              onCancel={onCancel}
              onSubmit={onSubmit}
              accessMode={accountDetail?.accessMode}
            />
          </>
        )}
      </SubContent>
      <Space className="sticky-footer">
        <Button onClick={() => navigate(`/monitor/account/configList`)}>返回</Button>
      </Space>
    </Wrapper>
  );
};

export default MonitorDeviceManage;

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import CardBox from '../../../CardBox';
import styles from './index.module.scss';
import { Space, Spin } from 'antd';
import { DatePicker, Empty, Key, Tabs } from '@maxtropy/components';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import CardItem from './CardItem';

import { ShareDataContext } from '@/pages/ZYFDashboard';
import { useRequest } from 'ahooks';
import {
  apiV2EnergyCarbonConfigConfigInfoPost,
  apiV2LeanEnergyFlowAnalysisConfigDetailPost,
} from '@maxtropy/device-customer-apis-v2';
import { EnergyFlowChartResponse, getEnergyFlowChart, getEnergyMediumAggregate } from '@/api/plustekSankey';
import { isNil } from 'lodash-es';
import Flow from './Flow';

const OverViewOfEnergyFlow = () => {
  const { configId } = useContext(ShareDataContext);
  const [date, setDate] = useState<Dayjs>(dayjs()); // 时间选择
  const [tabActiveKey, setTabActiveKey] = useState<Key>();
  const [swiperIntance, setSwiperIntance] = useState<SwiperCore | undefined>();

  // 能流图相关
  const [flowChartLoading, setFlowChartLoading] = useState<boolean>(false);
  const [fromLevel, setFromLevel] = useState<number>();
  const [toLevel, setToLevel] = useState<number>();
  const [selectedMediumId, setSelectedMediumId] = useState<Key>('total'); // 默认选中综合能耗
  const [isMedium, setIsMedium] = useState<boolean>(false);
  const [physicalUnitName, setPhysicalUnitName] = useState<string>('kgce');
  const [originChartData, setOriginChartData] = useState<EnergyFlowChartResponse>({
    nodes: [],
    links: [],
  });
  const [energyFlowChartData, setEnergyFlowChartData] = useState<EnergyFlowChartResponse>({
    nodes: [],
    links: [],
  });

  const timerRef = useRef<any>(); // tab轮播

  useEffect(() => {
    if (!isNil(tabActiveKey)) {
      const energyUnitGroupId = configDetails?.areaList?.find(
        item => item.areaNumber?.toString() === tabActiveKey
      )?.energyUnitGroupId;
      if (energyUnitGroupId) {
        // 获取层级配置
        apiV2LeanEnergyFlowAnalysisConfigDetailPost({ energyGroupId: energyUnitGroupId }).then(res => {
          setFromLevel(res.fromLevel ?? 1);
          setToLevel(res.toLevel ?? Math.min(3, res.maxLevel ?? 1));
        });
      }
    }
  }, [tabActiveKey]);

  useEffect(() => {
    if (!isNil(tabActiveKey) && date && selectedMediumId) {
      const energyUnitGroupId = configDetails?.areaList?.find(
        item => item.areaNumber?.toString() === tabActiveKey
      )?.energyUnitGroupId;

      if (energyUnitGroupId) {
        setFlowChartLoading(true);
        // 获取能流图数据
        getEnergyFlowChart({
          fromTime: dayjs(date).startOf('month').format('YYYY-MM-DD'),
          toTime: dayjs(date).endOf('month').format('YYYY-MM-DD'),
          energyUnitGroupId,
          energyMediumId: selectedMediumId === 'total' ? undefined : selectedMediumId,
        })
          .then(res => {
            setOriginChartData({
              nodes: (res.nodes ?? [])
                .map(i => ({
                  ...i,
                  name: i.key,
                }))
                .filter(i => !isNil(i.value) && i.value !== 0),
              links: res.links.filter(i => !isNil(i.value) && i.value !== 0),
            });
          })
          .finally(() => {
            setFlowChartLoading(false);
          });
      }
    }
  }, [tabActiveKey, selectedMediumId, date]);

  useEffect(() => {
    if (fromLevel && toLevel) {
      setEnergyFlowChartData({
        nodes: originChartData.nodes.filter(i => i.depth + 1 >= fromLevel && i.depth + 1 <= toLevel),
        links: originChartData.links,
      });
    }
  }, [fromLevel, toLevel, originChartData]);

  useEffect(() => {
    if (selectedMediumId) {
      setIsMedium(selectedMediumId !== 'total');
    }
  }, [selectedMediumId]);

  // 查询详情
  const { data: configDetails } = useRequest(
    async () => {
      const res = await apiV2EnergyCarbonConfigConfigInfoPost({
        id: Number(configId),
      });
      return res;
    },
    {
      ready: !!configId,
      refreshDeps: [configId],
    }
  );

  // 默认选择第一个
  useEffect(() => {
    if (!configDetails) return;
    setTabActiveKey(configDetails?.areaList?.[0].areaNumber?.toString());
  }, [configDetails]);

  // 轮播图
  const { data: swipperList } = useRequest(
    async () => {
      const res = await getEnergyMediumAggregate({
        energyUnitGroupId: configDetails?.areaList?.find(i => i.areaNumber?.toString() === tabActiveKey)
          ?.energyUnitGroupId!,
        fromTime: dayjs(date).startOf('month').format('YYYY-MM-DD'),
        toTime: dayjs(date).endOf('month').format('YYYY-MM-DD'),
      });

      const list = (res.energyMediumData ?? []).map(i => ({
        id: i.energyMediumId,
        name: i.energyMediumName,
        physicalUnitName: i.physicalUnitName,
        energyConsumption: i.energyConsumption,
        momRatio: i.momRatio,
      }));
      if (list.length > 0) {
        list.unshift({
          id: 'total',
          name: '综合能耗',
          physicalUnitName: 'kgce',
          energyConsumption: res.energyConsumptionStandardCoal,
          momRatio: res.momRatio,
        });
      }
      // 默认选中综合
      setSelectedMediumId('total');
      return list;
    },
    {
      ready: !!configDetails && !isNil(tabActiveKey) && !!date,
      refreshDeps: [configDetails, tabActiveKey, date],
    }
  );

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
  };

  const onDateChange: DatePickerProps['onChange'] = date => {
    if (date) {
      setDate?.(date);
    }
  };

  const items = useMemo(() => {
    return (configDetails?.areaList ?? []).map(item => ({
      label: item.areaName!,
      key: item.areaNumber?.toString()!,
    }));
  }, [configDetails]);

  // tab轮播切换
  useEffect(() => {
    if (tabActiveKey) {
      clearTimeout(timerRef.current);
      const currentActiveIndex = configDetails?.areaList?.findIndex(i => i.areaNumber?.toString() === tabActiveKey);
      if ((currentActiveIndex ?? 0) + 1 === (configDetails?.areaList ?? []).length) {
        timerRef.current = setTimeout(() => {
          setTabActiveKey(configDetails?.areaList?.[0]?.areaNumber?.toString());
        }, 60000);
      } else {
        timerRef.current = setTimeout(() => {
          setTabActiveKey(configDetails?.areaList?.[(currentActiveIndex ?? 0) + 1]?.areaNumber?.toString());
        }, 60000);
      }
    }
  }, [tabActiveKey]);

  return (
    <CardBox title="能流概览" className={styles.cardBoxSty}>
      <Space className={styles.top_wrapper} size={2}>
        <Tabs
          animated={true}
          className={styles.tabsStyle}
          getPopupContainer={triggerNode => triggerNode}
          activeKey={tabActiveKey as string}
          onChange={v => {
            setTabActiveKey(v);
          }}
          items={items ?? []}
        />
        <DatePicker
          suffixIcon={<DownOutlined />}
          className={styles.date_picker}
          allowClear={false}
          disabledDate={disabledDate}
          onChange={onDateChange}
          picker="month"
          value={date}
          // getPopupContainer={() => outerRef}
          getPopupContainer={triggerNode => triggerNode}
        />
      </Space>
      {swipperList?.length ? (
        <>
          <div className={styles.swiperWrapper}>
            <div
              className={classNames(`swiper-button-prev`, styles.swiper_prev_btn)}
              onClick={() => swiperIntance?.slidePrev()}
            >
              <LeftOutlined />
            </div>
            <Swiper
              spaceBetween={8}
              slidesPerView={2}
              freeMode={true}
              observer={true}
              observeParents={true}
              onSwiper={swiper => setSwiperIntance(swiper)}
              navigation={{
                nextEl: '.swiper-button-prev',
                prevEl: '.swiper-button-next',
              }}
            >
              {(swipperList ?? []).map((item, index) => (
                <SwiperSlide key={index}>
                  <CardItem
                    cardInfo={item}
                    setIsMedium={setIsMedium}
                    setPhysicalUnitName={setPhysicalUnitName}
                    selectId={selectedMediumId}
                    setSelectId={setSelectedMediumId}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <div
              className={classNames(`swiper-button-next`, styles.swiper_next_btn)}
              onClick={() => swiperIntance?.slideNext()}
            >
              <RightOutlined />
            </div>
          </div>

          <Flow
            flowChartLoading={flowChartLoading}
            isMedium={isMedium}
            data={energyFlowChartData}
            physicalUnitName={physicalUnitName}
          />
        </>
      ) : (
        <Empty />
      )}
    </CardBox>
  );
};

export default OverViewOfEnergyFlow;

import { Form, InputNumber } from '@maxtropy/components';
import React, { useContext } from 'react';
import { ShareDataContext } from '../..';

const AIEnergy = () => {
  const { isEdit } = useContext(ShareDataContext);
  return (
    <>
      <Form.Item label="用电安全" name="electricitySafety" rules={[{ required: true, message: '请输入用电安全' }]}>
        <InputNumber disabled={!isEdit} style={{ width: '40%' }} min={0} max={100} precision={2} placeholder="请输入" />
      </Form.Item>
      <Form.Item label="效能" name="efficiency" rules={[{ required: true, message: '请输入效能' }]}>
        <InputNumber disabled={!isEdit} style={{ width: '40%' }} min={0} max={100} precision={2} placeholder="请输入" />
      </Form.Item>
      <Form.Item
        label="电压合格率"
        name="voltageQualificationRate"
        rules={[{ required: true, message: '请输入电压合格率' }]}
      >
        <InputNumber disabled={!isEdit} style={{ width: '40%' }} min={0} max={100} precision={2} placeholder="请输入" />
      </Form.Item>
      <Form.Item label="峰谷比" name="peakValleyRatio" rules={[{ required: true, message: '请输入峰谷比' }]}>
        <InputNumber disabled={!isEdit} style={{ width: '40%' }} min={0} max={100} precision={2} placeholder="请输入" />
      </Form.Item>
      <Form.Item label="负载率" name="loadRate" rules={[{ required: true, message: '请输入负载率' }]}>
        <InputNumber disabled={!isEdit} style={{ width: '40%' }} min={0} max={100} precision={2} placeholder="请输入" />
      </Form.Item>
    </>
  );
};

export default AIEnergy;

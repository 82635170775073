import React, { createContext, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import Header from './components/Header';
import { useRequest, useSize } from 'ahooks';
import { cancelFullscreen, goFullscreen, positionPlace, setFitScale } from './utils';
import CenterLeftBox from './components/CenterLeftBox';
import CenterRightBox from './components/CenterRightBox';
// @ts-ignore
import video from './imgs/video/video.mp4';
import position from './imgs/position.png';
import light from './imgs/light.png';
import bg_hover from './imgs/bg_hover.gif';
import CenterBottomBox from './components/CenterBottomBox';
import { apiV2EnergyCarbonConfigConfigIdListPost } from '@maxtropy/device-customer-apis-v2';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

export interface ShareDataProps {
  configId?: number | null;
}

export const ShareDataContext = createContext<ShareDataProps>({});

const ZYFDashboard = () => {
  const resizeRef = useRef<any>();
  const baseDivRef = useRef<HTMLDivElement>();
  const size = useSize(baseDivRef);
  const innerDivRef = useRef<HTMLDivElement>();
  const videoRef = useRef<HTMLVideoElement>(); // 视频区域
  const videoSize = useSize(videoRef);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false); // 是否全屏
  const [scaleRate, setScaleRate] = useState<number>(1);
  const [focusPosition, setFocusPosition] = useState<string>();

  // 查询有无配置id
  const { data: configId } = useRequest(async () => {
    const res = await apiV2EnergyCarbonConfigConfigIdListPost();
    return res.list?.[0] ?? null;
  });

  const handleResize = () => {
    if (size?.width && size?.width > 1920) {
      setScaleRate(size?.width / 1920);
      setFitScale(innerDivRef.current, size?.width, size?.height, isFullScreen);
    }
  };

  useEffect(() => {
    if (resizeRef.current) {
      window.removeEventListener('resize', handleResize);
    }
    resizeRef.current = window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [size?.width, isFullScreen]);

  const handleFullScreen = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreen);
    return () => document.removeEventListener('fullscreenchange', handleFullScreen);
  }, []);

  const fullBtn = () => {
    if (baseDivRef.current) {
      isFullScreen ? cancelFullscreen() : goFullscreen(baseDivRef.current);
    }
  };

  return (
    <div
      className={styles.outer_wrap}
      id="full_screen_box"
      style={{
        overflow: 'hidden',
      }}
      ref={ref => {
        if (ref) {
          baseDivRef.current = ref;
        }
      }}
    >
      {/* <img className={styles.bg} src={bg} alt="" /> */}
      <div className={styles.bg_wrapper} style={{ width: videoSize?.width, minHeight: videoSize?.height }}>
        <video
          ref={ref => {
            if (ref) {
              videoRef.current = ref;
            }
          }}
          autoPlay
          loop
          muted
          preload="true"
          playsInline
          className={styles.bg}
          src={video}
        />
        {positionPlace.map(item => (
          <div
            className={styles.item_annotation}
            style={{
              transform: `scale(${focusPosition === item.name ? scaleRate + 0.5 : scaleRate})`,
              bottom: `${item.bottom}%`,
              left: `${item.left}%`,
              zIndex: focusPosition === item.name ? 12 : 11,
            }}
          >
            <div
              className={styles.name_box}
              style={{
                backgroundImage: `${focusPosition === item.name ? `url(${bg_hover})` : 'unset'} `,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
              }}
            >
              {item.name}
            </div>
            <img className={styles.position_img} src={position} alt="" />
            <img className={styles.light_img} src={light} alt="" />
          </div>
        ))}

        <svg className={styles.highlight} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" style={{ stopColor: '#48D53D90' }} />
              <stop offset="100%" style={{ stopColor: '#FFD90090' }} />
            </linearGradient>

            <filter id="blur-effect">
              <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
              <feComponentTransfer>
                <feFuncA type="linear" slope="0.8" />
              </feComponentTransfer>
            </filter>
          </defs>

          <svg className={styles.highlight} xmlns="http://www.w3.org/2000/svg">
            <g id="大屏" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="10个建筑">
                <polygon
                  onMouseEnter={() => setFocusPosition('金益')}
                  onMouseLeave={() => setFocusPosition(undefined)}
                  className={cx('region', 'jinyi')}
                  points="1017 687.75 1017 696 1162.5 760.5 1162.5 768 1167 766.5 1191 771 1215.75 787.5 1215.75 789.75 1227.75 797.25 1227.75 804.75 1295.25 834.75 1318.5 824.25 1324.5 822 1326.75 823.5 1500 747 1500 720 1483.5 711 1457.25 699.75 1439.25 695.25 1433.25 693 1407.75 679.5 1383 670.5 1373.25 668.25 1370.25 666.75 1351.5 656.25 1329 647.25 1311.27976 642.813008 1308 641.25 1290.75 632.25 1270.5 623.25 1249.5 617.25 1222.5 606 1047 675.75"
                ></polygon>
                <polygon
                  onMouseEnter={() => setFocusPosition('16MW屋顶光伏')}
                  onMouseLeave={() => setFocusPosition(undefined)}
                  className={cx('region', 'wudingguangfu')}
                  points="933.75 723.75 933.75 747.75 1087.5 819.75 1105.5 812.25 1105.5 805.5 1112.25 802.5 1112.25 791.25 1162.5 769.5 1162.5 760.5 1017 696 1017 698.25 1008 694.5"
                ></polygon>
                <polygon
                  onMouseEnter={() => setFocusPosition('数字化能源平台')}
                  onMouseLeave={() => setFocusPosition(undefined)}
                  className={cx('region', 'shuzihua')}
                  points="783.75 636 777.75 639 777.75 642.75 770.25 639 759 644.25 759 646.5 760.5 646.5 760.5 648 759 648 759 666 760.5 666 760.5 674.25 801 693 805.5 691.5 819.75 698.25 819.75 700.5 824.25 702.75 825.75 702 843 711 903 738 908.25 740.25 912 741 933.75 732.75 933.75 723.75 980.25 705.75 1008 694.5 1017 698.25 1017 696 1017 687.75 1055.25 672 1055.25 659.25 949.5 614.25 926.25 603.75 904.5 594 788.25 639"
                ></polygon>
                <polygon
                  onMouseEnter={() => setFocusPosition('智慧能源微网')}
                  onMouseLeave={() => setFocusPosition(undefined)}
                  className={cx('region', 'zhihuinengyuan')}
                  points="834.75 524.25 834.75 549 866.25 562.5 866.25 566.25 908.960626 585.742098 912.75 583.5 927 579 930.75 579.75 935.25 577.5 935.25 572.25 941.25 570 945.75 572.25 945.75 574.5 967.5 566.25 973.5 568.5 990.75 559.5 987.75 558.75 987.75 552.75 996 550.5 996 544.5 988.5 540.75 988.5 534 960 522.75 951 525.75 943.5 522.75 943.5 508.5 927.75 499.5 912.75 495"
                ></polygon>
                <polygon
                  onMouseEnter={() => setFocusPosition('罗普斯金')}
                  onMouseLeave={() => setFocusPosition(undefined)}
                  className={cx('region', 'luopu')}
                  points="731.25 457.5 693.75 471 693.75 475.5 669 483 718.5 504.75 792.75 536.25 816 545.25 824.25 549 834.75 545.25 834.75 524.25 912.75 495 926.25 498.75 926.25 481.5 904.5 469.5 885 466.5 881.25 468 855 457.5 855 446.25 844.5 438.75 836.25 438.75 831 441 831 436.5 822 429 812.25 429"
                ></polygon>
                <polygon
                  onMouseEnter={() => setFocusPosition('新能源生产基地')}
                  onMouseLeave={() => setFocusPosition(undefined)}
                  className={cx('region', 'xinnengyuan')}
                  points="540.75 521.25 540.75 532.5 534.75 536.25 541.5 540 541.5 545.25 589.5 567 609.75 576 613.5 575.25 621 579 621 580.5 630 584.25 762.75 642.75 770.25 639 776.25 642 777.75 641.25 777.75 639 783.75 636 786 637.5 886.5 597.75 886.5 577.5 833.25 554.25 829.5 555.75 829.5 551.25 660.75 479.25 619.5 493.5"
                ></polygon>
                <polygon
                  onMouseEnter={() => setFocusPosition('被动房')}
                  onMouseLeave={() => setFocusPosition(undefined)}
                  className={cx('region', 'beidong')}
                  points="327 459.75 188.25 521.25 175.5 529.5 168.75 536.25 173.25 543.75 180 547.5 276 580.5 333 598.5 414 570.75 501 540"
                ></polygon>
                <polygon
                  onMouseEnter={() => setFocusPosition('铭恒')}
                  onMouseLeave={() => setFocusPosition(undefined)}
                  className={cx('region', 'mingheng')}
                  points="1413 652.5 1415.25 652.5 1415.25 654.75 1419 656.25 1430.25 651.75 1430.25 645.75 1536.75 601.5 1537.5 578.25 1408.5 529.5 1371 540 1369.5 537.75 1365 537 1361.25 537.75 1360.5 539.25 1360.5 544.5 1335 555 1284.75 578.25 1284.75 585 1413 636.75"
                ></polygon>
                <polygon
                  onMouseEnter={() => setFocusPosition('热能回收')}
                  onMouseLeave={() => setFocusPosition(undefined)}
                  className={cx('region', 'renenghuishou')}
                  points="1284.75 585 1269 597 1256.25 602.25 1256.25 611.25 1384.5 663 1413 652.5 1413 636.75"
                ></polygon>
                <polygon
                  onMouseEnter={() => setFocusPosition('光储充电棚')}
                  onMouseLeave={() => setFocusPosition(undefined)}
                  className={cx('region', 'guangchuchongdianpeng')}
                  points="488.25 556.5 396 589.5 396 594.75 472.5 630.75 490.5 624 584.25 670.5 594 667.5 603.75 672.75 636 660 636 645.75 663.75 634.5"
                ></polygon>
              </g>
            </g>
          </svg>
        </svg>
      </div>

      <div
        className={styles.inner}
        ref={ref => {
          if (ref) {
            innerDivRef.current = ref;
          }
        }}
      >
        <ShareDataContext.Provider value={{ configId }}>
          {/* 上方header区域 */}
          <div className={styles.inner_top}>
            <Header fullBtn={fullBtn} isFullScreen={isFullScreen} configId={configId} />
          </div>
          {/* 中间content区域 */}
          <div className={styles.inner_center}>
            <div className={styles.center_left_box}>
              {/* 左侧（用能趋势） */}
              <CenterLeftBox />
            </div>
            <div className={styles.center_center_box}>
              {/* 中间下面的计数 */}
              <CenterBottomBox />
            </div>

            <div className={styles.center_left_box}>
              {/* 右侧（绿色工厂） */}
              <CenterRightBox />
            </div>
          </div>
        </ShareDataContext.Provider>
      </div>
    </div>
  );
};

export default ZYFDashboard;

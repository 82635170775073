import React, { useContext, useMemo, useState } from 'react';
import CardBox from '../../../CardBox';
import styles from './index.module.scss';
import { Space, Spin } from 'antd';
import { DatePicker, Empty, Tooltip } from '@maxtropy/components';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import classNames from 'classnames';
import CardItem from './CardItem';
import { ShareDataContext } from '@/pages/ZYFDashboard';
import { useRequest } from 'ahooks';
import { apiV2EnergyCarbonOverviewTotalCostPost } from '@maxtropy/device-customer-apis-v2';
import { keepTwoOrLine } from '@/pages/ZYFDashboard/utils';
import CustomTooltip from '../../../CustomTooltip';
import { isNil } from 'lodash-es';

const EnergyConsStatistics = () => {
  const [date, setDate] = useState<Dayjs>(dayjs()); // 时间选择
  const [swiperIntance, setSwiperIntance] = useState<SwiperCore | undefined>();

  const { configId } = useContext(ShareDataContext);

  // 用能统计
  const { data: unitRateData, loading } = useRequest(
    async () => {
      const res = await apiV2EnergyCarbonOverviewTotalCostPost({
        id: Number(configId),
        fromTime: dayjs(date).startOf('year').format('YYYY-MM-DD HH:mm:ss'),
        toTime: dayjs(date).endOf('year').format('YYYY-MM-DD HH:mm:ss'),
      });
      return res;
    },
    {
      ready: !!configId && !!date,
      refreshDeps: [configId, date],
    }
  );

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
  };

  const onDateChange: DatePickerProps['onChange'] = date => {
    if (date) {
      setDate?.(date);
    }
  };

  const slidesPerView = useMemo(() => {
    const filteredList = unitRateData?.details ?? [];
    if (filteredList.length === 2) {
      return 2;
    } else if (filteredList.length >= 3) {
      return 3;
    }
    return 1;
  }, [unitRateData?.details]);

  return (
    <CardBox
      title="用能统计"
      className={styles.cardBoxSty}
      extraContent={
        <Space size={10}>
          <DatePicker
            className={styles.date_picker}
            suffixIcon={<DownOutlined />}
            allowClear={false}
            disabledDate={disabledDate}
            onChange={onDateChange}
            picker="year"
            value={date}
            getPopupContainer={triggerNode => triggerNode}
          />
        </Space>
      }
    >
      {loading ? (
        <Spin spinning={loading} />
      ) : (
        <div className={styles.card_wrapper}>
          <div className={styles.top_card}>
            <div className={styles.left_title}>
              <div className={styles.spin_bg}></div>
              <div className={styles.fixed_bg}></div>
              <div className={styles.label}>用能总量</div>
            </div>
            <div className={styles.right_value}>
              <CustomTooltip title={keepTwoOrLine(unitRateData?.totalValue)}>
                <div className={styles.value}>
                  {!isNil(unitRateData?.totalValue) ? keepTwoOrLine(unitRateData?.totalValue! / 1000) : '--'}
                </div>
              </CustomTooltip>
              <div className={styles.unit}>{'tce'}</div>
            </div>
          </div>
          <div className={styles.bottom_swipper}>
            {unitRateData?.details?.length ? (
              <>
                <div
                  className={classNames(`swiper-button-prev`, styles.swiper_prev_btn)}
                  onClick={() => swiperIntance?.slidePrev()}
                >
                  <LeftOutlined />
                </div>
                <Swiper
                  style={{ height: '100%' }}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  spaceBetween={8}
                  slidesPerView={slidesPerView}
                  freeMode={true}
                  observer={true}
                  observeParents={true}
                  onSwiper={swiper => setSwiperIntance(swiper)}
                  navigation={{
                    nextEl: '.swiper-button-prev',
                    prevEl: '.swiper-button-next',
                  }}
                >
                  {(unitRateData?.details ?? []).map((item, index) => (
                    <SwiperSlide key={index}>
                      <CardItem cardInfo={item} />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div
                  className={classNames(`swiper-button-next`, styles.swiper_next_btn)}
                  onClick={() => swiperIntance?.slideNext()}
                >
                  <RightOutlined />
                </div>
              </>
            ) : (
              <Empty style={{ margin: 'auto' }} />
            )}
          </div>
        </div>
      )}
    </CardBox>
  );
};

export default EnergyConsStatistics;

import {
  Button,
  useUpdate,
  Select,
  Wrapper,
  Steps,
  useBreadcrumbRoutes,
  InputNumber,
  DatePicker,
  EllipsisSpan,
  Modal,
  Table,
  Tooltip,
  ShowInput,
} from '@maxtropy/components';
import { Form, Col, Row, Space, AutoComplete } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import type { ColumnsType } from 'antd/es/table';
import { isNil } from 'lodash-es';
import {
  StepsType,
  CalculateDataType,
  ProductSheetOtionsType,
  LCAOptionsType,
  FirstDataType,
  Procedure,
} from './const';
import {
  apiV2CarbonFootprintCalculateAddPost,
  apiV2CarbonFootprintCalculateDataDetailPost,
  apiV2CarbonFootprintCalculateDetailPost,
  apiV2CarbonFootprintCalculateUpdatePost,
  apiV2CarbonFootprintLcaListPost,
  apiV2ProductSheetListPost,
  V2ProductSheetListPostResponse,
  V2CarbonFootprintCalculateDetailPostResponse,
  V2CarbonFootprintCalculateDataDetailPostResponse,
  apiV2CarbonFootprintCalculateWordGeneratePost,
  apiV2CarbonFootprintCalculateAutoFillNumberAndTimePost,
} from '@maxtropy/device-customer-apis-v2';
import LCAView from '../LCAView';
import { isEmpty } from 'lodash-es';
import FillingModal from '../FillingModal';
import debounce from 'lodash/debounce';
import CalculateTable from '../CalculateTable';
import EnergtFillingModal from '../EnergyFillingModal';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;
const steps = [
  {
    title: '基本信息',
  },
  {
    title: '数据填报',
  },
  {
    title: '产品碳足迹',
  },
];
const items = steps.map(item => ({ key: item.title, title: item.title }));

export type BomItem = V2CarbonFootprintCalculateDataDetailPostResponse;

export const formatTreeData = (list: any) => {
  return list.map((item: any) => {
    const { children, ...rest } = item;
    return {
      ...rest,
      children: isEmpty(children) ? undefined : formatTreeData(children!),
    };
  });
};

export const formatChildrenTreeData = (list: any[]): any[] => {
  return list.map(item => {
    const { children, ...rest } = item;
    return {
      ...rest,
      children: isEmpty(children) ? undefined : formatChildrenTreeData(children!),
    };
  });
};

const AddFilling: React.FC = () => {
  const [form] = Form.useForm();
  const [update, updateFn] = useUpdate();
  const navigator = useNavigate();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const urlSearchParams = new URLSearchParams(window.location.search);
  let id = urlSearchParams.get('id');
  const routes = [{ name: '碳足迹填报' }];

  const [current, setCurrent] = useState<number>(0);
  const [checkIconFlag, setCheckIconFlag] = useState<boolean>(false);
  const [fillingModalOpen, setFillingModalOpen] = useState<boolean>(false);
  const [energyFillingModalOpen, setEnergyFillingModalOpen] = useState<boolean>(false);

  const [calculateData, setCalculateData] = useState<CalculateDataType>(); //包含主产品tabledata
  const [calculateTableData, setCalculateTableData] = useState<CalculateDataType[]>([]); //不包含主产品tabledata

  const [carbonLcaOptions, setcarbonLcaOptions] = useState<LCAOptionsType[]>([]); //核算产品option
  const [firstStepData, setFirstStepData] = useState<FirstDataType>(); //第一步创建数据
  const [productSheetOptions, setProductSheetOtions] = useState<ProductSheetOtionsType[]>([]); //生产单号option
  const [filterSheetOptions, setfilterSheetOtions] = useState<ProductSheetOtionsType[]>([]); //过滤生产单号option
  const [listProductSheet, setListProductSheet] = useState<V2ProductSheetListPostResponse['list']>([]); //生产单耗原先数据
  const [lcaData, setLcaData] = useState<V2CarbonFootprintCalculateDetailPostResponse>();
  const [lcaOrigin, setLcaOrigin] = useState<V2CarbonFootprintCalculateDetailPostResponse[]>();
  const [firstStepUnit, setFirstStepUnit] = useState<string>('--');
  const [fillingData, setFillingData] = useState<any>(); //弹窗填报数据
  const [fillingId, setFillingId] = useState<number>(); //填报原料id
  const [prevId, setPrevId] = useState<number>(); //记录新建的id
  const [fillingFlag, setFillingFlag] = useState<boolean>(false);
  const [materialId, setMaterialId] = useState<number>();
  const [outputProductId, setOutputProductId] = useState<number>();

  useEffect(() => {
    apiV2CarbonFootprintLcaListPost({}).then(res => {
      setLcaOrigin(res?.list);
      const newOptions = res?.list?.map(item => {
        return {
          label: `${item.code}/${item.name}/${item.spec}`,
          value: item.id as number,
          outputProductId: item.outputProductId,
        };
      });
      setcarbonLcaOptions(newOptions ?? []);
    });
  }, []);

  useEffect(() => {
    if (Number(id) === 0) return;
    if (id && carbonLcaOptions) {
      apiV2CarbonFootprintCalculateDetailPost({
        id: Number(id),
      }).then(result => {
        setLcaData(result);
        setFirstStepUnit(result?.unitCode ?? '--');
        form.setFieldsValue({
          ...result,
          carbonLcaId: result.carbonLcaId,
          productionTime: [dayjs(result.productionStartTime), dayjs(result.productionEndTime)],
        });
        apiV2ProductSheetListPost({ code: result.productSheetCode }).then(res => {
          const newSheetOptions = res.list?.map(item => {
            return { label: item.code as string, value: item.code as string };
          });
          setProductSheetOtions(newSheetOptions ?? []);
          setfilterSheetOtions(newSheetOptions ?? []);
          setListProductSheet(res.list ?? []);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, carbonLcaOptions]);

  //填报后刷新数据
  useEffect(() => {
    if (current === 0) return;
    if (!id) {
      apiDataDetail(prevId as number, false);
    } else {
      apiDataDetail(Number(id), false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  //检索核算产品
  const changeCarbonOptions = (value: string) => {
    apiV2CarbonFootprintLcaListPost({ name: value }).then(res => {
      const newOptions = res.list?.map(item => {
        return {
          label: `${item.code}/${item.name}/${item.spec}`,
          value: item?.id,
        };
      });

      setcarbonLcaOptions(newOptions as LCAOptionsType[]);
    });
  };

  //匹配生产单icon
  const autoCheck = debounce(value => {
    const findData = productSheetOptions?.find(item => item.label === value);
    setCheckIconFlag(!isNil(findData));
    if (findData) {
      const targetObject = listProductSheet?.find(item => item.code === findData.label);
      const targetId = targetObject ? targetObject.id : undefined;
      apiV2CarbonFootprintCalculateAutoFillNumberAndTimePost({
        productSheetId: targetId,
        outputProductId,
      }).then(res => {
        form.setFieldsValue({
          productionTime: [
            res.productionStartTime ? dayjs(res.productionStartTime) : null,
            res.productionEndTime ? dayjs(res.productionEndTime) : null,
          ],
          quantity: res.quantity,
        });
      });
    }
  }, 500);

  const next = () => {
    if (current === 0) {
      form.validateFields().then((result: any) => {
        console.log(result);
        const targetObject = listProductSheet?.find(item => item.code === result.productSheetCode);
        const targetId = targetObject ? targetObject.id : undefined;
        const newStepData = {
          carbonLcaId: result.carbonLcaId,
          quantity: result.quantity,
          productionStartTime: result.productionTime[0],
          productionEndTime: result.productionTime[1],
          productSheetCode: result.productSheetCode,
          productSheetId: targetId,
        };
        setFirstStepData({ ...newStepData, unitCode: firstStepUnit });
        const params = {
          ...newStepData,
          productionStartTime: result?.productionTime[0].format('YYYY-MM-DD HH:mm:ss'),
          productionEndTime: result?.productionTime[1].format('YYYY-MM-DD HH:mm:ss'),
        };

        if (!id) {
          if (!prevId) {
            apiV2CarbonFootprintCalculateAddPost(params).then(res => {
              setPrevId(res?.id);
              apiV2CarbonFootprintCalculateDetailPost({
                id: res.id,
              }).then(result => {
                setLcaData(result);
              });
              apiDataDetail(Number(res.id), true);
            });
          } else {
            apiV2CarbonFootprintCalculateUpdatePost({ ...params, id: prevId }).then(res => {
              apiV2CarbonFootprintCalculateDetailPost({
                id: prevId,
              }).then(result => {
                setLcaData(result);
              });
              apiDataDetail(prevId as number, true);
            });
          }
        } else {
          apiV2CarbonFootprintCalculateUpdatePost({ ...params, id: Number(id) }).then(res => {
            apiDataDetail(Number(id), true);
          });
        }
      });
    } else if (current === 1) {
      const allFlagsAreOne = formatChildrenTreeData(calculateData?.children!).every(item => item.filledFlag === 1);
      if (allFlagsAreOne) {
        if (!id) {
          apiDataDetail(prevId as number, false);
          apiV2CarbonFootprintCalculateWordGeneratePost({ id: prevId as number });
        } else {
          apiDataDetail(Number(id), false);
          apiV2CarbonFootprintCalculateWordGeneratePost({ id: Number(id) });
        }
        setCurrent(current + 1);
      } else {
        Modal.warning({
          content: '请填报活动数据',
        });
      }
    }
  };

  const apiDataDetail = (id: number, nextFlag: boolean) => {
    apiV2CarbonFootprintCalculateDataDetailPost({
      id,
    }).then((result: any) => {
      const Array = [];
      Array.push(result);
      setCalculateData(result);
      setCalculateTableData(formatTreeData(Array));
      if (nextFlag) {
        setCurrent(current + 1);
      }
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const columnsCalculate: ColumnsType<Procedure> = [
    {
      title: '名称',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: '类型',
      dataIndex: 'type',
      ellipsis: true,
    },
    {
      title: '理论用量',
      dataIndex: 'theoreticalQuantity',
      ellipsis: true,
      render(value, record, index) {
        return (
          <>
            <EllipsisSpan value={`${Number(value).toFixed(2) ?? '--'}${record.unitCode ?? '--'}`}></EllipsisSpan>
          </>
        );
      },
    },
    {
      title: '实际用量',
      dataIndex: 'actualQuantity',
      ellipsis: true,
      render(value, record, index) {
        if (!record.isProcedure) {
          return (
            <>
              <EllipsisSpan value={`${Number(value).toFixed(2) ?? '--'}${record.unitCode ?? '--'}`} />
              {record.autoGatherFlag ? (
                <Tooltip placement="top" title="数据由数采设备自动采集">
                  <InfoCircleOutlined
                    style={{ color: 'var(--mx-warning-color)', marginLeft: '6px', fontSize: '16px' }}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </>
          );
        } else {
          return (
            <EllipsisSpan
              value={`${Number(record.theoreticalQuantity).toFixed(2) ?? '--'}${record.unitCode ?? '--'}`}
            />
          );
        }
      },
    },
    {
      title: '碳足迹',
      dataIndex: 'carbonFootPrint',
      ellipsis: true,
      render(value, record, index) {
        const mainProduct = record.productDtoList?.find(item => item.isMaster === 1);
        const mainProductPercent = mainProduct ? mainProduct.percent : 0;

        const secondaryProducts = record.productDtoList?.filter(item => item.isMaster !== 1);
        const secondaryProductsContent = secondaryProducts?.map(item => (
          <span key={item.name}>
            ，副产品{item.name}占比
            <span style={{ color: '#00adff' }}>{item.percent}%</span>
          </span>
        ));

        const tooltipContent = (
          <>
            主产品碳足迹占过程总碳足迹的
            <span style={{ color: '#00adff' }}>{mainProductPercent}%</span> {secondaryProductsContent}
          </>
        );

        return (
          <>
            <div>
              {`${isNil(value) ? '--' : Number(value).toFixed(2)}kgCO2`}
              {record.isProcedure ? (
                <Tooltip placement="top" title={tooltipContent}>
                  <InfoCircleOutlined
                    style={{ color: 'var(--mx-warning-color)', marginLeft: '6px', fontSize: '16px' }}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: '操作',
      render(value, record, index) {
        if (!record.isProcedure) {
          return (
            <>
              {record.filledFlag ? (
                <Button
                  type="link"
                  onClick={() => {
                    setFillingId(record.id);
                    setFillingData(record);
                    setFillingFlag(true);
                    setMaterialId(record.materialOrEnergyId);

                    form.setFieldsValue({
                      name: record.name,
                      theoreticalQuantity: `${record.theoreticalQuantity}${record.unitCode}`,
                      actualQuantity: record.actualQuantity,
                    });
                    if (record.type !== '能源' && record.isInput !== 0) {
                      setFillingModalOpen(true);
                    } else {
                      setEnergyFillingModalOpen(true);
                    }
                  }}
                >
                  修改
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    setFillingData(record);
                    setMaterialId(record.materialOrEnergyId);

                    form.setFieldsValue({
                      name: record.name,
                      theoreticalQuantity: `${isNil(record.theoreticalQuantity) ? '--' : record.theoreticalQuantity} ${
                        record.unitCode
                      }`,
                      actualQuantity: isNil(record.theoreticalQuantity) ? '--' : record.actualQuantity,
                    });
                    if (record.type !== '能源' && record.isInput !== 0) {
                      setFillingModalOpen(true);
                      setFillingId(record.id);
                      setFillingFlag(false);
                    } else {
                      setFillingId(record.id);
                      setEnergyFillingModalOpen(true);
                      setFillingFlag(true);
                    }
                  }}
                >
                  填报
                </Button>
              )}
            </>
          );
        }
      },
    },
  ];

  const totalToolitip = useMemo(() => {
    const mainProduct = calculateData?.productDtoList?.find(item => item.isMaster === 1);
    const mainProductPercent = mainProduct ? mainProduct.percent : 0;

    const secondaryProducts = calculateData?.productDtoList?.filter(item => item.isMaster !== 1);
    const secondaryProductsContent = secondaryProducts?.map(item => (
      <span key={item.name}>
        ，副产品{item.name}占比
        <span style={{ color: '#00adff' }}>{item.percent}%</span>
      </span>
    ));

    return (
      <>
        主产品碳足迹占过程总碳足迹的
        <span style={{ color: '#00adff' }}>{mainProductPercent}%</span> {secondaryProductsContent}
      </>
    );
  }, [calculateData]);

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.content}>
        <div className={styles.stepsStyle}>
          <Steps current={current} items={items} />
        </div>
        <Form
          form={form}
          className={styles.createModelForm}
          labelAlign="left"
          layout={current === StepsType.First ? 'horizontal' : 'vertical'}
        >
          {current === StepsType.First && (
            <div className={styles.firstStep}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="carbonLcaId"
                    label="核算产品"
                    rules={[{ message: '请选择', required: true }]}
                    labelCol={{ flex: '140px' }}
                  >
                    <Select
                      placeholder={'请输入产品编号/名称/规格型号检索'}
                      className={styles.sizeSelect}
                      options={carbonLcaOptions}
                      onSearch={changeCarbonOptions}
                      showSearch
                      onChange={(val, option) => {
                        setOutputProductId((option as LCAOptionsType).outputProductId || undefined);
                        const findData = lcaOrigin?.find(i => i.id === val);
                        setFirstStepUnit(findData?.unitCode ?? '--');
                        apiV2ProductSheetListPost({ code: findData?.code }).then(res => {
                          const newSheetOptions = res.list?.map(item => {
                            return { label: item.code as string, value: item.code as string };
                          });
                          setProductSheetOtions(newSheetOptions ?? []);
                          setfilterSheetOtions(newSheetOptions ?? []);
                          setListProductSheet(res.list ?? []);
                        });
                      }}
                      optionFilterProp="label"
                      disabled={id ? true : false}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Space style={{ position: 'relative' }}>
                    <Form.Item
                      name="productSheetCode"
                      label="生产单号"
                      labelCol={{ flex: '140px' }}
                      rules={[
                        { message: '请输入', required: true },
                        { max: 50, message: '最多输入50字' },
                      ]}
                    >
                      <AutoComplete
                        options={filterSheetOptions}
                        optionFilterProp="label"
                        disabled={id ? true : false}
                        onSearch={text => {
                          const filtered = productSheetOptions.filter(option =>
                            option.label.toLowerCase().includes(text.toLowerCase())
                          );
                          setfilterSheetOtions(filtered);
                        }}
                        onChange={value => {
                          autoCheck(value);
                        }}
                        placeholder="请输入"
                      />
                    </Form.Item>
                    {checkIconFlag && <CheckCircleFilled className={styles.checkIcon} />}
                  </Space>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="productionTime"
                    labelCol={{ flex: '140px' }}
                    label="生产时间"
                    rules={[{ message: '请选择生产时间', required: true }]}
                  >
                    <RangePicker showTime />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="quantity"
                    labelCol={{ flex: '140px' }}
                    label="生产数量"
                    style={{ marginBottom: 32 }}
                    rules={[
                      { message: '请选择生产数量', required: true },
                      {
                        validator: (_, value) => {
                          if (value <= 0) {
                            return Promise.reject('生产数量必须大于0');
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber addonAfter={firstStepUnit}></InputNumber>
                  </Form.Item>
                </Col>

                <Space size={'small'} style={{ paddingLeft: 140 }}>
                  <Button className={styles.nextButtonStyle} htmlType="submit" type="primary" onClick={() => next()}>
                    下一步
                  </Button>
                </Space>
              </Row>
            </div>
          )}
          {current === StepsType.Second && (
            <div className={styles.secondStep}>
              <div className={styles.productonTitle}>产品信息</div>
              <Row>
                <Col span={8}>
                  <Form.Item label="产品编码">
                    <ShowInput value={lcaData?.code} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="产品名称">
                    <ShowInput value={lcaData?.name} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="规格型号">
                    <ShowInput value={lcaData?.spec} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="生产单号">
                    <ShowInput value={firstStepData?.productSheetCode} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="生产数量">
                    <ShowInput value={`${firstStepData?.quantity}${lcaData?.unitCode ?? '--'}`} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="生产时间">
                    <ShowInput
                      value={`${firstStepData?.productionStartTime.format(
                        'YYYY-MM-DD  HH:mm:ss'
                      )}～${firstStepData?.productionEndTime.format('YYYY-MM-DD  HH:mm:ss')}`}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className={styles.activityContent}>
                <div className={styles.flexTitle}>
                  <div className={styles.activityTitle}>活动数据填报</div>
                  <div className={styles.totalFootFit}>
                    碳足迹汇总：
                    <span className={styles.blueFootFit}>
                      {!isNil(calculateData) ? Number(calculateData.carbonFootPrint).toFixed(2) : 0} kgCO2
                    </span>
                    {!isNil(calculateData?.productDtoList) &&
                      (calculateData?.productDtoList ?? [])?.filter(item => item.isMaster !== 1)?.length > 0 && (
                        <Tooltip placement="top" title={totalToolitip}>
                          <InfoCircleOutlined
                            style={{
                              color: 'var(--warning-color)',
                              marginLeft: '6px',
                              fontSize: '16px',
                              marginBottom: '14px',
                            }}
                          />
                        </Tooltip>
                      )}
                  </div>
                </div>
                <Table
                  rowKey={record => record.id}
                  columns={columnsCalculate}
                  dataSource={formatChildrenTreeData(calculateData?.children!)}
                  pagination={false}
                  indentSize={16}
                  defaultExpandAllRows={true}
                />
              </div>

              <Space className={styles.stickyShadowFooter} style={{ paddingLeft: 0 }} size={'small'}>
                <Button className={styles.prevButtonStyle} onClick={() => prev()}>
                  上一步
                </Button>
                <Button className={styles.nextButtonStyle} type="primary" onClick={() => next()}>
                  下一步
                </Button>
              </Space>
            </div>
          )}
          {current === StepsType.Third && (
            <div className={styles.thirdStep}>
              <LCAView
                lcaData={lcaData}
                firstStepData={firstStepData}
                calculateData={calculateData}
                id={!id ? prevId : isNil(Number(id)) ? undefined : Number(id)}
              ></LCAView>
              <CalculateTable
                firstStepData={firstStepData}
                calculateData={calculateData}
                calculateTableData={calculateTableData}
              ></CalculateTable>
              <Space size="small" className={styles.stickyShadowFooter} style={{ paddingLeft: 0 }}>
                <Button onClick={() => prev()}>上一步</Button>
                <Button
                  onClick={() => {
                    navigator(`/productCarbonFootPrint/basic/reporting`);
                  }}
                >
                  返回列表
                </Button>
              </Space>
            </div>
          )}
        </Form>
        {fillingModalOpen && (
          <FillingModal
            form={form}
            id={fillingId as number}
            setOpen={setFillingModalOpen}
            open={fillingModalOpen}
            updateFn={updateFn}
            fillingData={fillingData}
            fillingFlag={fillingFlag}
            materialOrEnergyId={materialId}
          ></FillingModal>
        )}
        {energyFillingModalOpen && (
          <EnergtFillingModal
            form={form}
            setOpen={setEnergyFillingModalOpen}
            open={energyFillingModalOpen}
            updateFn={updateFn}
            fillingData={fillingData}
            id={fillingId}
          ></EnergtFillingModal>
        )}
      </Wrapper>
    </>
  );
};
export default AddFilling;

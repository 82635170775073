import {
  Form,
  Input,
  Select,
  Button,
  Modal,
  MultiSelect,
  useBreadcrumbRoutes,
  Wrapper,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import ResizeObserver from 'rc-resize-observer';
import { CellView, Graph, Shape, Node } from '@antv/x6';
import { Stencil } from '@antv/x6-plugin-stencil';
import { register } from '@antv/x6-react-shape';
import closeIcon from '../../icons/close-circle-outlined-normal@2x.png';
import createProcessIcon from '../../icons/createProcess@2x.png';
import gragProcessIcon from '../../icons/gragProcess@2x.png';
import {
  apiV2ProcessFlowAddOrUpdatePost,
  apiV2ProcessFlowDetailPost,
  apiV2WorkCenterListByOuPost,
  apiV2WorkProcedureGetListByWorkCenterIdsPost,
  V2ProcessFlowDetailPostResponse,
  V2WorkCenterListByOuPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Export } from '@antv/x6-plugin-export';
import NodeComponent from './NodeComponent';
import { DefaultOptionType } from 'antd/es/select';

function base64ToBlob(base64Data: string, contentType: string = ''): Blob {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

function uploadBase64Data(base64Data: string): Promise<{ key: string }> {
  const blob: Blob = base64ToBlob(base64Data, 'image/png');

  const formData: FormData = new FormData();
  formData.append('file', blob, 'flowChart.png');

  return fetch('/api/file-center/upload', {
    method: 'POST',
    body: formData,
  }).then(response => response.json());
}

interface ICraftProcessCreate {
  isEdit?: boolean;
}

interface ProcessItem {
  name?: string;
  code?: string;
  id?: number;
  iconKey?: string;
}

export const calMethodOptions = [
  {
    label: '单工序平均法',
    value: 1,
  },
  {
    label: '总能耗平均法',
    value: 2,
  },
];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const ports = {
  groups: {
    top: {
      position: 'top',
      markup: [
        {
          tagName: 'rect',
          selector: 'rect',
        },
      ],
      attrs: {
        rect: {
          magnet: true,
          width: 8,
          height: 8,
          stroke: '#B57CFC',
          fill: '#B57CFC',
          strokeWidth: 2,
          x: -4,
          y: -4,
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
    right: {
      position: 'right',
      markup: [
        {
          tagName: 'rect',
          selector: 'rect',
        },
      ],
      attrs: {
        rect: {
          magnet: true,
          width: 8,
          height: 8,
          stroke: '#B57CFC',
          fill: '#B57CFC',
          strokeWidth: 2,
          x: -4,
          y: -4,
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
    bottom: {
      position: 'bottom',
      markup: [
        {
          tagName: 'rect',
          selector: 'rect',
        },
      ],
      attrs: {
        rect: {
          magnet: true,
          width: 8,
          height: 8,
          stroke: '#B57CFC',
          fill: '#B57CFC',
          strokeWidth: 2,
          x: -4,
          y: -4,
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
    left: {
      position: 'left',
      markup: [
        {
          tagName: 'rect',
          selector: 'rect',
        },
      ],
      attrs: {
        rect: {
          magnet: true,
          width: 8,
          height: 8,
          stroke: '#B57CFC',
          fill: '#B57CFC',
          strokeWidth: 2,
          x: -4,
          y: -4,
          style: {
            visibility: 'hidden',
          },
        },
      },
    },
  },
  items: [
    {
      group: 'top',
    },
    {
      group: 'right',
    },
    {
      group: 'bottom',
    },
    {
      group: 'left',
    },
  ],
};

// 注册节点
register({
  shape: 'custom-react-node',
  width: 180,
  height: 48,
  effect: ['data'],
  component: NodeComponent,
  ports: { ...ports },
});

Graph.registerEdgeTool('custom-remove', {
  inherit: 'button-remove',
  markup: [
    {
      tagName: 'image',
      selector: 'image',
      attrs: {
        'xlink:href': closeIcon,
        width: 20,
        height: 20,
        x: -10,
        y: -10,
      },
    },
  ],
});

type WorkCenterListItem = Exclude<V2WorkCenterListByOuPostResponse['list'], undefined>[number];

const CraftProcessCreate: FC<ICraftProcessCreate> = props => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { isEdit } = props;
  const { id } = useParams<{ id: string }>();
  const graph = useRef<Graph>();
  const refContainer = useRef<HTMLDivElement>();
  const [processList, setProcessList] = useState<ProcessItem[]>([]);
  const [nodeList, setNodeList] = useState<any>([]); // 画布上的节点
  const refStencil = useRef<HTMLDivElement>();
  const [loading, setLoading] = useState(false);
  const [stencilLoading, setStencilLoading] = useState(false);
  const [data, setData] = useState<V2ProcessFlowDetailPostResponse>();
  // 有权限的工作中心列表
  const [workCenterHasPermissionList, setWorkCenterHasPermissionList] = useState<WorkCenterListItem[]>([]);
  // 选中的工作中心列表
  const [selectedWorkCenterList, setSelectedWorkCenterList] = useState<WorkCenterListItem[]>([]);
  const workCenterId = Form.useWatch('workCenterId', form);
  // 已被选中的禁用的工作中心
  const [selectedDisWorkCenterIds, setSelectedDisWorkCenterIds] = useState<number[]>([]);
  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}工艺流程` }];
  }, [isEdit]);

  useEffect(() => {
    if (id) {
      apiV2ProcessFlowDetailPost({ id }).then(res => {
        setData(res);
        setSelectedWorkCenterList(res.workCenters ?? []);
      });
    }
  }, [id]);

  useEffect(() => {
    // 获取工作中心 有权限的
    apiV2WorkCenterListByOuPost({}).then(res => {
      if (res && res.list) {
        setWorkCenterHasPermissionList(res.list ?? []);
      }
    });
  }, []);

  //根据工作中心查询绑定的工序
  useEffect(() => {
    if (workCenterId && workCenterId.length > 0) {
      setStencilLoading(true);
      apiV2WorkProcedureGetListByWorkCenterIdsPost({ ids: workCenterId })
        .then(res => {
          setProcessList(res.list ?? []);
        })
        .finally(() => {
          setStencilLoading(false);
        });
    } else {
      // 重置工序列表
      setProcessList([]);
    }
  }, [workCenterId]);

  useEffect(() => {
    if (!processList.length) return;
    graph.current = new Graph({
      container: refContainer.current!,
      panning: true,
      mousewheel: true,
      connecting: {
        router: 'manhattan',
        connector: {
          name: 'normal',
          args: {
            radius: 8,
          },
        },
        anchor: 'center',
        connectionPoint: 'anchor',
        allowBlank: false,
        allowLoop: true,
        allowMulti: false,
        snap: {
          radius: 20,
        },

        createEdge() {
          return new Shape.Edge({
            attrs: {
              line: {
                stroke: '#B57CFC',
                strokeWidth: 2,
                strokeDasharray: 6,
                targetMarker: 'classic',
                class: styles.antLine,
              },
            },
            zIndex: 0,
          });
        },
        validateConnection({ sourceCell, targetCell }) {
          if (sourceCell?.id) {
            // 每个工序可以有多个source，但只能有一个target
            const targetEdges = graph.current?.getOutgoingEdges(sourceCell?.id);
            if (targetEdges?.length && targetEdges?.length > 1) {
              return false;
            }
          }
          return true;
        },
      },
      highlighting: {
        magnetAdsorbed: {
          name: 'stroke',
          args: {
            attrs: {
              fill: '#B57CFC',
              stroke: '#B57CFC',
            },
          },
        },
      },
      interacting: function (cellView: CellView) {
        return true;
      },
    });

    const stencil = new Stencil({
      title: '流程图',
      target: graph.current,
      stencilGraphWidth: 212,
      stencilGraphHeight: 0,
      stencilGraphPadding: 0,

      layoutOptions: {
        columns: 1,
        columnWidth: 190,
        rowHeight: 60,
      },
    });

    refStencil.current?.appendChild(stencil.container);
    const nodeList = processList.map(k => {
      return graph.current?.createNode({
        shape: 'custom-react-node',
        data: {
          processId: k.id,
          label: k.name,
          iconKey: k.iconKey,
        },
      });
    });

    stencil.load(nodeList as Node<Node.Properties>[]);

    // 回显
    if (data?.nodes) {
      const nodeJson = JSON.parse(data?.nodes);
      graph.current.fromJSON(nodeJson as any);
      const nodes = graph.current?.getNodes();
      nodes.forEach(node => {
        const { processId } = node.getData();
        const processItem = processList?.find(k => k.id === processId);
        if (processItem) {
          node.updateData({ processId: processItem.id, label: processItem.name, iconKey: processItem.iconKey });
        }
      });
      setNodeList(nodes);
    }

    // 节点mouseenter
    graph.current?.on('node:mouseenter', ({ node }) => {
      // 展示连接桩
      const container = refContainer.current!;
      const ports = container.querySelectorAll('.x6-port-body') as NodeListOf<SVGElement>;
      showPorts(ports, true);
      // 展示删除图标
      node.updateData({ showIcon: true });
    });

    // 节点mouseleave
    graph.current?.on('node:mouseleave', ({ node }) => {
      const container = refContainer.current!;
      // 隐藏连接桩
      const ports = container.querySelectorAll('.x6-port-body') as NodeListOf<SVGElement>;
      showPorts(ports, false);
      // 隐藏删除图标
      const { showPop } = node.getData();
      if (!showPop) {
        node.updateData({ showIcon: false });
      }
    });

    // 节点added
    graph.current?.on('node:added', ({ node }) => {
      const nodes = graph.current?.getNodes();
      setNodeList(nodes);
    });

    // 节点removed
    graph.current?.on('node:removed', ({ node }) => {
      const nodes = graph.current?.getNodes();
      setNodeList(nodes);
    });

    // 边added
    graph.current?.on('edge:connected', ({ edge }) => {
      updateNodeStyle();
    });

    // 边removed
    graph.current?.on('edge:removed', ({ edge }) => {
      updateNodeStyle();
    });

    // 边mouseenter
    graph.current.on('edge:mouseenter', ({ cell }) => {
      cell.addTools([
        {
          name: 'custom-remove',
          args: {
            distance: 0.5,
          },
        },
      ]);
    });

    // 边mouseleave
    graph.current?.on('edge:mouseleave', ({ cell }) => {
      if (cell.hasTool('custom-remove')) {
        cell.removeTool('custom-remove');
      }
    });
  }, [processList, data]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        code: data.code,
        name: data.name,
        calMethod: data.calMethod,
        workCenterId: (data.workCenters ?? []).map(k => k.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // 编辑时：有权限与已选中的并集，新建时：有权限
  const workCenterOptions = useMemo(() => {
    if (id) {
      const noPermission = (selectedWorkCenterList ?? [])
        .filter(item => !workCenterHasPermissionList.some(i => i.id === item.id))
        .map(item => ({
          label: item.name,
          value: item.id,
          disabled: true,
        }));
      setSelectedDisWorkCenterIds((noPermission ?? []).map(item => item.value as number));
      const hasPermission = workCenterHasPermissionList.map(item => ({
        label: item.name,
        value: item.id,
        disabled: false,
      }));
      return [...hasPermission, ...noPermission];
    }
    return (workCenterHasPermissionList ?? []).map(item => ({
      label: item.name,
      value: item.id,
    }));
  }, [id, workCenterHasPermissionList, selectedWorkCenterList]);

  const onNavigateToList = () => {
    navigate('/productionEnergy/craftProcess/management');
  };
  // 更新节点样式
  const updateNodeStyle = () => {
    const nodes = graph.current?.getNodes();
    nodes?.forEach(node => {
      const inEdges = graph.current?.getIncomingEdges(node);
      const outEdges = graph.current?.getOutgoingEdges(node);
      const first = !inEdges?.length && !!outEdges?.length;
      const last = !outEdges?.length && !!inEdges?.length;
      node.updateData({ first, last });
    });
  };

  // 有向图通过拓扑排序判断是否有环
  const existLoop = (edges: { source: string; target: string }[]) => {
    const nodes: string[] = [];
    const list: Record<string, string[]> = {}; // 邻接表
    const queue: string[] = []; // 入度为0的节点集合
    const indegree: Record<string, number> = {};
    edges.forEach(e => {
      const { source, target } = e;
      if (!nodes.includes(source)) {
        nodes.push(source);
      }
      if (!nodes.includes(target)) {
        nodes.push(target);
      }
      addEdge(source, target);
    });
    const V = nodes.length;

    nodes.forEach(node => {
      if (!indegree[node]) indegree[node] = 0;
      if (!list[node]) list[node] = [];
    });

    function addEdge(source: string, target: string) {
      if (!list[source]) list[source] = [];
      if (!indegree[target]) indegree[target] = 0;
      list[source].push(target);
      indegree[target] += 1;
    }
    function noLoop() {
      Object.keys(indegree).forEach(id => {
        if (indegree[id] === 0) {
          queue.push(id);
        }
      });
      let count = 0;
      while (queue.length) {
        ++count;
        const currentNode = queue.pop();
        const nodeTargets = list[currentNode!];
        for (let i = 0; i < nodeTargets.length; i++) {
          const target = nodeTargets[i];
          indegree[target] -= 1;
          if (indegree[target] === 0) {
            queue.push(target);
          }
        }
      }
      // false 没有输出全部顶点，有向图中有回路
      return !(count < V);
    }
    return !noLoop();
  };

  // 保存前检查流程图
  const checkMap = () => {
    const nodes = graph.current?.getNodes();
    // 检查是否存在工序
    if (!nodes?.length) {
      Modal.warning({ title: '请创建工艺流程图' });
      return false;
    }
    // 检查是否有未连接工序
    const existAlone = nodes?.some(node => {
      const inEdges = graph.current?.getIncomingEdges(node);
      const outEdges = graph.current?.getOutgoingEdges(node);
      if (!inEdges?.length && !outEdges?.length) {
        return true;
      }
    });
    if (existAlone) {
      Modal.warning({ title: '工序需要都用连接线连接起来' });
      return false;
    }
    // 检查时是否有多个终节点
    const endNodes = nodes?.filter(node => {
      const outEdges = graph.current?.getOutgoingEdges(node);
      if (!outEdges?.length) {
        return true;
      }
    });
    if (endNodes && endNodes?.length > 1) {
      Modal.warning({ title: '有多个最后一道工序，请调整为有唯一的最后一道工序' });
      return false;
    }
    // 检查是否有环
    const edges = graph.current?.getEdges();
    const edgeIdList = edges?.map(edge => {
      return {
        source: edge.getSourceCellId(),
        target: edge.getTargetCellId(),
      };
    });
    if (edgeIdList?.length && existLoop(edgeIdList!)) {
      Modal.warning({ title: '不支持录入环形的工艺流程' });
      return false;
    }
    return true;
  };

  // 导出流程图
  const onExport = async (): Promise<string | undefined> => {
    const width = refContainer.current?.style.width.replace('px', '');
    const height = refContainer.current?.style.height.replace('px', '');

    if (width && height) {
      const url = await new Promise<string | undefined>((resolve, reject) => {
        graph.current?.use(new Export()).toPNG(
          url => {
            resolve(url);
          },
          {
            padding: 20,
            width: Number(width),
            height: Number(height),
            quality: 1,
            serializeImages: true,
            stylesheet:
              '.x6-graph-svg{ background-image: linear-gradient(90deg, rgba(223, 223, 223, 0.15) 10%, rgba(0, 0, 0, 0) 10%), linear-gradient(rgba(223, 223, 223, 0.15) 10%, rgba(0, 0, 0, 0) 10%);background-size: 10px 10px;background-color: #F5F5F5;} .x6-graph-svg-viewport .x6-graph-svg-stage .x6-cell.x6-node p{color: #444444 !important;}',
          }
        );
      });
      return url;
    }
    return undefined;
  };

  const onSave = async () => {
    if (!checkMap()) return;

    const nodes = graph.current?.getNodes();
    const procedureInfos = nodes
      ?.map(node => {
        const { processId: selfId } = node?.getData();
        let targetId: number | undefined = undefined;

        // 获取到节点的出线边
        const outEdges = graph.current?.getOutgoingEdges(node);

        // 最多有一个出线边或没有出线边
        if (outEdges?.length) {
          targetId = outEdges?.[0]?.getTargetCell()?.getData().processId;
        }

        // 获取到节点的进线边
        const inEdges = graph.current?.getIncomingEdges(node);
        // 通过进线边获取到进线边的源节点
        if (inEdges?.length) {
          const sourceNodes = inEdges?.map(edge => edge.getSourceCell());
          return sourceNodes?.map(node => {
            const { processId: sourceId } = node?.getData();
            return { selfId, targetId, sourceId };
          });
        } else {
          // 没有进线的节点
          return [{ selfId, targetId, sourceId: undefined }];
        }
      })
      .flat();
    const graphJson = graph.current?.toJSON();

    setLoading(true);
    try {
      const dataUrl: string | undefined = await onExport();
      if (!dataUrl) {
        console.error('Data URL is undefined');
        return;
      }
      // 去掉 data URL 的前缀：data:image/png;base64,
      const base64DataWithoutPrefix = dataUrl.substring(dataUrl.indexOf(',') + 1);
      const file = await uploadBase64Data(base64DataWithoutPrefix);
      const res = await form.validateFields();
      await apiV2ProcessFlowAddOrUpdatePost({
        ...res,
        nodes: JSON.stringify(graphJson),
        id: data?.id ?? undefined,
        procedureInfos,
        flowChart: file.key,
      })
        .then(() => {
          onNavigateToList();
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error occurred during onSave:', error);
    }
  };

  // 控制连接桩显示/隐藏
  const showPorts = (ports: NodeListOf<SVGElement>, show: boolean) => {
    for (let i = 0, len = ports.length; i < len; i += 1) {
      ports[i].style.visibility = show ? 'visible' : 'hidden';
    }
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapperStyles}>
      <FormTitle title={isEdit ? '编辑工艺流程' : '新建工艺流程'}></FormTitle>
      <SubContent>
        <Form isOrigin layout="vertical" colon={true} form={form} {...formLayout}>
          <Row>
            <Col span={8}>
              <Form.Item
                label="工艺流程编码"
                name="code"
                tooltip={{
                  placement: 'topRight',
                  title: '编码在生产日计划被使用后不可被修改',
                  icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
                }}
                rules={[
                  { required: true },
                  { max: 32 },
                  {
                    validator: (rule, value, callback) => {
                      if (!value) return Promise.resolve();
                      let reg = /[\\\\/*?:"<>|\u4E00-\u9FFF]+/;
                      if (reg.test(value)) {
                        return Promise.reject(new Error('编码为字母，数字，符号的组合,  不包括\\ / * ? : "<> | 字符'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input disabled={isEdit && data?.isUse} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="工艺流程名称"
                name="name"
                rules={[
                  { required: true },
                  { max: 32 },
                  {
                    validator: (rule, value, callback) => {
                      if (!value) return Promise.resolve();
                      let reg = /[\\\\/*?:"<>|]+/;
                      if (reg.test(value)) {
                        return Promise.reject(
                          new Error('名称为汉字，字母，数字，符号的组合,  不包括\\ / * ? : "<> | 字符')
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="单耗计算方式"
                name="calMethod"
                tooltip={{
                  title:
                    '每个工序的产出物跟最终产出物是1:1的关系使用单工序平均法；只要有工序产出物和最终产出物不是1:1关系则使用总能耗平均法',
                  icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
                }}
                rules={[{ required: true }]}
              >
                <Select placeholder="请选择" options={calMethodOptions} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="工作中心" name="workCenterId" rules={[{ required: true }]}>
                <MultiSelect
                  placeholder="请选择"
                  options={workCenterOptions}
                  showSearch
                  onChange={value => {
                    // 是否全选
                    const isAllSel = workCenterOptions.length === value.length;
                    const ouIds = isEdit
                      ? selectedDisWorkCenterIds.concat(
                          workCenterOptions
                            .filter((item: DefaultOptionType) => !item.disabled)
                            .map(item => item.value as number)
                        )
                      : workCenterOptions.filter((item: DefaultOptionType) => !item.disabled).map(item => item.value);

                    console.log(ouIds);
                    if (isAllSel) {
                      form.setFieldsValue({
                        ouIds,
                      });
                    }
                    if (value.length === 0) {
                      form.setFieldsValue({
                        ouIds: isEdit ? selectedDisWorkCenterIds : undefined,
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24}>
            <Form.Item label="工艺流程图" required wrapperCol={{ span: 24 }}>
              <ResizeObserver
                onResize={({ width }) => {
                  graph.current?.resize(width - 228);
                }}
              >
                <div className={styles.container}>
                  <Spin spinning={stencilLoading}>
                    {processList.length ? (
                      <div className={styles.stencil} ref={d => (refStencil.current = d!)}></div>
                    ) : (
                      <div className={styles.noProcess}>
                        <img className={styles.img} src={createProcessIcon} alt="" />
                        <div className={styles.desc}>暂无可用工序，请去工序管理中创建工序</div>
                      </div>
                    )}
                  </Spin>
                  <div className={styles.graphBox}>
                    <div className={styles.graph} ref={d => (refContainer.current = d!)}></div>
                    {nodeList.length ? null : (
                      <div className={styles.noMap}>
                        <img className={styles.img} src={gragProcessIcon} alt="" />
                        <div className={styles.desc}>请从左侧拖动工序到画布上来创建工艺流程图</div>
                      </div>
                    )}
                  </div>
                </div>
              </ResizeObserver>
            </Form.Item>
          </Col>
        </Form>

        <Row>
          <Space size={10}>
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                onSave();
              }}
            >
              保存
            </Button>
            <Button onClick={() => onNavigateToList()}>取消</Button>
          </Space>
        </Row>
      </SubContent>
    </Wrapper>
  );
};

export default CraftProcessCreate;

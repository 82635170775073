import * as echarts from 'echarts';
import spin_bg from '../../../../imgs/spin.png';
import { V2EnergyCarbonOverviewAiEnergyPostResponse } from '@maxtropy/device-customer-apis-v2';

export const getChartOption = (data?: V2EnergyCarbonOverviewAiEnergyPostResponse) => {
  return {
    responsive: true, // 开启响应式设计
    radar: [
      {
        indicator: [
          { text: '用电安全', nameLocation: 'end', nameGap: 15, offset: [0, -10] },
          { text: '效能', nameLocation: 'end', nameGap: 5, offset: [10, 0] },
          { text: '峰谷比', nameLocation: 'end', nameGap: 5, offset: [0, -20] },
          { text: '负载率', nameLocation: 'end', nameGap: 5, offset: [0, -20] },
          { text: '电压合格率', nameLocation: 'end', nameGap: 0, offset: [-10, 0] },
        ],

        center: ['50%', '50%'],
        radius: 80,
        startAngle: 90,
        splitNumber: 5,
        splitArea: {
          areaStyle: {
            color: ['#4A90E240'],
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowBlur: 10,
          },
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(211, 253, 250, 0.3)',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(211, 253, 250, 0.3)',
          },
        },
        name: {
          textStyle: {
            rich: {
              bg: {
                backgroundColor: '#4A90E220', // 设置背景色
                padding: [3, 5], // 设置背景色的内边距 [上下, 左右]
                borderRadius: 3, // 设置圆角
                align: 'center', // 文本对齐方式
              },
            },
            fontSize: 11, // 字体大小
            color: '#fff', // 文本颜色
          },
          formatter: function (name: string) {
            return `{bg|${name}}`; // 使用 rich 定义的背景样式
          },
        },
      },
    ],
    series: [
      {
        type: 'radar',
        emphasis: {
          lineStyle: {
            width: 2,
          },
        },
        data: [
          {
            value: [
              data?.electricitySafety,
              data?.efficiency,
              data?.peakValleyRatio,
              data?.loadRate,
              data?.voltageQualificationRate,
            ],
            symbolSize: 1,
            lineStyle: {
              color: '#57FB8B', // 设置连接线的颜色
              width: 2, // 设置连接线的宽度
              type: 'solid', // 连接线类型，可以是 'solid', 'dashed', 或 'dotted'
            },
            label: {
              show: true,
              color: '#57FB8B', // 设置字体颜色
              fontSize: 11, // 设置字体大小
              // formatter: function (params: { value: any[] }) {
              //   // 自定义格式化
              //   if (!params.value || params.value.every(v => v === undefined || v === null || v === 0)) {
              //     return '暂无数据'; // 替换 undefined 为文案
              //   }
              //   return `数据: ${params.value.join(', ')}`; // 正常数据的格式
              // },
              // position: 'inside', // 设置数值显示位置为雷达图内
            },
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
                { offset: 0, color: '#4AFF7F20' }, // 中心颜色
                { offset: 0.8, color: '#4AFF7F90' }, // 中心颜色
                { offset: 1, color: '#16DD8E' }, // 外部颜色
              ]),
            },
          },
        ],
      },
    ],
  };
};

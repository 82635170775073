import {
  DeviceListRequest,
  getDeviceList,
  getOrganizationWithCodeWithCurrent,
  OrganizationResponse,
} from '@/api/device';
import { formatOptionData } from '@/shared/components/CascadingMultipleSelector/utils';
import { useQuery } from '@/utils/utils';
import {
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Key,
  Modal,
  Paging,
  Radio,
  Select,
  Table,
  TreeSelect,
  useAsync,
  usePaging,
} from '@maxtropy/components';
import { Cascader } from 'antd';
import { DefaultOptionType } from 'antd/es/cascader';
import { isNil } from 'lodash-es';
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { formatTreeData } from '../../../utils';
import { getDeviceTypeTree } from '@/api/deviceType';
import { useRequest } from 'ahooks';
import { getOuListOwned } from '@/api/ou';
import { ShareDataContext } from '../../..';

export interface IDeviceModal {
  deviceVisible: boolean;
  cabintRecord?: any;
  index?: number;
  onCancel: () => void;
  onOk: (data: any) => void;
}

type SearchParams = Omit<DeviceListRequest, 'page' | 'size'>;

const columns = [
  {
    title: '设备名称',
    dataIndex: 'name',
    width: 260,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const DeviceModal: FC<IDeviceModal> = props => {
  const { deviceVisible, cabintRecord, onCancel, onOk, index } = props;
  const { form } = useContext(ShareDataContext);

  const [organization, setOrganition] = useState<OrganizationResponse>();
  const [filterForm] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [selectRowsKeys, setSelectRowsKeys] = useState<any[]>([]);
  const [typeSelect, setTypeSelect] = useState<string>('elec');

  useEffect(() => {
    if (deviceVisible && !isNil(index) && form && cabintRecord) {
      const list = form?.getFieldValue(['workShopList', index, 'machineList']) ?? [];
      const deviceIds = list?.find((c: any) => c?.machineNumber === cabintRecord?.machineNumber)?.deviceIds;
      setSelectRowsKeys(deviceIds ?? []);
    }
  }, [deviceVisible, index, form, cabintRecord]);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getDeviceList({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return res.list ?? [];
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  // 组织列表list
  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setOrganition(res);
    });
  }, []);
  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  // ou列表List
  const { data: ouList = [] } = useRequest(async () => {
    const res = await getOuListOwned();
    if (res) {
      return res?.map(item => ({
        label: item.name,
        value: item.id,
      }));
    }
  });

  const onFinish = (v: SearchParams) => {
    const typeIds = typeSelect === 'elec' ? [5002, 8888, 5022] : typeSelect === 'gas' ? [5035, 5036] : [5024, 5087];
    setSearchParams({
      ...v,
      typeId: v.typeId ? [v.typeId as unknown as number] : typeIds,
    });
    setPageOffset(1);
  };

  const onReset = () => {
    const typeIds = typeSelect === 'elec' ? [5002, 8888, 5022] : typeSelect === 'gas' ? [5035, 5036] : [5024, 5087];
    setSearchParams({ typeId: typeIds });
    setPageOffset(1);
  };

  const typeOptions = useMemo(() => {
    if (!typeSelect) return [];
    if (typeSelect === 'elec') {
      return [
        {
          label: '智能电表',
          value: 5002,
        },
        {
          label: '极熵测试设备',
          value: 8888,
        },
        {
          label: '红豆高压采集电柜/变压器',
          value: 5022,
        },
      ];
    } else if (typeSelect === 'gas') {
      return [
        {
          label: '流量计',
          value: 5035,
        },
        {
          label: '多相流量计',
          value: 5036,
        },
        {
          label: '天然气仪表',
          value: 10000001,
        },
      ];
    } else {
      return [
        {
          label: '积算仪',
          value: 5024,
        },
        {
          label: '热能表',
          value: 5087,
        },
      ];
    }
  }, [typeSelect]);

  const filters = (
    <CustomFilter form={filterForm} colSpan={8} onFinish={onFinish} onReset={onReset}>
      <Form.Item name="codeOrName" label="编号/名称">
        <Input placeholder="请输入编号查询" />
      </Form.Item>
      <Form.Item name="typeId" label="所属类目">
        <Select options={typeOptions} placeholder="请选择" />
      </Form.Item>

      <Form.Item name="mcid" label="所属组织">
        <TreeSelect treeData={treeData} placeholder="请选择" />
      </Form.Item>

      <Form.Item name="ouId" label="运营单元">
        <Select options={ouList} placeholder="请选择" />
      </Form.Item>
    </CustomFilter>
  );

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (rowKeys: Key[], _: any[]) => {
      setSelectRowsKeys(rowKeys);
    },
    selectedRowKeys: selectRowsKeys,
  };

  // 选择button按钮，筛选设备
  useEffect(() => {
    if (!typeSelect) return;
    if (typeSelect === 'elec') {
      setSearchParams({
        typeId: [5002, 8888, 5022], // 智能电表
      });
    } else if (typeSelect === 'gas') {
      setSearchParams({
        typeId: [5035, 5036, 10000001], // 流量仪表
      });
    } else {
      setSearchParams({
        typeId: [5024, 5087], // 流量仪表
      });
    }
  }, [typeSelect]);

  return (
    <>
      <Modal
        open={deviceVisible}
        size="big"
        contentClassName="modal-form-content"
        title={'编辑设备'}
        onCancel={() => {
          setSelectRowsKeys([]);
          filterForm.resetFields();
          onCancel();
        }}
        onOk={async () => {
          onOk?.(selectRowsKeys);
          filterForm.resetFields();
        }}
        destroyOnClose
      >
        <Radio.Group
          style={{ marginBottom: 12 }}
          buttonStyle="solid"
          onChange={e => {
            setTypeSelect(e.target.value);
            filterForm.resetFields();
          }}
          value={typeSelect}
        >
          <Radio.Button value="elec">电能</Radio.Button>
          <Radio.Button value="gas">燃气</Radio.Button>
          <Radio.Button value="steam">蒸汽</Radio.Button>
        </Radio.Group>
        {filters}
        <Table
          loading={isLoading}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          selectedCount={(selectRowsKeys ?? []).length}
          showSelectedCount
          scroll={{ y: 300 }}
          rowKey="id"
          columns={columns}
          dataSource={data}
        />
        <Paging pagingInfo={pagingInfo} />
      </Modal>
    </>
  );
};

export default DeviceModal;

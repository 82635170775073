import React from 'react';
import styles from './index.module.scss';
import AIEnergyIndex from './components/AIEnergyIndex';
import GreenElecReCarbonEmissions from './components/GreenElecReCarbonEmissions';
import OverViewOfEnergyFlow from './components/OverViewOfEnergyFlow';
import light_line from '../../imgs/light_line.png';

const CenterLeftBox = () => {
  return (
    <div className={styles.left_wrapper}>
      <div className={styles.top_bg}>
        <span className={styles.top_bg_text}>绿色工厂</span>
      </div>
      <div className={styles.content_wrapper}>
        <div className={styles.content_top_box}>
          <AIEnergyIndex />
        </div>
        <div className={styles.content_center_box}>
          <GreenElecReCarbonEmissions />
        </div>
        <div className={styles.content_bottom_box}>
          <OverViewOfEnergyFlow />
        </div>
        <img className={styles.light_line} src={light_line} alt="" />
      </div>
    </div>
  );
};

export default CenterLeftBox;

import { Form, Tabs } from '@maxtropy/components';
import { TabsProps } from 'antd';
import React, { FC, useMemo, useState } from 'react';
import { tabTypeDisplay } from '../../const';
import styles from './index.module.scss';
import EachWorkshop from './EachWorkshop';

const UnitConsumptionSetting = () => {
  const [currentTab, setCurrentTab] = useState('0');

  const tabItems: TabsProps['items'] = useMemo(() => {
    return Object.entries(tabTypeDisplay).map(([key, value]) => {
      return {
        label: value,
        key: String(key),
        children: <EachWorkshop index={Number(key)} />,
      };
    });
  }, []);

  const onTabChange = (v: string) => {
    setCurrentTab(v);
  };

  return (
    <Form.List name="workShopList">
      {() => {
        return (
          <div className={styles.tabStyle}>
            <Tabs className={styles.tab_box} items={tabItems} activeKey={currentTab} onChange={onTabChange} />
          </div>
        );
      }}
    </Form.List>
  );
};

export default UnitConsumptionSetting;

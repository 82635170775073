import React, { Key, useCallback, useImperativeHandle, useState } from 'react';
import { CustomFilter, EllipsisSpan, Input, Paging, Table, usePaging } from '@maxtropy/components';
import { Form } from 'antd';
import { useQuery } from '../../../utils/utils';
import { Link } from 'react-router-dom';
import { DevicePageRequest, getDevicePage } from '../../../api/pages';
import { DeviceSelectOtions } from './index';

type SearchParams = Omit<DevicePageRequest, 'page' | 'size'>;

type FilterParams = Omit<SearchParams, 'deviceType'>;

export interface DeviceModalRef {
  selectedRowKeys: Array<Key> | undefined;
}

interface DeviceModalProps {
  deviceIds: Array<number> | undefined;
  options?: DeviceSelectOtions;
}

export const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'tags',
    ellipsis: { showTitle: true },
    render: (v: string[]) => {
      const tags = v ? v.join(', ') : '--';
      return <EllipsisSpan value={tags} />;
    },
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const DeviceModal: React.ForwardRefRenderFunction<DeviceModalRef, DeviceModalProps> = ({ deviceIds, options }, ref) => {
  const pagingInfo = usePaging();
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<Key> | undefined>(deviceIds);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const { isMgmt, deviceType, rootMcid, iotProtocol, driveType, objectModalType } = options ?? {};

  const onReset = () => {
    form.resetFields();
    form.submit();
    setPageOffset(1);
  };

  const onFinish = (val: FilterParams) => {
    setSearchParams({ ...val, deviceTypeId: deviceType?.slice(-1) });
    setPageOffset(1);
  };

  const onSelectChange = (rowKeys: Key[]) => {
    setSelectedRowKeys(rowKeys);
  };

  useImperativeHandle(ref, () => ({
    selectedRowKeys,
  }));

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getDevicePage({
          ...searchParams,
          deviceTypeId: deviceType?.[deviceType?.length - 1],
          tenantMcid: isMgmt ? rootMcid : undefined,
          iotProtocol,
          driveType,
          physicalModelId: objectModalType,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) {
            setTotalCount(res.total);
            return res.list;
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  return (
    <div>
      <CustomFilter<FilterParams>
        form={form}
        colSpan={8}
        onFinish={val => onFinish(val as FilterParams)}
        onReset={onReset}
      >
        <Form.Item name="codeOrName" label="编号/名称">
          <Input placeholder={'请输入编号或名称查询'} />
        </Form.Item>
        <Form.Item name="tag" label="标签">
          <Input placeholder="请输入" />
        </Form.Item>
      </CustomFilter>
      <div style={{ marginBottom: 10 }}>
        <span style={{ color: 'var(--mx-text-secondary-color)' }}>点击前往</span>
        <Link to={'/device/manage/device/create'} style={{ padding: '0 5px', cursor: 'pointer' }}>
          添加设备
        </Link>
      </div>
      <Table
        scroll={{ y: 300 }}
        rowKey="id"
        loading={isLoading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        selectedCount={selectedRowKeys?.length ?? 0}
        showSelectedCount
      >
        <Paging pagingInfo={pagingInfo} />
      </Table>
    </div>
  );
};

export default React.forwardRef(DeviceModal);

import { isNil } from 'lodash-es';
import React, { Key } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { formatCompositeNumWithUnit, formatMediaNumWithUnit } from '@/pages/ZYFDashboard/utils';

interface Props {
  cardInfo: any;
  selectId?: Key;
  setSelectId: (id: Key) => void;
  setPhysicalUnitName: (v: string) => void;
  setIsMedium: (v: boolean) => void;
}

export function formatMomRatio(num?: number | null) {
  if (isNil(num)) return <span>--</span>;
  return num > 0 ? (
    <span style={{ color: '#E64242' }}>+{num.toFixed(2)}%</span>
  ) : (
    <span style={{ color: '#52C41A' }}>{num.toFixed(2)}%</span>
  );
}

const CardItem: React.FC<Props> = ({ selectId, setIsMedium, cardInfo, setSelectId, setPhysicalUnitName }) => {
  return (
    <div
      className={classNames(styles.cardItem, selectId === cardInfo.id ? styles.cardItemClicked : null)}
      onClick={() => {
        setSelectId(cardInfo.id);
        setPhysicalUnitName(cardInfo.physicalUnitName);
        setIsMedium(cardInfo.id !== 'total');
      }}
    >
      <div className={styles.firstLine}>
        <p className={styles.firstLine_left}>{cardInfo.name}</p>
        <p className={styles.firstLine_right}>环比</p>
      </div>
      <div className={styles.secondLine}>
        <p className={styles.secondLine_left}>
          {isNil(cardInfo.energyConsumption)
            ? '--'
            : cardInfo.id === 'total'
            ? formatCompositeNumWithUnit(cardInfo.energyConsumption)
            : formatMediaNumWithUnit(cardInfo.energyConsumption, cardInfo.physicalUnitName)}
        </p>
        <p className={styles.secondLine_left}>{formatMomRatio(cardInfo.momRatio)}</p>
      </div>
    </div>
  );
};

export default CardItem;

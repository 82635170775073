import { allNull, keepTwoOrLine } from '../../utils';
import circleImg from '../../imgs/circle@2x.png';
import circleBgImg from '../../imgs/circlebg-big@2x.png';
import {
  V2EnergyUnitOverviewAggregatedKgcePostResponse,
  V2EnergyUnitOverviewAggregatedPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import { Key } from '@maxtropy/components';

export const getChartOption = (
  mediumId: Key,
  chartData?: V2EnergyUnitOverviewAggregatedKgcePostResponse | V2EnergyUnitOverviewAggregatedPostResponse
) => {
  const isAllNull = allNull((chartData?.childList ?? []).map(i => i.uploadValue));
  const totalValue = chartData?.aggregatedValue;

  const formatShowChartData = chartData?.childList?.slice(0, 6); // 取前五个展示，剩余用剩余展示
  const restChartData = chartData?.childList?.slice(6, chartData?.childList?.length);
  const restTotalValue = restChartData?.reduce((pre, cur) => (cur.uploadValue || 0) + pre, 0);
  const finalChartData =
    (chartData?.childList ?? []).length > 5
      ? [
          ...(formatShowChartData ?? []),
          {
            energyUnitName: '其他',
            uploadValue: allNull(restChartData?.map(i => i.uploadValue)) ? undefined : restTotalValue,
          },
        ]
      : [...(formatShowChartData ?? [])];
  return {
    // color: ['#FFCB47', '#16DD8E', '#2D8DFF', '#556583'],
    legend: {
      left: 360,
      top: '20%',
      orient: 'vertical',
      icon: 'circle',
      itemGap: 8,
      itemHeight: 7,
      itemWidth: 7,
      textStyle: {
        color: 'rgba(255,255,255,0.65)',
        rich: {
          name: {
            fontSize: 14,
            lineHeight: 20,
            width: 150,
          },
          percent: {
            fontSize: 14,
            width: 100,
          },
          value: {
            fontSize: 14,
            width: 120,
          },
        },
      },
      formatter: (name: string) => {
        const item = chartData?.childList?.find(item => item.energyUnitName === name);
        let areaName =
          (item?.energyUnitName ?? '').length > 10 ? item?.energyUnitName?.slice(0, 10) + '...' : item?.energyUnitName;
        const percent = totalValue ? (((item?.uploadValue ?? 0) / totalValue) * 100).toFixed(2) + '%' : '-';
        return `{name| ${areaName}}{percent| ${percent}}{value|${keepTwoOrLine(item?.uploadValue)}${
          mediumId === 'total' ? 'kgce' : (chartData as V2EnergyUnitOverviewAggregatedPostResponse)?.physicalUnitName
        }}`;
      },
    },
    title: {
      text: isAllNull ? '-' : !isNil(totalValue) ? totalValue.toFixed(2) : '-',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
        fontSize: 20,
        lineHeight: 22,
      },
      subtext: `总计用量(${
        mediumId === 'total' ? 'kgce' : (chartData as V2EnergyUnitOverviewAggregatedPostResponse)?.physicalUnitName
      })`,
      subtextStyle: {
        color: 'rgba(255,255,255,0.65)',
        fontSize: 14,
      },
      left: 208,
      top: 98,
      itemGap: 8,
      textAlign: 'center',
    },
    graphic: {
      elements: [
        {
          type: 'image',
          style: {
            image: circleImg,
            x: 156,
            y: 66,
            width: 112,
            height: 112,
          },
        },
        {
          type: 'image',
          style: {
            image: circleBgImg,
            x: 136,
            y: 46,
            width: 152,
            height: 152,
            opacity: isAllNull ? 1 : 0,
          },
        },
      ],
    },
    series: [
      {
        type: 'pie',
        name: `总计用量(${
          mediumId === 'total' ? 'kgce' : (chartData as V2EnergyUnitOverviewAggregatedPostResponse)?.physicalUnitName
        })`,
        percentPrecision: 5,
        minAngle: 2,
        padAngle: 1,
        radius: [58, 72],
        left: 150,
        top: 60,
        width: 124,
        height: 124,
        label: {
          show: false,
        },
        data: (finalChartData ?? []).map(item => ({
          name: item.energyUnitName,
          value: keepTwoOrLine(item.uploadValue),
        })),
        showEmptyCircle: false,
      },
      {
        showEmptyCircle: false,
        data: (finalChartData ?? []).map(item => ({
          name: item.energyUnitName,
          value: keepTwoOrLine(item.uploadValue),
        })),
        type: 'pie',
        percentPrecision: 5,
        padAngle: 1,
        minAngle: 2,
        left: 150,
        top: 60,
        name: `总计用量(${
          mediumId === 'total' ? 'kgce' : (chartData as V2EnergyUnitOverviewAggregatedPostResponse)?.physicalUnitName
        })`,
        radius: [66, 86],
        width: 124,
        height: 124,
        emphasis: {
          disabled: true,
        },
        label: {
          show: false,
        },
        itemStyle: {
          opacity: 0.2,
        },
      },
    ],
  };
};

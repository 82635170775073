import React, { useEffect, useState } from 'react';
import {
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  SubContent,
  Button,
  FormTitle,
  Form,
  ShowInput,
  Empty,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { EnergyType, OutputTypeDisplay, UnitTypeWithUnitList, OutputUnitType, getUnits } from '@/api/outputConfig';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  apiV2OutputProductDetailPost,
  V2OutputProductDetailPostResponse,
  apiV2OutputProductBaseLinePhysicalUnitPost,
  apiV2ProductionBaseCenterHierarchyPost,
  V2OutputProductCategoryListSpecByCategoryIdPostResponse,
  apiV2OutputProductCategoryListSpecByCategoryIdPost,
  V2OutputProductUpdatePostRequest,
} from '@maxtropy/device-customer-apis-v2';

import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import { useNavigate, useParams } from 'react-router-dom';
import { CHARACTERISTIC_FORMART, ConfigType, UnitItem } from './utils';
import styles from './index.module.scss';

const Detail: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm();
  // 所有单位
  const [units, setUnits] = useState<UnitTypeWithUnitList[]>([]);
  const [unitCodeAndUnitNameList, setUnitCodeAndUnitNameList] = useState<UnitItem[]>([]);

  const [detail, setDetail] = useState<V2OutputProductDetailPostResponse>();

  const [formFlag, setFormFlag] = useState<boolean>(false);
  // 获取所有能源介质, 不包含场景, 例如新水, 包含电能
  const [physicalUnitId, setPhysicalUnitId] = useState<any>([]);
  const [specList, setSpecList] = useState<V2OutputProductCategoryListSpecByCategoryIdPostResponse['list']>();
  const [unitTypeCodeName, setUnitTypeCodeName] = useState<string>();

  const outputProductLink = Form.useWatch('outputProductLink', form);
  const nationalEconomicIndustryId = Form.useWatch('nationalEconomicIndustryId', form);

  useEffect(() => {
    if (detail) {
      // 获取所有单位
      getUnits().then(res => {
        if (res) {
          setUnits(res.list);
          setUnitCodeAndUnitNameList([...res.list[0].outputProductUnitList, ...res.list[1].outputProductUnitList]);
          if (detail.unitCode && detail.unitTypeCode) {
            const unitCodeList = res.list
              .find(unit => unit.unitTypeCode === detail?.unitTypeCode)
              ?.outputProductUnitList?.find(i => i.unitCode === detail?.unitCode);
            if (unitCodeList) {
              setUnitTypeCodeName(
                detail?.unitTypeCode === OutputUnitType.PIECE
                  ? unitCodeList.unitName
                  : `${unitCodeList.unitCode} (${unitCodeList?.unitName})`
              );
            }
          }
        }
      });
    }
  }, [detail]);

  useEffect(() => {
    if (id) {
      // 获取产出物详情
      apiV2OutputProductDetailPost({ id: Number(id) }).then(res => {
        if (res) {
          res.baseLines?.forEach((item, index: number) => {
            apiV2OutputProductBaseLinePhysicalUnitPost({ id: item.energyMediumId as number }).then(result => {
              const physicalListMap: Record<string, any[]> = {};
              const unitListMap: Record<string, any[]> = {};

              const physicalOption = result?.list?.map((i: any) => ({
                label: i.basePhysicalQuantityName,
                value: i.basePhysicalQuantityId,
              }));
              if (physicalOption) {
                physicalListMap[item.id as number] = physicalOption;
              }
              const unitList = result?.list;
              if (unitList) {
                unitListMap[item.id as number] = unitList;
              }

              const baseLinesTemp = cloneDeep(res.baseLines);
              if (!isNil(baseLinesTemp) && !isNil(res.baseLines)) {
                setPhysicalUnitId(physicalUnitId);
                physicalUnitId.splice(index, 0, {
                  name: item.physicalUnitName,
                  value: item.physicalUnitId,
                });
              }
            });
          });
          const filteredInformation = res?.outputProductivityInformation?.filter(
            item => item.productionBaseCode !== null
          );
          apiV2ProductionBaseCenterHierarchyPost({}).then(() => {
            const detailNational = (filteredInformation ?? [])
              ?.map(item => `${item.workCode}-${item.workName}`)
              .join('，');
            form.setFieldsValue({
              ...res,
              specList: res.outputProductCategorySpecList,
              outputProductLink: (res.outputProductLink ?? []).map(i => ({
                ...i,
                constituentOutputId: `${i.code}/${i.name}`,
              })),
              outputProductivityInformation: filteredInformation,
              nationalEconomicIndustryId: detailNational ?? '--',
            });
          });
          setDetail(res);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // 设置参考单耗基线能源类型初始表格
  useEffect(() => {
    if (!formFlag) {
      const baseLines = form.getFieldValue('baseLines');
      const values = Object.values(EnergyType)
        .filter(value => !isNaN(Number(value)))
        .map((v, i) => ({
          energyTypeCode: v as EnergyType,
          configType: ConfigType.MANUALENTRY,
          baseLineValue: baseLines?.[i]?.baseLineValue,
          advancedValue: baseLines?.[i]?.advancedValue,
          id: `${Date.now()}-${Math.floor(Math.random() * 1000)}`,
        }));
      form.setFieldValue('baseLines', values);
      setFormFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detail?.categoryId) {
      apiV2OutputProductCategoryListSpecByCategoryIdPost({ id: detail.categoryId }).then(res => {
        setSpecList(res.list ?? []);
        const formSpecList: V2OutputProductUpdatePostRequest['specList'] = form.getFieldValue('specList') ?? [];
        const data =
          res.list?.map(item => ({
            specId: item.id,
            specEnumId: formSpecList?.find(spec => spec.specId === item.id)?.specEnumId,
            specValue: formSpecList?.find(spec => spec.specId === item.id)?.specValue,
          })) || [];
        form.setFieldValue('specList', data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  const columnsView = [
    {
      title: '能源类型',
      dataIndex: 'energyMediumName',
      ellipsis: { showTitle: true },
      render: (v: EnergyType, record: any, index: any) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              <Form.Item
                style={{ width: 110, marginBottom: 0, display: 'inline-block' }}
                name={['baseLines', index, 'energyMediumName']}
              >
                <ShowInput />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, display: 'inline-block', marginLeft: 15 }}
                name={['baseLines', index, 'physicalUnitId']}
              >
                {physicalUnitId[index]?.name ?? '--'}/
                {(detail?.unitTypeCode === OutputUnitType.PIECE
                  ? units
                      .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                      ?.outputProductUnitList?.find(e => e?.unitCode === detail?.unitCode)?.unitName
                  : detail?.unitCode) ?? '--'}
              </Form.Item>
            </div>
          </>
        );
      },
    },
    {
      title: '物理量',
      dataIndex: 'basePhysicalQuantityName',
      ellipsis: { showTitle: true },
      render: (v: number, record: any, index: any) => {
        return (
          <Form.Item style={{ marginBottom: 0 }} name={['baseLines', index, 'basePhysicalQuantityName']}>
            <ShowInput />
          </Form.Item>
        );
      },
    },
    {
      title: '基准值',
      dataIndex: 'baseLineValue',
      ellipsis: { showTitle: true },
      render: (text: string, record: any, index: number) => {
        return (
          <Form.Item noStyle name={['baseLines', index, 'baseLineValue']}>
            <ShowInput />
          </Form.Item>
        );
      },
    },
    {
      title: '先进值',
      dataIndex: 'advancedValue',
      ellipsis: { showTitle: true },
      render: (text: string, record: any, index: number) => {
        return (
          <Form.Item noStyle name={['baseLines', index, 'advancedValue']}>
            <ShowInput />
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.page_content_copy}>
      <Form form={form}>
        <div className={styles.editContent}>
          <FormTitle title="新增产出物" />
          <SubContent>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name="code"
                  label="产出物编码"
                  tooltip={{
                    title: '编码在生产单、产量录入或者物料表中被使用后不可被修改',
                    icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
                  }}
                >
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="name" label="产出物名称">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="categoryNames" label="产出物分类">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="type" label="类型">
                  <div>{OutputTypeDisplay[form.getFieldValue('type') as keyof typeof OutputTypeDisplay]}</div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="characteristic" label="产出物特性">
                  <div>
                    {CHARACTERISTIC_FORMART[form.getFieldValue('characteristic') as keyof typeof OutputTypeDisplay] ??
                      '--'}
                  </div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="spec" label="型号">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item labelCol={{ span: 5 }} label="单位" className={styles.unitFormItem}>
                  <Space size="middle" className={styles.unitFormItems}>
                    <Form.Item name="unitCode" noStyle>
                      <div>{unitTypeCodeName ?? '--'}</div>
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="unitOutputValue" label="单位产值">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="grossWeight" label="单位毛重">
                  <ShowInput addonAfter="千克" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="remark" label="备注">
                  <ShowInput />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <div className={styles.capacityTitle}>产能信息</div>
              </Col>
              <Col span={12}>
                <Form.Item label="生产基地" name="nationalEconomicIndustryId">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="processFlowName" label="工艺流程">
                  <ShowInput />
                </Form.Item>
              </Col>
            </Row>
            {!isEmpty(nationalEconomicIndustryId) && (
              <div className={styles.information}>
                <Form.List name="outputProductivityInformation">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => {
                        const outputProductivityInformation = form.getFieldValue('outputProductivityInformation');
                        return (
                          <Row gutter={10}>
                            <Col span={6}>
                              <div>
                                {!isNil(outputProductivityInformation[index])
                                  ? `${outputProductivityInformation[index].productionBaseCode}-${outputProductivityInformation[index].productionBaseName}`
                                  : '--'}
                              </div>
                            </Col>
                            <Col span={6}>
                              <div>
                                {!isNil(outputProductivityInformation[index])
                                  ? `${outputProductivityInformation[index].workCode}-${outputProductivityInformation[index].workName}`
                                  : '--'}
                              </div>
                            </Col>
                            <Col span={12}>
                              <Form.Item name={[field.name, 'capacity']} label={`标准产能`}>
                                <ShowInput
                                  addonAfter={`${
                                    unitCodeAndUnitNameList.find(u => u.unitCode === detail?.unitCode)?.unitName ?? '--'
                                  }/小时`}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      })}
                    </>
                  )}
                </Form.List>
              </div>
            )}
            {!isEmpty(specList) && (
              <Row>
                <Col span={24}>
                  <div className={styles.capacityTitle}>规格参数</div>
                </Col>
                {specList?.map((spec, index) => {
                  return (
                    <Col span={12} key={index}>
                      <Form.Item name={['specList', index, 'specValue']} label={`${spec.name}`}>
                        <ShowInput />
                      </Form.Item>
                    </Col>
                  );
                })}
              </Row>
            )}
            <Row gutter={10}>
              <Col span={24}>
                <Space style={{ marginBottom: 10 }}>
                  参考单耗基线
                  <span style={{ marginLeft: 8, color: 'var(--mx-text-secondary-color)' }}>
                    依据ISO 50006标准设置相关能源基线衡量能源绩效
                  </span>
                </Space>
                <Form.Item
                  labelAlign="left"
                  name="baseLines"
                  style={{ marginBottom: 0 }}
                  valuePropName="dataSource"
                  labelCol={{ span: 2.5 }}
                  wrapperCol={{ span: 21.5 }}
                >
                  <Table rowKey="energyTypeCode" columns={columnsView} scroll={{ y: 400 }} />
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
        </div>

        <SubContent title="物料表">
          {!detail?.outputProductLink || detail?.outputProductLink.length === 0 ? (
            <Empty />
          ) : (
            <Form.List name="outputProductLink">
              {fields => (
                <>
                  {fields.map((field, index) => {
                    return (
                      <Row key={field.key} gutter={24}>
                        <Col span={8}>
                          <Form.Item
                            name={[field.name, 'constituentOutputId']}
                            label="产出物编码"
                            labelCol={{ span: 6 }}
                          >
                            <ShowInput />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label="产出物名称" labelCol={{ span: 8 }} name={[field.name, 'name']}>
                            <ShowInput />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            name={[field.name, 'constituentOutputDosage']}
                            label="物料用量"
                            labelCol={{ span: 10 }}
                          >
                            <ShowInput />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item label="单位" labelCol={{ span: 12 }}>
                            <ShowInput
                              value={
                                outputProductLink && outputProductLink.at(index)
                                  ? unitCodeAndUnitNameList.find(
                                      u => u.unitCode === outputProductLink.at(index).unitCode
                                    )?.unitName
                                  : undefined
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Form.Item label="类型" labelCol={{ span: 10 }}>
                            <ShowInput value="半成品" style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              )}
            </Form.List>
          )}
        </SubContent>
        <Space className="sticky-footer" size={8}>
          <Button type="primary" onClick={() => navigate('/energy/output/config')}>
            返回
          </Button>
          <Button onClick={() => navigate('/energy/output/config')}>取消</Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default Detail;

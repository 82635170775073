import { useEffect, useMemo, useState } from 'react';
import { Form, Input, Modal, Radio, Select, message } from '@maxtropy/components';
import { Spin } from 'antd';

import {
  apiV2AirCompressorAddPost,
  apiV2AirCompressorEditPost,
  apiV2AirCompressorGetPost,
  apiV2GasPreparationStationDeviceFlowmeterListPost,
  V2GasPreparationStationDeviceFlowmeterListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { CircuitType } from '@/api/uet';
import { OperationType, OperationTypeDisplay } from '../utils';
import { ModalProp } from '..';
import { CircuitProps, getCircuitList } from '@/api/circuit';

const FormItem = Form.Item;

const AirCompressionStation = ({ onCancel, onOk, operationType, ouList, uetId, stationId }: ModalProp) => {
  const [circuitList, setCircuitList] = useState<CircuitProps[]>([]);
  const [flowmeterList, setFlowmeterList] = useState<V2GasPreparationStationDeviceFlowmeterListPostResponse['list']>(
    []
  );
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    // 获取回路数据 1:
    getCircuitList({ uetId, type: CircuitType.GRID_BILLING_CIRCUIT }).then(res => {
      setCircuitList(res.list ?? []);
    });
  }, [uetId]);

  useEffect(() => {
    // 获取流量计数据
    apiV2GasPreparationStationDeviceFlowmeterListPost({ uetId }).then(res => {
      setFlowmeterList(res.list ?? []);
    });
  }, [uetId]);

  useEffect(() => {
    if (stationId) {
      setLoading(true);

      // 获取空压站详情
      apiV2AirCompressorGetPost({ id: stationId })
        .then(res => {
          form.setFieldsValue({
            ouIds: { label: res.ouName, value: res.ouId },
            name: res.stationName,
            type: res.type,
            loopId: res.loopId,
            flowmeterId: res.flowmeterId,
            status: res.status,
            id: res.stationId,
          });
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationId]);

  const circuitOptions = useMemo(() => {
    if (circuitList.length !== 0) {
      return circuitList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [circuitList]);

  const flowmeterOptions = useMemo(() => {
    if (flowmeterList?.length !== 0) {
      return flowmeterList?.map(i => ({ label: i.name, value: i.id }));
    }
  }, [flowmeterList]);

  const handleForm = () => {
    form.validateFields().then(() => {
      const formData = form.getFieldsValue(true);
      const values = {
        ...formData,
        uetId,
        ouIds: [formData.ouIds.value],
      };

      setConfirmLoading(true);
      if (operationType === OperationType.ADD) {
        apiV2AirCompressorAddPost(values)
          .then(res => {
            message.success('新增成功');
            onOk();
          })
          .finally(() => setConfirmLoading(false));
      }
      if (operationType === OperationType.EDIT) {
        apiV2AirCompressorEditPost(values)
          .then(res => {
            message.success('修改成功');
            onOk();
          })
          .finally(() => setConfirmLoading(false));
      }
    });
  };

  return (
    <Modal
      title={`${OperationTypeDisplay[operationType]}空压站`}
      contentClassName="modal-form-content"
      open
      bodyScroll
      onCancel={onCancel}
      onOk={handleForm}
      confirmLoading={confirmLoading}
    >
      <Spin spinning={loading}>
        <Form form={form}>
          <FormItem label="运营单元" name="ouIds" rules={[{ required: true, message: '请选择运营单元' }]}>
            <Select placeholder="请选择" labelInValue={true} allowClear>
              {(ouList ?? []).map(i => (
                <Select.Option value={i.id} key={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            label="空压站名称"
            name="name"
            rules={[
              { required: true, message: '请输入空压站名称' },
              { min: 2, message: '最少输入2个字符' },
              { max: 20, message: '最多输入20个字符' },
            ]}
          >
            <Input placeholder="请输入空压站名称" allowClear />
          </FormItem>
          <FormItem label="空压站类型" name="type" rules={[{ required: true, message: '请选择空压站类型' }]}>
            <Radio.Group>
              <Radio value={0}>自营</Radio>
              <Radio value={1}>托管（EMC）</Radio>
            </Radio.Group>
          </FormItem>
          <FormItem label="计量回路" name="loopId">
            <Select placeholder="请选择" options={circuitOptions} showSearch allowClear optionFilterProp="label" />
          </FormItem>
          <FormItem label="排气口流量计" name="flowmeterId">
            <Select placeholder="请选择" options={flowmeterOptions} showSearch allowClear optionFilterProp="label" />
          </FormItem>
          <FormItem label="状态" name="status" initialValue={1}>
            <Radio.Group>
              <Radio value={1}>启用</Radio>
              <Radio value={0}>禁用</Radio>
            </Radio.Group>
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AirCompressionStation;

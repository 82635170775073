import { isNil } from 'lodash-es';

export const keepTwoNull = (data?: number): number | null => {
  if (!isNil(data)) {
    return Number(data.toFixed(2));
  } else {
    return null;
  }
};
export const keepPercentTwoNull = (data?: number): number | null => {
  if (!isNil(data)) {
    return Number((data * 100).toFixed(2));
  } else {
    return null;
  }
};
export const keepPercentOrLine = (data?: number): string => {
  if (!isNil(data)) {
    return (data * 100).toFixed(2);
  } else {
    return '--';
  }
};

export const keepTwoOrLine = (data?: number) => {
  if (!isNil(data)) {
    return Number(data.toFixed(2));
  }
  return '--';
};

export function allNull(arr?: (number | undefined)[]) {
  return arr?.every(item => item === null);
}

import React, { Key, useEffect, useMemo, useState } from 'react';
import { Divider, Spin } from 'antd';
import styles from './index.module.scss';
import { DataNode } from 'antd/lib/tree';
import overview from './overview.png';
import classNames from 'classnames';
import { EnergyUnitItem, UnitBasicProps } from '@/api/energyUnitDashboard';
import { EnergyUnitContext } from '..';
import { isNil } from 'lodash-es';
import { LockFilled } from '@ant-design/icons';
import { Empty, Input, Select, Tooltip, Tree } from '@maxtropy/components';
import { useSearchParams } from 'react-router-dom';
import { apiV2EnergyAnalysisListByOuPost, apiV2EnergyAnalysisTreePost } from '@maxtropy/device-customer-apis-v2';

const { Search } = Input;

interface OptionProps {
  label: string;
  value: number;
}

interface EnergyTreeProps {
  setNodePath?: (path: string) => void;
  defaultData: EnergyUnitItem[];
  setDefaultData: (val: EnergyUnitItem[]) => void;
}

const EnergyTree: React.FC<EnergyTreeProps> = ({ setNodePath, setDefaultData, defaultData }) => {
  const { unitId, setUnitId, groupId, setGroupId, showDetail, setShowDetail } = React.useContext(EnergyUnitContext);
  const [groupOptions, setGroupOptions] = useState<OptionProps[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [dataList, setDataList] = useState<{ key: React.Key; title: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const energyGroupId = urlSearchParams.get('energyGroupId') ?? undefined;
  const prevUnitId = urlSearchParams.get('unitId') ?? undefined;

  useEffect(() => {
    if (prevUnitId && selectedKeys.length === 0 && isNil(unitId)) {
      setShowDetail?.(true);
      setSelectedKeys([Number(prevUnitId)]);
      setUnitId?.(Number(prevUnitId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys, prevUnitId, unitId]);

  // 回显当前选择的用能单元
  useEffect(() => {
    if (!unitId) return;
    const path = getPathByNodeKey(defaultData, Number(unitId));
    setNodePath?.(path);
  }, [unitId]);

  // useEffect(()=)
  useEffect(() => {
    // 获取用能分析组
    apiV2EnergyAnalysisListByOuPost({}).then(res => {
      setGroupOptions((res.list ?? []).map(item => ({ label: item.name as string, value: item.id as number })));
    });
  }, []);

  useEffect(() => {
    if (!groupId) return;
    setLoading(true);
    apiV2EnergyAnalysisTreePost({ energyUnitGroupId: String(groupId) })
      .then(res => {
        setDefaultData((res.list ?? []) as EnergyUnitItem[]);
        setSearchValue('');
        generateList((res.list ?? []) as EnergyUnitItem[]);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {
    if (showDetail && !isNil(unitId)) {
      setSelectedKeys([unitId]);
      setExpandedKeys([...expandedKeys, unitId]);
    } else {
      setSelectedKeys([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId, showDetail]);

  useEffect(() => {
    if (energyGroupId) {
      setUnitId?.(undefined);
      setGroupId?.(Number(energyGroupId));
    } else if (groupOptions.length > 0) {
      setUnitId?.(undefined);
      setGroupId?.(groupOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupOptions, energyGroupId]);

  // 初始化展开的节点列表
  const initExpandedKeys = (data: DataNode[]) => {
    let keys: string[] = [];
    const loop = (nodeList: DataNode[], level: number = 0) => {
      for (const item of nodeList) {
        keys.push(String(item.key));
        if (level < 2 && item.children) {
          loop(item.children, level + 1);
        }
      }
    };
    loop(data);
    return keys;
  };

  // 生成节点映射表
  const generateList = (data: EnergyUnitItem[]) => {
    let tempDataList: { key: React.Key; title: string }[] = [];
    const loopData = (data: EnergyUnitItem[]) => {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const { energyUnitId, name } = node;
        tempDataList.push({ key: energyUnitId, title: name });
        if (node.children) {
          loopData(node.children);
        }
      }
    };
    loopData(data);
    setDataList(tempDataList);
  };

  // 获取父节点key
  const getParentKey = (key: React.Key, tree: EnergyUnitItem[]): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some(item => item.energyUnitId === key)) {
          parentKey = node.energyUnitId;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  };

  // 根据节点的Id获取所有父节点路径
  const getPathByNodeKey = (data: EnergyUnitItem[], nodeKey: number): string => {
    const loopNode = (data: EnergyUnitItem[], path: string[] = []): string[] => {
      for (const node of data) {
        const id = node.energyUnitId;
        path.push(node.name as string);
        if (id === nodeKey) return path;
        if (node.children) {
          const findChildren = loopNode(node.children, path);
          if (findChildren?.length) return findChildren;
        }
        path.pop();
      }
      return [];
    };
    const path = loopNode(data);
    return path.join('/');
  };

  // tree数据生成
  const treeData = useMemo(() => {
    const loop = (data: EnergyUnitItem[]): DataNode[] =>
      data
        .map(item => {
          const strTitle = item.name as string;
          const index = strTitle.indexOf(searchValue);
          const beforeStr = strTitle.substring(0, index);
          const afterStr = strTitle.slice(index + searchValue.length);
          const disableIcon = <LockFilled style={{ color: 'var(--mx-warning-color)', fontSize: 12, marginLeft: 4 }} />;

          let title = <span>{strTitle}</span>;

          if (index > -1) {
            if (item.disabled) {
              title = (
                <Tooltip title="无数据权限" key={item.energyUnitId}>
                  <span>
                    {beforeStr}
                    <span className={styles.highLight}>{searchValue}</span>
                    {afterStr}
                    {disableIcon}
                  </span>
                </Tooltip>
              );
            } else {
              title = (
                <span>
                  {beforeStr}
                  <span className={styles.highLight}>{searchValue}</span>
                  {afterStr}
                </span>
              );
            }
          } else {
            if (item.disabled) {
              title = (
                <Tooltip title="无数据权限" key={item.energyUnitId}>
                  <span>
                    {strTitle}
                    {disableIcon}
                  </span>
                </Tooltip>
              );
            } else {
              title = <span>{strTitle}</span>;
            }
          }

          if (item.children && item.children.length > 0) {
            const children = loop(item.children);
            return {
              title: index > -1 || children.length ? title : null,
              key: item.energyUnitId,
              disabled: item.disabled,
              children: children.length ? children : undefined,
            };
          }

          return index > -1
            ? {
                key: item.energyUnitId,
                disabled: item.disabled,
                title,
              }
            : {
                key: item.energyUnitId,
                disabled: item.disabled,
                title: null,
              };
        })
        .filter(item => !!item.title);
    return loop(defaultData);
  }, [searchValue, defaultData]);

  useEffect(() => {
    const defaulteExpandedKeys = initExpandedKeys(treeData);
    setExpandedKeys(defaulteExpandedKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultData]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      const newExpandedKeys = dataList
        .map(item => {
          if (item.title.indexOf(value) > -1) {
            return getParentKey(item.key, defaultData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);

      setExpandedKeys([...expandedKeys, ...(newExpandedKeys as React.Key[])]);
    }

    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys([...newExpandedKeys]);
    setAutoExpandParent(false);
  };

  return (
    <div className={styles.leftSider}>
      <div className={styles.filterArea}>
        <div className={styles.option}>用能分析组：</div>
        <Select
          placeholder="请选择"
          className={styles.selectBox}
          value={groupId}
          allowClear={false}
          options={groupOptions}
          onChange={val => {
            if (prevUnitId) {
              setUrlSearchParams('');
            }
            setShowDetail?.(false);
            setUnitId?.(undefined);
            setGroupId?.(val);
          }}
        ></Select>
      </div>
      <div className={styles.searchArea}>
        <Search placeholder="请输入用能单元名称" value={searchValue} allowClear onChange={onChange} />
      </div>
      <div className={styles.splitLine}>
        <Divider className={styles.divider} />
      </div>
      <div className={styles.treeArea}>
        <Spin spinning={loading}>
          {treeData && treeData.length > 0 ? (
            <>
              <div
                className={classNames(styles.rootNode, !showDetail && styles.selected)}
                onClick={() => {
                  setShowDetail?.(false);
                  setSelectedKeys([]);
                }}
              >
                <img src={overview} alt="" />
                <div>用能分析组总览</div>
              </div>
              <div className={styles.tree_box}>
                <Tree
                  className={styles.treeStyle}
                  showIcon
                  blockNode
                  treeData={treeData}
                  selectedKeys={selectedKeys}
                  onExpand={onExpand}
                  expandedKeys={expandedKeys}
                  autoExpandParent={autoExpandParent}
                  onSelect={(selectedKeys, info) => {
                    if (selectedKeys.length === 0) return;
                    const path = getPathByNodeKey(defaultData, Number(selectedKeys[0]));
                    setNodePath?.(path);
                    setSelectedKeys(selectedKeys);
                    setShowDetail?.(true);
                    setUnitId?.(Number(selectedKeys[0]));
                  }}
                />
              </div>
            </>
          ) : (
            <Empty style={{ paddingTop: 100 }} />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default EnergyTree;

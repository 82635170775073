// 产出物设定 api
import { Key, fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';

// 一个单位类型, 比如计量单位为一个对象, 包含计量单位下所有的单位
export interface UnitTypeWithUnitList {
  // 单位类型, 1 计量单位, 2 计件单位
  unitTypeCode: OutputUnitType;
  // 单位类型名称
  unitTypeName: string;
  // 单位类型下的所有单位
  outputProductUnitList: {
    // 单位 code
    unitCode: string;
    // 单位名
    unitName: string;
  }[];
}

// 获取所有单位, 返回大致为:
// [{ unitTypeCode: 1, unitTypeName: 计量单位, outputProductUnitList: [{ unitName: m, unitCode: abc }, {...}] }, {...}]
export function getUnits() {
  return fetch<{ list: UnitTypeWithUnitList[] }>('/api/v2/outputProduct/units', { method: 'POST' }, true);
}

// 产出物类型
export enum OutputType {
  // 在制品
  WIP = 1,
  // 制成品
  FINAL_GOOD = 2,
}

export const OutputTypeDisplay = {
  [OutputType.WIP]: '半成品',
  [OutputType.FINAL_GOOD]: '成品',
};

// 产出物单位类型
export enum OutputUnitType {
  // 计量单位
  QUANTITY = 1,
  // 计件单位
  PIECE = 2,
}

export const OutputUnitTypeDisplay = {
  [OutputUnitType.QUANTITY]: '计量单位',
  [OutputUnitType.PIECE]: '计件单位',
};

// 能源类型
export enum EnergyType {
  // 用电
  ELECTRICITY = 1,
  // 用水
  WATER = 2,
  // 用天然气
  NATURAL_GAS = 3,
  // 综合能耗
  COMPREHENSIVE = 4,
}

export const EnergyTypeDisplay = {
  [EnergyType.ELECTRICITY]: '用电',
  [EnergyType.WATER]: '用水',
  [EnergyType.NATURAL_GAS]: '用天然气',
  [EnergyType.COMPREHENSIVE]: '综合能耗',
};

// 能源类型单位展示
export const EnergyTypeUnitDisplay = {
  [EnergyType.ELECTRICITY]: 'kWh',
  [EnergyType.WATER]: 'm³',
  [EnergyType.NATURAL_GAS]: 'm³',
  [EnergyType.COMPREHENSIVE]: 'kgce',
};

export enum OutputState {
  ABLE = 1,
  DISABLE = 0,
}

export interface BaseLine {
  id: number | string;
  // 能源类型code（1 用电 2 用水 3 用天然气 4 综合能耗）
  energyTypeCode: EnergyType;
  // 基准值
  baseLineValue: number;
  // 先进值
  advancedValue: number;
  // 所属产出物id（一个产出物对应多个基线）
  outputProductId: number;
  // 所属租户
  tenantMcid: string;
  // 创建时间
  createTime: string;
  // 更新时间
  updateTime: string;
}

export interface OutputProductLinkItem {
  id: Key;
  constituentOutputId: Key; // 组成物id(半成品id)
  name?: string; // 产出物名称(半成品名称)
  code?: string; // 产出物编码(半成品code)
  constituentOutputDosage: number; // 组成物的用量(半成品用量)
  unitCode?: string; // 单位
  tenantMcid?: string; // 所属租户
  createTime?: string; // 创建时间
  updateTime?: string; // 更新时间
}

export interface OutputProductivityInfoItem {
  id?: Key;
  workCenterId: Key; // 工作中心id
  workCode?: string; // 工作中心code
  workName?: string; // 工作中心name
  capacity: number; // 标准产能(单位类型/时间(小时))
  unitCode?: string; // 单位
  tenantMcid?: string; // 所属租户
  createTime?: string; // 创建时间
  updateTime?: string; // 更新时间
}

// 列表页产出物字段
export interface OutputRecord {
  id: string | number;
  // 产出物名称
  name?: string;
  // 类型, 1 在制品, 2 制成品
  type?: OutputType;

  code?: string; // 编码

  isUseCode?: boolean; // 编码是否被使用

  // 规格
  spec?: string;
  // 单位类型, 1 计量单位, 2 计件单位
  unitTypeCode?: OutputUnitType;
  // 单位 code
  unitCode?: string;
  // 备注
  remark?: string;
  // 所属租户
  tenantMcid?: string;
  createTime?: string;
  updateTime?: string;
  categoryNames?: string[];
  // 是否被逻辑删除
  deleted?: boolean;
  // 是否录入产量
  hasOutputAmount?: boolean;
  state?: OutputState;
  // 基线
  baseLines?: BaseLine[];
  outputProductLink?: OutputProductLinkItem[];
  outputProductivityInformation?: OutputProductivityInfoItem[];
  nameOrCode?: string;
}

export interface OutputListRequest extends PageRequest {
  // 产出物名称
  name?: string;
  // 产出物类型
  type?: OutputType;
  code?: string;
}

// 获取产出物列表
export function getOutputList(query: OutputListRequest) {
  return fetch<PageResponse<OutputRecord>>(`/api/outputProduct/page?${qs.stringify(query, { indices: false })}`);
}

// 逻辑删除产出物
export function deleteOutput(outputId: string | number) {
  return fetch<boolean>(`/api/outputProduct/${outputId}`, {
    method: 'DELETE',
  });
}

// 新增产出物传递的数据
export type CreateOrUpdateOutputParams = Pick<
  OutputRecord,
  'name' | 'type' | 'spec' | 'unitTypeCode' | 'unitCode' | 'remark'
> & { baseLines: Array<Pick<BaseLine, 'id' | 'baseLineValue' | 'advancedValue'>> };

// 新增产出物
export function createOutput(params: CreateOrUpdateOutputParams) {
  return fetch<boolean>('/api/outputProduct', {
    method: 'POST',
    // 空值为 undefined 的时候传 null
    body: JSON.stringify(params, (k, v) => (v === undefined ? null : v)),
  });
}

// 产出物详情
export interface OutputDetail extends OutputRecord {}

// 获取产出物详情
export function getOutputDetail(id: string | number) {
  return fetch<OutputDetail>(`/api/v2/outputProduct/detail`, { method: 'POST', body: JSON.stringify({ id }) }, true);
}

// 更新产出物
export function updateOutput(outputId: string | number, params: CreateOrUpdateOutputParams) {
  return fetch<boolean>(`/api/outputProduct/${outputId}`, {
    method: 'PUT',
    // 空值为 undefined 的时候传 null
    body: JSON.stringify(params, (k, v) => (v === undefined ? null : v)),
  });
}

// 查询产出物
export function getOutputAllHalfList(id?: number) {
  return fetch<{ list: OutputRecord[] }>(
    `/api/v2/outputProduct/list`,
    {
      method: 'POST',
      body: JSON.stringify({
        type: 1,
        outputId: id,
      }),
    },
    true
  );
}

export interface WorkCenterRes {
  id: Key;
  code?: string; // 编码
  name?: string; // 名称
  state?: OutputState;
}

// 查询工作中心
export function getWorkCenterAllList() {
  return fetch<WorkCenterRes[]>(
    '/api/v2/work-center//all',
    {
      method: 'POST',
    },
    true
  );
}

// 产出物状态更改
export function updateStateById(id: Key, query: { state: OutputState }) {
  return fetch<{ flag: boolean }>(
    `/api/v2/outputProduct/putState`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...query }),
    },
    true
  );
}

import { Key, fetch } from '@maxtropy/components';
import qs from 'qs';

export interface EnergyMediumDtosItem {
  id?: Key;
  energyMediumId: Key; // 能源介质id
  energyMediumName?: string; // 能源介质名称
  dataPropertyId: Key; // 数据属性id
  dataPropertyName?: string; // 数据属性名称
}

export interface EnergyUnitDtosItem {
  id?: Key;
  energyUnitId?: Key; // 用能单元id
  energyUnitName?: string; //用能单元Name
}

export interface EnergyOverviewConfigBody {
  id?: Key; // 新增时不传入id，修改时传入id
  customerMcids?: string[]; //所属组织id
  customerMcidDtos?: { mcid: string; name: string }[];
  energyAnalysisUnitGroupId: Key; // 用能分析组id
  energyAnalysisUnitGroupName?: string; // 用能分析组名称
  picUrl?: string; // 图片地址
  energyFlowLevel1: number; // 能流层级1
  energyFlowLevel2: number; // 能流层级2
  energyFlowLevel3: number; // 能流层级3
  energyMediumDtos: EnergyMediumDtosItem[]; // 能源介质和数据属性list（修改时，全量删除）
  energyUnitDtos: EnergyUnitDtosItem[]; // 用能单元list （修改时，全量删除）
  yearPlan?: number; // 年计划
  januaryPlan?: number; // 1月计划
  februaryPlan?: number; // 2月计划
  marchPlan?: number; // 3月计划
  aprilPlan?: number; // 4月计划
  mayPlan?: number; // 5月计划
  junePlan?: number; // 6月计划
  julyPlan?: number; // 7月计划
  augustPlan?: number; // 8月计划
  septemberPlan?: number; // 9月计划
  octoberPlan?: number; // 10月计划
  novemberPlan?: number; // 11月计划
  decemberPlan?: number; // 12月计划
  title: string; // 标题
  ouIds: Key[]; // 用能单元ids
  planSource: number; // 计划来源
}

export interface EnergyOverviewConfigListSaveBody {
  add: EnergyOverviewConfigBody[];
  delete: Array<Key | undefined>;
  update: EnergyOverviewConfigBody[];
}

// 新增和修改 （id不传为新增，传id为修改）
export const saveEnergyOverviewConfig = (body: EnergyOverviewConfigListSaveBody) => {
  return fetch(
    '/api/v2/energyOverview/save',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

// 查询总用能配置明细
export const getEnergyOverviewConfigDetail = () => {
  return fetch<{
    list: EnergyOverviewConfigBody[];
  }>(
    '/api/v2/energyOverview/getDetailList',
    {
      method: 'POST',
    },
    true
  );
};

// 获取回路数
export const getCircuitSum = (id: Key) => {
  return fetch<{ id: number }>(
    `/api/v2/circuit/circuitSum`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.id);
};

export interface EdgeDeviceStatistics {
  // 采集设备数
  edgeDeviceCount?: number;
  // 采集点数
  edgeDevicePointCount?: number;
}

// 获取物联接入数据
export function getEdgeDeviceStatistics(id: number) {
  return fetch<EdgeDeviceStatistics>(
    `/api/v2/gateway/edge-device/data`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface GatewayStatistics {
  totalCount?: number;
  // 离线数
  offlineCount?: number;
  // 在线数
  onlineCount?: number;
}

// 获取网关数据
export function getGatewayStatistics(id: number) {
  return fetch<GatewayStatistics>(
    `/api/v2/gateway/data`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

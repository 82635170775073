import { fetch } from '@maxtropy/components';
import { ChannelApp } from '../types';

export function getStaffChannelApp() {
  return fetch<{ list: ChannelApp[] }>(
    `/api/v2/common/push/channel/staff`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list ?? []);
}

export function getOutsidersChannelApp() {
  return fetch<{ list: ChannelApp[] }>(
    `/api/v2/common/push/channel/outsiders`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list ?? []);
}

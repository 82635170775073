import { Radio, Tooltip } from '@maxtropy/components';
import React, { FC, useState } from 'react';
import { CenterTime, CenterTimeDisPlay } from '../../type';
import styles from './index.module.scss';
import { RadioChangeEvent, Spin } from 'antd';
import { useRequest } from 'ahooks';
import { apiV2StationSignboardStationDetailIndicatorOverviewValuePost } from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';

interface ICenterDataCompartion {
  stationType?: string;
  stationId?: string;
}

const CenterDataCompartion: FC<ICenterDataCompartion> = props => {
  const { stationType, stationId } = props;
  const [selectBtn, setSelectBtn] = useState<CenterTime>(CenterTime.Yesterday);
  // const [timeRange, setTimeRange] = useState<[Dayjs, Dayjs]>();

  // 请求接口
  const { data: indicatorValueList, loading } = useRequest(
    () => {
      return apiV2StationSignboardStationDetailIndicatorOverviewValuePost(
        {
          stationType: Number(stationType),
          stationId: Number(stationId),
          granularityType: selectBtn,
          // startTime: timeRange?.[0].valueOf(),
          // endTime: timeRange?.[1].valueOf(),
        }
        // { server: 'mock' }
      );
    },
    {
      ready: !!stationId && !!selectBtn && !!stationType,
      refreshDeps: [stationId, selectBtn, stationType],
    }
  );

  // 昨日与上月时间戳设定(废)
  // useEffect(() => {
  //   if (selectBtn) {
  //     if (selectBtn === CenterTime.Yesterday) {
  //       const prevDayStart = dayjs().subtract(1, 'day').startOf('day');
  //       const prevDayEnd = dayjs().subtract(1, 'day').endOf('day');
  //       setTimeRange([prevDayStart, prevDayEnd]);
  //     } else {
  //       const prevDayStart = dayjs().subtract(1, 'month').startOf('month');
  //       const prevDayEnd = dayjs().subtract(1, 'month').endOf('month');
  //       setTimeRange([prevDayStart, prevDayEnd]);
  //     }
  //   }
  // }, [selectBtn]);

  // 渲染颜色
  const renderColor = (indicatorName?: string, value?: number) => {
    if (indicatorName === '气电比' || indicatorName === '用电成本') {
      if (isNil(value) || value === 0) {
        return 'var(--mx-text-base-color)';
      } else if (value > 0) {
        return '#E64242';
      } else {
        return '#52C41A';
      }
    } else {
      return 'var(--mx-text-base-color)';
    }
  };

  return (
    <div className={styles.center_wrapper}>
      <div className={styles.center_wrapper_top}>
        <Radio.Group
          onChange={(e: RadioChangeEvent) => setSelectBtn(e.target.value)}
          value={selectBtn}
          buttonStyle="solid"
        >
          <Radio.Button value={CenterTime.Yesterday}>{CenterTimeDisPlay[CenterTime.Yesterday]}</Radio.Button>
          <Radio.Button value={CenterTime.LastMonth}>{CenterTimeDisPlay[CenterTime.LastMonth]}</Radio.Button>
        </Radio.Group>
      </div>
      <Spin spinning={loading}>
        <div className={styles.center_content_wrapper}>
          {(indicatorValueList?.list ?? []).map(item => (
            <div className={styles.itemcard_wrapper} key={item.indicatorName}>
              <div className={styles.itemcard_wrapper_top}>
                <Tooltip title={`${item?.indicatorName ?? '--'}(${item?.physicalUnitName ?? '--'})`}>
                  <span className={styles.itemcard_wrapper_top_label}>{`${item?.indicatorName ?? '--'}(${
                    item?.physicalUnitName ?? '--'
                  })`}</span>
                </Tooltip>
                <Tooltip title={!isNil(item?.value) ? item?.value.toFixed(2) : '--'}>
                  <span className={styles.itemcard_wrapper_top_value}>
                    {!isNil(item?.value) ? item?.value.toFixed(2) : '--'}
                  </span>
                </Tooltip>
              </div>
              <div className={styles.itemcard_wrapper_bottom}>
                <span className={styles.itemcard_wrapper_bottom_label} style={{ marginRight: 6 }}>
                  同比：
                  <Tooltip title={`${!isNil(item?.yoyRatio) ? item?.yoyRatio.toFixed(2) : '--'}%`}>
                    <span style={{ color: renderColor(item.indicatorName, item?.yoyRatio) }}>{`${
                      !isNil(item?.yoyRatio) ? item?.yoyRatio.toFixed(2) : '--'
                    }%`}</span>
                  </Tooltip>
                </span>
                <span className={styles.itemcard_wrapper_bottom_label}>
                  环比：
                  <Tooltip title={`${!isNil(item?.momRatio) ? item?.momRatio.toFixed(2) : '--'}%`}>
                    <span style={{ color: renderColor(item.indicatorName, item?.momRatio) }}>{`${
                      !isNil(item?.momRatio) ? item?.momRatio.toFixed(2) : '--'
                    }%`}</span>
                  </Tooltip>
                </span>
              </div>
            </div>
          ))}
        </div>
      </Spin>
    </div>
  );
};

export default CenterDataCompartion;

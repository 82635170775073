import { useEffect, useState, useCallback } from 'react';
import {
  Form,
  Input,
  SubContent,
  Wrapper,
  useUpdate,
  useBreadcrumbRoutes,
  FormTitle,
  Table,
  Button,
  Radio,
  Tooltip,
  Modal,
  PopConfirm,
} from '@maxtropy/components';
import { Col, Layout, Row, Space, Spin, message } from 'antd';
import { InfoCircleOutlined, LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { v4 as uuidV4 } from 'uuid';
import StationTree, { CateNode } from './components/StationTree';
import {
  apiV2OutputProductCategoryNewAddPost,
  apiV2OutputProductCategoryDetailPost,
  V2OutputProductCategoryDetailPostResponse,
  V2OutputProductCategoryNewAddPostRequest,
  apiV2OutputProductCategoryCheckSpecPost,
  apiV2OutputProductCategoryNewUpdatePost,
} from '@maxtropy/device-customer-apis-v2';
import { isEmpty } from 'lodash-es';
import { ColumnsType } from 'antd/es/table';
import { DataNode } from 'antd/es/tree';
import DragResize from '@/components/DragResize';
import CustomTags from './components/CustomTags';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import styles from './index.module.scss';

const FormItem = Form.Item;

type DataType = Exclude<V2OutputProductCategoryDetailPostResponse['specList'], undefined>[number] & {
  key?: string;
};

export enum InputType {
  ENUM = 1,
  TEXT = 2,
}

export enum TreeOperationType {
  ADD,
  EDIT,
  COPY,
}

export interface TagProp {
  id?: number;
  value: string;
}

export type CurNode = CateNode & DataNode;

const OutputCategory = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [treeUpdate, treeUpdateFn] = useUpdate();
  const [isEdit, setEdit] = useState<boolean>(false);
  const [curNode, setCurNode] = useState<CurNode | null>();
  const [operationType, setOperationType] = useState<TreeOperationType>(TreeOperationType.EDIT);
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [deleteSpecList, setDeleteSpecList] = useState<number[]>([]);

  // 左侧拖拽展开相关
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态

  const hasEdit = useHasPermission(PermissionsType.B_EDITDESCRIPTIONANDSPECIFICATION); // 编辑

  const [form] = Form.useForm();

  const getDetail = () => {
    apiV2OutputProductCategoryDetailPost({ id: curNode?.key })
      .then(res => {
        switch (operationType) {
          case TreeOperationType.COPY:
            form.setFieldsValue({
              code: undefined,
              name: undefined,
              specList: res.specList,
            });
            break;

          case TreeOperationType.EDIT:
            if (res) {
              form.setFieldsValue(res);
            } else {
              form.setFieldsValue({
                code: undefined,
                name: undefined,
                specList: undefined,
              });
            }
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!isEmpty(curNode)) {
      setEdit(operationType !== TreeOperationType.EDIT);
      if (operationType === TreeOperationType.ADD) {
        form.resetFields();
      } else {
        setLoading(true);
        getDetail();
      }
    }
    setDeleteSpecList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curNode, operationType]);

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  const addRow = () => {
    const tableData = form.getFieldValue('specList') || [];
    const newRow = {
      key: uuidV4(),
      type: InputType.TEXT,
    };
    form.setFieldsValue({ specList: [...tableData, newRow] });
  };

  const transEnumList = (specList: DataType[]) => {
    if (!specList || specList.length === 0) return [];
    return specList.map(spec => {
      return {
        ...spec,
        name: spec.name?.trim(),
        enumList: spec.enumList?.filter(i => !i.id)?.map(i => i.value),
      };
    });
  };

  const handleSubmit = useCallback(() => {
    form.validateFields().then(values => {
      setBtnLoading(true);
      if (operationType === TreeOperationType.EDIT && curNode?.key !== 0) {
        const specList: DataType[] = form.getFieldValue('specList') || [];
        const addSpecList = specList.filter(item => !item.id);
        const updateSpecList = specList.filter(item => item.id);

        delete values.specList;

        apiV2OutputProductCategoryNewUpdatePost({
          ...values,
          id: curNode?.key,
          addSpecList: transEnumList(addSpecList),
          updateSpecList: transEnumList(updateSpecList),
          deleteSpecIdList: deleteSpecList,
        })
          .then(() => {
            message.success('修改成功');
            treeUpdateFn();
            setOperationType(TreeOperationType.EDIT);
            setCurNode({ key: curNode?.key! });
            setEdit(false);
          })
          .finally(() => setBtnLoading(false));
      } else {
        const params: V2OutputProductCategoryNewAddPostRequest = {
          ...values,
          specList: transEnumList(values.specList),
          rootId: curNode?.rootId,
        };

        if (operationType === TreeOperationType.COPY) {
          params.parentId = curNode?.parentId;
          params.level = curNode?.level;
        }
        if (operationType === TreeOperationType.ADD || curNode?.key === 0) {
          params.parentId = curNode?.key;
          params.level = curNode?.level! + 1;
        }
        apiV2OutputProductCategoryNewAddPost(params)
          .then(res => {
            message.success('新增成功');
            treeUpdateFn();
            setOperationType(TreeOperationType.EDIT);
            setCurNode({ key: res.id! });
            setEdit(false);
          })
          .finally(() => setBtnLoading(false));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curNode, operationType, deleteSpecList]);

  const handleDelete = (row: DataType) => {
    const tableData: DataType[] = form.getFieldValue('specList') || [];

    if (!row.id) {
      //  新增
      form.setFieldsValue({ specList: tableData.filter(item => item.key !== row.key) });
      return;
    }

    apiV2OutputProductCategoryCheckSpecPost({ id: row.id }).then(res => {
      const tableData: DataType[] = form.getFieldValue('specList') || [];

      if (res.flag) {
        Modal.confirm({
          title: ` 当前规格有产出物已填写信息，是否清空产出物填写的【${row.name}】规格值`,
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
            form.setFieldsValue({ specList: tableData.filter(item => item.id !== row.id) });
            setDeleteSpecList(deleteSpecList => [...deleteSpecList, row.id!]);
          },
        });
      } else {
        form.setFieldsValue({ specList: tableData.filter(item => item.id !== row.id) });
        setDeleteSpecList(deleteSpecList => [...deleteSpecList, row.id!]);
      }
    });
  };

  const onCancel = () => {
    setEdit(false);
    setOperationType(TreeOperationType.EDIT);
    getDetail();
  };

  const columns: ColumnsType<DataType> = [
    {
      title: <div className={styles.requiredTitle}>规格名称</div>,
      dataIndex: 'name',
      width: 300,
      render: (_, record, index) => {
        return (
          <Form.Item
            name={['specList', index, 'name']}
            rules={[
              { required: true, message: '请输入' },
              { max: 64, message: '长度不能超过64个字符' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const specList: DataType[] = getFieldValue('specList');
                  if ((specList ?? []).filter(spec => spec?.name?.trim() === value?.trim()).length > 1) {
                    return Promise.reject(new Error('规格名称不能重复'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="请输入" disabled={!isEdit} />
          </Form.Item>
        );
      },
    },
    {
      title: (
        <div className={styles.requiredTitle}>
          规格值输入方式
          <Tooltip title="枚举方式用于规格值可以穷举的场景帮助快速填写, 文本方式用于规格值没有规律自由输入的场景.">
            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', marginLeft: 4 }} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'type',
      width: 280,
      render: (_, record, index) => {
        return (
          <FormItem name={['specList', index, 'type']}>
            <Radio.Group disabled={!isEdit || record.relateProduct}>
              <Radio value={InputType.ENUM}>枚举选择</Radio>
              <Radio value={InputType.TEXT}>文本</Radio>
            </Radio.Group>
          </FormItem>
        );
      },
    },
    {
      title: '枚举值',
      dataIndex: 'enumList',
      width: 400,
      render(_, record, index) {
        return form.getFieldValue(['specList', index, 'type']) === InputType.ENUM ? (
          <Form.Item name={['specList', index, 'enumList']}>
            <CustomTags disabled={!isEdit} form={form} index={index} />
          </Form.Item>
        ) : (
          '--'
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 100,
      hidden: !isEdit,
      render: (_, record) => {
        return (
          <PopConfirm title="是否删除此规格" onConfirm={() => handleDelete(record)}>
            <Button type="link">删除</Button>
          </PopConfirm>
        );
      },
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.container}>
      <Layout className={styles.layout}>
        <Layout.Sider
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: 'var(--mx-module-bg-color)' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <StationTree
            treeUpdate={treeUpdate}
            treeUpdateFn={treeUpdateFn}
            setCurNode={setCurNode}
            curNode={curNode}
            isEdit={isEdit}
            setOperationType={setOperationType}
          />
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          <div
            className={styles.toggleBtn}
            style={{ left: openSider ? -30 : 0 }}
            onClick={() => setOpenSider(!openSider)}
          >
            {openSider ? <LeftOutlined /> : <RightOutlined />}
          </div>
          <div className={styles.paneConfig}>
            <Spin spinning={loading}>
              <FormTitle title="产出物分类" />
              <Form form={form}>
                <SubContent title="分类配置">
                  <Row>
                    <Col span={12}>
                      <FormItem
                        label="分类名"
                        name="name"
                        rules={[
                          { required: true, message: '请输入分类名' },
                          { max: 64, message: '长度不能超过64个字符' },
                        ]}
                      >
                        <Input placeholder="请输入分类名" disabled={!isEdit} />
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem
                        label="分类编码"
                        name="code"
                        rules={[
                          { required: true, message: '请输入分类编码' },
                          { max: 64, message: '长度不能超过64个字符' },
                        ]}
                      >
                        <Input placeholder="请输入分类编码" disabled={!isEdit} />
                      </FormItem>
                    </Col>
                  </Row>
                </SubContent>
                <SubContent
                  title="规格配置"
                  extraContent={
                    <div className={styles.extraDesc}>
                      <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
                      该规格用于产出物的描述和分类，根据不同分类产出物分别配置
                    </div>
                  }
                >
                  <Form.Item noStyle labelAlign="left" name="specList" valuePropName="dataSource">
                    <Table className={styles.dynamicTable} columns={columns} />
                  </Form.Item>
                  {isEdit && (
                    <Button wrapClassName={styles.addBtn} type="dashed" onClick={addRow} ghost icon={<PlusOutlined />}>
                      新增规格
                    </Button>
                  )}
                </SubContent>
                <SubContent>
                  {isEdit ? (
                    <Space size={8}>
                      <Button type="primary" loading={btnLoading} onClick={handleSubmit}>
                        保存
                      </Button>
                      <Button onClick={onCancel}>取消</Button>
                    </Space>
                  ) : (
                    hasEdit && (
                      <Button type="primary" onClick={() => setEdit(true)}>
                        编辑
                      </Button>
                    )
                  )}
                </SubContent>
              </Form>
            </Spin>
          </div>
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
};

export default OutputCategory;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Wrapper,
  Table,
  usePaging,
  Paging,
  useBreadcrumbRoutes,
  useUpdate,
  Button,
  EllipsisSpan,
  Form,
  Select,
  CustomFilter,
  TreeSelect,
  Input,
  Modal,
  DatePicker,
} from '@maxtropy/components';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { Space } from 'antd';
import dayjs from 'dayjs';
import {
  BaseElectricityBillResponse,
  BillPaymentStatusEnum,
  BillStatusEnum,
  BillStatusEnumDisplay,
  checkCapitalContribution,
  electricityBillBatchDownload,
  ElectricityBillBatchListRes,
  electricityBillReverseProcessInvoicingRescinded,
  electricityBillReverseProcessRecheck,
  ElectricityContractListByPropertyManagementConfigIdOrSaleClientId,
  electricityStatusProcessNext,
  getElectricityBillBatchList,
  getElectricityContractListByPropertyManagementConfigIdOrSaleClientId,
  rescindBill,
  TaskStatusType,
} from '../../api/electricity';
import { getOrganizationWithCodeWithCurrent } from '../../api/device';
import {
  ElectricitySaleContractVo,
  getPropertyList,
  getPropertyListOwned,
  PropertyItem,
} from '../../api/electricitySalesContract';
import { ColumnType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { useHasPermission } from '../../utils/utils';
import { PermissionsType } from '../../common/permissionsConst';
import { getSaleClientList, SaleClientInfo } from '../../api/sale';
import { WorkDivisionEnum } from '@/shared/types';
import ElectricityBillModal, {
  BaseElectricityOperatorModalProps,
  BillOperator,
} from './components/ElectricityBillModal';
import {
  ElectricitySaleContractSettlement,
  ElectricitySaleContractSettlementType,
  ElectricitySettlementRulesResponse,
  getSaleElectricityContractList,
  getSaleElectricitySettlementRuleListOwn,
} from '../../api/electricitySettlementRules';
import qs from 'qs';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getRealUrl } from '@maxtropy/components';

const { RangePicker } = DatePicker;

// const routes = [
//   {name: '运营配置'},
//   {name: '结算规则'},
//   {name: '电费核查联列表'}
// ];

const dateAndTimeDisplay = 'YYYY-MM-DD HH:mm:ss';
const dateDisplay = 'YYYY-MM-DD';

function formatTreeData(data: any[]) {
  return data.map(item => {
    const data = item?.data;
    const result: DefaultOptionType = {
      key: data?.mcid ?? '',
      value: data?.mcid ?? '',
      title: data?.name,
      children: formatTreeData(item.children),
      disabled: !data?.hasPermission,
    };
    return result;
  });
}

function settlementTypeOptions() {
  const types: Array<{ label: string; value: ElectricitySaleContractSettlementType }> = [
    {
      label: ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.UNITARYSYSTEM],
      value: ElectricitySaleContractSettlementType.UNITARYSYSTEM,
    },
    {
      label: ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.TWOPARTSYSTEM],
      value: ElectricitySaleContractSettlementType.TWOPARTSYSTEM,
    },
    {
      label: ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST],
      value: ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST,
    },
    {
      label: ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL],
      value: ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL,
    },
    {
      label:
        ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY],
      value: ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY,
    },
  ];
  return types?.map(item => ({ label: item.label, value: item.value }));
}

function electricityStatusOptions() {
  const status: Array<{ label: string; value: BillStatusEnum }> = [
    {
      label: BillStatusEnumDisplay[BillStatusEnum.EXCEPTION],
      value: BillStatusEnum.EXCEPTION,
    },
    {
      label: BillStatusEnumDisplay[BillStatusEnum.RESCINDED],
      value: BillStatusEnum.RESCINDED,
    },
    {
      label: BillStatusEnumDisplay[BillStatusEnum.DRAFT],
      value: BillStatusEnum.DRAFT,
    },
    {
      label: BillStatusEnumDisplay[BillStatusEnum.CHECK_PENDING],
      value: BillStatusEnum.CHECK_PENDING,
    },
    {
      label: BillStatusEnumDisplay[BillStatusEnum.REVIEW_PENDING],
      value: BillStatusEnum.REVIEW_PENDING,
    },
    {
      label: BillStatusEnumDisplay[BillStatusEnum.INVOICE_PENDING],
      value: BillStatusEnum.INVOICE_PENDING,
    },
    {
      label: BillStatusEnumDisplay[BillStatusEnum.PAY_PENDING],
      value: BillStatusEnum.PAY_PENDING,
    },
    {
      label: BillStatusEnumDisplay[BillStatusEnum.PAY_DONE],
      value: BillStatusEnum.PAY_DONE,
    },
    {
      label: BillStatusEnumDisplay[BillStatusEnum.SETTLE_DONE],
      value: BillStatusEnum.SETTLE_DONE,
    },
  ];
  return status.map(i => ({ label: i.label, value: i.value }));
}

function formatParams(value: ElectricityBillSearchParams) {
  return {
    customerMcid: value.customerMcid,
    billNo: value.billNo,
    propertyManagementConfigId: value.propertyManagementConfigId,
    saleElectricityContractId: value.saleElectricityContractId,
    status: value.status,
    saleClientId: value.saleClientId,
    settlementRuleId: value.settlementRuleId,
    settlementType: value.settlementType,
    settlementStartTimeBegin: value.settlementStartTime ? value.settlementStartTime[0].format(dateDisplay) : undefined,
    settlementStartTimeEnd: value.settlementStartTime ? value.settlementStartTime[1].format(dateDisplay) : undefined,
    settlementEndTimeBegin: value.settlementEndTime ? value.settlementEndTime[0].format(dateDisplay) : undefined,
    settlementEndTimeEnd: value.settlementEndTime ? value.settlementEndTime[1].format(dateDisplay) : undefined,
  };
}

export interface ElectricityBillSearchParams
  extends Omit<ElectricityContractListByPropertyManagementConfigIdOrSaleClientId, 'page' | 'size'> {}

const ElectricityBill: React.FC = () => {
  const [form] = Form.useForm();
  const [refreshState, refresh] = useUpdate();
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]); // 组织树
  const [propertyList, setPropertyList] = useState<PropertyItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [contractData, setContractData] = useState<ElectricitySaleContractVo[]>([]);
  const [electricityBillData, setElectricityBillData] = useState<BaseElectricityBillResponse[]>([]); // 电费核查联列表
  const hasElectricBillInvoiceConfirm = useHasPermission(PermissionsType.B_ELECTRICBILLINVOICECONFIRM); // 确认开票
  const hasElectricBillInvoiceRescind = useHasPermission(PermissionsType.B_ELECTRIC_BILL_INVOICE_RESCIND); // 作废发票
  const hasElectricBillRecheck = useHasPermission(PermissionsType.B_ELECTRIC_BILL_RECHECK); // 重新复核
  const hasElectricBillAntiBookkeeping = useHasPermission(PermissionsType.B_ELECTRIC_BILL_ANTI_BOOKKEEPING); // 反记账
  const hasBillPay = useHasPermission(PermissionsType.B_ELECTRIC_BILL_PAY); // 缴费按钮权限
  const hasElectricBillManual = useHasPermission(PermissionsType.B_GENERATE_ELECTRIC_BILL_MANUAL); // 手动生成核查联
  const hasB_DISCARD_ELECTRIC_BILL = useHasPermission(PermissionsType.B_DISCARD_ELECTRIC_BILL); // 作废
  const hasB_REGENERATE_ELECTRIC_BILL_MANUAL = useHasPermission(PermissionsType.B_REGENERATE_ELECTRIC_BILL_MANUAL); // 手动重新生成
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [customList, setCustomList] = useState<SaleClientInfo[]>([]);
  const [process, setProcess] = useState<boolean>(false);
  const [currentRecord, setCurrentRecord] = useState<BaseElectricityOperatorModalProps>();
  const searchParamsRef = useRef<ElectricityBillSearchParams>();
  const customerMcid: string | undefined = Form.useWatch('customerMcid', form);
  const [settlementNameList, setSettlementNameList] = useState<ElectricitySettlementRulesResponse[]>([]);
  const [uploadListVisible, setUploadListVisible] = useState<boolean>(false);
  const [uploadListData, setUploadListData] = useState<ElectricityBillBatchListRes[]>([]);
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const settlementNameOptions = useMemo(() => {
    if (settlementNameList?.length !== 0) {
      return settlementNameList?.map(item => ({ label: item.name, value: item.id }));
    }
  }, [settlementNameList]);

  const customListOptions = useMemo(() => {
    if (customList.length !== 0) {
      return customList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [customList]);

  const propertyListOptions = useMemo(() => {
    if (propertyList.length !== 0) {
      return propertyList.map(i => ({ label: `${i.code}【${i.salesOrg}】`, value: i.id }));
    }
  }, [propertyList]);

  const contractDataOptions = useMemo(() => {
    if (contractData.length !== 0) {
      return contractData.map(i => ({ label: `${i.code}【${i.name}】`, value: i.id }));
    }
  }, [contractData]);

  const getPropertyLists = useCallback((mcid: string) => {
    return getPropertyList([mcid]).then(res => {
      setPropertyList(res);
      const search = {
        customerMcid: mcid,
        propertyManagementConfigId: res.at(0)?.id,
      };
      return search;
    });
  }, []);

  useEffect(() => {
    if (!customerMcid) {
      getPropertyListOwned().then(res => {
        setPropertyList(res);
      });
    }
  }, [customerMcid]);

  useEffect(() => {
    getSaleClientList().then(setCustomList);
    getSaleElectricityContractList().then(setContractData);
    getSaleElectricitySettlementRuleListOwn().then(res => setSettlementNameList(res.list ?? []));
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
    });
  }, [form]);

  useEffect(() => {
    if (customerMcid) {
      form.resetFields(['propertyManagementConfigId']);
      getPropertyLists(customerMcid)?.then(search => {
        form.setFieldsValue(search);
      });
    }
  }, [customerMcid, form, getPropertyLists]);

  const getData = useCallback((query: ElectricityContractListByPropertyManagementConfigIdOrSaleClientId) => {
    setLoading(true);
    getElectricityContractListByPropertyManagementConfigIdOrSaleClientId(query).then(res => {
      setLoading(false);
      setElectricityBillData(res.list);
      setTotalCount(res.total);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (value: ElectricityBillSearchParams) => {
    const params = formatParams(value);
    searchParamsRef.current = params;
    setPageOffset(1);
    refresh();
  };

  useEffect(() => {
    const serachParams = searchParamsRef.current;
    getData({
      ...serachParams,
      page: pageOffset,
      size: pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, pageOffset, pageSize, refreshState]);

  const onReset = () => {
    searchParamsRef.current = undefined;
    setPageOffset(1);
    refresh();
  };

  // 下载
  const onDownload = (id: number) => {
    window.open(`/api/v2/electricity/bill/download/${id}`);
  };
  // 作废
  const onRescind = (id: number, status: number) => {
    Modal.confirm({
      title: '确认作废？',
      content: '',
      onOk() {
        setProcess(true);
        rescindBill({
          billId: id,
          status,
        })
          .then(() => {
            setPageOffset(1);
            refresh();
          })
          .finally(() => {
            setProcess(false);
          });
      },
    });
  };
  //正流程
  const onSubmit = (id: number, status: number) => {
    setProcess(true);
    electricityStatusProcessNext({
      billId: id,
      status,
    })
      .then(() => {
        setPageOffset(1);
        refresh();
      })
      .finally(() => {
        setProcess(false);
      });
  };

  // 重新复核
  const onReverseProcessRecheck = (billId: number, status: BillStatusEnum) => {
    electricityBillReverseProcessRecheck({
      billId,
      status,
    })
      .then(() => {
        setPageOffset(1);
        refresh();
      })
      .finally(() => {
        setProcess(false);
      });
  };

  const billColumn: ColumnType<BaseElectricityBillResponse>[] = [
    {
      title: '电费核查联编号',
      dataIndex: 'billNo',
      width: 180,
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '核查联状态',
      dataIndex: 'status',
      width: 120,
      ellipsis: { showTitle: true },
      render: (v: BillStatusEnum) => <EllipsisSpan value={BillStatusEnumDisplay[v]} />,
    },
    {
      title: '结算周期',
      dataIndex: 'settlementStartTime',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string, record) => (
        <EllipsisSpan
          value={`${dayjs(v).format(dateDisplay)} - ${dayjs(record.settlementEndTime)
            .subtract(1, 'day')
            .format(dateDisplay)}`}
        />
      ),
    },
    {
      title: '生成日期',
      dataIndex: 'createTime',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format(dateDisplay)} />,
    },
    {
      title: '所属客户',
      dataIndex: 'saleClientName',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '结算规则名称',
      dataIndex: 'settlementRuleName',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '结算规则类型',
      dataIndex: 'settlementType',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: ElectricitySaleContractSettlementType) => (
        <EllipsisSpan value={ElectricitySaleContractSettlement[v]} />
      ),
    },
    {
      title: '结账/时间',
      dataIndex: 'checkTime',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(dateAndTimeDisplay) : '--'} />,
    },
    {
      title: '生成人',
      dataIndex: 'createByUsername',
      width: 180,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      ellipsis: { showTitle: true },
      width: 350,
      fixed: 'right',
      render: (v, record) => {
        // console.log(record);
        return (
          <Space size={16}>
            {record.status !== BillStatusEnum.EXCEPTION && (
              <Button type="link">
                <Link to={`/operation/settlementRules/electricityBill/electricityBillChargeSchedule/${record.id}`}>
                  缴费明细
                </Link>
              </Button>
            )}
            <Button type="link">
              <Link to={`/operation/settlementRules/electricityBill/detail/${record.id}`}>详情</Link>
            </Button>
            {record.status === BillStatusEnum.DRAFT && (
              <>
                <Button
                  loading={process}
                  disabled={!(record.staffWorkDivision ?? []).includes(WorkDivisionEnum.ACCOUNTANT)}
                  onClick={() => onSubmitExamine(record.id, record.status)}
                  type="link"
                >
                  提交审核
                </Button>
              </>
            )}
            {record.status === BillStatusEnum.CHECK_PENDING && (
              <>
                <Button
                  disabled={!(record.staffWorkDivision ?? []).includes(WorkDivisionEnum.AUDITOR)}
                  onClick={() => {
                    setCurrentRecord({
                      record,
                      op: BillOperator.Reverse,
                    });
                  }}
                  type="link"
                >
                  打回
                </Button>
                <Button
                  loading={process}
                  disabled={!(record.staffWorkDivision ?? []).includes(WorkDivisionEnum.AUDITOR)}
                  onClick={() => onExaminePass(record.id, record.status)}
                  type="link"
                >
                  审核通过
                </Button>
              </>
            )}
            {record.status === BillStatusEnum.REVIEW_PENDING && (
              <>
                <Button
                  loading={process}
                  disabled={!(record.staffWorkDivision ?? []).includes(WorkDivisionEnum.REVIEWER)}
                  onClick={() => {
                    setCurrentRecord({
                      record,
                      op: BillOperator.Reverse,
                    });
                  }}
                  type="link"
                >
                  打回
                </Button>
                <Button
                  loading={process}
                  disabled={!(record.staffWorkDivision ?? []).includes(WorkDivisionEnum.REVIEWER)}
                  onClick={() => onCheckPass(record.id, record.status)}
                  type="link"
                >
                  复核通过
                </Button>
              </>
            )}
            {record.status === BillStatusEnum.INVOICE_PENDING && (
              <>
                {hasElectricBillRecheck && (
                  <Button
                    loading={process}
                    type="link"
                    onClick={() => onReverseProcessRecheck(record.id, record.status)}
                  >
                    重新复核
                  </Button>
                )}
                {hasElectricBillInvoiceConfirm && (
                  <Button
                    type="link"
                    loading={process}
                    onClick={() => {
                      setCurrentRecord({
                        record,
                        op: BillOperator.invoicing,
                      });
                    }}
                  >
                    确认开票
                  </Button>
                )}
              </>
            )}
            {record.status === BillStatusEnum.PAY_PENDING && (
              <>
                <Button type="link" onClick={() => onDownload(record.id)}>
                  下载
                </Button>
                {record.paymentStatus === BillPaymentStatusEnum.PARTIALLY_PAID && hasElectricBillAntiBookkeeping && (
                  <Button
                    type="link"
                    onClick={() => {
                      setCurrentRecord({
                        record,
                        op: BillOperator.antiBookkeeping,
                      });
                    }}
                  >
                    反记账
                  </Button>
                )}

                {hasElectricBillInvoiceRescind && record.paymentStatus === BillPaymentStatusEnum.UNPAID && (
                  <Button type="link" onClick={() => onBecomeInvalid(record.id, record.status)}>
                    作废发票
                  </Button>
                )}
                {hasBillPay && (
                  <Button type="link">
                    <Link to={`/operation/settlementRules/electricityBill/payment/${record.id}`}>缴费</Link>
                  </Button>
                )}
              </>
            )}
            {record.status === BillStatusEnum.PAY_DONE && (
              <>
                <Button type="link" onClick={() => onDownload(record.id)}>
                  下载
                </Button>
                {hasElectricBillAntiBookkeeping && (
                  <Button
                    type="link"
                    onClick={() => {
                      setCurrentRecord({
                        record,
                        op: BillOperator.antiBookkeeping,
                      });
                    }}
                  >
                    反记账
                  </Button>
                )}
                <Button
                  loading={process}
                  disabled={!(record.staffWorkDivision ?? []).includes(WorkDivisionEnum.REVIEWER)}
                  onClick={() => onFinishChecking(record.id, record.status)}
                  type="link"
                >
                  结账完成
                </Button>
              </>
            )}
            {record.status === BillStatusEnum.SETTLE_DONE && (
              <>
                <Button type="link" onClick={() => onDownload(record.id)}>
                  下载
                </Button>
              </>
            )}
            {hasB_DISCARD_ELECTRIC_BILL && (
              <Button
                loading={process}
                disabled={
                  record.status === BillStatusEnum.INVOICE_PENDING ||
                  record.status === BillStatusEnum.PAY_PENDING ||
                  record.status === BillStatusEnum.PAY_DONE ||
                  record.status === BillStatusEnum.SETTLE_DONE ||
                  record.status === BillStatusEnum.RESCINDED
                }
                onClick={() => onRescind(record.id, record.status)}
                type="link"
              >
                作废
              </Button>
            )}
            {hasB_REGENERATE_ELECTRIC_BILL_MANUAL && (
              <Button
                type="link"
                disabled={
                  record.status === BillStatusEnum.INVOICE_PENDING ||
                  record.status === BillStatusEnum.PAY_PENDING ||
                  record.status === BillStatusEnum.PAY_DONE ||
                  record.status === BillStatusEnum.SETTLE_DONE
                }
              >
                <Link
                  to={`/operation/settlementRules/electricityBill/manualAgainCreate?${qs.stringify({
                    electricityId: record.id,
                  })}`}
                >
                  手动重新生成
                </Link>
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const uploadListColumn: ColumnType<ElectricityBillBatchListRes>[] = [
    {
      title: '序号',
      render: (_, record, index) => index + 1,
    },
    {
      title: '核查联数量',
      dataIndex: 'taskBillQuantity',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={v} />,
    },
    {
      title: '下载时间',
      dataIndex: 'createTime',
      ellipsis: { showTitle: true },
      render: v => <EllipsisSpan value={dayjs(v).format(dateAndTimeDisplay)} />,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      ellipsis: { showTitle: true },
      fixed: 'right',
      render: (v, record) => {
        return (
          <Space size={16}>
            {record.taskStatus === TaskStatusType.PROCESSING && (
              <Button type="link" disabled>
                正在处理
              </Button>
            )}
            {record.taskStatus === TaskStatusType.SUCCESS && (
              <Button type="link" onClick={() => window.open(getRealUrl(record.zipFileKey))}>
                下载
              </Button>
            )}
            {record.taskStatus === TaskStatusType.FAIL && (
              <Button type="link" style={{ color: 'var(--mx-error-color)' }} disabled>
                处理失败
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const filters = (
    <CustomFilter form={form} onFinish={value => onFinish(value as ElectricityBillSearchParams)} onReset={onReset}>
      <Form.Item name="customerMcid" label="所属组织">
        <TreeSelect
          treeDefaultExpandAll
          treeData={treeData}
          placeholder="请选择"
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        />
      </Form.Item>

      <Form.Item name="propertyManagementConfigId" label="所属物业">
        <Select placeholder="请选择" options={propertyListOptions} />
      </Form.Item>

      <Form.Item label="客户名称" name="saleClientId">
        <Select placeholder="请选择" options={customListOptions} showSearch optionFilterProp="label" />
      </Form.Item>

      <Form.Item name="saleElectricityContractId" label="所属合同">
        <Select placeholder="请选择" options={contractDataOptions} showSearch optionFilterProp="label" />
      </Form.Item>

      <Form.Item name="settlementRuleId" label="结算规则名称">
        <Select placeholder="请选择" options={settlementNameOptions} showSearch optionFilterProp="label" />
      </Form.Item>

      <Form.Item name="settlementType" label="结算规则类型">
        <Select placeholder="请选择" options={settlementTypeOptions()} />
      </Form.Item>

      <Form.Item name="status" label="核查联状态">
        <Select placeholder="请选择" options={electricityStatusOptions()} />
      </Form.Item>

      <Form.Item name="billNo" label="电费核查联编号">
        <Input placeholder="请输入编号查询" />
      </Form.Item>

      <Form.Item label="结算周期-开始日期" name="settlementStartTime">
        <RangePicker style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label="结算周期-结束日期" name="settlementEndTime">
        <RangePicker style={{ width: '100%' }} />
      </Form.Item>
    </CustomFilter>
  );

  const onSubmitExamine = (id: number, status: number) => {
    checkCapitalContribution(id).then(res => {
      if (res) {
        Modal.confirm({
          title: '确定提交审核？',
          content: <p style={{ color: 'red' }}>请仔细检查完信息后再提交审核！</p>,
          bodyStyle: { color: 'red' },
          onOk() {
            onSubmit(id, status);
          },
        });
      } else {
        Modal.info({
          title: '请到详情页填写公摊电量',
          content: '',
          okText: '好的',
        });
      }
    });
  };
  const onExaminePass = (id: number, status: number) => {
    Modal.confirm({
      title: '确定审核通过？',
      content: '',
      onOk() {
        onSubmit(id, status);
      },
    });
  };
  const onCheckPass = (id: number, status: number) => {
    Modal.confirm({
      title: '确定复核通过？',
      content: '',
      onOk() {
        onSubmit(id, status);
      },
    });
  };
  const onFinishChecking = (id: number, status: number) => {
    Modal.confirm({
      title: '确定结账完成？',
      content: <p style={{ color: 'red' }}>结账完成后，核查联不可再修改和回退，请谨慎操作！</p>,
      onOk() {
        onSubmit(id, status);
      },
    });
  };
  const onBecomeInvalid = (billId: number, status: BillStatusEnum) => {
    Modal.confirm({
      title: '确定作废开票？',
      content: '',
      onOk() {
        setProcess(true);
        electricityBillReverseProcessInvoicingRescinded({
          billId,
          status,
        })
          .then(() => {
            setPageOffset(1);
            refresh();
          })
          .finally(() => {
            setProcess(false);
          });
      },
    });
  };

  // 批量导出确认
  const confirmBatchDownLoad = () => {
    Modal.confirm({
      title: '确定导出？',
      content: '',
      okText: '继续',
      onOk() {
        const filterValue = form.getFieldsValue(true);
        const params = formatParams(filterValue);
        electricityBillBatchDownload(params).then(() => {
          setUploadListVisible(true);
          setModalLoading(true);
          getElectricityBillBatchList()
            .then(res => setUploadListData(res.list || []))
            .finally(() => setModalLoading(false));
        });
      },
    });
  };

  const openUploadList = () => {
    setUploadListVisible(true);
    setModalLoading(true);
    getElectricityBillBatchList()
      .then(res => setUploadListData(res.list || []))
      .finally(() => setModalLoading(false));
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} filters={filters}>
      <Space size={8} style={{ marginBottom: 10 }}>
        {hasElectricBillManual && (
          <Button type="primary">
            <Link to={`/operation/settlementRules/electricityBill/manualCreate`}>手动生成核查联</Link>
          </Button>
        )}
        {hasElectricBillManual && (
          <>
            <Button type="primary" onClick={confirmBatchDownLoad}>
              批量下载
            </Button>
            <Button type="link" onClick={openUploadList}>
              下载记录
            </Button>
            <span>
              <InfoCircleOutlined style={{ fontSize: '14px', marginRight: '6px', color: 'var(--mx-warning-color)' }} />
              <span style={{ color: 'var(--mx-text-desc-color)' }}>
                本次最多支持导出列表的前200条，且只有待缴费、已缴费、结账完成状态的核查联，超出条数请联系我们。
              </span>
            </span>
          </>
        )}
      </Space>

      <Table
        rowKey="id"
        scroll={{ x: 1300 }}
        loading={loading}
        sticky
        columns={billColumn}
        dataSource={electricityBillData}
      />
      <Paging pagingInfo={pagingInfo} />

      <ElectricityBillModal
        setPageOffset={setPageOffset}
        refresh={refresh}
        electricityOperatorRecord={currentRecord}
        changeVisible={setCurrentRecord}
      />
      <Modal
        size="big"
        title="下载记录"
        open={uploadListVisible}
        onCancel={() => {
          setUploadListVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setUploadListVisible(false);
            }}
          >
            关闭
          </Button>,
        ]}
      >
        <Table
          scroll={{ y: 450 }}
          rowKey="id"
          loading={modalLoading}
          columns={uploadListColumn}
          dataSource={uploadListData}
        />
        <InfoCircleOutlined style={{ fontSize: '14px', marginRight: '6px', color: 'var(--mx-warning-color)' }} />
        <span style={{ color: 'var(--mx-text-desc-color)' }}>本列表只展示7天的导出数据</span>
      </Modal>
    </Wrapper>
  );
};

export default ElectricityBill;

import { EllipsisSpan, Key } from '@maxtropy/components';
import { Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import styles from '../../index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import {
  TimeSharingElectricityTableDataItem,
  TotalTimeSharingElectricityListItem,
  getTotalTimeSharingElectricityTableData,
} from '@/api/universeMeterQuery';
import {
  changeTreeData,
  deleteToTreeData,
  getAllExpandedRow,
  insertExpandToTreeData,
} from '@/pages/UniverseMeterQuery/handleTreeDataUtils';

// 格式化抄表值
const formatMeterDataToTreeTableData = (
  data: TotalTimeSharingElectricityListItem[],
  treeTableData: TimeSharingElectricityTableDataItem[]
) => {
  data.forEach(project => {
    project.rows.forEach((row, index) => {
      treeTableData.push({
        id: `${project.meterReadingObjectId}-${row.deviceId}-${index}`,
        meterReadingObjectId: project.meterReadingObjectId,
        meterReadingObjectName: project.meterReadingObjectName,
        meterReadingLevel: project.meterReadingLevel,
        ...row,
        isHightLight: false,
        expand: index === 0 ? false : undefined,
        children: index === 0 ? (project.list && project.list.length > 0 ? [] : undefined) : undefined,
        child:
          index === 0
            ? project.list && project.list.length > 0
              ? formatMeterDataToTreeTableData(project.list, [])
              : undefined
            : undefined,
      });
    });
  });
  return treeTableData;
};

interface Props {
  isAllExpand: boolean;
  setIsAllExpand: (v: boolean) => void;
}

const CopyMeterTable: React.FC<Props> = ({ isAllExpand, setIsAllExpand }) => {
  const { id } = useParams<{ id: string }>();
  const [table, setTable] = useState<TimeSharingElectricityTableDataItem[]>([]);
  const [expandedTreeData, setExpandedTreeData] = useState<TimeSharingElectricityTableDataItem[]>([]); // 是否展开的数据
  const [originTable, setOriginData] = useState<TimeSharingElectricityTableDataItem[]>([]); // 原始数据
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
  const [allExpandRowKeys, setAllExpandRowKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getTotalTimeSharingElectricityTableData(id)
        .then(res => {
          if (res) {
            const tableData = formatMeterDataToTreeTableData(res.list ?? [], []);
            setTable(tableData);
            setExpandedTreeData(tableData);
            setOriginData(tableData);
            setAllExpandRowKeys(getAllExpandedRow(tableData, []).map(i => i.id));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (isAllExpand) {
      const allExpandRow = getAllExpandedRow(originTable, []);
      let expandedTree = originTable;
      let tree = originTable;
      allExpandRow.forEach(i => {
        expandedTree = changeTreeData(expandedTree, i.id, true);
        tree = insertExpandToTreeData(expandedTree, tree, i);
      });
      setTable(tree);
      setExpandedTreeData(expandedTree);
      setExpandedRowKeys(allExpandRowKeys);
    } else {
      setExpandedRowKeys([]);
      setExpandedTreeData(originTable);
      setTable(originTable);
    }
  }, [allExpandRowKeys, isAllExpand, originTable]);

  useEffect(() => {
    if (
      expandedRowKeys.length > 0 &&
      allExpandRowKeys.length > 0 &&
      expandedRowKeys.length === allExpandRowKeys.length
    ) {
      setIsAllExpand(true);
      return;
    }
    if (expandedRowKeys.length === 0 && allExpandRowKeys.length !== 0) {
      setIsAllExpand(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRowKeys]);

  // 获取展开节点样式
  const getExpandStyle = (isHightLight?: boolean) => {
    return isHightLight
      ? {
          backgroundColor: 'var(--mx-page-bg-color)',
        }
      : {};
  };

  // 获取抄表值行合并
  const getMeterRowSpan = (record: TimeSharingElectricityTableDataItem, index?: number) => {
    const firstIndex = table.findIndex(i => i.meterReadingObjectId === record.meterReadingObjectId);
    const objectOccupyRows = table.filter(i => i.meterReadingObjectId === record.meterReadingObjectId).length;
    if (index === firstIndex) {
      return objectOccupyRows;
    }
    return 0;
  };

  const columns = [
    {
      title: '层级',
      dataIndex: 'meterReadingLevel',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      onCell: (record: TimeSharingElectricityTableDataItem, index?: number) => ({
        rowSpan: getMeterRowSpan(record, index),
        style: getExpandStyle(record.isHightLight),
      }),
      render: (v: number) => {
        return <EllipsisSpan value={v ? `${v}级` : v} />;
      },
    },
    {
      title: '抄表对象',
      dataIndex: 'meterReadingObjectName',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      render: (v: string) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        rowSpan: getMeterRowSpan(record, index),
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '类型',
      dataIndex: 'deviceTypeName',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      render: (v: string) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
      isHide: true,
    },
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      render: (v: string) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '对应拓扑节点',
      dataIndex: 'topologyNodeName',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      isHide: true,
      render: (v: string) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '抄表值 (尖)',
      dataIndex: 'summitMeterReadingAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '抄表值 (峰)',
      dataIndex: 'peakMeterReadingAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '抄表值 (平)',
      dataIndex: 'plainMeterReadingAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '抄表值 (谷) ',
      dataIndex: 'valleyMeterReadingAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '尖峰平谷合计',
      dataIndex: 'totalMeterReadingAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: '实际抄表值',
      dataIndex: 'realReadingAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
    {
      title: (
        <>
          <span style={{ paddingRight: 5 }}>核对差值</span>
          <Tooltip placement="topLeft" title={'核对差值=实际抄表值-尖峰平谷合计'}>
            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
          </Tooltip>
        </>
      ),
      dataIndex: 'checkAmount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v} />,
      onCell: (record: any, index?: number) => ({
        style: getExpandStyle(record.isHightLight),
      }),
    },
  ];

  return (
    <>
      <div className={styles.tableStyles}>
        <Table
          columns={columns}
          dataSource={table}
          loading={loading}
          rowKey="id"
          pagination={false}
          bordered
          sticky
          expandable={{
            onExpand: (isShow, record) => {
              if (isShow) {
                setExpandedRowKeys([...expandedRowKeys, record.id]);
                setExpandedTreeData(changeTreeData(expandedTreeData, record.id, true));
                setTable(insertExpandToTreeData(expandedTreeData, table, record));
              } else {
                setExpandedRowKeys(expandedRowKeys.filter(i => i !== record.id));
                setExpandedTreeData(changeTreeData(expandedTreeData, record.id, false));
                setTable(deleteToTreeData(expandedTreeData, table, record));
              }
            },
            expandedRowKeys,
          }}
        />
      </div>
    </>
  );
};

export default CopyMeterTable;

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import CardBox from '../../../CardBox';
import styles from './index.module.scss';
import ReactEcharts from 'echarts-for-react';
import { ChartsData, getChartOption } from './chart';
import { Key, Tabs } from '@maxtropy/components';
import classnames from 'classnames/bind';
import { ShareDataContext } from '@/pages/ZYFDashboard';
import { useRequest } from 'ahooks';
import { apiV2EnergyCarbonOverviewEnergyConsumptionAvgPost } from '@maxtropy/device-customer-apis-v2';
import { keepTwoOrLine, uniqueArray } from '@/pages/ZYFDashboard/utils';
import CustomTooltip from '../../../CustomTooltip';
import { Spin } from 'antd';

const cx = classnames.bind(styles);

const MonthAverEnergyCons = () => {
  const chartRef = useRef<ReactEcharts>();
  const [tabActiveKey, setTabActiveKey] = useState<Key>();
  const timerRef = useRef<any>();
  const { configId } = useContext(ShareDataContext);

  // 月均用能
  const { data: energyConsumptionData, loading } = useRequest(
    async () => {
      const res = await apiV2EnergyCarbonOverviewEnergyConsumptionAvgPost({
        id: Number(configId),
      });
      return res;
    },
    {
      ready: !!configId,
      refreshDeps: [configId],
    }
  );

  // 能源工质tabs
  const items = useMemo(() => {
    // TODO后面可能需要energyMediumId + generalName作为唯一的key
    return uniqueArray(
      energyConsumptionData?.list
        ?.map(i => {
          return i.valueDtoList?.map(c => ({
            label: c.energyMediumName!,
            key: c.energyMediumId?.toString()!,
            unit: c.generalName,
          }));
        })
        .flat() ?? []
    );
  }, [energyConsumptionData]);

  // 默认选择第一个
  useEffect(() => {
    if (energyConsumptionData && items) {
      setTabActiveKey(items?.[0]?.key);
    }
  }, [energyConsumptionData, items]);

  // 查询图表
  const chartOption = useMemo(() => {
    const chartData = (energyConsumptionData?.list ?? []).map(item => ({
      ...item,
      valueDto: item.valueDtoList?.filter(c => c.energyMediumId?.toString() === tabActiveKey)?.[0],
    }));
    const unit = items?.find(item => item?.key === tabActiveKey)?.unit ?? '--';
    return getChartOption(chartData as ChartsData[], unit);
  }, [tabActiveKey, items]);

  const handleResize = () => {
    const echartsInstance = chartRef.current?.getEchartsInstance();
    if (echartsInstance) {
      echartsInstance.resize();
    }
  };

  const containerRef = useRef(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const echartsInstance = chartRef.current?.getEchartsInstance();
      if (echartsInstance) {
        echartsInstance.resize();
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // tab轮播切换
  useEffect(() => {
    if (tabActiveKey) {
      clearTimeout(timerRef.current);
      const currentActiveIndex = items?.findIndex(i => i?.key === tabActiveKey);
      if ((currentActiveIndex ?? 0) + 1 === (items ?? []).length) {
        timerRef.current = setTimeout(() => {
          setTabActiveKey(items?.[0]?.key);
        }, 30000);
      } else {
        timerRef.current = setTimeout(() => {
          setTabActiveKey(items?.[(currentActiveIndex ?? 0) + 1]?.key);
        }, 30000);
      }
    }
  }, [tabActiveKey]);

  const unit = useMemo(() => {
    if (items && tabActiveKey) {
      return items?.find(item => item?.key === tabActiveKey)?.unit;
    }
    return undefined;
  }, [items, tabActiveKey]);

  return (
    <CardBox title="月均用能" className={styles.cardBoxSty}>
      <Tabs
        getPopupContainer={triggerNode => triggerNode}
        animated={true}
        className={styles.tabsStyle}
        activeKey={tabActiveKey as string}
        onChange={v => {
          setTabActiveKey(v);
        }}
        items={items ?? []}
      />
      {loading ? (
        <Spin spinning={loading} />
      ) : (
        <>
          <div className={styles.charts_wrapper} ref={containerRef}>
            <ReactEcharts
              style={{ height: '100%' }}
              ref={d => {
                if (d) {
                  chartRef.current = d;
                }
              }}
              option={chartOption}
              notMerge
              lazyUpdate={false}
            />
          </div>
          <div className={styles.bottom_card}>
            <div className={styles.label}>用量均值({unit ? (unit === 'kgce' ? 'tce' : unit) : '--'})</div>
            <div className={styles.value_box}>
              <div className={styles.each_box} style={{ borderRight: '1px dashed rgba(255,255,255,0.2)' }}>
                <CustomTooltip title={keepTwoOrLine(chartOption?.series?.[0]?.avg)}>
                  <div className={cx('value', 'green')}>{keepTwoOrLine(chartOption?.series?.[0]?.avg)}</div>
                </CustomTooltip>
                <div className={styles.name}>{chartOption?.series?.[0]?.name ?? '--'}</div>
              </div>
              <div className={styles.each_box} style={{ borderRight: '1px dashed rgba(255,255,255,0.2)' }}>
                <CustomTooltip title={keepTwoOrLine(chartOption?.series?.[1]?.avg)}>
                  <div className={cx('value', 'yellow')}>{keepTwoOrLine(chartOption?.series?.[1]?.avg)}</div>
                </CustomTooltip>
                <div className={styles.name}>{chartOption?.series?.[1]?.name ?? '--'}</div>
              </div>
              <div className={styles.each_box}>
                <CustomTooltip title={keepTwoOrLine(chartOption?.series?.[2]?.avg)}>
                  <div className={cx('value', 'blue')}>{keepTwoOrLine(chartOption?.series?.[2]?.avg)}</div>
                </CustomTooltip>
                <div className={styles.name}>{chartOption?.series?.[2]?.name ?? '--'}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </CardBox>
  );
};

export default MonthAverEnergyCons;

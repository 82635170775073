import React, { CSSProperties, FC, Key, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Spin, Tag } from 'antd';
import { DataNode } from 'antd/lib/tree';
import styles from './index.module.scss';
import { CircuitProps, CircuitRelationTypeFormat, CircuitTreeUetProps, getCircuitTreeById } from '@/api/circuit';
import circuitIcon from './images/circuit.svg';
import { Input, Select, Tree } from '@maxtropy/components';

export interface CircuitTreeProps {
  className?: string;
  style?: CSSProperties;
  changeTreeSelect?: (value: React.Key[], info: any) => void;
  circuitList: CircuitProps[];
}
const transformTree = (tree?: CircuitTreeUetProps[]): DataNode[] => {
  if (!tree) return [];
  return tree.map(item => {
    return {
      icon: <img src={circuitIcon} alt="" />,
      className: 'circuit-title',
      key: item.id,
      title: item.name ? (
        <>
          <span style={{ marginRight: '5px' }}>{item.name}</span>
          {item.linkType && <Tag color={'blue'}>{CircuitRelationTypeFormat[item.linkType]}</Tag>}
        </>
      ) : (
        '--'
      ),
      selectable: true,
      info: {
        capacity: item.capacity,
        name: item.name,
        circuitId: item.id,
        type: item.type,
        children: item.children ? transformTree(item.children) : [],
      },
      children: item.children ? transformTree(item.children) : [],
    };
  });
};

const flattenMap = (data: DataNode[], map: Map<Key, DataNode & { info?: any }> = new Map()) => {
  data.forEach(i => {
    map.set(i.key, i);
    // if (i.children) {
    //   flattenMap(i.children, map);
    // }
  });
  return map;
};

// 树转为平铺
const cusTree = (arr: any[], tree?: CircuitTreeUetProps[]): any[] => {
  if (!tree) return [];
  tree.forEach(item => {
    arr.push({
      icon: <img src={circuitIcon} alt="" />,
      className: 'circuit-title',
      key: item.id,
      title: item.name ? (
        <>
          <span style={{ marginRight: '5px' }}>{item.name}</span>
          {item.linkType && <Tag color="info">{CircuitRelationTypeFormat[item.linkType]}</Tag>}
        </>
      ) : (
        '--'
      ),
      selectable: true,
      info: {
        capacity: item.capacity,
        name: item.name,
        circuitId: item.id,
        type: item.type,
        children: item.children ? transformTree(item.children) : [],
      },
    });
    if (item.children && item.children.length) {
      cusTree(arr, item.children);
    }
  });
  return arr;
};

const { Search } = Input;

const CircuitTree: FC<CircuitTreeProps> = props => {
  const { className, changeTreeSelect, style, circuitList } = props;
  const [currentCircuitId, setCurrentCircuitId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<any>({});
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [data, setData] = useState<DataNode[]>();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const circuitId = urlSearchParams.get('circuitId');

  useEffect(() => {
    if (currentCircuitId) {
      setLoading(true);
      getCircuitTreeById(String(currentCircuitId)).then(res => {
        if (JSON.stringify(searchParams) === '{}' || searchParams.name === '') {
          // 没有搜索为树
          const treeData = transformTree(res.list ?? []);
          // 对treeData进行平铺
          const flattenTreeData = flattenMap(treeData);
          setExpandedKeys(Array.from(flattenTreeData.keys()) as string[]);
          setData(treeData);
        } else {
          let arr: CircuitTreeUetProps[] = [];
          let flattenRes = cusTree(arr, res.list);
          const flattenTreeData = flattenMap(flattenRes);
          setExpandedKeys(Array.from(flattenTreeData.keys()) as string[]);
          let searchArr: any[] = [];
          flattenRes.forEach(i => {
            if (i.info.name.includes(searchParams.name)) {
              searchArr.push(i);
            }
          });
          setData(searchArr);
        }
        setLoading(false);
      });
    }
  }, [currentCircuitId, searchParams]);

  // 电网进线的options
  const options = useMemo(() => {
    if (circuitId) {
      setCurrentCircuitId(Number(circuitId));
    } else {
      if (circuitList.length !== 0) {
        setCurrentCircuitId(circuitList[0].id);
      }
    }
    return circuitList.map(i => ({ label: i.name, value: i.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circuitList]);

  const onSelect = (selectedKeys: React.Key[], e: any) => {
    changeTreeSelect?.(selectedKeys, e.node.info);
  };

  return (
    <div className={classNames(className, styles.treeStyle)} style={style}>
      <h3 style={{ padding: '10px 0 0 10px' }}>微网电能拓扑</h3>
      <div className={styles.filterArea}>
        <span className={styles.label}>电网进线：</span>
        <Select
          style={{ width: 160 }}
          placeholder="请选择"
          onChange={(id: number) => {
            setCurrentCircuitId(id);
          }}
          options={options}
          value={currentCircuitId}
        />
      </div>
      <div className={styles.searchArea}>
        <Search placeholder="请输入回路名称" allowClear onSearch={v => setSearchParams({ ...searchParams, name: v })} />
      </div>
      <Spin spinning={loading}>
        <Tree
          // showLine={{ showLeafIcon: false }}
          showIcon
          blockNode
          treeData={data}
          defaultSelectedKeys={currentCircuitId ? [currentCircuitId] : []}
          expandedKeys={expandedKeys}
          onExpand={expandedKeys => {
            setExpandedKeys(expandedKeys as string[]);
          }}
          onSelect={onSelect}
        />
      </Spin>
    </div>
  );
};

export default CircuitTree;

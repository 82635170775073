import { CarbonAccountUnitsSearchParams, delAccountUnit } from '@/api/CarbonAccountUnits';
import {
  Button,
  EllipsisSpan,
  Key,
  Paging,
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  useSearchParams,
  useUpdate,
  PopConfirm,
  Form,
  Input,
  CustomFilter,
} from '@maxtropy/components';
import { Space, Layout } from 'antd';
import { LeftOutlined, RightOutlined, PlusOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import AddUnitModal from './AddUnitModal';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import EntityTree from './EntityTree';
import { useNavigate } from 'react-router-dom';
import {
  apiV2CarbonAccountingUnitPagePost,
  V2CarbonAccountingUnitPagePostResponse,
} from '@maxtropy/device-customer-apis-v2';

interface SearchParams extends Omit<CarbonAccountUnitsSearchParams, 'page' | 'size'> {}

const CarbonAccountUnits: React.FC = () => {
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [tableData, setTableData] = useState<V2CarbonAccountingUnitPagePostResponse['list']>();
  const [open, setOpen] = useState<boolean>(false);
  const [unitId, setUnitId] = useState<number>();
  const [update, updateFn] = useUpdate();
  const Navigate = useNavigate();
  const hasAdd = useHasPermission(PermissionsType.B_ADD_CARBON_ACCOUNTING_ENTITY); // 新建填报
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle

  const [treeSelectedKeys, setTreeSelectedKeys] = useState<Key[]>([]); // 选择树
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<Key[]>([]);
  const [treeUpdate, treeUpdateFn] = useUpdate();
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true); // 左部树父节点是否自动展开
  const [entityId, setEntityId] = useState<number>(0);
  const [entityName, setEntityName] = useState<string>('--');

  useEffect(() => {
    setLoading(true);

    apiV2CarbonAccountingUnitPagePost({
      name: !isNil(searchParams.name) && searchParams.name !== '' ? searchParams.name : undefined,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
      carbonAccountingEntityId: entityId,
    })
      .then(res => {
        setTableData(res?.list);
        setTotalCount(res?.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, setTotalCount, update, entityId]);

  const onReset = () => {
    reset();
  };

  const onFinish = (v: SearchParams) => {
    finish(v);
  };

  // 删除核算单元
  const onDel = (id: Key) => {
    delAccountUnit(id).then(() => {
      updateFn();
    });
  };

  const buildColumns: ColumnsType<any> = [
    {
      title: '核算单元名称',
      dataIndex: 'unitName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '核算指南行业',
      dataIndex: 'industryName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '核算指南标准',
      dataIndex: 'standard',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '操作',
      width: 160,
      fixed: 'right' as 'right',
      render: (v: any, record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setUnitId(record?.id);
                setOpen(true);
              }}
            >
              编辑
            </Button>
            <PopConfirm title="您是否删除此核算单元？" onConfirm={() => onDel(record?.id)}>
              <Button type="link">删除</Button>
            </PopConfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper className={styles.wrapperStyles} routes={[...(breadcrumbRoutes?.routes ?? [])]}>
      <Layout className={styles.layout}>
        <Layout.Sider
          width={openSider ? 260 : 0}
          style={{ padding: '10px 0px', background: 'var(--mx-module-bg-color)' }}
        >
          {hasAdd && (
            <Button
              type="dashed"
              wrapStyle={{ width: 'calc(100% - 16px)', margin: '0 8px' }}
              style={{ width: '100%' }}
              onClick={() => {
                Navigate(`/carbonManage/mainAccount/carbonAccountUnits/create`);
              }}
              icon={<PlusOutlined />}
            >
              新建核算主体
            </Button>
          )}
          <EntityTree
            treeUpdate={treeUpdate}
            treeUpdateFn={treeUpdateFn}
            selectedKeys={treeSelectedKeys}
            setSelectedKeys={setTreeSelectedKeys}
            expandedKeys={treeExpandedKeys}
            setExpandedKeys={setTreeExpandedKeys}
            setEntityId={setEntityId}
            setEntityName={setEntityName}
            autoExpandParent={autoExpandParent}
            setAutoExpandParent={setAutoExpandParent}
          />
        </Layout.Sider>
        <Layout.Content className={styles.content} style={{ marginLeft: openSider ? 10 : 0 }}>
          <>
            <div
              className={styles.toggleBtn}
              style={{ left: openSider ? -30 : 0 }}
              onClick={() => setOpenSider(!openSider)}
            >
              {openSider ? <LeftOutlined /> : <RightOutlined />}
            </div>
            <div className={styles.filterTop}>
              <CustomFilter<SearchParams> form={form} onFinish={onFinish} onReset={onReset}>
                <Form.Item label="核算单元名称" name="name">
                  <Input placeholder="请输入核算单元名称搜索" />
                </Form.Item>
              </CustomFilter>
            </div>
            <div
              className="divider"
              style={{ width: '100%', backgroundColor: 'var(--mx-page-bg-color)', height: '10px' }}
            ></div>
            <div className={styles.operationArea}>
              <div style={{ marginBottom: 12 }}>
                <Button
                  type="primary"
                  disabled={entityId === 0}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <PlusOutlined /> 新建核算单元
                </Button>
              </div>
              <Table columns={buildColumns} rowKey="id" pagination={false} loading={loading} dataSource={tableData} />
              <Paging pagingInfo={pagingInfo} />
            </div>
            {open && (
              <AddUnitModal
                open={open}
                entityId={entityId}
                entityName={entityName}
                setOpen={setOpen}
                id={unitId}
                setId={setUnitId}
                updateFn={updateFn}
              />
            )}
          </>
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
};

export default CarbonAccountUnits;

import { Space } from 'antd';
import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from '../index.module.scss';
import { isNil } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { useTenantPermissions, Button, Table, EllipsisSpan, Form, Modal, message, Select } from '@maxtropy/components';

import GridConnectionPoint from '../components/GridConnectionPoints';
import {
  NetInfoProp,
  PvInfoProp,
  PvListProps,
  updateUetPv,
  createUetPv,
  getBindingCircuitList,
} from '../../../../../api/pv';
import DeliveryStatus from '../components/DeliveryStatus';
import { OuInfoProps } from '../../../../../api/ou';
import { PermissionsType } from '../../../../../common/permissionsConst';
import dayjs, { Dayjs } from 'dayjs';
import { ElectricityDeviceListResponse } from '@/api/uet';
import { DoubleRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import CircuitGrid from './CircuitGrid';
import { CircuitProps } from '@/api/circuit';

interface PvStationProps {
  pvStation?: PvInfoProp;
  gridConnectionPoints: NetInfoProp[];
  circuits: CircuitProps[];
  pvIndex?: number;
  isDetail?: boolean;
  electricityDeviceList?: ElectricityDeviceListResponse[];

  onPvModal: (v: number) => void;
  onUetStatus: (v: number) => void;
  onDelPv: (v: number) => void;
  onUpdatePvList: (v: PvListProps) => void;
}

interface PriceItem {
  beginDate: Dayjs;
  endDate: Dayjs;
  electricityPrice: number;
  id?: number;
}

export const PvStationContext = createContext<{
  id?: number;
  hasPutOn?: boolean;
  ou?: OuInfoProps;
  updateCircuitEmit?: () => void;
}>({});

const PvStation: React.FC<PvStationProps> = ({
  pvStation,
  gridConnectionPoints,
  circuits,
  pvIndex,
  isDetail,
  electricityDeviceList,
  onPvModal,
  onUetStatus,
  onDelPv,
  onUpdatePvList,
}) => {
  const [form] = Form.useForm();
  // 获取uetid
  const { id } = useParams<{ id: string }>();
  const [priceEditList, setPriceEditList] = useState<number[]>([]);
  const GridConnectionPointRef = useRef<(NetInfoProp[] | null)[]>([]);

  // 编辑电价信息
  const priceEditBtn = (index: number, cancel?: boolean) => {
    let currentIndex = priceEditList.findIndex(m => m === index);
    if (currentIndex > -1) {
      priceEditList.splice(currentIndex, 1);
      setPriceEditList([...priceEditList]);
      return;
    }
    if (cancel) {
      return;
    }
    setPriceEditList([...priceEditList, index]);
  };

  useEffect(() => {
    form.setFieldsValue({
      costElectricityPrice: pvStation?.costElectricityPrice,
      feedInElectricityPrices: pvStation?.feedInElectricityPrices?.map(item => {
        return {
          ...item,
          beginDate: dayjs(item.beginDate),
          endDate: dayjs(item.endDate),
        };
      }),
      subsidyElectricityPrices: pvStation?.subsidyElectricityPrices?.map(item => {
        return {
          ...item,
          beginDate: dayjs(item.beginDate),
          endDate: dayjs(item.endDate),
        };
      }),
      gatewayMeterId: pvStation?.gatewayMeterId,
    });
  }, [pvStation, form]);

  const permission = useTenantPermissions();
  // 新增光伏站按钮
  const showAddPvBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_PV_STATION_ADD) && !isDetail;
  }, [permission, isDetail]);
  // 编辑光伏站按钮
  const showEditPvBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_PV_NET_EDIT) && !isDetail;
  }, [permission, isDetail]);

  const checkOverlap = (items = []) => {
    let filterItems = items
      ?.filter((m: PriceItem) => m?.beginDate && !isNil(m?.electricityPrice) && m?.endDate)
      ?.map((i: PriceItem) => {
        return {
          ...i,
          beginDate: dayjs(i.beginDate).valueOf(),
          endDate: dayjs(i.endDate).valueOf(),
        };
      });
    const beginList = filterItems.map(k => k.beginDate).sort((a, b) => a - b);
    const endList = filterItems.map(k => k.endDate).sort((a, b) => a - b);
    for (let k = 0; k < beginList.length; k++) {
      if (beginList[k] >= endList[k]) {
        return true;
      }
    }
    return false;
  };

  // 校验同组内是否有重复区间
  const preCheckData = () => {
    let { feedInElectricityPrices, subsidyElectricityPrices } = form.getFieldsValue([
      'feedInElectricityPrices',
      'subsidyElectricityPrices',
    ]);

    if (checkOverlap(feedInElectricityPrices)) {
      Modal.warning({
        title: '上网电价存在重叠日期区间，请修改',
      });
      return false;
    }
    if (checkOverlap(subsidyElectricityPrices)) {
      Modal.warning({
        title: '补贴电价存在重叠日期区间，请修改',
      });
      return false;
    }
    return true;
  };

  const preHandleData = (v: PvInfoProp, index: number) => {
    // 并网点
    let gridList = GridConnectionPointRef.current[index] || [];
    gridList.forEach(item => {
      item.pvStationId = v.id;
      item.loadTransformerIds = item.loadTransformers?.map(v => v.id);
      item.meterId = item.meter?.id;
      item.gatewayMeterId = item.gatewayMeter?.id;
    });
    // 电价信息
    let { costElectricityPrice, feedInElectricityPrices, subsidyElectricityPrices, gatewayMeterId } =
      form.getFieldsValue([
        'costElectricityPrice',
        'feedInElectricityPrices',
        'subsidyElectricityPrices',
        'gatewayMeterId',
      ]);

    let params = {
      pvStation: {
        ...v,
        costElectricityPrice,
        gatewayMeterId,
        feedInElectricityPrices: feedInElectricityPrices
          ?.filter((m: PriceItem) => m?.beginDate && !isNil(m?.electricityPrice) && m?.endDate)
          ?.map((i: PriceItem) => {
            return {
              ...i,
              beginDate: dayjs(i.beginDate).format('YYYY-MM-DD'),
              endDate: dayjs(i.endDate).format('YYYY-MM-DD'),
            };
          }),
        subsidyElectricityPrices: subsidyElectricityPrices
          ?.filter((m: PriceItem) => m?.beginDate && !isNil(m?.electricityPrice) && m?.endDate)
          ?.map((i: PriceItem) => {
            return {
              ...i,
              beginDate: dayjs(i.beginDate).format('YYYY-MM-DD'),
              endDate: dayjs(i.endDate).format('YYYY-MM-DD'),
            };
          }),
        uetId: Number(id),
      },
      gridList,
    };
    return params;
  };

  // 更新光伏站
  const onEdit = (v: PvInfoProp, index: number) => {
    form.validateFields().then(() => {
      if (!preCheckData()) return;
      let data = preHandleData(v, index);
      let params = {
        pvStationUpdateRequest: data.pvStation,
        gridConnectionPointUpdateRequests: data.gridList,
      };
      if (data.gridList.map(i => i.isForwOrRevEdit).includes(true)) {
        message.error('有暂未保存的并网点正反向卡片！');
      } else {
        updateUetPv(id!, params).then(res => {
          setUpdateCiruit([]);
          priceEditBtn(index, true);
          onUpdatePvList(res);
          message.success('更新成功');
        });
      }
    });
  };

  // 创建/编辑光伏站
  const onSubmit = async (v: PvInfoProp, index: number) => {
    form.validateFields().then(() => {
      if (!preCheckData()) return;
      let data = preHandleData(v, index);
      let params = {
        pvStationCreateRequest: data.pvStation,
        gridConnectionPointCreateRequests: data.gridList,
      };
      if (data.gridList.map(i => i.isForwOrRevEdit).includes(true)) {
        message.error('有暂未保存的并网点正反向卡片！');
      } else {
        createUetPv(id!, params).then(res => {
          v.code = res.pvStation.code;
          v.id = res.pvStation.id;
          priceEditBtn(index, true);
          onUpdatePvList(res);
          message.success('保存成功');
        });
      }
    });
  };
  const colums = [
    {
      title: '光伏站编号',
      ellipsis: true,
      dataIndex: 'code',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '光伏站名称',
      ellipsis: true,
      dataIndex: 'name',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '装机容量',
      ellipsis: true,
      dataIndex: 'installedCapacity',
      render: (v: string) => <EllipsisSpan value={v ? (+v).toFixed(2) + 'kW' : '--'} />,
    },
    {
      title: '运营单元',
      ellipsis: true,
      dataIndex: 'ou',
      render: (v: string, record: PvInfoProp) => <EllipsisSpan value={record?.ou?.name} />,
    },
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 100,
      render: (v: any, record: PvInfoProp) => {
        return (
          <Space size={16}>
            {showEditPvBtn ? (
              <Button type="link" onClick={() => onPvModal(pvIndex!)}>
                编辑
              </Button>
            ) : (
              '--'
            )}
          </Space>
        );
      },
    },
  ];
  const [updateCircuit, setUpdateCiruit] = useState<CircuitProps[]>();
  const updateCircuitEmit = () => {
    if (typeof pvIndex === 'number') {
      let instance = GridConnectionPointRef.current[pvIndex];
      let ids = instance?.filter(item => item.circuitId).map(item => item.circuitId!);
      getBindingCircuitList(ids ?? []).then(setUpdateCiruit);
    }
  };
  return (
    <div>
      <Form form={form} className={styles.top} key={'form' + pvIndex}>
        <Table columns={colums} dataSource={pvStation ? [pvStation] : []} rowKey={'table' + pvIndex} />
        <div style={{ height: 24 }}></div>
        {/* 并网点列表 */}
        <div>
          <Space size={0} className={styles.title_line}>
            <span>创建并网点及回路绑定</span>
            <Button
              type="link"
              onClick={() => {
                window.open(`/dc/circuit/circuitManage`, '_blank');
              }}
            >
              回路管理
              <DoubleRightOutlined />
            </Button>
          </Space>
          <PvStationContext.Provider
            value={{
              id: pvStation?.id,
              ou: pvStation?.ou,
              hasPutOn: pvStation?.hasPutOn,
              updateCircuitEmit: updateCircuitEmit,
            }}
          >
            <GridConnectionPoint
              isDetail={isDetail}
              listData={gridConnectionPoints}
              ref={f => (GridConnectionPointRef.current[pvIndex!] = f)}
              hasPutOn={pvStation?.hasPutOn}
            />
          </PvStationContext.Provider>
        </div>
        <div style={{ height: 24 }}></div>
        {/* 电网计费回路 */}
        <div>
          <Space size={8} className={styles.title_line_box}>
            <span>电网计费回路</span>
            <Space className={styles.right_tips}>
              <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
              <span>
                注意，电网计费回路下的总计量电表将用于计算上网电量、上网收益，如有上网需求请配置相关回路和电表
              </span>
            </Space>
          </Space>
          <CircuitGrid data={updateCircuit ?? circuits} />
        </div>
        {/* 抄表业务关口表 */}
        <div style={{ height: 24 }}></div>
        <div>
          <Space size={16} className={styles.title_line}>
            <span>抄表业务关口表</span>
          </Space>
          <div>
            <Space>
              <Form.Item labelCol={{ style: { width: 70 } }} label="关联电表" name="gatewayMeterId">
                <Select
                  showSearch
                  allowClear
                  placeholder="请选择"
                  style={{ width: 260 }}
                  options={electricityDeviceList}
                  fieldNames={{
                    label: 'name',
                    value: 'id',
                  }}
                  filterOption={(input, option) => (option?.name ?? '').includes(input)}
                  onChange={val => {
                    form.setFieldValue('gatewayMeterId', val);
                  }}
                />
              </Form.Item>
              <Space style={{ marginLeft: 8, paddingBottom: 25 }} className={styles.title_line_box}>
                <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
                <span className={styles.right_tips}>该电表将用于抄表计量，如有抄表需求请选择该电表</span>
              </Space>
            </Space>
          </div>
        </div>

        {/* 投放光伏站 */}
        <div>
          <Space size={0} className={styles.title_line}>
            <span>投放光伏站</span>
            <Button
              type="link"
              onClick={() => {
                window.open(`${window.DMEPLATFORMSORIGIN}/pv-storage/basic/manage`, '_blank');
              }}
            >
              光伏站列表
              <DoubleRightOutlined />
            </Button>
          </Space>
          <DeliveryStatus
            isDetail={isDetail}
            pvId={pvStation?.id}
            update={() => onUetStatus(pvIndex!)}
            gridNetList={gridConnectionPoints}
            hasPutOn={pvStation?.hasPutOn}
          />
        </div>

        <div style={{ height: 32 }}></div>
        {pvStation?.id && showEditPvBtn && (
          <Button type="primary" onClick={() => onEdit(pvStation!, pvIndex!)}>
            更新
          </Button>
        )}
        {!pvStation?.id && showAddPvBtn && (
          <Space size={8}>
            <Button type="primary" onClick={() => onSubmit(pvStation!, pvIndex!)}>
              保存
            </Button>
            <Button onClick={() => onDelPv(pvIndex!)}>取消</Button>
          </Space>
        )}
      </Form>
    </div>
  );
};

export default PvStation;

import { useUpdate, Input, Paging, usePaging, Table, Button, Modal } from '@maxtropy/components';
import { useContext, useEffect, useState } from 'react';
import styles from './index.module.scss';
import type { ColumnsType } from 'antd/es/table';
import { apiV2OutputProductPagePost } from '@maxtropy/device-customer-apis-v2';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { BaseLineContext } from '../utils/common';
import { isNil } from 'lodash';
import { OutputUnitType } from '@/api/outputConfig';
import { useNavigate } from 'react-router-dom';
import { Space } from 'antd';

interface SelectProductProps {
  nextStep?: () => void;
}

const SelectProduct: React.FC<SelectProductProps> = ({ nextStep }) => {
  const [update, updateFn] = useUpdate();
  const { lineItem, setLineItem } = useContext(BaseLineContext);

  const pagingInfo = usePaging(10);
  const navigate = useNavigate();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [outputData, setOutputData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isNil(lineItem?.outputProductId)) {
      setSelectedRowKeys([lineItem!.outputProductId!]);
    }
  }, [lineItem]);

  useEffect(() => {
    setLoading(true);
    apiV2OutputProductPagePost({
      codeOrNameOrSpec: searchValue,
      state: 1, // 启用
      characteristic: 1, // 高频生产
      page: pageOffset,
      size: pageSize,
    })
      .then((res: any) => {
        setOutputData(res?.list);
        setTotalCount(res?.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, setTotalCount, update]);

  const next = () => {
    if (!selectedRowKeys.length) {
      Modal.warning({ title: '请选择产出物' });
      return;
    }
    nextStep?.();
  };

  const columns: ColumnsType<any> = [
    {
      title: '产出物编码',
      dataIndex: 'code',
      ellipsis: true,
    },
    {
      title: '产出物名称',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: '规格型号',
      ellipsis: true,
      dataIndex: 'spec',
    },
  ];

  return (
    <div className={styles.SelectProduct}>
      <div className={styles.headerSearch}>
        <Input
          placeholder="请输入产出物名称/编码/规格"
          className={styles.searchInput}
          suffix={<SearchOutlined />}
          onBlur={e => {
            setSearchValue(e.target.value);
            setPageOffset(1);
            updateFn();
          }}
          onPressEnter={(e: any) => {
            setSearchValue(e.target.value);
            setPageOffset(1);
            updateFn();
          }}
        ></Input>
        <div className={styles.rightTip}>
          <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} /> 仅展示高频生产的产出物
        </div>
      </div>
      <Table
        rowKey={record => record.id}
        loading={loading}
        rowSelection={{
          selectedRowKeys,
          type: 'radio',
          onSelect: (record, selected, selectedRows) => {
            setSelectedRowKeys([record.id]);
            setLineItem?.({
              name: record.name,
              code: record.code,
              outputProductId: record.id,
              unitCode: record.unitCode,
              unitName: record.unitName,
              unit: record.unitTypeCode === OutputUnitType.PIECE ? record.unitName : record.unitCode,
              unitTypeCode: record.unitTypeCode,
            });
          },
        }}
        columns={columns}
        dataSource={outputData}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
      <Space size={8}>
        <Button
          onClick={() => {
            navigate('/systemConfig/productionTopo/baseLineManage');
          }}
        >
          返回
        </Button>
        <Button type="primary" onClick={next}>
          下一步
        </Button>
      </Space>
    </div>
  );
};
export default SelectProduct;

import { isNil } from 'lodash-es';

export const setFitScale = (
  targetContainer?: HTMLDivElement,
  currentClientWidth?: number,
  currentClientHeight?: number,
  isFullScreen?: boolean
) => {
  if (!targetContainer || !currentClientWidth || !currentClientHeight) return;
  const designWidth = 1920;

  let scale = currentClientWidth / designWidth;

  // 实际容器的高度(减去页面顶部导航栏的高度)
  let containerHeight = isFullScreen
    ? document.documentElement.clientHeight
    : document.documentElement.clientHeight - 50;

  /**
   * 1. 当前容器的宽度>=设计稿的宽度时，才进行缩放
   * 2. 先等比缩放, 只考虑宽度, 高度未撑满的话, 取容器高度, 然后再进行flex布局
   *
   */

  if (currentClientWidth >= designWidth) {
    // let currentH = containerHeight >= designHeight ? containerHeight : designHeight;
    let currentH = containerHeight;

    targetContainer.style.width = `${designWidth}px`;

    targetContainer.style.height = `${currentH / scale}px`;
    targetContainer.style.transform = `scale3d(${scale}, ${scale}, ${scale})`;
    targetContainer.style.transformOrigin = 'top left';

    return;
  }
  targetContainer.style.width = '';
  targetContainer.style.height = '';
  targetContainer.style.transform = '';
  targetContainer.style.transformOrigin = '';
};

export function goFullscreen(element: HTMLDivElement) {
  if (document.fullscreenElement) {
    return Promise.reject(new Error('全屏模式被禁用'));
  }
  let result = null;
  if (element.requestFullscreen) {
    result = element.requestFullscreen();
  }
  return result || Promise.reject(new Error('不支持全屏'));
}
export function cancelFullscreen() {
  document.exitFullscreen?.();
}

export const formatCompositeNumWithUnit = (num: number) => {
  if (num > 10000) {
    return `${(num / 1000).toFixed(2)}tce`;
  } else {
    return `${num.toFixed(2)}kgce`;
  }
};

export const formatMediaNumWithUnit = (num: number, unit?: string) => {
  if (num > 100000) {
    return `${(num / 10000).toFixed(2)}万${unit}`;
  } else {
    return `${num.toFixed(2)}${unit}`;
  }
};

export const keepTwoNull = (data?: number) => {
  if (!isNil(data)) {
    return toFixedTwo(data);
  } else {
    return null;
  }
};

export const keepTwoNullMulti100 = (data?: number) => {
  if (!isNil(data)) {
    if (data * 100 > 100) {
      return toFixedTwo(100);
    } else if (data * 100 < 100) {
      return toFixedTwo(0);
    } else {
      return toFixedTwo(data * 100);
    }
  } else {
    return null;
  }
};

export const keepTwoNullRid1000 = (data?: number) => {
  if (!isNil(data)) {
    return toFixedTwo(data / 1000);
  } else {
    return null;
  }
};

// 保留两位小数（截取）
export const toFixedTwo = (num: number) => {
  return Math.floor(num * 100) / 100;
};

export const keepTwoOrLine = (data?: number) => {
  if (!isNil(data)) {
    return toFixedTwo(data);
  }
  return '--';
};

export const keepTwoOrLineWithTenThousand = (data?: number, unit?: string) => {
  if (!isNil(data)) {
    if (unit === 'kWh') {
      return data > 1000 ? toFixedTwo(data / 1000) : toFixedTwo(data);
    }
    return data > 10000 ? toFixedTwo(data / 10000) : toFixedTwo(data);
  }
  return '--';
};

export const formatUnitWithTenThousand = (data?: number, unit?: string) => {
  if (!isNil(unit) && !isNil(data)) {
    if (unit === 'kWh') {
      return data > 1000 ? 'MWh' : unit;
    }
    return data > 10000 ? '万' + unit : unit;
  }
  return '--';
};

// 数组对象去重
export const uniqueArray = (array: any[]) => {
  return Array.from(new Map(array.map(item => [item.key, item])).values());
};

export enum EnergyMediumSceneEnum {
  WATER = 500,
  GAS = 503,
  INDUSTRIAL_GASES = 504,
  VENTILATION_AND_AIR = 505,
  INDUSTRIAL_LIQUID = 506,
  COMPRESSED_AIR = 501,
  HOT_STEAM = 502,
  ELEC = 507, // 电能
}

export const EnergyMediumSceneEnumDisplay = {
  [EnergyMediumSceneEnum.WATER]: '用水监控数',
  [EnergyMediumSceneEnum.GAS]: '空压监控数',
  [EnergyMediumSceneEnum.INDUSTRIAL_GASES]: '工业气体监控数',
  [EnergyMediumSceneEnum.VENTILATION_AND_AIR]: '空调暖通监控数',
  [EnergyMediumSceneEnum.INDUSTRIAL_LIQUID]: '工业液体监控数',
  [EnergyMediumSceneEnum.COMPRESSED_AIR]: '用气监控数',
  [EnergyMediumSceneEnum.HOT_STEAM]: '蒸汽监控数',
  [EnergyMediumSceneEnum.ELEC]: '用电监控数',
};

export const positionPlace = [
  {
    name: '金益',
    bottom: 35,
    left: 64,
  },
  {
    name: '铭恒',
    bottom: 48,
    left: 72,
  },
  {
    name: '热能回收',
    bottom: 44,
    left: 68,
  },
  {
    name: '16MW屋顶光伏',
    bottom: 31,
    left: 54,
  },
  {
    name: '数字化能源平台',
    bottom: 39,
    left: 46,
  },
  {
    name: '智慧能源微网',
    bottom: 51,
    left: 47,
  },
  {
    name: '罗普斯金',
    bottom: 56,
    left: 42,
  },
  {
    name: '新能源生产基地',
    bottom: 48,
    left: 37,
  },
  {
    name: '光储充电棚',
    bottom: 44,
    left: 26,
  },
  {
    name: '被动房',
    bottom: 51,
    left: 21,
  },
];

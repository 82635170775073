import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  Wrapper,
  useBreadcrumbRoutes,
  message,
  Button,
  FormTitle,
  SubContent,
  Select,
  Radio,
  Input,
  Form,
  TreeSelect,
  InputNumber,
} from '@maxtropy/components';
import { Col, Row, Space, Empty } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import {
  EnergyType,
  OutputTypeDisplay,
  OutputUnitTypeDisplay,
  UnitTypeWithUnitList,
  OutputUnitType,
  getUnits,
} from '@/api/outputConfig';
import {
  apiV2OutputProductBaseLinePhysicalUnitPost,
  apiV2OutputProductCreatePost,
  V2OutputProductCreatePostRequest,
  apiV2OutputProductCategoryListPost,
  V2OutputProductCategoryListPostResponse,
  apiV2OutputProductCategoryListSpecByCategoryIdPost,
  V2OutputProductCategoryListSpecByCategoryIdPostResponse,
  V2OutputProductDetailPostResponse,
  apiV2OutputProductDetailPost,
  apiV2OutputProductUpdatePost,
} from '@maxtropy/device-customer-apis-v2';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { UetEMTAllEnergyMediumListResponse, getUetEMTAllEnergyMediumList } from '@/api/uet';
import { InputType } from '../OutputCategory';
import { CHARACTERISTIC, CHARACTERISTIC_FORMART, ConfigType, MapList, OptionUnit, TreeNode, UnitItem } from './utils';
import ProductLink from './components/ProductLink';
import ProductivityInfo from './components/ProductivityInfo';

const formatTreeData = (data: V2OutputProductCategoryListPostResponse['children']): TreeNode[] => {
  return (
    data?.map((item: any) => {
      const res = {
        key: item.id!,
        value: item.id,
        title: item.name,
        children: formatTreeData(item.children),
        disabled: item.id === 0,
      };
      return res;
    }) || []
  );
};

const CreateOutput: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // 新增才会有productValue
  const productValue = searchParams.get('productValue');

  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();

  // 所有单位
  const [units, setUnits] = useState<UnitTypeWithUnitList[]>([]);
  const [unitCodeAndUnitNameList, setUnitCodeAndUnitNameList] = useState<UnitItem[]>([]);
  const [detail, setDetail] = useState<V2OutputProductDetailPostResponse>();

  // 获取所有能源介质, 不包含场景, 例如新水, 包含电能
  const [mediumList, setMediumList] = useState<UetEMTAllEnergyMediumListResponse[]>([]);
  const [physicalUnitId, setPhysicalUnitId] = useState<OptionUnit[]>([]);
  const [physicalOption, setPhysicalOptions] = useState<any>({});
  const [physicalUnitOption, setPhysicalUnitOptions] = useState<any>({});
  const [treeData, setTreeData] = useState<any>([]);

  const [currentIndex, setCurrentIndex] = useState<any>();
  const [currentRecordId, setCurrentRecordId] = useState<number>();
  const [specList, setSpecList] = useState<V2OutputProductCategoryListSpecByCategoryIdPostResponse['list']>();
  // 单位类型
  const unitTypeCode = Form.useWatch('unitTypeCode', form);
  // 具体单位
  const unitCode = Form.useWatch('unitCode', form);

  const outputCategoryId = Form.useWatch('categoryId', form);

  useEffect(() => {
    apiV2OutputProductCategoryListPost({}).then(res => {
      setTreeData(formatTreeData(res?.children));
      if (!id) {
        // 新增
        form.setFieldsValue({ categoryId: Number(productValue) === 0 ? undefined : productValue });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      // 获取产出物详情
      apiV2OutputProductDetailPost({ id: Number(id) }).then(res => {
        if (res) {
          res.baseLines?.forEach(item => {
            apiV2OutputProductBaseLinePhysicalUnitPost({ id: item.energyMediumId as number }).then(result => {
              const physicalListMap: Record<string, any[]> = {};
              const unitListMap: Record<string, any[]> = {};

              const physicalOption = result?.list?.map((i: any) => ({
                label: i.basePhysicalQuantityName,
                value: i.basePhysicalQuantityId,
              }));
              if (physicalOption) {
                physicalListMap[item.id as number] = physicalOption;
              }
              const unitList = result?.list;
              if (unitList) {
                unitListMap[item.id as number] = unitList;
              }
              setPhysicalOptions((prevMap: any) => ({
                ...prevMap,
                ...physicalListMap,
              }));
              setPhysicalUnitOptions((prevMap: any) => ({
                ...prevMap,
                ...unitListMap,
              }));

              if (item.physicalUnitName && item.physicalUnitId) {
                setPhysicalUnitId(prev => [
                  ...prev,
                  {
                    name: item.physicalUnitName,
                    value: item.physicalUnitId,
                  },
                ]);
              }
            });
          });

          setDetail(res);
          form.setFieldsValue({
            ...res,
            specList: res.outputProductCategorySpecList,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    apiV2OutputProductCategoryListSpecByCategoryIdPost({ id: outputCategoryId }).then(res => {
      setSpecList(res.list ?? []);
      const data =
        res.list?.map(item => ({
          specId: item.id,
          specEnumId: detail?.outputProductCategorySpecList?.find(spec => spec.specId === item.id)?.specEnumId,
          specValue: detail?.outputProductCategorySpecList?.find(spec => spec.specId === item.id)?.specValue,
        })) || [];

      form.setFieldValue('specList', data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputCategoryId, detail]);

  // 获取所有工质列表, 包含电能
  useEffect(() => {
    getUetEMTAllEnergyMediumList().then(res => {
      setMediumList(res);
    });
  }, []);

  //能源类型自动关联物理量
  useEffect(() => {
    form.setFieldValue(
      ['baseLines', currentIndex, 'basePhysicalQuantityId'],
      physicalOption[currentRecordId as number]?.[physicalOption[currentRecordId as number].length - 1]?.value
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [physicalOption]);

  useEffect(() => {
    const baseLines = form.getFieldValue('baseLines');

    for (let index = 0; index < baseLines.length; index++) {
      const item = baseLines[index];
      const result = findPhysicalUnitInfo(physicalUnitOption[item.id], item.basePhysicalQuantityId);

      if (!isNil(result)) {
        baseLines.splice(index, 1, {
          ...baseLines[index],
          physicalUnitId: result.physicalUnitId ?? '--',
        });
        physicalUnitId.splice(index, 1, {
          name: result.physicalUnitName,
          value: result.physicalUnitId,
        });

        setPhysicalUnitId(physicalUnitId);
        form.setFieldsValue({ baseLines });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [physicalUnitOption]);

  useEffect(() => {
    // 获取所有单位
    getUnits().then(res => {
      if (res) {
        setUnits(res.list);
        setUnitCodeAndUnitNameList([...res.list[0].outputProductUnitList, ...res.list[1].outputProductUnitList]);
      }
    });
  }, []);

  // 设置参考单耗基线能源类型初始表格
  useEffect(() => {
    const baseLines = form.getFieldValue('baseLines');
    const values = Object.values(EnergyType)
      .filter(value => !isNaN(Number(value)))
      .map((v, i) => ({
        energyTypeCode: v as EnergyType,
        configType: ConfigType.MANUALENTRY,
        baseLineValue: baseLines?.[i]?.baseLineValue,
        advancedValue: baseLines?.[i]?.advancedValue,
        id: `${Date.now()}-${Math.floor(Math.random() * 1000)}`,
      }));
    form.setFieldValue('baseLines', values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 根据选择计量单位还是计件单位展示不同的单位选项
  const unitNameOptions = useMemo(() => {
    const matchedUnit = units.find(u => u.unitTypeCode === unitTypeCode);
    if (matchedUnit) {
      return (
        matchedUnit?.outputProductUnitList?.map(v => ({
          value: v?.unitCode,
          label: unitTypeCode === OutputUnitType.PIECE ? v.unitName : `${v.unitCode} (${v?.unitName})`,
        })) ?? []
      );
    } else {
      return [];
    }
  }, [unitTypeCode, units]);

  const RenderSpecList = useMemo(() => {
    return (
      <SubContent title="规格参数">
        <Row>
          {specList?.map((spec, index) => {
            return (
              <Col span={12} key={index}>
                <Form.Item
                  name={['specList', index, 'specValue']}
                  label={`${spec.name}`}
                  rules={[{ max: 64, message: '最多输入64个字符' }]}
                >
                  {spec.type === InputType.ENUM ? (
                    <Select
                      placeholder="请选择"
                      showSearch
                      allowClear
                      onChange={(_, option: any) => {
                        form.setFieldValue(['specList', index, 'specEnumId'], option?.key);
                      }}
                      notFoundContent={
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="请先在产出物分类中添加规格值" />
                      }
                    >
                      {spec?.enumList?.map(i => {
                        return (
                          <Select.Option key={i.id} value={i.value}>
                            {i.value}
                          </Select.Option>
                        );
                      }) || []}
                    </Select>
                  ) : (
                    <Input placeholder="请输入" />
                  )}
                </Form.Item>
                <Form.Item name={['specList', index, 'specEnumId']} hidden>
                  <InputNumber placeholder="请输入" />
                </Form.Item>
                <Form.Item name={['specList', index, 'specId']} hidden>
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </SubContent>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specList]);

  function findPhysicalUnitInfo(data: any[], basePhysicalQuantityId: number) {
    return data?.find(
      (item: { basePhysicalQuantityId: any }) => item.basePhysicalQuantityId === basePhysicalQuantityId
    );
  }

  const columns = [
    {
      title: '能源类型',
      dataIndex: 'energyMediumId',
      ellipsis: { showTitle: true },
      render: (v: EnergyType, record: any, index: number) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              <Form.Item
                style={{ minWidth: 150, marginBottom: 0, display: 'inline-block' }}
                name={['baseLines', index, 'energyMediumId']}
                rules={[{ required: true, message: '请选择能源类型' }]}
              >
                <Select
                  placeholder="请选择能源类型"
                  showSearch
                  optionFilterProp="label"
                  style={{ minWidth: 150 }}
                  options={mediumList.map(ct => ({
                    label: ct.energyName,
                    value: ct.id,
                  }))}
                  onChange={val => {
                    apiV2OutputProductBaseLinePhysicalUnitPost({ id: val }).then(res => {
                      const physicalListMap: MapList = {};
                      const unitListMap: Record<string, any[]> = {};

                      const physicalOption = res?.list?.map(i => ({
                        label: i.basePhysicalQuantityName,
                        value: i.basePhysicalQuantityId,
                      }));
                      if (!isNil(record.id) && !isNil(res.list)) {
                        if (physicalOption) {
                          physicalListMap[record.id] = physicalOption;
                        }
                        const unitList = res?.list;
                        if (unitList) {
                          unitListMap[record.id] = unitList;
                        }
                        setPhysicalOptions((prevMap: MapList) => ({
                          ...prevMap,
                          ...physicalListMap,
                        }));
                        setPhysicalUnitOptions((prevMap: MapList) => ({
                          ...prevMap,
                          ...unitListMap,
                        }));
                      }
                    });
                    physicalUnitId.splice(index, 1, {
                      name: '--',
                      value: undefined,
                    });
                    setPhysicalUnitId(physicalUnitId);
                  }}
                  onSelect={val => {
                    setCurrentIndex(index as number);
                    setCurrentRecordId(record.id);
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, display: 'inline-block', marginLeft: 15 }}
                name={['baseLines', index, 'physicalUnitId']}
              >
                {physicalUnitId[index]?.name ?? '--'}/
                {(unitTypeCode === OutputUnitType.PIECE
                  ? units
                      .find(u => u.unitTypeCode === OutputUnitType.PIECE)
                      ?.outputProductUnitList?.find(e => e?.unitCode === unitCode)?.unitName
                  : unitCode) ?? '--'}
              </Form.Item>
            </div>
          </>
        );
      },
    },
    {
      title: '物理量',
      dataIndex: 'basePhysicalQuantityId',
      ellipsis: { showTitle: true },
      render: (v: number, record: any, index: any) => {
        return (
          <Form.Item
            style={{ marginBottom: 0 }}
            name={['baseLines', index, 'basePhysicalQuantityId']}
            rules={[{ required: true, message: '请选择物理量' }]}
          >
            <Select
              allowClear
              options={physicalOption[record.id]}
              placeholder="请选择物理量"
              onChange={val => {
                const result = findPhysicalUnitInfo(physicalUnitOption[record.id], val);
                const baseLines = form.getFieldValue('baseLines');
                const baseLinesTemp = cloneDeep(baseLines);

                baseLinesTemp.splice(index, 1, {
                  ...baseLines[index],
                  physicalUnitId: result.physicalUnitId ?? '--',
                });
                physicalUnitId.splice(index, 1, {
                  name: result.physicalUnitName,
                  value: result.physicalUnitId,
                });
                setPhysicalUnitId(physicalUnitId);
                form.setFieldsValue({ baseLines: baseLinesTemp });
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '基准值',
      dataIndex: 'baseLineValue',
      ellipsis: { showTitle: true },
      render: (text: string, record: any, index: number) => {
        return (
          <Form.Item noStyle name={['baseLines', index, 'baseLineValue']}>
            <InputNumber style={{ width: '100%' }} min={0} max={99999} precision={4} placeholder={`请输入基准值`} />
          </Form.Item>
        );
      },
    },
    {
      title: '先进值',
      dataIndex: 'advancedValue',
      ellipsis: { showTitle: true },
      render: (text: string, record: any, index: number) => {
        return (
          <Form.Item noStyle name={['baseLines', index, 'advancedValue']}>
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              max={99999}
              precision={4}
              // 支持4位小数
              // formatter={value =>
              //   !isNaN(value!) ? String(value).replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3') : ''
              // }
              placeholder={`请输入先进值`}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '操作',
      ellipsis: { showTitle: true },
      width: '6%',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                handleRemove(record.id);
              }}
            >
              删除
            </Button>
          </>
        );
      },
    },
  ];

  const handleAdd = () => {
    let newData: any = {
      advancedValue: undefined,
      baseLineValue: undefined,
      configType: ConfigType.MANUALENTRY,
      id: `${Date.now()}-${Math.floor(Math.random() * 1000)}`,
    };
    const prevBaseLine = form.getFieldValue('baseLines') || [];
    form.setFieldsValue({ baseLines: [...prevBaseLine, newData] });
  };

  const handleRemove = (id: number | string) => {
    const prevBaseLine: V2OutputProductDetailPostResponse['baseLines'] = form.getFieldValue('baseLines');
    const latestBaseLine = prevBaseLine?.filter(item => item.id !== id) || [];
    form.setFieldsValue({ baseLines: latestBaseLine });
  };

  const onFinish = (v: any) => {
    form.validateFields().then(res => {
      const uniqueEnergyMediumId = new Set(res.baseLines?.map((item: any) => item.energyMediumId));
      if (uniqueEnergyMediumId.size !== res?.baseLines?.length) {
        message.error('能源类型不能重复!');
        return;
      }
      delete v.nationalEconomicIndustryId;

      const params: V2OutputProductCreatePostRequest = {
        ...v,
        baseLines: v.baseLines?.map((k: any) => ({ ...k, configType: ConfigType.MANUALENTRY })),
      };
      if (id) {
        apiV2OutputProductUpdatePost({
          id: Number(id),
          ...params,
        }).then(() => {
          navigate('/energy/output/config');
        });
      } else {
        apiV2OutputProductCreatePost(params).then(() => {
          navigate('/energy/output/config');
        });
      }
    });
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.page_content_copy}>
      <Form
        form={form}
        initialValues={{
          // 默认选择计量单位
          unitTypeCode: 1,
        }}
        onFinish={onFinish}
      >
        <FormTitle title="新增产出物" />
        <SubContent>
          <Row>
            <Col span={12}>
              <Form.Item
                name="code"
                label="产出物编码"
                rules={[
                  { required: true },
                  {
                    pattern: /^((?![\\/*?？:：''<>|\u4e00-\u9fa5]).)+$/,
                    message: `请确保产出物编码中不含下列字符：\\ / * ? ？ : ： ' ' < > |和中文`,
                  },
                ]}
                tooltip={{
                  title: '编码在生产单、产量录入或者物料表中被使用后不可被修改',
                  icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
                }}
              >
                <Input maxLength={32} placeholder="请输入产出物编码" disabled={detail?.isUseCode} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                label="产出物名称"
                rules={[
                  { required: true },
                  { pattern: /^((?![\\/:*?<>|.]).)+$/, message: '请确保产出物名称中不含下列字符：\\ / : * ? < > | .' },
                ]}
              >
                <Input maxLength={80} placeholder="请输入产出物名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="categoryId" label="产出物分类" rules={[{ required: true }]}>
                <TreeSelect
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeDefaultExpandAll
                  treeData={treeData}
                  placeholder="请选择产出物分类"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="type" label="类型" rules={[{ required: true }]}>
                <Select
                  placeholder="请选择类型"
                  options={Object.entries(OutputTypeDisplay).map(([k, v]) => ({ label: v, value: Number(k) }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="characteristic" label="产出物特性">
                <Radio.Group>
                  <Radio
                    onClick={() => {
                      return form.getFieldValue('characteristic') === 1
                        ? form.setFieldsValue({ characteristic: undefined })
                        : null;
                    }}
                    value={CHARACTERISTIC.HIGH}
                  >
                    {CHARACTERISTIC_FORMART[CHARACTERISTIC.HIGH]}
                  </Radio>
                  <Radio
                    onClick={() => {
                      return form.getFieldValue('characteristic') === 2
                        ? form.setFieldsValue({ characteristic: undefined })
                        : null;
                    }}
                    value={CHARACTERISTIC.LOWER}
                  >
                    {CHARACTERISTIC_FORMART[CHARACTERISTIC.LOWER]}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="spec" label="型号">
                <Input maxLength={15} placeholder="请输入型号" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="单位"
                tooltip={{
                  title: '录入产量后不允许更改',
                  icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
                }}
                required
              >
                <Space size="middle" className={styles.unitFormItems}>
                  <Form.Item name="unitTypeCode" rules={[{ required: true, message: '请输入单位类型' }]} noStyle>
                    <Select
                      placeholder="请选择单位类型"
                      options={Object.entries(OutputUnitTypeDisplay).map(([k, v]) => ({
                        label: v,
                        value: Number(k),
                      }))}
                      onChange={() => {
                        form.setFieldValue('unitCode', undefined);
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="unitCode" rules={[{ required: true, message: '请选择单位' }]} noStyle>
                    <Select placeholder="请选择单位" options={unitNameOptions} />
                  </Form.Item>
                </Space>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="unitOutputValue" label="单位产值">
                <InputNumber precision={4} addonAfter={'元'} placeholder="请输入单位产值" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="grossWeight" label="单位毛重">
                <InputNumber precision={4} addonAfter="千克" placeholder="请输入单位毛重" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="remark" label="备注">
                <Input placeholder="请输入备注" maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
        </SubContent>
        {/*产能信息  */}
        <ProductivityInfo
          form={form}
          detail={detail}
          unitCodeAndUnitNameList={unitCodeAndUnitNameList}
          unitCode={unitCode}
        />
        {!isEmpty(specList) && RenderSpecList}
        <SubContent>
          <Row>
            <Col span={24}>
              <Space style={{ marginBottom: 10 }}>
                参考单耗基线
                <span style={{ marginLeft: 8, color: 'var(--mx-text-secondary-color)' }}>
                  依据ISO 50006标准设置相关能源基线衡量能源绩效
                </span>
              </Space>
              <Form.Item
                labelAlign="left"
                name="baseLines"
                style={{ marginBottom: 0 }}
                valuePropName="dataSource"
                labelCol={{ span: 2.5 }}
                wrapperCol={{ span: 21.5 }}
              >
                <Table rowKey="energyTypeCode" columns={columns} scroll={{ y: 400 }} />
              </Form.Item>
              <Button
                onClick={handleAdd}
                type="dashed"
                wrapClassName={styles.addRow}
                className={styles.rowInnerBtn}
                ghost
                icon={<PlusOutlined />}
              >
                新增能源类型
              </Button>
            </Col>
          </Row>
        </SubContent>
        {/* 物料表 */}
        <ProductLink form={form} unitCodeAndUnitNameList={unitCodeAndUnitNameList} detail={detail} />
        <Space className="sticky-footer" size={8}>
          <Button htmlType="submit" type="primary">
            确定
          </Button>
          <Button
            onClick={() => {
              navigate('/energy/output/config');
            }}
          >
            取消
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateOutput;

import React, { FC } from 'react';
import styles from './index.module.scss';
import light_line from '../../../../imgs/light_line.png';
import fire_gas from '../../../../imgs/fire_gas.png'; // 可燃气体类
import elec_icon from '../../../../imgs/elec_icon.png'; // 电
import water_icon from '../../../../imgs/water_icon.png'; // 水
import air_icon from '../../../../imgs/air_icon.png'; // 空调
import hotSteam_icon from '../../../../imgs/hotSteam_icon.png'; // 热蒸汽
import ind_water_icon from '../../../../imgs/ind_water_icon.png'; // 工业液体
import comp_air_icon from '../../../../imgs/comp_air_icon.png'; // 压缩空气
import ind_air_icon from '../../../../imgs/ind_air_icon.png'; // 工业气体
import { V2EnergyCarbonOverviewTotalCostPostResponse } from '@maxtropy/device-customer-apis-v2';
import { formatUnitWithTenThousand, keepTwoOrLine, keepTwoOrLineWithTenThousand } from '@/pages/ZYFDashboard/utils';
import CustomTooltip from '../../../CustomTooltip';

const mediumIdwithImg = [
  {
    mediumId: 2001, // 新水
    img: water_icon,
  },
  {
    mediumId: 2002, // 硬水
    img: water_icon,
  },
  {
    mediumId: 2003, // 消防水
    img: water_icon,
  },
  {
    mediumId: 2004, // 饮用水
    img: water_icon,
  },
  {
    mediumId: 2005, // 中水
    img: water_icon,
  },
  {
    mediumId: 2006, // 下水/污水
    img: water_icon,
  },
  {
    mediumId: 2007, // 冷却水
    img: water_icon,
  },
  {
    mediumId: 2008, // 高温水
    img: water_icon,
  },
  {
    mediumId: 2009, // 去离子水
    img: water_icon,
  },
  {
    mediumId: 2010, // 纯水
    img: water_icon,
  },
  {
    mediumId: 2011, // 软水
    img: water_icon,
  },
  {
    mediumId: 2012, // 冷媒水
    img: water_icon,
  },
  {
    mediumId: 2013, // 饱和蒸汽
    img: hotSteam_icon,
  },
  {
    mediumId: 2013, // 饱和蒸汽
    img: hotSteam_icon,
  },
  {
    mediumId: 2014, // 湿蒸汽
    img: hotSteam_icon,
  },
  {
    mediumId: 2015, // 过热蒸汽
    img: hotSteam_icon,
  },
  {
    mediumId: 2016, // 超临界水
    img: hotSteam_icon,
  },
  {
    mediumId: 2017, // 非饱和水
    img: hotSteam_icon,
  },
  {
    mediumId: 2018, // 液化天然气（LNG）
    img: fire_gas,
  },
  {
    mediumId: 2019, // 压缩天然气（CNG）
    img: fire_gas,
  },
  {
    mediumId: 2020, // 天然气（CH4）
    img: fire_gas,
  },
  {
    mediumId: 2021, // 压缩空气
    img: comp_air_icon,
  },
  {
    mediumId: 2022, // 氧气
    img: ind_air_icon,
  },
  {
    mediumId: 2023, // 氮气
    img: ind_air_icon,
  },
  {
    mediumId: 2024, // 二氧化碳
    img: ind_air_icon,
  },
  {
    mediumId: 2025, // 氢
    img: ind_air_icon,
  },
  {
    mediumId: 2026, // 合成气体
    img: ind_air_icon,
  },
  {
    mediumId: 2027, // 焊接屏蔽气体
    img: ind_air_icon,
  },
  {
    mediumId: 2028, // 制冷剂
    img: air_icon,
  },
  {
    mediumId: 2029, // 冷水
    img: air_icon,
  },
  {
    mediumId: 2030, // 电能
    img: elec_icon,
  },
  {
    mediumId: 2031, // 工业液体
    img: ind_water_icon,
  },
];

export type ItemProps = Exclude<V2EnergyCarbonOverviewTotalCostPostResponse['details'], undefined>[number];

interface Props {
  cardInfo: ItemProps;
}

const CardItem: FC<Props> = props => {
  const { cardInfo } = props;

  return (
    <div className={styles.swip_card_wrapper}>
      <img
        className={styles.img_sty}
        src={mediumIdwithImg.find(i => i.mediumId === cardInfo?.energyMediumId)?.img}
        alt=""
      />
      <div className={styles.label}>
        <CustomTooltip
          title={`${cardInfo?.energyMediumName ?? '-'}(${formatUnitWithTenThousand(
            cardInfo?.consumption,
            cardInfo?.generalName
          )})`}
        >
          {cardInfo?.energyMediumName ?? '-'}({formatUnitWithTenThousand(cardInfo?.consumption, cardInfo?.generalName)})
        </CustomTooltip>
      </div>
      <CustomTooltip title={keepTwoOrLineWithTenThousand(cardInfo?.consumption, cardInfo?.generalName)}>
        <div className={styles.value}>{keepTwoOrLineWithTenThousand(cardInfo.consumption, cardInfo?.generalName)}</div>
      </CustomTooltip>
      <img className={styles.light_line} src={light_line} alt="" />
    </div>
  );
};

export default CardItem;

import qs from 'qs';
import { PageRequest, PageResponse, PageRequestDates, PageResponseDates } from './page';
import { fetch, Key } from '@maxtropy/components';
import { ElectricitySaleContractVo } from './electricitySalesContract';
import { SearchParams } from '@/pages/SettlementRules/ElectricitySettlementRulesList';

// 分页查询 返回的数据类型
export interface ElectricitySettlementRulesResponse {
  id: number; // 唯一标识
  name: string; // 结算规则名称
  code: string; // 结算规则编号
  propertyManagementConfigId: number; // 物业id
  propertyManagementConfigName: string; // 物业名称
  saleClientId: number; // 销售客户id
  saleClientName: string; // 销售客户名称
  customerMcid: string; //所属组织
  customerName: string; // 所属组织名称
  saleElectricityContractId: number; // 销售用电合同id
  saleElectricityContractName: string; // 销售用电合同名称
  ouId: number; // 运营单元id
  ouName: string; // 运营单元名称
  startTime: string; // 生效开始时间
  endTime: string; // 生效结束时间
  // address: string; // 位置
  status: SettlementRulesStatusType; // 状态（0:启用 1：禁用）
  // taxPoint: ElectricitySaleContractTaxPointType; // 税点
  settlementType: ElectricitySaleContractSettlementType; // 结算类型
  // settlementCycleType: SettlementCycleType; // 结算周期类型
  // settlementTime: string; // 结算时间
  createTime: string; // 创建时间
  updateTime: string; // 修改时间
  createByUserId: string; // 创建人id
  updateByUserId: string; // 修改人id
  updateSource: number; // 修改端
  deleteFlag: boolean; // 删除标记
  saleElectricitySettlementRuleSingleSettlementInfoId: number; // 单一制结算规则合同信息id

  meteringScenarios: MeasurementScenarioType; // 计量场景（1：照明，2：光伏，3：专用）
  electricityPrice: number; // 电度电价
  hasCapitalContributionRate: boolean; // 是否有公摊系数
  capitalContributionRate: number; // 公摊系数
  hasServiceCharge: boolean; // 是否有服务费
  serviceCharge: number; // 服务费
}

// 分页查询 请求参数
export interface ElectricitySettlementRulesPageRequest extends PageRequest {
  name?: string; // 结算规则名称
  code?: string; // 结算规则编号
  // meteringScenarios?: MeasurementScenarioType; // 计量场景（1：照明，2：光伏，3：专用）
  propertyManagementConfigIds?: Key[]; // 物业id
  saleElectricityContractIds?: Key[]; // 所属合同id
  saleClientIds?: Key[]; // 客户id
  effectiveState?: TakeEffectStatusType; // 生效状态
  status?: SettlementRulesStatusType; // 状态
}

export interface SettlementRulesBlogPageRequest extends PageRequest {
  name?: string; // 结算规则名称
  code?: string; // 结算规则编号
  updateTime?: string; // 修改时间
}

export interface SettlementRulesBlogResponse {
  name?: string; // 结算规则名称
  code?: string; // 结算规则编号
  updateTime?: string;
  updateName?: string;
}

// 新增结算规则参数
export interface CreateElectricitySettlementRulesRequest {
  customerMcid?: string; // 关联组织
  propertyManagementConfigId?: number; // 所属物业

  name: string; // 结算规则名称
  description: string; // 描述
  startTime?: string; // 开始时间
  endTime?: string; // 结束时间
  saleElectricityContractId: number; // 所属合同
  settlementType: ElectricitySaleContractSettlementType; //	结算类型
  onePartInfo?: {
    timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[];
    rateType?: number; // 电度电价类型
    standingRate?: number; // 电度单价
    summitRate?: number; // 尖
    peakRate?: number; // 峰
    plainRate?: number; // 平
    valleyRate?: number; // 谷
    hasCapitalContributionRate?: boolean; // 是否有公摊系数
    capitalContributionRate?: number; // 公摊系数
    // hasServiceCharge: boolean;// 是否有服务费
    // serviceCharge: number;// 服务费
    hasDiscountedRate?: number; // 是否有优惠金额
    discountedType?: number; // 优惠方式
    totalRateDiscountedRatio?: number; // 电度电费总折扣比例
    totalRateDiscountedRate?: number; // 电度电费总优惠金额
    unitRateDiscountedRate?: number; // 电度电费单价优惠金额
    totalRateDiscountedQuantity?: number; // 优惠电度数
  };
  twoPartInfo?: {
    timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[];
    declarationType?: TwoPartSystemPowerConsumptionType; // 用电类型
    rateType?: number; // 电度电价类型
    transformerCapacity?: number; // 变压器容量
    unitCapacityRate?: number; // 容量单价
    declaredDemand?: number; // 申报需量
    unitDeclaredDemandRate?: number; // 申报需量单价
    unitDemandRate?: number; // 需量单价
    standingRate?: number; // 电度单价
    summitRate?: number; // 尖
    peakRate?: number; // 峰
    plainRate?: number; // 平
    valleyRate?: number; // 谷
    hasLightingTariff?: boolean; // 是否有照明费率
    lightingTariffRatio?: number; // 照明费率
    unitLightingTariffRate?: number; // 照明费率单价
    hasDiscountedRate?: number; // 是否有优惠金额
    discountedType?: number; // 优惠方式
    totalRateDiscountedRatio?: number; // 电度电费总折扣比例
    totalRateDiscountedRate?: number; // 电度电费总优惠金额
    unitRateDiscountedRate?: number; // 电度电费单价优惠金额
    hasSubMeterRateDeduct?: boolean;
    subMeterUnitDemandRate?: number; // 子表需量单价
    subMeterSummitRate?: number; // 子表尖时电价
    subMeterPeakRate?: number; //	子表峰时电价
    subMeterPlainRate?: number; // 子表平时电价
    subMeterValleyRate?: number; //子表谷时电价
    totalRateDiscountedQuantity?: number; // 优惠电度数
  };
  distributedNewEnergyInfo?: {
    timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[]; // 电度电价类型
    summitRate?: number; // 尖
    peakRate?: number; // 峰
    plainRate?: number; // 平
    valleyRate?: number; // 谷
    hasDiscountedRate?: number; // 是否有优惠金额
    discountedType?: number; // 优惠方式
    totalRateDiscountedRatio?: number; // 电度电费总折扣比例
    totalRateDiscountedRate?: number; // 电度电费总优惠金额
    unitRateDiscountedRate?: number; // 电度电费单价优惠金额
    totalRateDiscountedQuantity?: number; // 优惠电度数
  };
  distEnergyAllFeedInInfo?: {
    timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[]; // 电度电价类型
    summitRate?: number; // 尖
    peakRate?: number; // 峰
    plainRate?: number; // 平
    valleyRate?: number; // 谷
    hasDiscountedRate?: number; // 是否有优惠金额
    discountedType?: number; // 优惠方式
    totalRateDiscountedRatio?: number; // 电度电费总折扣比例
    totalRateDiscountedRate?: number; // 电度电费总优惠金额
    unitRateDiscountedRate?: number; // 电度电费单价优惠金额
    totalRateDiscountedQuantity?: number; // 优惠电度数
  };
  twoPartAndEnergyInfo?: {
    timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[];
    declarationType?: TwoPartSystemPowerConsumptionType; // 用电类型
    rateType?: number; // 电度电价类型
    transformerCapacity?: number; // 变压器容量
    unitCapacityRate?: number; // 容量单价
    declaredDemand?: number; // 申报需量
    unitDeclaredDemandRate?: number; // 申报需量单价
    unitDemandRate?: number; // 需量单价
    standingRate?: number; // 电度单价
    summitRate?: number; // 尖
    peakRate?: number; // 峰
    plainRate?: number; // 平
    valleyRate?: number; // 谷
    hasLightingTariff?: boolean; // 是否有照明费率
    lightingTariffRatio?: number; // 照明费率
    unitLightingTariffRate?: number; // 照明费率单价
    hasDiscountedRate?: number; // 是否有优惠金额
    discountedType?: number; // 优惠方式
    totalRateDiscountedRatio?: number; // 电度电费总折扣比例
    totalRateDiscountedRate?: number; // 电度电费总优惠金额
    unitRateDiscountedRate?: number; // 电度电费单价优惠金额
    totalRateDiscountedQuantity?: number; // 优惠电度数
    powerFactorRatioCalculationType?: number; //功率因数比值计算规则
  };
  saleElectricityContractName?: string; // 所属合同（销售合同名称）
  newMeterIds?: number[]; // 新增表计id（合同生效表计id）
  removeMeterIds?: number[]; // 删除表计id（合同生效表计id）
  // 电量生效表计
  // 需量生效表计

  // status?: SettlementRulesStatusType;//状态（0:启用 1：禁用）
  // createByUserId?: string;//创建人id
  // updateByUserId?: string; //修改人id
  // updateSource?: number; // 修改端
  // deleteFlag?: boolean;//删除标记
  // singleSettlementInfo: IsingleSettlementInfo;//单一制结算规则合同信息
}

// 结算规则详情
export interface ElectricitySettlementRulesDetails extends ElectricitySettlementRulesResponse {
  saleElectricityContractId: number;
  saleElectricityContractName: string; //  // 所属合同（销售合同名称）
  description: string; // 备注
  onePartInfo?: {
    timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[];
    rateType?: number; // 电度电价类型
    standingRate?: HasTimeOfUseElectricityPriceType; // 电度单价
    summitRate?: number; // 尖
    peakRate?: number; // 峰
    plainRate?: number; // 平
    valleyRate?: number; // 谷
    hasCapitalContributionRate?: boolean; // 是否有公摊系数
    capitalContributionRate?: number; // 公摊系数
    hasServiceCharge: boolean; // 是否有服务费
    serviceCharge: number; // 服务费
    hasDiscountedRate?: number; // 是否有优惠金额
    discountedType?: HasPreferentialAmountType; // 优惠方式
    totalRateDiscountedRatio?: number; // 电度电费总折扣比例
    totalRateDiscountedRate?: number; // 电度电费总优惠金额
    unitRateDiscountedRate?: number; // 电度电费单价优惠金额
    totalRateDiscountedQuantity?: number; // 优惠电度数
  };
  twoPartInfo?: {
    timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[];
    declarationType?: TwoPartSystemPowerConsumptionType; // 用电类型
    rateType?: number; // 电度电价类型
    transformerCapacity?: number; // 变压器容量
    unitCapacityRate?: number; // 容量单价
    declaredDemand?: number; // 申报需量
    unitDeclaredDemandRate?: number; // 申报需量单价
    unitDemandRate?: number; // 需量单价
    standingRate?: number; // 电度单价
    summitRate?: number; // 尖
    peakRate?: number; // 峰
    plainRate?: number; // 平
    valleyRate?: number; // 谷
    hasLightingTariff?: boolean; // 是否有照明费率
    lightingTariffRatio?: number; // 照明费率
    unitLightingTariffRate?: number; // 照明费率单价
    // hasCapitalContributionRate?: boolean;// 是否有公摊系数
    // capitalContributionRate?: number;// 公摊系数
    // hasServiceCharge: boolean;// 是否有服务费
    // serviceCharge: number;// 服务费
    hasDiscountedRate?: number; // 是否有优惠金额
    discountedType?: HasPreferentialAmountType; // 优惠方式
    totalRateDiscountedRatio?: number; // 电度电费总折扣比例
    totalRateDiscountedRate?: number; // 电度电费总优惠金额
    unitRateDiscountedRate?: number; // 电度电费单价优惠金额
    hasSubMeterRateDeduct?: boolean;
    subMeterUnitDemandRate?: number; // 子表需量单价
    subMeterSummitRate?: number; // 子表尖时电价
    subMeterPeakRate?: number; //	子表峰时电价
    subMeterPlainRate?: number; // 子表平时电价
    subMeterValleyRate?: number; //子表谷时电价
    totalRateDiscountedQuantity?: number; // 优惠电度数
  };
  distributedNewEnergyInfo?: {
    timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[]; // 电度电价类型
    summitRate?: number; // 尖
    peakRate?: number; // 峰
    plainRate?: number; // 平
    valleyRate?: number; // 谷
    hasServiceCharge: boolean; // 是否有服务费
    serviceCharge: number; // 服务费
    hasDiscountedRate?: number; // 是否有优惠金额
    discountedType?: HasPreferentialAmountType; // 优惠方式
    totalRateDiscountedRatio?: number; // 电度电费总折扣比例
    totalRateDiscountedRate?: number; // 电度电费总优惠金额
    unitRateDiscountedRate?: number; // 电度电费单价优惠金额
    totalRateDiscountedQuantity?: number; // 优惠电度数
  };
  distEnergyAllFeedInInfo?: {
    timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[]; // 电度电价类型
    summitRate?: number; // 尖
    peakRate?: number; // 峰
    plainRate?: number; // 平
    valleyRate?: number; // 谷
    hasServiceCharge: boolean; // 是否有服务费
    serviceCharge: number; // 服务费
    hasDiscountedRate?: number; // 是否有优惠金额
    discountedType?: HasPreferentialAmountType; // 优惠方式
    totalRateDiscountedRatio?: number; // 电度电费总折扣比例
    totalRateDiscountedRate?: number; // 电度电费总优惠金额
    unitRateDiscountedRate?: number; // 电度电费单价优惠金额
    totalRateDiscountedQuantity?: number; // 优惠电度数
  };
  twoPartAndEnergyInfo?: {
    timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[];
    declarationType?: TwoPartSystemPowerConsumptionType; // 用电类型
    rateType?: number; // 电度电价类型
    transformerCapacity?: number; // 变压器容量
    unitCapacityRate?: number; // 容量单价
    declaredDemand?: number; // 申报需量
    unitDeclaredDemandRate?: number; // 申报需量单价
    unitDemandRate?: number; // 需量单价
    standingRate?: number; // 电度单价
    summitRate?: number; // 尖
    peakRate?: number; // 峰
    plainRate?: number; // 平
    valleyRate?: number; // 谷
    hasLightingTariff?: boolean; // 是否有照明费率
    lightingTariffRatio?: number; // 照明费率
    unitLightingTariffRate?: number; // 照明费率单价
    // hasCapitalContributionRate?: boolean;// 是否有公摊系数
    // capitalContributionRate?: number;// 公摊系数
    // hasServiceCharge: boolean;// 是否有服务费
    // serviceCharge: number;// 服务费
    hasDiscountedRate?: number; // 是否有优惠金额
    discountedType?: HasPreferentialAmountType; // 优惠方式
    totalRateDiscountedRatio?: number; // 电度电费总折扣比例
    totalRateDiscountedRate?: number; // 电度电费总优惠金额
    unitRateDiscountedRate?: number; // 电度电费单价优惠金额
    totalRateDiscountedQuantity?: number; // 优惠电度数
    powerFactorRatioCalculationType?: number; //功率因数比值计算规则
  };
}

// 单一制结算规则合同信息
export interface IsingleSettlementInfo {
  meteringScenarios: MeasurementScenarioType; // 计量场景（1：照明，2：光伏，3：专用）
  electricityPrice: number; // 电度电价
  hasSharedCoefficient: boolean; // 是否有公摊系数
  sharedCoefficient: number; // 公摊系数
  hasServiceCharge: boolean; // 是否有服务费
  serviceCharge: number; // 服务费
}

// 结算规则状态的枚举类型
export enum SettlementRulesStatusType {
  Disable = 0, // 禁用
  Enable = 1, // 启用
}

// 结算规则状态的枚举类型的值
export const SettlementRulesStatusTypeValue = {
  [SettlementRulesStatusType.Disable]: '禁用',
  [SettlementRulesStatusType.Enable]: '启用',
};

// 生效状态
export enum TakeEffectStatusType {
  IN_EFFECT = 1, // 生效中
  NOT_ACTIVE = 2, // 未生效
  EXPIRED = 3, // 已过期
}

export const TakeEffectStatusTypeValue = {
  [TakeEffectStatusType.IN_EFFECT]: '生效中',
  [TakeEffectStatusType.NOT_ACTIVE]: '未生效',
  [TakeEffectStatusType.EXPIRED]: '已过期',
};

// 计量场景的枚举类型
export enum MeasurementScenarioType {
  LIGHTING = 1, // 照明
  PHOTOVOLTAIC = 2, // 光伏
  SPECIALPURPOSE = 3, // 专用
}

// 计量场景的枚举类型的值
export const MeasurementScenarioTypeValue = {
  [MeasurementScenarioType.LIGHTING]: '照明电量',
  [MeasurementScenarioType.PHOTOVOLTAIC]: '光伏电量',
  [MeasurementScenarioType.SPECIALPURPOSE]: '专用电量',
};

// 税点的枚举类型
export enum ElectricitySaleContractTaxPointType {
  ONEPERCENT = 1,
  THREEPERCENT = 2,
  THIRTEENPERCENT = 3,
}

// 税点的枚举类型的值
export const ElectricitySaleContractTaxPoint = {
  [ElectricitySaleContractTaxPointType.ONEPERCENT]: '1%',
  [ElectricitySaleContractTaxPointType.THREEPERCENT]: '3%',
  [ElectricitySaleContractTaxPointType.THIRTEENPERCENT]: '13%',
};

// 结算类型的枚举类型
export enum ElectricitySaleContractSettlementType {
  UNITARYSYSTEM = 1, // 单一制
  TWOPARTSYSTEM = 2, // 两部制
  DISTRIBUTEDNEWENERGY_REST = 3, // 分布式新能源（余电上网）
  DISTRIBUTEDNEWENERGY_FULL = 4, // 分布式新能源（全额上网）
  TWOPARTCOMMERCIALPOWER_AND_NEWENERGY = 5, // 两部制市电 + 新能源
  DISTRIBUTED_PV = 6, // 分布式光伏
  DISTRIBUTED_BSA = 7, // 分布式储能
}

// 结算单生成方式的枚举类型
export enum SettlementGeneratorMode {
  AUTO = 1, // 跟随合同结算日自动生成
  MANUAL = 2, // 手动生成
}

// 结算规则表计类型
export enum ElectricitySaleContractSettlementMeterType {
  ELECTRICITY = 1, // 电量
  DEMAND = 2, // 需量
  PHOTOVOLTAIC = 3, // 光伏（不扣除反向电量-光伏）
  DEDUCTPHOTOVOLTAIC = 4, // 扣除反向电量-光伏
  SUBTABLE = 5, // 子表
}

// 结算类型的为单一制时是否具有分时电度电价枚举类型
export enum HasTimeOfUseElectricityPriceType {
  NOTIMEOFUSEELECTRICITYPRICETYPE = 1, // 不分时电度电价
  TIMEOFUSEELECTRICITYPRICETYPE = 2, // 分时电度电价
}

// 结算类型的为单一制时具有分时电度电价属性枚举类型
export enum HasTimeOfUseElectricityPricePropsType {
  SHARPPEAK = 1, // 尖峰
  PEAK = 2, // 峰
  AVERAGE = 3, // 平
  VALLEY = 4, // 谷
}

// 结算类型的为两部制时用电类型
export enum TwoPartSystemPowerConsumptionType {
  BEFOREPOWERCONSUMPTION = 1, // 用电前报装
  AFTERPOWERCONSUMPTION = 2, // 用电后报装
}

// 结算类型的为两部制时电度电价类型
export enum TwoPartSystemElectricityPriceType {
  CHARGEBYCAPACITY = 1, // 按容计费
  CHARGEBYDECLAREDDEMAND = 2, // 按申报需量计费
  CHARGEBYACTUALDEMAND = 3, // 按实际需量计费
}

// 生成方式
export const SettlementGeneratorModeName = {
  [SettlementGeneratorMode.AUTO]: '跟随合同结算日自动生成', // 按容计费
  [SettlementGeneratorMode.MANUAL]: '手动生成', // 按申报需量计费
};

// 结算类型的为两部制时电度电价类型的值
export const TwoPartSystemElectricityPriceTypeValue = {
  [TwoPartSystemElectricityPriceType.CHARGEBYCAPACITY]: '按容计费', // 按容计费
  [TwoPartSystemElectricityPriceType.CHARGEBYDECLAREDDEMAND]: '按申报需量计费', // 按申报需量计费
  [TwoPartSystemElectricityPriceType.CHARGEBYACTUALDEMAND]: '按实际需量计费', // 按实际需量计费
};

// 结算类型的为两部制时用电类型的值
export const TwoPartSystemPowerConsumptionTypeValue = {
  [TwoPartSystemPowerConsumptionType.BEFOREPOWERCONSUMPTION]: '用电前报装', // 用电前报装
  [TwoPartSystemPowerConsumptionType.AFTERPOWERCONSUMPTION]: '用电前报装', // 用电后报装
};

// 结算规则是否有优惠金额枚举类型
export enum HasPreferentialAmountType {
  TOTALPREFERENTIALAMOUNT = 1, // 电度电费总优惠金额
  UNITPRICEPREFERENTIALAMOUNT = 2, // 电度电费单价优惠金额
  TOTALDISCOUNTRATIO = 3, // 电度电费总折扣比例
  // TIMESHARINGDISCOUNTRATIO = 4, // 分时电度电费折扣比例
  // TIMESHARINGPREFERENTIALAMOUNT = 5 // 分时电度单价优惠金额
}

// 结算类型的枚举类型的值
export const ElectricitySaleContractSettlement = {
  [ElectricitySaleContractSettlementType.UNITARYSYSTEM]: '售电单一制',
  [ElectricitySaleContractSettlementType.TWOPARTSYSTEM]: '售电两部制',
  [ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST]: '分布式新能源（余电上网）',
  [ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL]: '分布式新能源（全额上网）',
  [ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY]: '两部制市电 + 新能源',
  [ElectricitySaleContractSettlementType.DISTRIBUTED_PV]: '分布式光伏',
  [ElectricitySaleContractSettlementType.DISTRIBUTED_BSA]: '分布式储能',
};

// 结算类型的为单一制时是否具有分时电度电价枚举类型的值
export const HasTimeOfUseElectricityPrice = {
  [HasTimeOfUseElectricityPriceType.NOTIMEOFUSEELECTRICITYPRICETYPE]: '不分时电度电价', // 不分时电度电价
  [HasTimeOfUseElectricityPriceType.TIMEOFUSEELECTRICITYPRICETYPE]: '分时电度电价', // 分时电度电价
};

// 结算类型的为单一制时具有分时电度电价属性的值枚举类型
export const HasTimeOfUseElectricityPriceProps = {
  [HasTimeOfUseElectricityPricePropsType.SHARPPEAK]: '尖峰', // 尖峰
  [HasTimeOfUseElectricityPricePropsType.PEAK]: '峰', // 峰
  [HasTimeOfUseElectricityPricePropsType.AVERAGE]: '平', // 平
  [HasTimeOfUseElectricityPricePropsType.VALLEY]: '谷', // 谷
};

export const HasPreferentialAmount = {
  [HasPreferentialAmountType.TOTALDISCOUNTRATIO]: '电度电费总折扣比例', // 电度电费总折扣比例
  [HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT]: '电度电费总优惠金额', // 不分时电度电费折扣比例
  [HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT]: '电度电费单价优惠金额', // 不分时电度单价优惠金额
  // [HasPreferentialAmountType.TIMESHARINGDISCOUNTRATIO]: '分时电度电费折扣比例', // 分时电度电费折扣比例
  // [HasPreferentialAmountType.TIMESHARINGPREFERENTIALAMOUNT]: '分时电度单价优惠金额' // 分时电度单价优惠金额
};

export enum SettlementCycleType {
  MONTH = 1,
  DAY = 2,
}

export const SettlementCycleTypeDisplay = {
  [SettlementCycleType.MONTH]: '按月',
  [SettlementCycleType.DAY]: '按日',
};

// 获取结算规则列表
export function getElectricitySettlementRulesList(params: ElectricitySettlementRulesPageRequest) {
  return fetch<PageResponse<ElectricitySettlementRulesResponse>>(
    `/api/v2/sale-electricity/settlement-rule/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 查看结算规则详情
export function getElectricitySettlementRulesById(id: Key): Promise<ElectricitySettlementRulesDetails> {
  return fetch(
    `/api/v2/sale-electricity/settlement-rule/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 根据ou权限获取合同列表
export function getSaleElectricityContractList() {
  return fetch<{ list: ElectricitySaleContractVo[] }>(
    `/api/v2/sale-electricity/contract/list/own`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list);
}

// 新增销售合同结算规则
export function createSettlementRules(params: CreateElectricitySettlementRulesRequest) {
  return fetch(
    `/api/v2/sale-electricity/settlement-rule/add`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 编辑销售合同结算规则
export function updateSettlementRules(id: number, params: CreateElectricitySettlementRulesRequest): Promise<void> {
  return fetch(
    `/api/v2/sale-electricity/settlement-rule/edit`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...params }),
    },
    true
  );
}

// 根据销售合同结算规则id进行状态变更
export function updateSettlementRulesStatus(id: number, params: { status: SettlementRulesStatusType }): Promise<void> {
  return fetch(
    `/api/v2/sale-electricity/settlement-rule/status`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...params }),
    },
    true
  );
}

// 删除结算规则
export function deleteSettlementRulesRecord(id: number): Promise<boolean> {
  return fetch(
    `/api/v2/sale-electricity/settlement-rule/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 获取规则绑定表计列表
interface ElectricitySaleContractSettlementRulesMeterType {
  id: number;
  code: string; // 编号
  deviceId: number; // 	设备id
  electricityAccountId: number; //用电账户id
  electricityAccountNumber: string; //用电账户编号
  saleClientId: string; // 销售客户id
  createByUserId: string; // 操作人
  updateByUserId: string; // 修改人
  updateSource: number; // 修改端(0:运管，1:租户)
  createTime: string;
  updateTime: string;
  deleteFlag: boolean; // 删除标记
  startTime: string; //开始时间
  endTime: string; // 结束时间
  deviceName: string; // 设备名称
  deviceCode: string; // 设备编号
  deviceSn: string; // 设备S/N
  deviceTags: string[]; // 设备标签
  saleElectricityContractElectricityMeterInfoId: number; //绑定销售合同后的表主键
  saleElectricitySettlementRuleElectricityMeterInfoId: number; //绑定结算规则生效表计的表主键
  meterTags: string[]; //表计标签
  meterType: number; // 表计类型（电量，需量，光伏）
}

// 获取规则绑定标记列表
export function getElectricitySaleContractSettlementRulesMeterList(id: string, type?: number) {
  return fetch<{ list: ElectricitySaleContractSettlementRulesMeterType[] }>(
    `/api/v2/sale-electricity/settlement-rule/meter`,
    {
      method: 'POST',
      body: JSON.stringify({ id, type }),
    },
    true
  ).then(res => res.list ?? []);
}

export interface SaleElectricitySettlementRuleListByConIdRes {
  id: Key;
  name: string; // 结算规则名称
  code: string; // 结算规则编号
  saleElectricityContractId: Key; // 销售用电合同id
  startTime: string; // 开始时间
  endTime: string; // 结束时间
  settlementType: ElectricitySaleContractSettlementType; // 结算类型
  description: string; // 备注
  status: SettlementRulesStatusType; // 状态（0:禁用 1：启用）
  createTime: string; // 创建时间
  updateTime: string; // 修改时间
  createByUserId: Key; // 创建人id
  updateByUserId: Key; // 修改人id
  updateSource: number;
}

// 根据合同id获取结算规则列表
export function getSaleElectricitySettlementRuleListByConId(id: Key) {
  return fetch<{ list: SaleElectricitySettlementRuleListByConIdRes[] }>(
    `/api/v2/sale-electricity/settlement-rule/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 获取合同结算规则列表
export function getSaleElectricitySettlementRuleListOwn() {
  return fetch<{ list: ElectricitySettlementRulesResponse[] }>(
    '/api/v2/sale-electricity/settlement-rule/list/own',
    {
      method: 'POST',
    },
    true
  );
}

export function exportSettlementRule(params: SearchParams) {
  return `/api/v2/sale-electricity/settlement-rule/export?${qs.stringify(params, { indices: false })}`;
}

export function exportSettlementRuleSelected(params: { ids: Key[] }) {
  return `/api/v2/sale-electricity/settlement-rule/export/selected?${qs.stringify(params, { indices: false })}`;
}

export enum ImportLogState {
  IMPORTING = 1, // 导入中；
  ALL_SUCCESS, // 全部成功
  ALL_FAILED, // 全部失败
  PART_SUCCESS_FAILED, // 部分成功/失败
  EXCEL_ERROR, //导入文件格式错误
}
export enum ImportLogStateDates {
  IMPORTING = 0, // 导入中；
  ALL_SUCCESS = 1, // 全部成功
  PART_SUCCESS_FAILED = 2, // 部分成功/失败
  ALL_FAILED = 3, // 全部失败
  EXCEL_ERROR = 4, //导入文件格式错误
}

export interface ImportLogPageRes {
  id: Key;
  state: ImportLogState;
  successCount: number; // 成功数量
  failureCount: number; // 失败数量
  failureExcelSource: string; // 失败excel地址
  failureExcelSourceErrorMsg: string; // 生成excel失败原因
  createTime: string; // 创建时间
  updateTime: string; // 更新时间
  createByUsername: string; // 创建人username
  tenantMcid: Key; // 所属租户
}
export interface ImportLogPageResDates {
  fileKey: string;
  msg: string;
  successAmount: number;
  failAmount: number;
  createTime: string;
  updateTime: string;
  failFileKey: string;
  id: number;
  status: number;
  tenantMcid: string;
  updateByUsername: string;
}

export function getImportLogPage(params: PageRequest) {
  return fetch<PageResponse<ImportLogPageRes>>(
    `/api/v2/sale-electricity/settlement-rule/import-log/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
// 抄表导入日志数据
//
export function getImportLogPageDates(params: PageRequestDates) {
  return fetch<PageResponseDates<ImportLogPageResDates>>(
    `/api/v2/sale-electricity/meter-reading/queryImportLogPage`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface ChangeLogPageParams extends PageRequest {
  code?: string; // 编号
  name?: string; // 名称
  updateTime?: string; // 修改时间
  distinctOnRuleId?: boolean; //	是否根据结算规则去重
}

export interface ChangeLogRes {
  id: Key; //
  saleElectricitySettlementRuleId: Key; // 结算规则id
  code: string; // 结算规则code
  name: string; // 结算规则名称
  originalData: string; // 原始数据
  currentData: string; // 当前数据
  createTime: string; // 创建时间
  updateTime: string; // 更新时间
  createByUsername: string; // 创建人username
  tenantMcid: string; // 所属租户
  changedData?: string;
}

export function getChangeLogPage(params: ChangeLogPageParams) {
  return fetch<PageResponse<ChangeLogRes>>(
    `/api/v2/sale-electricity/settlement-rule/change-log/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export function getImportLogById(id: Key) {
  return fetch<ImportLogPageRes>(
    `/api/v2/sale-electricity/settlement-rule/import-log`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
export function getImportLogByIdDate(fileKey: Key) {
  return fetch<ImportLogPageResDates>(
    `/api/v2/sale-electricity/meter-reading/import`,
    {
      method: 'POST',
      body: JSON.stringify({ fileKey }),
    },
    true
  );
}

export function getChangeLogById(id: Key) {
  return fetch<ChangeLogRes>(
    `/api/v2/sale-electricity/settlement-rule/change-log`,
    { method: 'POST', body: JSON.stringify({ id }) },
    true
  );
}

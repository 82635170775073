import styles from './NewUnitConsumption/index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FormContent,
  Wrapper,
  Button,
  EllipsisSpan,
  useBreadcrumbRoutes,
  Select,
  Table,
  Tag,
  Modal,
  Form,
  Input,
  InputNumber,
} from '@maxtropy/components';
import { Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { isNil } from 'lodash-es';
import {
  apiV2OutputProductListAllPost,
  apiV2ProductionEnergyConsumptionEnergyMediumByWorkCenterIdPost,
  apiV2UnitConsumptionAddPost,
  apiV2UnitConsumptionDetailPost,
  apiV2WorkCenterWorkCenterPost,
  V2ProductionEnergyConsumptionEnergyMediumByWorkCenterIdPostResponse,
  V2UnitConsumptionDetailPostResponse,
} from '@maxtropy/device-customer-apis-v2';

interface TableItem {
  id: number;
  name: string;
}

interface CreateEnergyAlarmRuleProps {
  isEdit?: boolean;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 8 },
    sm: { span: 14 },
  },
};

const buildColumns: any = [
  {
    key: 'name',
    title: '产出物',
    ellipsis: { showTitle: true },
    dataIndex: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
const buildColumnsCenter: any = [
  {
    title: '工作中心',
    ellipsis: { showTitle: true },
    dataIndex: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
const CopyPage: React.FC<CreateEnergyAlarmRuleProps> = ({ isEdit }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [prductionOpen, setProductionOpen] = useState<boolean>(false);
  const [workCentreOpen, setWorkCentreOpen] = useState<boolean>(false);

  const [productSelectionVal, setProductSelectionVal] = useState<string>();
  const [centerSelectionVal, setCenterSelectionVal] = useState<string>();

  const [energyThresholdData, setEnergyThresholdData] = useState<
    V2ProductionEnergyConsumptionEnergyMediumByWorkCenterIdPostResponse['list']
  >([]);
  const [productList, setProductList] = useState<TableItem[]>([]);
  const [workerCenterList, setWorkerCenterList] = useState<TableItem[]>([]);

  const [productId, setProductId] = useState<number>();
  // 防止初次监听渲染 以及选中就发请求
  const [centerIdTS, setCenterIdTS] = useState<number>();
  const [centerId, setCenterId] = useState<number>();

  const [unit, setUnit] = useState<string[]>(['--']);

  const [tagStatusProduct, setTagStatusProduct] = useState<boolean>(false);
  const [tagStatusCenter, setTagStatusCenter] = useState<boolean>(false);

  const [searchDate, setSearchDate] = useState<any>();
  const [searchDateCenter, setSearchDateCenter] = useState<any>();

  const { id } = useParams<{ id: string }>();
  const [detailData, setDetailData] = useState<V2UnitConsumptionDetailPostResponse>();

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const routes = [
    {
      name: '复制单耗阈值',
    },
  ];

  const onReset = () => {
    form.resetFields();
    form.submit();
  };

  const onFinish = (values: any) => {
    apiV2UnitConsumptionAddPost({
      workCenterId: values.workCenter,
      outputProductId: values.product,
      shopFlowUnitConsumptionThresholdValueDetailDtos: values.valueDetailDtos,
    }).finally(() => {
      navigate('/productionManagement/production/unitConsumptionSetting');
    });
  };

  useEffect(() => {
    setProductList(searchDate);
  }, [searchDate]);
  useEffect(() => {
    setWorkerCenterList(searchDateCenter);
  }, [searchDateCenter]);

  // 能源阈值设定数据
  useEffect(() => {
    if (isNil(centerId)) return;
    apiV2ProductionEnergyConsumptionEnergyMediumByWorkCenterIdPost({ id: centerId }).then(res => {
      setEnergyThresholdData(res.list);
    });
  }, [centerId]);

  useEffect(() => {
    if (isNil(id)) return;
    apiV2UnitConsumptionDetailPost({ id }).then(res => {
      setDetailData(res);
      setProductSelectionVal(res.outputProductName);
      setProductId(res.outputProductId);
      setCenterSelectionVal(res.workCenterName);
      setCenterIdTS(res.workCenterId);
      setCenterId(res.workCenterId);
      setTagStatusProduct(true);
      setTagStatusCenter(true);
      // 编辑拿到阈值设定id去找对应单位 ，放到unit数组中维护
      apiV2ProductionEnergyConsumptionEnergyMediumByWorkCenterIdPost({ id: res?.workCenterId! }).then(resUnit => {
        const arr: string[] = (res.shopFlowUnitConsumptionThresholdValueDetailDtos ?? [])?.map(i => {
          const findUnit = (resUnit.list ?? []).find(item => item.energyMediumId === i.energyMediumId);
          if (findUnit) {
            return findUnit.generalName ?? '--';
          } else {
            return '--';
          }
        });

        setUnit(arr);
      });
    });
  }, [id]);

  useEffect(() => {
    if (isNil(detailData)) return;
    form.setFieldsValue({
      product: detailData.outputProductId,
      workCenter: detailData.workCenterId,
      valueDetailDtos: detailData?.shopFlowUnitConsumptionThresholdValueDetailDtos?.map(item => ({
        energyMediumId: item.energyMediumId,
        averageUnitConsumptionUpLimit: item.averageUnitConsumptionUpLimit,
        averageUnitConsumptionDownLimit: item.averageUnitConsumptionDownLimit,
        normalProductionUnitConsumptionUpLimit: item.normalProductionUnitConsumptionUpLimit,
        normalProductionUnitConsumptionDownLimit: item.normalProductionUnitConsumptionDownLimit,
        stopLineUnitConsumptionUpLimit: item.stopLineUnitConsumptionUpLimit,
        stopLineUnitConsumptionDownLimit: item.stopLineUnitConsumptionDownLimit,
        leisureEnergyConsumptionUpLimit: item.leisureEnergyConsumptionUpLimit,
        leisureEnergyConsumptionDownLimit: item.leisureEnergyConsumptionDownLimit,
        restEnergyConsumptionUpLimit: item.restEnergyConsumptionUpLimit,
        restEnergyConsumptionDownLimit: item.restEnergyConsumptionDownLimit,
      })),
    });
  }, [detailData, form]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.contentTable}>
      <Form
        form={form}
        name="dynamic_form_item"
        {...formItemLayout}
        onFinish={onFinish}
        onReset={onReset}
        style={{ width: 948 }}
      >
        <FormContent title="复制单耗阈值">
          <Row>
            <Col span={12}>
              <Form.Item
                name="product"
                label="产出物"
                {...formItemLayout}
                rules={[{ required: true, message: '请选择产出物' }]}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    type="link"
                    onClick={() => {
                      setProductionOpen(true);
                      apiV2OutputProductListAllPost({}).then(res => {
                        setProductList((res.list ?? []).map(item => ({ id: item.id!, name: item.name! })));
                      });
                    }}
                  >
                    选择
                  </Button>
                  <Tag
                    closable
                    border="solid"
                    color="#2D8DFF"
                    style={{ display: tagStatusProduct ? 'block' : 'none' }}
                    onClose={e => {
                      e.preventDefault();
                      setTagStatusProduct(false);
                      setTagStatusCenter(false);
                      // setWorkerCenterList([]);
                    }}
                  >
                    {productSelectionVal}
                  </Tag>
                </div>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="workCenter"
                label="工作中心"
                rules={[{ required: true, message: '请选择工作中心' }]}
                {...formItemLayout}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    disabled={tagStatusProduct ? false : true}
                    type="link"
                    onClick={() => {
                      apiV2WorkCenterWorkCenterPost({ id: String(productId) }).then(res => {
                        setWorkerCenterList((res.list ?? []).map(item => ({ id: item.id!, name: item.name! })));
                      });
                      setWorkCentreOpen(true);
                    }}
                  >
                    选择
                  </Button>
                  <Tag
                    closable
                    border="solid"
                    color="#2D8DFF"
                    style={{ display: tagStatusCenter ? 'block' : 'none' }}
                    onClose={e => {
                      e.preventDefault();
                      setTagStatusCenter(false);
                    }}
                  >
                    {centerSelectionVal}
                  </Tag>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </FormContent>
        <Form.List name="valueDetailDtos">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name }, index) => (
                <div className={styles.newlayout}>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label="能源阈值设定"
                        rules={[{ required: true, message: '请选择阈值' }]}
                        name={[name, 'energyMediumId']}
                        {...formItemLayout}
                      >
                        <Select
                          onChange={val => {
                            let unitValue = energyThresholdData?.filter(id => val === id.energyMediumId)[0]
                              ?.generalName;
                            const newUnit = [...unit];
                            newUnit.splice(index, 1, unitValue ? unitValue : '--');
                            setUnit(newUnit);
                          }}
                          style={{ width: 280 }}
                          placeholder="请选择能源介质"
                          options={energyThresholdData?.map(i => ({
                            label: i.energyMediumName,
                            value: i.energyMediumId,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'averageUnitConsumptionUpLimit']}
                        {...formItemLayout}
                        label="平均单耗上限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const lowLimit = getFieldValue('valueDetailDtos')[name].averageUnitConsumptionDownLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'averageUnitConsumptionDownLimit'],
                                  value: lowLimit,
                                  errors: undefined,
                                },
                              ]);

                              if (isNil(lowLimit) || value > lowLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗上限需要大于单耗下限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'averageUnitConsumptionDownLimit']}
                        {...formItemLayout}
                        label="平均单耗下限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const upLimit = getFieldValue('valueDetailDtos')[name].averageUnitConsumptionUpLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'averageUnitConsumptionUpLimit'],
                                  value: upLimit,
                                  errors: undefined,
                                },
                              ]);
                              if (isNil(upLimit) || value < upLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗下限需要小于单耗上限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          className={styles.iptStyle}
                          controls={false}
                          style={{ border: 'none' }}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'normalProductionUnitConsumptionUpLimit']}
                        {...formItemLayout}
                        label="正常生产-单耗上限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const lowLimit =
                                getFieldValue('valueDetailDtos')[name].normalProductionUnitConsumptionDownLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'normalProductionUnitConsumptionDownLimit'],
                                  value: lowLimit,
                                  errors: undefined,
                                },
                              ]);
                              if (isNil(lowLimit) || value > lowLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗上限需要大于单耗下限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'normalProductionUnitConsumptionDownLimit']}
                        {...formItemLayout}
                        label="正常生产-单耗下限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const upLimit =
                                getFieldValue('valueDetailDtos')[name].normalProductionUnitConsumptionUpLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'normalProductionUnitConsumptionUpLimit'],
                                  value: upLimit,
                                  errors: undefined,
                                },
                              ]);
                              if (isNil(upLimit) || value < upLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗下限需要小于单耗上限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'stopLineUnitConsumptionUpLimit']}
                        {...formItemLayout}
                        label="停线-单耗上限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const lowLimit = getFieldValue('valueDetailDtos')[name].stopLineUnitConsumptionDownLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'stopLineUnitConsumptionDownLimit'],
                                  value: lowLimit,
                                  errors: undefined,
                                },
                              ]);
                              if (isNil(lowLimit) || value > lowLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗上限需要大于单耗下限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'stopLineUnitConsumptionDownLimit']}
                        {...formItemLayout}
                        required
                        label="停线-单耗下限"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const upLimit = getFieldValue('valueDetailDtos')[name].stopLineUnitConsumptionUpLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'stopLineUnitConsumptionUpLimit'],
                                  value: upLimit,
                                  errors: undefined,
                                },
                              ]);
                              if (isNil(upLimit) || value < upLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('单耗下限需要小于单耗上限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'leisureEnergyConsumptionUpLimit']}
                        {...formItemLayout}
                        label="空闲-能耗上限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const lowLimit = getFieldValue('valueDetailDtos')[name].leisureEnergyConsumptionDownLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'leisureEnergyConsumptionDownLimit'],
                                  value: lowLimit,
                                  errors: undefined,
                                },
                              ]);
                              if (isNil(lowLimit) || value > lowLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('能耗上限需要大于能耗下限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'leisureEnergyConsumptionDownLimit']}
                        {...formItemLayout}
                        label="空闲-能耗下限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const upLimit = getFieldValue('valueDetailDtos')[name].leisureEnergyConsumptionUpLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'leisureEnergyConsumptionUpLimit'],
                                  value: upLimit,
                                  errors: undefined,
                                },
                              ]);
                              if (isNil(upLimit) || value < upLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('能耗下限需要小于能耗上限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'restEnergyConsumptionUpLimit']}
                        {...formItemLayout}
                        label="休息-能耗上限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const lowLimit = getFieldValue('valueDetailDtos')[name].restEnergyConsumptionDownLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'restEnergyConsumptionDownLimit'],
                                  value: lowLimit,
                                  errors: undefined,
                                },
                              ]);
                              if (isNil(lowLimit) || value > lowLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('能耗上限需要大于能耗下限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[name, 'restEnergyConsumptionDownLimit']}
                        {...formItemLayout}
                        label="休息-能耗下限"
                        required
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const upLimit = getFieldValue('valueDetailDtos')[name].restEnergyConsumptionUpLimit;
                              form.setFields([
                                {
                                  name: ['valueDetailDtos', index, 'restEnergyConsumptionUpLimit'],
                                  value: upLimit,
                                  errors: undefined,
                                },
                              ]);
                              if (isNil(upLimit) || value < upLimit) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('能耗下限需要小于能耗上限'));
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          className={styles.iptStyle}
                          controls={false}
                          placeholder="请输入"
                          addonAfter={<span className={styles.suffixIcon}>{unit[index] ? unit[index] : '--'}</span>}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {index > 0 ? (
                    <MinusCircleOutlined
                      style={{ fontSize: 16, color: '#4A90E2' }}
                      className={styles.reduceTable}
                      onClick={() => remove(name)}
                    />
                  ) : null}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  style={{ marginLeft: 32, width: 948, minHeight: 32, color: '#4A90E2' }}
                  className={styles.btn}
                >
                  新增能源阈值
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Space className="sticky-footer" style={{ paddingLeft: 32 }}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            <Button
              className={styles.button}
              onClick={() => {
                Modal.confirm({
                  title: <span style={{ color: 'white' }}>是否取消该复制产出物阈值设定</span>,
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => navigate('/productionManagement/production/unitConsumptionSetting'),
                  okText: '确定',
                  cancelText: '取消',
                });
              }}
            >
              取消
            </Button>
          </Space>
        </Form.Item>
      </Form>

      {prductionOpen && (
        <Modal
          title="产出物选择"
          width={480}
          bodyStyle={{ overflowY: 'auto', maxHeight: 600 }}
          open={prductionOpen}
          onCancel={() => {
            setProductionOpen(false);
          }}
          onOk={() => {
            setProductionOpen(false);
            setTagStatusProduct(true);
            form.setFieldValue('product', productId);
            setTagStatusCenter(false);
          }}
        >
          <Input
            className={styles.inputStyle}
            type="text"
            placeholder="请输入产出物名称/产出物编码"
            suffix={<SearchOutlined style={{ color: 'rgba(255, 255, 255, 0.25)' }} />}
            onChange={e => {
              if (e.target.value === '') {
                apiV2OutputProductListAllPost({}).then(res => {
                  setProductList(
                    (res.list ?? []).map(item => ({ id: item.id!, name: item.code! + '--' + item.name! }))
                  );
                });
              }
              const found = productList?.filter(item => item?.name.includes(e.target.value));
              setSearchDate(found);
            }}
          />

          <Table
            rowKey={'id'}
            sticky
            // loading={loading}
            scroll={{ y: 500 }}
            dataSource={productList}
            columns={buildColumns}
            rowSelection={{
              type: 'radio',
              onChange: (selectedRowKeys: React.Key[], selectedRows: TableItem[]) => {
                setProductSelectionVal(selectedRows[0].name);
                setProductId(selectedRows[0].id);
              },
              selectedRowKeys: productId ? [productId!] : [],
            }}
            pagination={false}
          />
        </Modal>
      )}

      {workCentreOpen && (
        <Modal
          title="工作中心选择"
          size="small"
          bodyStyle={{ overflowY: 'auto', maxHeight: 600 }}
          open={workCentreOpen}
          onCancel={() => {
            setWorkCentreOpen(false);
            setWorkerCenterList([]);
          }}
          onOk={() => {
            setWorkCentreOpen(false);
            setCenterId(centerIdTS);
            form.setFieldValue('workCenter', centerIdTS);
            if (!isNil(centerSelectionVal) && centerSelectionVal !== '') {
              setTagStatusCenter(true);
            }
          }}
        >
          <Input
            className={styles.inputStyle}
            type="text"
            placeholder="请输入工作中心名称/工作中心编码"
            suffix={<SearchOutlined style={{ color: 'rgba(255, 255, 255, 0.25)' }} />}
            onChange={e => {
              if (e.target.value === '') {
                apiV2WorkCenterWorkCenterPost({ id: String(productId) }).then(res => {
                  setWorkerCenterList(
                    (res.list ?? []).map(item => ({ id: item.id!, name: item.code + '--' + item.name! }))
                  );
                });
              }
              const foundCenter = workerCenterList?.filter(item => item?.name.includes(e.target.value));
              setSearchDateCenter(foundCenter);
            }}
          />

          <Table
            rowKey={'id'}
            sticky
            // loading={loading}
            scroll={{ y: 500 }}
            dataSource={workerCenterList}
            columns={buildColumnsCenter}
            rowSelection={{
              type: 'radio',
              onChange: (selectedRowKeys: React.Key[], selectedRows: TableItem[]) => {
                console.log('onchange', selectedRowKeys);
                setCenterSelectionVal(selectedRows[0].name);
                setCenterIdTS(selectedRows[0].id);
              },
              selectedRowKeys: centerIdTS ? [centerIdTS!] : [],
            }}
            pagination={false}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default CopyPage;

import { queryProductionUnitList } from '@/api/ou';
import { Divider, FormInstance } from 'antd';
import { FC, useEffect, useMemo } from 'react';
import { CurrentDataProps } from '../..';
import { cloneDeep, uniqBy } from 'lodash-es';
import { Form, Input, Modal, Select, Tag, CustomFilter, Tabs, MXTabsProps, Tree, message } from '@maxtropy/components';
import {
  V2EnergyAnalysisUnitConfGroupLinkedOuAllPostResponse,
  apiV2CustomerUserListByStaffouPost,
  apiV2EnergyAnalysisUnitConfGroupAddPost,
  apiV2EnergyAnalysisUnitConfGroupLinkedOuAllPost,
  apiV2EnergyAnalysisUnitConfGroupUpdatePost,
  apiV2EnergyConsumptionEvaluationAnalysisUnitUnitConfGroupListPost,
  apiV2EnergyUnitCopyPost,
  apiV2OuPagePost,
} from '@maxtropy/device-customer-apis-v2';
import { useCallback, useState, ReactText } from 'react';
import { Paging, Table, usePaging, EllipsisSpan } from '@maxtropy/components';
import { useQuery } from '../../../../../utils/utils';
import { mcidItem } from '@/api/energyConsumption';
import { OuStatusDisplay, OuStatus } from '@/shared/const';
import { V2OuPagePostRequest } from '@maxtropy/device-customer-apis-v2';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

interface FilterParams {
  nameOrSerialNum?: string;
  mcids?: string[];
  unitId?: number;
}

export interface UnitModalRef {
  selectedRows: OuItem[];
}
interface OuItem {
  id?: number;
  serialNumber?: string;
  name?: string;
  status?: number;
  customerName?: string;
  customerMcid?: string;
  tenantMcid?: string;
  isLinked?: boolean;
}

type SearchParams = Omit<V2OuPagePostRequest, 'page' | 'size'>;
interface AddChildModalProps {
  visible: boolean;
  onCancel: () => void;
  isAdd: boolean;
  // { name, currentId }, 其中 name 和 currentId 分别为当前用能单元某个节点的 name 和 unitConfId
  // 如果是最顶上的添加一级用能单元的话为 undefined
  currentData?: CurrentDataProps;
  // onFinish: (values: CurrentDataProps) => void;
  addEditform: FormInstance<any>;
  mcid: string;
  unitId: string | number;
  addChildModalVisible: boolean;
  addRootAnalysisGroupVisible: boolean;
  editModalVisible: boolean;
  refresh: () => void;
  mcidOptions?: mcidItem[];
}

type FormProps = {
  name: string;
  linkedOuList: number[];
};

interface TiledArrayProps {
  value: string;
  label: string;
}
export type OuInfoProps = Exclude<V2EnergyAnalysisUnitConfGroupLinkedOuAllPostResponse['list'], undefined>[number];

const AddChildOrEditModal: FC<AddChildModalProps> = props => {
  const {
    visible,
    onCancel,
    isAdd,
    currentData,
    addEditform,
    mcid,
    unitId,
    refresh,
    addChildModalVisible,
    addRootAnalysisGroupVisible,
  } = props;
  const [allOuList, setAllOutList] = useState<OuInfoProps[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>('1');
  // 编辑时候初始化查到的 ou list
  const [initialLinkedOutList, setInitialLinkedOutList] = useState<OuInfoProps[]>([]);
  const [form] = Form.useForm();
  const [selectedItems, setSelectedItem] = useState<OuItem[]>([]);
  const pagingInfo = usePaging(100);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [selectedRows, setSelectedRows] = useState<OuItem[]>([]);
  const [organization, setOrganization] = useState<TiledArrayProps[]>([]);
  const [linkedOuList, setLinkedOuList] = useState<number[]>([]);
  const [treeData, setTreeData] = useState<any>([]);
  const [treeCheckedKeys, setTreeCheckedKeys] = useState<number[]>([]);

  useEffect(() => {
    apiV2CustomerUserListByStaffouPost({}).then(res => {
      const map = (res?.list ?? []).map(item => ({
        label: item.name as string,
        value: item.mcid as string,
      }));
      setOrganization(map);
    });

    apiV2EnergyConsumptionEvaluationAnalysisUnitUnitConfGroupListPost({ id: Number(unitId) }).then(res => {
      setTreeData(res.list ?? []);
    });
  }, []);

  useEffect(() => {
    setLinkedOuList(selectedItems.map(item => item.id as number));
  }, [selectedItems]);

  const { data, isLoading } = useQuery(
    useCallback(async () => {
      const res = await apiV2OuPagePost({
        ...searchParams,
        mcids: searchParams?.mcids,
        ifCancel: false,
        nameOrSerialNum: searchParams?.nameOrSerialNum,
        page: pageOffset,
        size: pageSize,
      });
      if (res) setTotalCount(res.total as number);
      return res?.list;
    }, [searchParams, pageOffset, pageSize, setTotalCount])
  );

  useEffect(() => {
    setSelectedItem([...selectedItems, ...selectedRows]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const onReset = () => {
    form.resetFields();
    form.submit();
    setPageOffset(1);
  };

  const onFinishSearch = (val: FilterParams) => {
    const params: SearchParams = {
      nameOrSerialNum: val.nameOrSerialNum,
      mcids: val.mcids,
    };
    setSearchParams(params);
    setPageOffset(1);
  };
  const onCheck = (checkedKeys: any) => {
    setTreeCheckedKeys([...checkedKeys.checked]);
  };
  const columns = [
    {
      title: '运营单元编号',
      dataIndex: 'serialNumber',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '运营单元运营名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '状态',
      dataIndex: 'status',
      ellipsis: { showTitle: true },
      render: (v: OuStatus) => <EllipsisSpan value={OuStatusDisplay[v]} />,
    },
    {
      title: '所属组织',
      dataIndex: 'customerName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  const onSelectChange = (rowKeys: ReactText[], rows: OuItem[]) => {
    const data = rows.filter(item => !!item).filter(item => !selectedItems?.map(s => s.id).includes(item.id));
    setSelectedRows(data);
  };

  const rowSelection = {
    selectedRowKeys: (selectedItems || []).map(item => item.id as number),
    getCheckboxProps: (record: OuItem) => ({ disabled: selectedItems?.map(s => s.id).includes(record.id) }),
    onChange: onSelectChange,
  };

  const ouList = useMemo(() => {
    // 所有的 ou, 包含有权限的和无权限的
    return uniqBy([...allOuList, ...initialLinkedOutList], 'id');
  }, [allOuList, initialLinkedOutList]);

  const onFinish = (values: FormProps) => {
    if (selectedItems.length === 0) {
      Modal.warning({ content: '请选择运营单元' });
      return;
    }
    const filterLinkedOuList = ouList
      ?.filter(o => linkedOuList.includes(o.id as number))
      ?.map(v => ({ ouId: v.id!, mcid: v.mcid!, tenantMcid: v.tenantMcid! }));
    setSubmitting(true);
    if (addChildModalVisible || addRootAnalysisGroupVisible) {
      // 添加子组或者总分析组
      apiV2EnergyAnalysisUnitConfGroupAddPost({
        name: values.name,
        parentId: addChildModalVisible ? Number(currentData?.currentId) : 0,
        unitId: unitId.toString(),
        linkedOuList: filterLinkedOuList,
      })
        .then(() => {
          refresh();
          addEditform.resetFields();
          onCancel();
        })
        .finally(() => {
          setSubmitting(false);
          // onCancel();
        });
    } else {
      // 编辑
      apiV2EnergyAnalysisUnitConfGroupUpdatePost({
        energyUnitGroupId: currentData!.currentId!.toString(),
        name: values.name,
        beforeLinkedOuList: initialLinkedOutList.map(v => ({ mcid: v.mcid!, ouId: v.id!, tenantMcid: v.tenantMcid! })),
        linkedOuList: filterLinkedOuList,
      })
        .then(() => {
          refresh();
          addEditform.resetFields();
          onCancel();
        })
        .finally(() => {
          setSubmitting(false);
          // onCancel();
        });
    }
  };

  useEffect(() => {
    // 编辑时候设置初始值等
    if (currentData && !isAdd) {
      addEditform.setFieldsValue({ name: currentData.name });
      apiV2EnergyAnalysisUnitConfGroupLinkedOuAllPost({ energyUnitId: currentData.currentId }).then(res => {
        const linkedOuList = (res.list ?? []).map(v => v?.id as number);
        setSelectedItem(res.list as any);
        setLinkedOuList(linkedOuList);
        // 设置初始化的 oulist
        setInitialLinkedOutList(res.list ?? []);
      });
    }
  }, [addEditform, currentData, isAdd]);

  // 查询所有 ou
  useEffect(() => {
    queryProductionUnitList({ page: 1, size: 500 }).then(res => {
      setAllOutList(res.list as any);
    });
  }, [mcid]);

  const copyBatch = () => {
    setSubmitting(true);
    apiV2EnergyUnitCopyPost({
      parentId: Number(currentData?.currentId),
      copyEnergyUnitIds: treeCheckedKeys,
    })
      .then(res => {
        message.success('复制成功');
        refresh();
        addEditform.resetFields();
        onCancel();
      })
      .finally(() => setSubmitting(false));
  };

  const items: MXTabsProps['items'] = [
    {
      key: '1',
      label: '创建',
    },
    {
      key: '2',
      label: '复制',
    },
  ];

  return (
    <Modal
      title="用能单元"
      open={visible}
      size="big"
      destroyOnClose
      bodyScroll
      contentClassName={styles.contentWrapper}
      onOk={() => {
        activeKey === '1' ? addEditform.submit() : copyBatch();
      }}
      onCancel={() => {
        addEditform.resetFields();
        onCancel();
      }}
      // 新增需求，蒙层外无法关闭
      maskClosable={false}
      // 设置提交后禁用提交按钮, 防止重复提交
      okButtonProps={{
        disabled: submitting,
      }}
    >
      <Tabs activeKey={activeKey} items={items} onChange={(key: string) => setActiveKey(key)} />
      {activeKey === '1' ? (
        <>
          <div className={styles.content}>
            <Form preserve={false} form={addEditform} onFinish={onFinish} style={{ marginLeft: 24 }}>
              <Form.Item validateFirst name="name" label="用能单元名称" rules={[{ required: true, message: '请输入' }]}>
                <Input placeholder="请输入" style={{ maxWidth: 474 }} />
              </Form.Item>
              <Form.Item label="包含运营单元">
                {selectedItems?.length ? (
                  selectedItems?.map(item => {
                    return (
                      <Tag
                        key={item.id}
                        style={{ marginBottom: 4 }}
                        closeIcon={true}
                        onClose={() => {
                          const deepCopy = cloneDeep(selectedItems);
                          const findIndex = selectedItems.findIndex((i: OuItem) => i.id === item.id);
                          deepCopy.splice(findIndex, 1);
                          setSelectedItem(deepCopy);
                        }}
                      >
                        {item.name}
                      </Tag>
                    );
                  })
                ) : (
                  <div className={styles.specialTip}>
                    <InfoCircleOutlined
                      style={{ color: 'var(--mx-warning-color)', marginRight: 4, fontSize: '16px' }}
                    />
                    请在下方列表中选择
                  </div>
                )}
              </Form.Item>
            </Form>
          </div>
          <Divider></Divider>
          <CustomFilter colSpan={8} form={form} onFinish={val => onFinishSearch(val as FilterParams)} onReset={onReset}>
            <Form.Item name="nameOrSerialNum" label="运营单元名称">
              <Input placeholder={'请输入名称查询'} />
            </Form.Item>
            <Form.Item name="mcids" label="所属组织">
              <Select placeholder="请选择" allowClear options={organization} mode="multiple" />
            </Form.Item>
          </CustomFilter>
          <Table
            rowKey="id"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            loading={isLoading}
            showSelectedCount
            scroll={{ y: 227 }}
            selectedCount={selectedItems.length}
            columns={columns}
            dataSource={data}
          >
            <Paging pagingInfo={pagingInfo} />
          </Table>
        </>
      ) : (
        <>
          <Tree
            checkable
            className={styles.treeBox}
            defaultExpandAll
            selectable={false}
            checkStrictly={true}
            onCheck={onCheck}
            fieldNames={{ title: 'name', children: 'childList', key: 'id' }}
            treeData={treeData}
          />
          <div style={{ color: 'var(--mx-text-secondary-color)' }}>
            将所选单元按当前生效配置复制为
            <span style={{ color: 'var(--mx-primary-color)' }}>【{currentData?.name ?? '--'}】</span>子级单元
          </div>
        </>
      )}
    </Modal>
  );
};

export default AddChildOrEditModal;

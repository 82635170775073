import { Empty, Key, Wrapper, useBreadcrumbRoutes, useUpdate } from '@maxtropy/components';
import styles from './index.module.scss';
import { Layout } from 'antd';
import { useState } from 'react';
import DragResize from '@/components/DragResize';
import StationTree from './components/StationTree';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import RightContent from './components/RightContent';
import { DataNode } from 'antd/es/tree';

const EnergyUnitMapping: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [siderWidth, setSiderWidth] = useState(260); // 初始值
  const [dragStatus, setDragStatus] = useState(false); // 拖拽状态
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  const [selectTreeKey, setSelectTreeKey] = useState<Key[]>([]);
  const [selectedNode, setSelectedNode] = useState<DataNode>();

  const [treeSelectedKeys, setTreeSelectedKeys] = useState<Key[]>([]); // 选择树
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<Key[]>([]);
  const [treeUpdate, treeUpdateFn] = useUpdate();
  const [searchUpdate, searchUpdateFn] = useUpdate(); // 右部搜索框重置
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true); // 左部树父节点是否自动展开
  const [productionBaseId, setProductionBaseId] = useState<number>();
  const [titleIcon, setTitleIcon] = useState<string>('');

  // 拖拽方法
  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 左部树选择
  const onTreeSelect = (v: Key[], selectedNodes: DataNode[]) => {
    setSelectTreeKey(v);
    setSelectedNode(selectedNodes.at(0));
    searchUpdateFn(); // 切换树选择时，清空搜索项
  };

  // 树跳转
  const onJumpToTreeItem = (v: Key[]) => {
    setTreeSelectedKeys(v);
    setTreeExpandedKeys(v);
    setSelectTreeKey(v);
    setAutoExpandParent(true);
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapperStyles}>
      <Layout className={styles.layout}>
        <Layout.Sider
          width={openSider ? siderWidth : 0}
          style={{ transition: dragStatus ? 'none' : 'all 0.2s', background: 'var(--mx-module-bg-color)' }}
        >
          <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
          <StationTree
            treeUpdate={treeUpdate}
            setProductionBaseId={setProductionBaseId}
            selectedKeys={treeSelectedKeys}
            setSelectedKeys={setTreeSelectedKeys}
            expandedKeys={treeExpandedKeys}
            setTitleIcon={setTitleIcon}
            setExpandedKeys={setTreeExpandedKeys}
            onTreeSelect={onTreeSelect}
            autoExpandParent={autoExpandParent}
            setAutoExpandParent={setAutoExpandParent}
          />
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          {selectTreeKey && selectTreeKey.length > 0 ? (
            <div>
              <div
                className={styles.toggleBtn}
                style={{ left: openSider ? -30 : 0 }}
                onClick={() => setOpenSider(!openSider)}
              >
                {openSider ? <LeftOutlined /> : <RightOutlined />}
              </div>
              <RightContent
                searchUpdate={searchUpdate}
                onJumpToTreeItem={onJumpToTreeItem}
                selectTreeKey={selectTreeKey}
                selectedNode={selectedNode}
                titleIcon={titleIcon}
                productionBaseId={productionBaseId as number}
                treeUpdateFn={treeUpdateFn}
              />
            </div>
          ) : (
            <div style={{ position: 'relative', height: '85vh' }}>
              <Empty description={'暂无数据'} className={styles.empty} />
            </div>
          )}
        </Layout.Content>
      </Layout>
    </Wrapper>
  );
};

export default EnergyUnitMapping;

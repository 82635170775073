import { keepTwoNull } from '@/pages/ZYFDashboard/utils';
import dayjs from 'dayjs';
import * as echarts from 'echarts';

export interface ChartsData {
  valueDto?: {
    energyMediumId?: number;
    energyMediumName?: string;
    generalName?: string;
    avg?: number;
    dtoList?: {
      ts?: number;
      value?: number;
    }[];
  };
  areaName?: string;
}

export const getChartOption = (data?: ChartsData[], unit?: string) => {
  const colors = [
    new echarts.graphic.LinearGradient(
      0,
      0,
      0,
      1, // 渐变从上到下
      [
        { offset: 0, color: 'rgba(74,255,127,0.9)' }, // 起始颜色
        { offset: 0.65, color: 'rgba(74,255,127,0.5)' }, // 起始颜色
        { offset: 1, color: 'rgba(74,255,127,0)' }, // 结束颜色
      ]
    ),
    new echarts.graphic.LinearGradient(
      0,
      0,
      0,
      1, // 渐变从上到下
      [
        { offset: 0, color: 'rgba(255,228,74,0.9)' }, // 起始颜色
        { offset: 0.65, color: 'rgba(255,228,74,0.5)' }, // 起始颜色
        { offset: 1, color: 'rgba(255,228,74,0)' }, // 结束颜色
      ]
    ),
    new echarts.graphic.LinearGradient(
      0,
      0,
      0,
      1, // 渐变从上到下
      [
        { offset: 0, color: 'rgba(0,173,255,0.9)' }, // 起始颜色
        { offset: 0.65, color: 'rgba(0,173,255,0.5)' }, // 起始颜色
        { offset: 1, color: 'rgba(0,173,255,0)' }, // 结束颜色
      ]
    ),
  ];

  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format('YYYY-MM');
        let otherStr = '';
        let str = '';

        items.forEach((item: any) => {
          const { seriesName, data, marker } = item;
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;">${marker}</span>${seriesName} : &nbsp;${
            data[1] || data[1] === 0 ? data[1] : '-'
          }${unit === 'kgce' ? 'tce' : unit}</div>`;
          str = firstStr + otherStr;
        });

        return str;
      },
    },
    grid: {
      left: 10,
      right: 10,
      top: 60,
      bottom: 10,
      containLabel: true,
    },
    color: ['#4AFF7F', '#FFE44A', '#00ADFF'],
    legend: {
      top: 10,
      right: 10,
      textStyle: {
        color: 'rgba(255, 255, 255, 0.85)',
      },
      inactiveColor: 'rgba(255, 255, 255, 0.45)',
      itemWidth: 16,
      itemHeight: 4,
      data: data?.map(item => ({
        name: item.areaName,
        icon: 'rect',
      })),
    },
    xAxis: [
      {
        minInterval: 60 * 1000 * 60 * 24 * 30,
        type: 'time',
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: function (value: number) {
            const month = dayjs(value).format('YYYY-MM').split('-')[1]; // 从 '2024-01' 中提取月份
            return Number(month) + '月'; // 转换为数字，会自动去掉前导 0
          },
          fontSize: 10,
        },
        axisPointer: {
          type: 'shadow',
        },
        axisLine: {
          show: true, // 是否显示轴线
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: unit === 'kgce' ? 'tce' : unit,
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    series: data?.map((item, index) => ({
      name: item.areaName,
      type: 'bar',
      data: (item.valueDto?.dtoList ?? []).map(c => [
        dayjs(c.ts).valueOf(),
        keepTwoNull(unit === 'kgce' ? (c.value ?? 0) / 1000 : c.value),
      ]),
      avg: unit === 'kgce' ? (item.valueDto?.avg ?? 0) / 1000 : item.valueDto?.avg,
      itemStyle: {
        color: colors[index % colors.length],
      },
    })),
  };
};

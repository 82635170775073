import React, { FC, Key, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import tittleDecoration from '../../imgs/tittleDecoration.png';
import { Spin } from 'antd';
import ReactEcharts from 'echarts-for-react';
import { getChartOption } from './chart';
import { Empty } from '@maxtropy/components';
import {
  V2EnergyUnitOverviewAggregatedKgcePostResponse,
  V2EnergyUnitOverviewAggregatedPostResponse,
} from '@maxtropy/device-customer-apis-v2';

export interface ITotalPie {
  data?: V2EnergyUnitOverviewAggregatedKgcePostResponse | V2EnergyUnitOverviewAggregatedPostResponse;
  mediumId?: Key;
}

const TotalPie: FC<ITotalPie> = props => {
  const { data, mediumId } = props;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  // 图表
  const chartOption = useMemo(() => {
    if (mediumId && data) {
      return getChartOption(mediumId, data);
    }
    return {};
  }, [data, mediumId]);

  const isShowEmpty = useMemo(() => {
    if ((data?.childList ?? []).length && mediumId) {
      return false;
    }
    return true;
  }, [data, mediumId]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <img src={tittleDecoration} alt="pic" className={styles.titleBorder} />
        <span className={styles.title}>总计用量</span>
      </div>
      <div className={styles.content_box}>
        <div className={styles.chart_box}>
          {loading ? (
            <Spin spinning={true} />
          ) : isShowEmpty ? (
            <Empty className={styles.empty_box} />
          ) : (
            <ReactEcharts style={{ height: 260 }} option={chartOption} notMerge lazyUpdate={false} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TotalPie;

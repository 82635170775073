import { FC, useEffect, useMemo, useState } from 'react';

import { OuInfoProps } from '@/api/ou';
import { useParams } from 'react-router-dom';
import { Form, Input, InputNumber, Modal, Select } from '@maxtropy/components';
import { CircuitProps, getCircuitList } from '@/api/circuit';

const formLayout = {
  labelCol: { flex: '120px' },
};

interface Iprops {
  visible: boolean;
  rowInfo?: any;
  onCancel?: () => void;
  onConfirm?: (v: any) => void;
  ouList?: OuInfoProps[];
}

const NewChargeStationModal: FC<Iprops> = props => {
  const { visible, rowInfo, onCancel, onConfirm, ouList } = props;
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>(); // 获取uetid
  const [circuitList, setCircuitList] = useState<CircuitProps[]>([]);

  useEffect(() => {
    if (visible && id) {
      getCircuitList({ uetId: id }).then(res => {
        setCircuitList(res.list ?? []);
      });
    }
  }, [visible, id]);

  useEffect(() => {
    if (rowInfo && visible) {
      form.setFieldsValue({ ...rowInfo });
    }
  }, [rowInfo, form, visible]);

  const title = useMemo(() => {
    return rowInfo ? '编辑充电站' : '新建充电站';
  }, [rowInfo]);

  const circuitOptions = useMemo(() => {
    if (circuitList.length !== 0) {
      return circuitList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [circuitList]);

  return (
    <>
      <Modal
        title={title}
        open={visible}
        destroyOnClose
        onCancel={() => {
          onCancel && onCancel();
          form.resetFields();
        }}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          {...formLayout}
          form={form}
          onFinish={v => {
            onConfirm?.(v);
            form.resetFields();
          }}
          style={{ marginTop: 24 }}
        >
          <Form.Item label="运营单元" name="ouId" rules={[{ required: true, message: '请选择运营单元' }]}>
            <Select disabled={!!rowInfo} placeholder="请选择">
              {(ouList ?? []).map(i => (
                <Select.Option value={i.id} key={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="充电站名称"
            name="name"
            rules={[
              { required: true, message: '充电站名称' },
              { max: 20, message: '最多20个字符' },
            ]}
          >
            <Input placeholder="请输入" maxLength={20} />
          </Form.Item>
          <Form.Item label="装机容量" name="installedCapacity" rules={[{ required: true, message: '请输入装机容量' }]}>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="请输入"
              max={99999999.999}
              min={0}
              precision={3}
              addonAfter="MW"
            />
          </Form.Item>
          <Form.Item label="关联回路" name="circuitId">
            <Select
              style={{ width: '100%' }}
              placeholder="请选择"
              options={circuitOptions}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NewChargeStationModal;

import React, { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { useRequest } from 'ahooks';

interface IHeaderProps {
  fullBtn: () => void;
  isFullScreen: boolean;
  configId: number | null | undefined;
}

const Header: FC<IHeaderProps> = props => {
  const { fullBtn, isFullScreen, configId } = props;
  const hasZYFConfig = useHasPermission(PermissionsType.B_LARGESCREENDISPOSITION);
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState<string>();

  useRequest(
    async () => {
      setCurrentTime(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    },
    {
      pollingInterval: 1000,
    }
  );

  return (
    <div className={styles.header_area_box}>
      <div className={styles.date_box}></div>
      <div className={styles.title_box}>中亿丰制造产业园能源管理平台</div>
      <div className={styles.set_box}>
        {/* 全屏按钮 */}
        <div className={styles.refreshTimeSty}>{currentTime}</div>
        {hasZYFConfig && (
          <div
            className={styles.btn}
            onClick={() => {
              navigate(`/factory/zyf/dashboard/config?configId=${configId}`);
            }}
          >
            &nbsp;
            <span className={styles.text}>{'配置'}</span>
            <div className={styles.bottomLine} />
          </div>
        )}

        <div className={styles.btn} onClick={fullBtn}>
          {isFullScreen ? (
            <FullscreenExitOutlined className={styles.icon} />
          ) : (
            <FullscreenOutlined className={styles.icon} />
          )}
          &nbsp;
          <span className={styles.text}>{isFullScreen ? '退出' : '全屏'}</span>
          <div className={styles.bottomLine} />
        </div>
      </div>
    </div>
  );
};

export default Header;

import { EnergyFlowChartNodeItem, EnergyFlowChartResponse } from '@/api/plustekSankey';
import { Empty, Key } from '@maxtropy/components';
import styles from './index.module.scss';
import React, { useEffect, useMemo, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import { formatCompositeNumWithUnit, formatMediaNumWithUnit } from '@/pages/ZYFDashboard/utils';
import { Spin } from 'antd';

interface Props {
  data?: EnergyFlowChartResponse;
  physicalUnitName?: string;
  isMedium: boolean;
  flowChartLoading: boolean;
}

const color = ['#57FB8BCC', '#52E7FFCC', '#2D8DFFCC', '#CE90D1CC', '#FF9247CC'];

export const formatNum = (num: number, isMedium: boolean, unit?: string) => {
  return isMedium ? formatMediaNumWithUnit(num, unit) : formatCompositeNumWithUnit(num);
};

export const getNodeMaxDepth = (nodes: EnergyFlowChartNodeItem[]) => {
  const all_depth = nodes.map(i => i.depth);
  return Math.max(...all_depth);
};

const Flow: React.FC<Props> = ({ data, physicalUnitName, isMedium, flowChartLoading }) => {
  const chartOption = useMemo(() => {
    return {
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        backgroundColor: '#000',
        borderColor: 'transparent',
        textStyle: {
          color: 'rgba(255,255,255,0.65)',
          fontSize: 12,
        },
        formatter: (v: any) => {
          let res = '';
          if (v.dataType === 'node') {
            res = `<div>
          <p>${v.data.energyUnitName}</p>
          <p style="color: rgba(255,255,255,0.85); margin-bottom: 0px;" >${formatNum(
            v.value,
            isMedium,
            physicalUnitName
          )}</p>
          </div>`;
          }
          if (v.dataType === 'edge') {
            const sourceName = data?.nodes?.find(i => i.key === v.data.source)?.energyUnitName;
            const targetName = data?.nodes?.find(i => i.key === v.data.target)?.energyUnitName;
            res = `<div>
          <p>${sourceName}——${targetName}</p>
          <p style="color: rgba(255,255,255,0.85); margin-bottom: 0px;" >${formatNum(
            v.value,
            isMedium,
            physicalUnitName
          )}</p>
          </div>`;
          }

          return res;
        },
      },
      series: [
        {
          type: 'sankey',
          left: 0,
          right: 0,
          layoutIterations: 0, // 解决能流图衔接问题
          nodeAlign: 'justify',
          label: {
            color: 'rgba(255,255,255,0.85)',
            rich: {
              a: {
                color: '#FF4D4F',
              },
              b: {
                color: '#ABD335',
              },
            },
            formatter: (v: any) => {
              if (v.data.energyUnitName === '未知能耗') {
                return `{a|${v.data.energyUnitName}}`;
              }
              if (v.data.energyUnitName === '用能损耗') {
                return `{b|${v.data.energyUnitName}}`;
              }
              return `${v.data.energyUnitName}`;
            },
          },
          labelLayout: {
            hideOverlap: true,
          },
          draggable: false,
          data: data?.nodes?.map(i => {
            return {
              ...i,
              itemStyle: {
                color:
                  i.energyUnitName === '用能损耗'
                    ? '#ABD335'
                    : i.energyUnitName === '未知能耗'
                    ? '#FF4D4F'
                    : color[i.depth % color.length],
              },
              label: {
                position: i.depth === getNodeMaxDepth(data?.nodes) ? 'left' : 'right',
              },
            };
          }),
          links: data?.links?.map(i => {
            return {
              ...i,
              lineStyle: {
                color: 'gradient',
                opacity: 0.3,
                // curveness: 0.5, // sankey图边曲度，尽量不要设置，在苹果电脑上展示会有问题
              },
            };
          }),
        },
      ],
    };
  }, [data, physicalUnitName, isMedium]);

  const isEmptyChart = useMemo(() => {
    const all_depth = (data?.nodes ?? []).map(i => i.depth);
    const max_depth = Math.max(...all_depth);
    const min_depth = Math.min(...all_depth);
    return data?.nodes.length === 0 || data?.links.length === 0 || max_depth === min_depth;
  }, [data]);

  const chartRef = useRef<ReactEcharts>();
  const handleResize = () => {
    const echartsInstance = chartRef.current?.getEchartsInstance();
    if (echartsInstance) {
      echartsInstance.resize();
    }
  };

  const containerRef = useRef(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const echartsInstance = chartRef.current?.getEchartsInstance();
      if (echartsInstance) {
        echartsInstance.resize();
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={styles.charts_wrapper} ref={containerRef}>
      {flowChartLoading ? (
        <Spin spinning={flowChartLoading} />
      ) : isEmptyChart ? (
        <Empty />
      ) : (
        <ReactEcharts
          style={{ height: `calc(100% - 60px)`, overflowX: 'hidden', width: '100%' }}
          option={chartOption}
          notMerge
          lazyUpdate={false}
        />
      )}
    </div>
  );
};

export default Flow;

import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { AdjustmentMeterRecordsRes } from './electricity';

export enum SaleElectricityMeterStatus {
  SUCCESS = 1,
  FAILED,
}

export interface SaleElectricityMeterReadingRecordSearchPageRequest extends PageRequest {
  contractId?: string;
  saleClientId?: number[];
  meterReadingStartTs?: string;
  meterReadingEndTs?: string;
  mcid?: string;
  propertyManagementId?: string;
  ouId?: string;
  deviceName?: string;
  deviceSn?: string;
  // 添加的抄表来源 表计编号 TS校验
  type?: number;
  meterCode?: string;
  status?: SaleElectricityMeterStatus;
}

export enum SaleElectricityMeterReadingType {
  CRONJOB = 0,
  MANUAL,
  IMPORT,
}

export const SaleElectricityMeterReadingTypeDisplay: Record<SaleElectricityMeterReadingType, string> = {
  [SaleElectricityMeterReadingType.CRONJOB]: '合同自动抄表',
  [SaleElectricityMeterReadingType.MANUAL]: '手工生成核查联',
  [SaleElectricityMeterReadingType.IMPORT]: '抄表导入',
};

export interface SaleElectricityMeterReadingRecordPageVo {
  id?: number;
  saleClientId?: number;
  saleClientName?: string;
  saleClientCode?: string;
  saleMeterId?: number;
  saleMeterCode?: string;
  deviceId?: number;
  deviceCode?: string;
  deviceName?: string;
  deviceSn?: string;
  // 类型
  type?: SaleElectricityMeterReadingType;
  // 抄表周期id
  cycleId?: number;
  // 倍率
  magnification?: number;
  // 配置抄表时间（页面上配置几点开始抄表）
  meterReadingTs?: string;
  // 正向有功电能
  epp?: number;
  // 正向有功电能示数
  eppDisplayValue?: number;
  // 反向有功电能
  epn?: number;
  // 反向有功电能示数
  epnDisplayValue?: number;
  // 正向无功电能
  eqp?: number;
  // 正向无功电能示数
  eqpDisplayValue?: number;
  // 最大需量
  maxDemand?: number;
  // 最大需量示数
  maxDemandDisplayValue?: number;
  // 尖正向有功电能
  epp1?: number;
  // 尖正向有功电能示数
  epp1DisplayValue?: number;
  // 峰正向有功电能
  epp2?: number;
  // 峰正向有功电能示数
  epp2DisplayValue?: number;
  // 平正向有功电能
  epp3?: number;
  // 平正向有功电能示数
  epp3DisplayValue?: number;
  // 谷正向有功电能
  epp4?: number;
  // 谷正向有功电能示数
  epp4DisplayValue?: number;
  // 尖反向有功电能
  epn1?: number;
  // 尖反向有功电能示数
  epn1DisplayValue?: number;
  // 峰反向有功电能
  epn2?: number;
  // 峰反向有功电能示数
  epn2DisplayValue?: number;
  // 平反向有功电能
  epn3?: number;
  // 平反向有功电能示数
  epn3DisplayValue?: number;
  // 谷反向有功电能
  epn4?: number;
  // 谷反向有功电能示数
  epn4DisplayValue?: number;
  // 抄表状态
  status?: SaleElectricityMeterStatus;
}

export function getMeterReadingAdjustmentById(id: Key) {
  return fetch<AdjustmentMeterRecordsRes[]>(
    `/api/v2/sale-electricity/meter-reading/getAdjustment`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 重新抄表
export function reBuildEleMeter(body: { saleElectricityMeterReadingRecordIds: Key[] }) {
  return fetch(
    `/api/v2/sale-electricity/meter-reading/manualRegenerate`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export interface ReBuildEleMeterPageRequest extends PageRequest {
  saleClientName?: string; //	客户名称
  meterCode?: string; // 表计编号
  deviceCode?: string; // 设备编号
  deviceSn?: string; // 设备sn
  createTime?: string; // 创建日期
  meterReadingStartTs?: string; // 计划抄表开始时间
  meterReadingEndTs?: string; // 计划抄表结束时间
  type: string; //抄表来源
}

export interface ReBuildEleMeterPageRes extends AdjustmentMeterRecordsRes {
  createTime?: string;
  updateTime?: string;
  updateByUserName?: string;
}

// 重新抄表日志 改名为抄表日志
export function getReBuildEleMeterRecordPage(request: ReBuildEleMeterPageRequest) {
  return fetch<PageResponse<ReBuildEleMeterPageRes>>(
    `/api/v2/sale-electricity/meter-reading/manualRegenerate/log/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

import { Button, DatePicker, Form, FormTitle, SubContent, useBreadcrumbRoutes, Wrapper } from '@maxtropy/components';
import { FormInstance, Space, Spin } from 'antd';
import React, { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import MappingRelation from './components/MappingRelation';
import GreenElec from './components/GreenElec';
import AIEnergy from './components/AIEnergy';
import UnitConsumptionSetting from './components/UnitConsumptionSetting';
import dayjs from 'dayjs';
import {
  apiV2EnergyCarbonConfigConfigInfoPost,
  apiV2EnergyCarbonConfigConfigUpsertPost,
  V2EnergyCarbonConfigConfigInfoPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { useRequest } from 'ahooks';
import { isNil } from 'lodash-es';

export interface ShareDataProps {
  form?: FormInstance<any>;
  isEdit?: boolean;
  data?: V2EnergyCarbonConfigConfigInfoPostResponse;
}

const routes = [{ name: '配置' }];

export const ShareDataContext = createContext<ShareDataProps>({});

const ZYFDashboardConfig = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_configId = urlSearchParams.get('configId') || undefined;

  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  // 查询详情
  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2EnergyCarbonConfigConfigInfoPost({
        id: Number(url_configId),
      });
      return res;
    },
    {
      ready: !!url_configId,
      refreshDeps: [url_configId],
    }
  );

  // 给表单赋值
  useEffect(() => {
    if (data && form) {
      form.setFieldsValue({
        startMeasurementDate: data?.startMeasurementDate ? dayjs(data?.startMeasurementDate) : undefined,
        areaNameOne: data.areaList?.[0].areaName,
        areaOne: data.areaList?.[0].energyUnitGroupId,
        energyOne: data.areaList?.[0].energyUnitIdList,
        areaNameTwo: data.areaList?.[1].areaName,
        areaTwo: data.areaList?.[1].energyUnitGroupId,
        energyTwo: data.areaList?.[1].energyUnitIdList,
        areaNameThree: data.areaList?.[2].areaName,
        areaThree: data.areaList?.[2].energyUnitGroupId,
        energyThree: data.areaList?.[2].energyUnitIdList,
        workShopList: data.workShopList,
        // 绿电
        carbonEmissionFactor: data.carbonEmissionFactor,
        photovoltaicMeterId: data.photovoltaicMeterId,
        // AI能源指数
        electricitySafety: data.aiEnergyIndex?.electricitySafety,
        efficiency: data.aiEnergyIndex?.efficiency,
        voltageQualificationRate: data.aiEnergyIndex?.voltageQualificationRate,
        peakValleyRatio: data.aiEnergyIndex?.peakValleyRatio,
        loadRate: data.aiEnergyIndex?.loadRate,
      });
    }
  }, [data, form]);

  const onFinish = (values: any) => {
    console.log('values', values);
    const params = {
      startMeasurementDate: dayjs(values?.startMeasurementDate).format('YYYY-MM-DD'),
      areaList: [
        {
          areaNumber: 0,
          energyUnitGroupId: values?.areaOne,
          energyUnitIdList: (values?.energyOne ?? []).map((item: any) =>
            typeof item === 'number' ? item : item?.value
          ),
          areaName: values?.areaNameOne,
        },
        {
          areaNumber: 1,
          energyUnitGroupId: values?.areaTwo,
          energyUnitIdList: (values?.energyTwo ?? []).map((item: any) =>
            typeof item === 'number' ? item : item?.value
          ),
          areaName: values?.areaNameTwo,
        },
        {
          areaNumber: 2,
          energyUnitGroupId: values?.areaThree,
          energyUnitIdList: (values?.energyThree ?? []).map((item: any) =>
            typeof item === 'number' ? item : item?.value
          ),
          areaName: values?.areaNameThree,
        },
      ],
      workShopList: (values?.workShopList ?? [])
        .filter((c: any) => c.workShopName)
        .map((item: any, index: number) => ({
          workShopNumber: index,
          workShopName: item?.workShopName,
          machineList: item?.machineList?.map((c: any, i: number) => ({
            machineNumber: i,
            machineName: c?.machineName,
            machineCode: c?.machineCode?.map((o: any) => (typeof o === 'string' ? o : o.code)),
            deviceIds: c?.deviceIds,
            consumptions: [
              {
                consumptionType: 1,
                consumptionValue:
                  c?.electricEnergy === undefined
                    ? c?.consumptions?.find((x: any) => x.consumptionType === 1)?.consumptionValue
                    : c?.electricEnergy,
              },
              {
                consumptionType: 2,
                consumptionValue:
                  c?.gas === undefined
                    ? c?.consumptions?.find((x: any) => x.consumptionType === 2)?.consumptionValue
                    : c?.gas,
              },
              {
                consumptionType: 3,
                consumptionValue:
                  c?.steam === undefined
                    ? c?.consumptions?.find((x: any) => x.consumptionType === 3)?.consumptionValue
                    : c?.steam,
              },
            ],
          })),
        })),
      photovoltaicMeterId: values?.photovoltaicMeterId,
      carbonEmissionFactor: values?.carbonEmissionFactor,
      aiEnergyIndex: {
        electricitySafety: values?.electricitySafety,
        efficiency: values?.efficiency,
        voltageQualificationRate: values?.voltageQualificationRate,
        peakValleyRatio: values?.peakValleyRatio,
        loadRate: values?.loadRate,
      },
    };
    console.log('params', params);
    apiV2EnergyCarbonConfigConfigUpsertPost({ ...params, configId: url_configId }).then(_ => {
      navigate('/factory/zyf/dashboard');
    });
  };

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish}>
          <ShareDataContext.Provider value={{ form, isEdit, data }}>
            <FormTitle
              title="配置"
              extraContent={
                <Space size={8}>
                  {!isEdit ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        setTimeout(() => {
                          setIsEdit(true);
                        }, 5);
                      }}
                    >
                      编辑
                    </Button>
                  ) : (
                    <Button htmlType="submit" type="primary">
                      保存
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      navigate('/factory/zyf/dashboard');
                    }}
                  >
                    返回
                  </Button>
                </Space>
              }
            />
            <SubContent title={`开始计量日期`}>
              <Form.Item label="开始计量日期" name="startMeasurementDate">
                <DatePicker allowClear={false} disabled={!isEdit} style={{ width: 320 }} />
              </Form.Item>
            </SubContent>
            <SubContent title={`区域映射关系`}>
              <MappingRelation />
            </SubContent>
            <SubContent title={`单耗设定`}>
              <UnitConsumptionSetting />
            </SubContent>
            <SubContent title={`绿电降碳`}>
              <GreenElec />
            </SubContent>
            <SubContent title={`AI能源指数`}>
              <AIEnergy />
            </SubContent>
          </ShareDataContext.Provider>
        </Form>
      </Spin>
    </Wrapper>
  );
};

export default ZYFDashboardConfig;

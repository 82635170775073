import React from 'react';
import { Col, FormInstance, Row } from 'antd';
import { Form, ShowInput } from '@maxtropy/components';
import styles from './index.module.scss';

interface Props {
  form: FormInstance;
}
const TaskInfoForm: React.FC<Props> = ({ form }) => {
  const renderLabel = (text: string) => {
    return <span style={{ color: 'var(--mx-text-secondary-color)' }}>{text}</span>;
  };

  return (
    <>
      <Form form={form} className={styles.form}>
        <Row>
          <Col span={6}>
            <Form.Item label={renderLabel('所属核算单元')} name="accountingUnitName">
              <ShowInput />
            </Form.Item>
          </Col>
          <Form.Item label={renderLabel('所属行业')} name="industryName">
            <ShowInput />
          </Form.Item>
        </Row>

        <Row>
          <Col span={6}>
            <Form.Item label={renderLabel('填报周期')} name="issuingDate">
              <ShowInput />
            </Form.Item>
          </Col>

          <Form.Item label={renderLabel('核算标准')} name="standard">
            <ShowInput />
          </Form.Item>
        </Row>
      </Form>
    </>
  );
};

export default TaskInfoForm;

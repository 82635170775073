import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getBillReactiveQuantity,
  getElectricityBillSummary,
  QuantitiesItem,
  SummaryRes,
} from '../../../../api/electricity';
import PowerFactorConfigModal from '../PowerFactorConfigModal';
import { units } from '../utils';
import styles from './index.module.scss';
import { ShowInput } from '@maxtropy/components';

const ElectricityBillReactivePower: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<QuantitiesItem>();
  const [summaryData, setSummaryData] = useState<SummaryRes>();

  useEffect(() => {
    if (id) {
      getBillReactiveQuantity(id).then(res => {
        setData(res);
      });
      getElectricityBillSummary(id).then(res => {
        setSummaryData(res);
      });
    }
  }, [id]);

  const info = (
    <div>
      <p>两部制+新能源规则根据功率因数比值配置计算（两部制默认按规则1）</p>
      <ol style={{ paddingInlineStart: '15px' }}>
        <li>
          无功电量/需量电表有功总电量
          <ul className={styles.thirdUl}>
            <li>无功总电量：核查联无功总电量</li>
            <li>有功总电量：核查联需量电表有功总电量</li>
          </ul>
        </li>
        <li>
          无功电量/（需量表有功电量+光伏用电量）
          <ul className={styles.thirdUl}>
            <li>无功总电量：核查联无功总电量</li>
            <li>有功总电量：核查联需量电表有功总电量</li>
            <li>光伏用电量：核查联光伏实际用电总电量</li>
          </ul>
        </li>
      </ol>
    </div>
  );

  return (
    <>
      <div className={styles.box}>
        <div className={styles.boxBorder}>
          <Form layout="vertical">
            <Row>
              <Col span={2}>
                <Form.Item label="本月示数">
                  <ShowInput value={data?.currentQuantity?.toFixed(2)} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="上月示数">
                  <ShowInput value={data?.lastQuantity?.toFixed(2)} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="倍率">
                  <ShowInput value={data?.multiplyRatio} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="无功总电量">
                  <ShowInput value={data?.totalReactivePowerQuantity?.toFixed(2)} addonAfter={units.千瓦时} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="有功总电量">
                  <ShowInput value={summaryData?.totalActivePowerQuantity?.toFixed(2)} addonAfter={units.千瓦时} />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="需量表有功总电量">
                  <ShowInput value={data?.totalActivePowerQuantity?.toFixed(2)} addonAfter={units.千瓦时} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item
                  label={
                    <>
                      功率因数
                      <Tooltip overlayInnerStyle={{ width: '480px' }} title={info}>
                        <InfoCircleOutlined style={{ marginLeft: '5px', color: 'var(--mx-primary-color)' }} />
                      </Tooltip>
                    </>
                  }
                >
                  <ShowInput value={data?.powerFactor} addonAfter="%" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="增减率">
                  <ShowInput value={data?.powerFactorRatio} addonAfter="%" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div className={styles.btn}>
          <PowerFactorConfigModal tierConfigName={data?.tierConfigName} />
        </div>
      </div>
    </>
  );
};
export default ElectricityBillReactivePower;

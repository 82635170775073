import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import { AdjustmentMeterRecordsRes } from './electricity';
import qs from 'qs';

export interface ElectricityPurQuery extends PageRequest {
  saleClientCode?: string; // 客户编号
  deviceId?: Key; // 设备id
  meterReadingStartTs?: string; // 计划抄表开始时间
  meterReadingEndTs?: string; // 计划抄表结束时间
}

export const getElectricityPurPage = (query: ElectricityPurQuery) => {
  return fetch<PageResponse<AdjustmentMeterRecordsRes>>(
    `/api/v2/sale-electricity/meter-reading/purchaser/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
};

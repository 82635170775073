import {
  BusinessUnitTypeType,
  CircuitDetailProps,
  CircuitLinkObj,
  CircuitListByOuResponse,
  CircuitRelationType,
  CircuitRelationTypeFormat,
  CircuitType,
  getCircuitLink,
  getUETList,
  UETListResponse,
} from '@/api/circuit';
import { Form, Select } from '@maxtropy/components';
import { Cascader, Col, FormInstance, Row } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { searchParent } from '../..';
import UpstreamCircuit from './components/UpstreamCircuit';

interface CircuitRelationFormProps {
  form: FormInstance;
  isEdit?: boolean;
  ouId?: number;
  data?: CircuitDetailProps;
}

const CircuitRelationForm: FC<CircuitRelationFormProps> = props => {
  const { form, isEdit, data } = props;
  const linkType: CircuitRelationType = Form.useWatch('linkType', form);
  const [UEtList, setUETList] = useState<UETListResponse[]>([]);
  const [linkObjOptions, setLinkObjOptions] = useState<CircuitLinkObj[]>([]);
  const [leftCircuitInfo, setLeftCircuitInfo] = useState<CircuitListByOuResponse>();
  const [rightCircuitInfo, setRightCircuitInfo] = useState<CircuitListByOuResponse>();

  useEffect(() => {
    if (leftCircuitInfo && rightCircuitInfo) {
      if (leftCircuitInfo.uetId === rightCircuitInfo.uetId) {
        form.setFieldValue('uetId', leftCircuitInfo.uetId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftCircuitInfo, rightCircuitInfo]);

  useEffect(() => {
    if (linkType) {
      getCircuitLink({ circuitLinkType: linkType, circuitId: isEdit ? data?.id : undefined }).then(res => {
        setLinkObjOptions(res.list ?? []); // 所属UET列表
        if (isEdit && data?.linkObjectId) {
          let arr = searchParent(res.list, data?.linkObjectId).map(i => i.id);
          form.setFieldsValue({
            linkObjectId: arr,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkType, data]);

  useEffect(() => {
    // 查询uet列表
    getUETList({ businessUnitType: BusinessUnitTypeType.MICROGRID_POWER_TOPOLOGY }).then(setUETList); // 所属UET列表
  }, []);

  const uetOptions = useMemo(() => {
    if (UEtList && UEtList.length !== 0) {
      return UEtList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [UEtList]);

  const onCircuitChangeAutoUetInfo = (circuitInfo: CircuitListByOuResponse) => {
    circuitInfo && form.setFieldValue('uetId', circuitInfo.uetId);
  };

  return (
    <>
      <Row gutter={50}>
        <UpstreamCircuit
          isEdit={isEdit}
          form={form}
          data={data}
          onCircuitChangeAutoUetInfo={onCircuitChangeAutoUetInfo}
          onLeftCircuitChangeAutoUetInfo={circuitInfo => setLeftCircuitInfo(circuitInfo)}
          onRightCircuitChangeAutoUetInfo={circuitInfo => setRightCircuitInfo(circuitInfo)}
        />
      </Row>
      <Row gutter={50}>
        <Form.Item noStyle dependencies={['type']}>
          {({ getFieldValue }) => {
            let type = getFieldValue('type');
            return (
              <Col span={8}>
                <Form.Item name="uetId" label="所属UET" rules={[{ required: true, message: '请选择所属UET' }]}>
                  <Select
                    placeholder="请选择所属UET"
                    disabled={isEdit || type !== CircuitType.GRID_BILLING_CIRCUIT}
                    options={uetOptions}
                  />
                </Form.Item>
              </Col>
            );
          }}
        </Form.Item>
        <Col span={8}>
          <Form.Item name="linkType" label="回路关联">
            <Select placeholder="请选择回路关联" allowClear>
              {Object.keys(CircuitRelationTypeFormat).map(key => (
                <Select.Option key={key} value={Number(key)}>
                  {CircuitRelationTypeFormat[key as unknown as CircuitRelationType]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Form.Item noStyle dependencies={['linkType']}>
          {({ getFieldValue }) =>
            getFieldValue('linkType') && (
              <Col span={8}>
                <Form.Item name="linkObjectId" label="关联对象" rules={[{ required: true, message: '请选择关联对象' }]}>
                  <Cascader
                    allowClear={false}
                    style={{ width: '100%' }}
                    options={linkObjOptions}
                    fieldNames={{ label: 'name', value: 'id' }}
                    placeholder="请选择"
                  />
                </Form.Item>
              </Col>
            )
          }
        </Form.Item>
      </Row>
    </>
  );
};

export default CircuitRelationForm;

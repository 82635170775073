import React from 'react';
import styles from './index.module.scss';
import UnitConsCompRate from './components/UnitConsCompRate';
import EnergyConsStatistics from './components/EnergyConsStatistics';
import MonthAverEnergyCons from './components/MonthAverEnergyCons';
import light_line from '../../imgs/light_line.png';

const CenterRightBox = () => {
  return (
    <div className={styles.right_wrapper}>
      <div className={styles.top_bg}>
        <span className={styles.top_bg_text}>用能趋势</span>
      </div>
      <div className={styles.content_wrapper}>
        <div className={styles.content_top_box}>
          <UnitConsCompRate />
        </div>
        <div className={styles.content_center_box}>
          <EnergyConsStatistics />
        </div>
        <div className={styles.content_bottom_box}>
          <MonthAverEnergyCons />
        </div>
        <img className={styles.light_line} src={light_line} alt="" />
      </div>
    </div>
  );
};

export default CenterRightBox;

import { Button, EllipsisSpan, Key, useUpdate } from '@maxtropy/components';
import { Form, Input, InputNumber, Modal, Space, Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { MeterProjectChangeButtonType, SingleOrDetail } from '../MeterProjectDetail';
import styles from './index.module.scss';
import EleAdjustInput from '../EleAdjustInput';
import EleShareInput from '../EleShareInput';
import AdjustModal from '../AdjustModal';
import TimeIntervalShowTag, { TimeIntervalType } from '../TimeIntervalShowTag';
import {
  AdjustBody,
  ApportionListItem,
  EnergyMediumTableDataItem,
  MeterTaskBaseInfo,
  TotalEnergyMediumTableDataListItem,
  apportionFunc,
  batchAdjustFunc,
  getTotalEnergyMediumTableData,
  meterReadingAmountChangeFunc,
} from '@/api/universeMeterQuery';
import { useParams } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import {
  changeAdjust,
  changeCurrentCopyNum,
  changeShare,
  changeTreeData,
  deleteToTreeData,
  getAllExpandedRow,
  getCellStyle,
  getRowSpan,
  insertExpandToTreeData,
} from '../../handleTreeDataUtils';

interface Props {
  changeButtonType?: MeterProjectChangeButtonType;
  setChangeButtonType: (type?: MeterProjectChangeButtonType) => void;
  singleOrDetail: SingleOrDetail;
  isElectricity: boolean;
  mediumId: number;
  basicInfo?: MeterTaskBaseInfo;
  unitName: string;
  isAllExpand: boolean;
  setIsAllExpand: (isAllExpand: boolean) => void;
}

// 输入框共同属性
const inputNumberProps = {
  style: { width: '100%' },
  max: 9999999999,
  step: 0.01,
  precision: 2,
};

const formatDataToTreeTableData = (
  data: TotalEnergyMediumTableDataListItem[],
  treeTableData: EnergyMediumTableDataItem[]
) => {
  data.forEach(project => {
    project.rows.forEach((row, index) => {
      treeTableData.push({
        id: `${project.meterReadingObjectId}-${row.itemId}-${index}`,
        itemId: row.itemId,
        meterReadingObjectId: project.meterReadingObjectId,
        meterReadingObjectName: project.meterReadingObjectName,
        meterReadingLevel: project.meterReadingLevel,
        deviceTypeName: row.deviceTypeName,
        deviceId: row.deviceId,
        deviceCode: row.deviceCode,
        deviceName: row.deviceName,
        topologyNodeName: row.topologyNodeName,
        dataPropertyName: row.dataPropertyName,
        last: row.lastPeriodReadingValue,
        current: row.currentPeriodReadingValue,
        fact: row.realReadingAmount,
        adjust: row.adjustAmount,
        afterAdjust: row.afterAdjustAmount,
        share: project.apportionAmount,
        final: project.finalAmount,
        summitAdjust: row.summitAdjustAmount,
        peakAdjust: row.peakAdjustAmount,
        plainAdjust: row.plainAdjustAmount,
        valleyAdjust: row.valleyAdjustAmount,
        adjustReason: row.adjustReason,
        summitShare: project.summitApportionAmount,
        peakShare: project.peakApportionAmount,
        plainShare: project.plainApportionAmount,
        valleyShare: project.valleyApportionAmount,
        isHightLight: false,
        expand: index === 0 ? false : undefined,
        children: index === 0 ? (project.list && project.list.length > 0 ? [] : undefined) : undefined,
        child:
          index === 0
            ? project.list && project.list.length > 0
              ? formatDataToTreeTableData(project.list, [])
              : undefined
            : undefined,
      });
    });
  });
  return treeTableData;
};

const TotalEnergyMediumTable: React.FC<Props> = ({
  changeButtonType,
  setChangeButtonType,
  singleOrDetail,
  isElectricity,
  mediumId,
  basicInfo,
  unitName,
  isAllExpand,
  setIsAllExpand,
}) => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<EnergyMediumTableDataItem[]>([]);
  const [originData, setOriginData] = useState<EnergyMediumTableDataItem[]>([]); // 原始数据
  const [beforeEditData, setBeforeEditData] = useState<EnergyMediumTableDataItem[]>([]); // 编辑前的数据
  const [expandedTreeData, setExpandedTreeData] = useState<EnergyMediumTableDataItem[]>([]); // 是否展开的数据
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [batchAdjustForm] = Form.useForm();
  const [adjustOpen, setAdjustOpen] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<EnergyMediumTableDataItem>();
  const [adjustEditItemIds, setAdjustEditItemIds] = useState<Key[]>([]); // 调整编辑项 id
  const [copyNumEditItemIds, setCopyNumEditItemIds] = useState<Key[]>([]); // 本期抄见数编辑项 id
  const [shareEditItemIds, setShareEditItemIds] = useState<Key[]>([]); // 分摊编辑项 id
  const [loading, setLoading] = useState<boolean>(false);
  const [update, updateFn] = useUpdate();
  const hasAdjust = useHasPermission(PermissionsType.B_GLOBALMETERREADINGADJUST); // 调整
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
  const [allExpandRowKeys, setAllExpandRowKeys] = useState<Key[]>([]);

  useEffect(() => {
    if (id && mediumId) {
      // 获取分项抄表
      setLoading(true);
      getTotalEnergyMediumTableData(id, mediumId)
        .then(res => {
          if (res) {
            const tableData = formatDataToTreeTableData(res.list, []);
            setData(tableData);
            setBeforeEditData(tableData);
            setExpandedTreeData(tableData);
            setOriginData(tableData);
            setAllExpandRowKeys(getAllExpandedRow(tableData, []).map(i => i.id));
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, mediumId, update]);

  useEffect(() => {
    if (isAllExpand) {
      const allExpandRow = getAllExpandedRow(originData, []);
      let expandedTree = originData;
      let tree = originData;
      allExpandRow.forEach(i => {
        expandedTree = changeTreeData(expandedTree, i.id, true);
        tree = insertExpandToTreeData(expandedTree, tree, i);
      });
      setData(tree);
      setBeforeEditData(tree);
      setExpandedTreeData(expandedTree);
      setExpandedRowKeys(allExpandRowKeys);
    } else {
      setExpandedRowKeys([]);
      setExpandedTreeData(originData);
      setData(originData);
      setBeforeEditData(originData);
    }
  }, [isAllExpand, originData, allExpandRowKeys]);

  useEffect(() => {
    if (
      expandedRowKeys.length > 0 &&
      allExpandRowKeys.length > 0 &&
      expandedRowKeys.length === allExpandRowKeys.length
    ) {
      setIsAllExpand(true);
      return;
    }
    if (expandedRowKeys.length === 0 && allExpandRowKeys.length !== 0) {
      setIsAllExpand(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedRowKeys]);

  const singleOrDetailColumns = useMemo(() => {
    // 获取展开节点样式
    const getExpandStyle = (isHightLight?: boolean) => {
      return isHightLight
        ? {
            backgroundColor: 'var(--mx-page-bg-color)',
          }
        : {};
    };

    let columns = [
      {
        title: '层级',
        dataIndex: 'meterReadingLevel',
        width: 100,
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        onCell: (record: any, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: number) => {
          return <EllipsisSpan value={v ? `${v}级` : v} />;
        },
      },
      {
        title: '抄表对象',
        dataIndex: 'meterReadingObjectName',
        width: 100,
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
          style: getExpandStyle(record.isHightLight),
        }),
      },
      {
        title: '类型',
        dataIndex: 'deviceTypeName',
        width: 100,
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        render: (v: string) => <EllipsisSpan value={v} />,
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        isHide: true,
      },
      {
        title: '设备编号',
        width: 100,
        dataIndex: 'deviceCode',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        isHide: true,
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '设备名称',
        width: 100,
        dataIndex: 'deviceName',
        ellipsis: { showTitle: true },
        fixed: 'left' as 'left',
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '对应拓扑节点',
        width: 100,
        dataIndex: 'topologyNodeName',
        ellipsis: { showTitle: true },
        isHide: true,
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '数据属性',
        width: 100,
        dataIndex: 'dataPropertyName',
        ellipsis: { showTitle: true },
        isHide: true,
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '上期抄见数',
        width: 100,
        dataIndex: 'last',
        ellipsis: { showTitle: true },
        isHide: true,
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '本期抄见数',
        width: 160,
        dataIndex: 'current',
        ellipsis: { showTitle: true },
        onCell: (record: EnergyMediumTableDataItem) => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.CHANGE_COPY_NUM, record.isHightLight),
        }),
        onHeaderCell: () => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.CHANGE_COPY_NUM),
        }),
        render: (v: number, record: EnergyMediumTableDataItem) => {
          return isNil(record.dataPropertyName) ? (
            '--'
          ) : changeButtonType === MeterProjectChangeButtonType.CHANGE_COPY_NUM ? (
            <InputNumber
              {...inputNumberProps}
              value={v}
              min={0}
              onChange={value => {
                if (isNil(value)) return;
                setData(changeCurrentCopyNum(value, isElectricity, record, data));
                setCopyNumEditItemIds([...copyNumEditItemIds, record.itemId]);
              }}
            />
          ) : (
            <EllipsisSpan value={v} />
          );
        },
      },
      {
        title: '实际抄表值',
        width: 100,
        dataIndex: 'fact',
        ellipsis: { showTitle: true },
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '调整值',
        dataIndex: isElectricity ? 'electricityAdjust' : 'adjust',
        ellipsis: { showTitle: true },
        width: isElectricity ? (changeButtonType === MeterProjectChangeButtonType.BATCH_ADJUST ? 650 : 450) : 200,
        onCell: (record: EnergyMediumTableDataItem) => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.BATCH_ADJUST, record.isHightLight),
        }),
        onHeaderCell: () => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.BATCH_ADJUST),
        }),
        render: (v: number, record: EnergyMediumTableDataItem) => {
          return isNil(record.dataPropertyName) ? (
            '--'
          ) : changeButtonType === MeterProjectChangeButtonType.BATCH_ADJUST ? (
            isElectricity ? (
              <EleAdjustInput
                adjustEditItemIds={adjustEditItemIds}
                setAdjustEditItemIds={setAdjustEditItemIds}
                data={data}
                setData={setData}
                currentRow={record}
              />
            ) : (
              <InputNumber
                {...inputNumberProps}
                value={v}
                min={-999999}
                onChange={value => {
                  setData(changeAdjust(value, record, data));
                  setAdjustEditItemIds([...adjustEditItemIds, record.itemId]);
                }}
              />
            )
          ) : isElectricity ? (
            <>
              <Space>
                {[
                  { value: record.summitAdjust, type: TimeIntervalType.SUMMIT },
                  { value: record.peakAdjust, type: TimeIntervalType.PEAK },
                  { value: record.plainAdjust, type: TimeIntervalType.PLAIN },
                  { value: record.valleyAdjust, type: TimeIntervalType.VALLEY },
                ].map((item, index) => {
                  return <TimeIntervalShowTag key={index} timeIntervalType={item.type} value={item.value} />;
                })}
              </Space>
            </>
          ) : (
            <EllipsisSpan value={v} />
          );
        },
      },
      {
        title: '调整后值',
        width: 100,
        dataIndex: 'afterAdjust',
        ellipsis: { showTitle: true },
        isHide: true,
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          style: getExpandStyle(record.isHightLight),
        }),
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '分摊值',
        dataIndex: isElectricity ? 'eleShare' : 'share',
        width: isElectricity ? (changeButtonType === MeterProjectChangeButtonType.APPORTION_INPUT ? 650 : 450) : 200,
        ellipsis: { showTitle: true },
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.APPORTION_INPUT, record.isHightLight),
        }),
        onHeaderCell: () => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.APPORTION_INPUT),
        }),
        render: (v: number, record: EnergyMediumTableDataItem) => {
          return isNil(record.dataPropertyName) ? (
            '--'
          ) : changeButtonType === MeterProjectChangeButtonType.APPORTION_INPUT ? (
            isElectricity ? (
              <EleShareInput
                setShareEditItemIds={setShareEditItemIds}
                shareEditItemIds={shareEditItemIds}
                data={data}
                setData={setData}
                currentRow={record}
              />
            ) : (
              <InputNumber
                {...inputNumberProps}
                value={v}
                min={0}
                onChange={value => {
                  setData(changeShare(value, record, data));
                  setShareEditItemIds([...shareEditItemIds, record.itemId]);
                }}
              />
            )
          ) : isElectricity ? (
            <Space>
              {[
                { value: record.summitShare, type: TimeIntervalType.SUMMIT },
                { value: record.peakShare, type: TimeIntervalType.PEAK },
                { value: record.plainShare, type: TimeIntervalType.PLAIN },
                { value: record.valleyShare, type: TimeIntervalType.VALLEY },
              ].map((item, index) => {
                return <TimeIntervalShowTag key={index} timeIntervalType={item.type} value={item.value} />;
              })}
            </Space>
          ) : (
            <EllipsisSpan value={v} />
          );
        },
        editable: true,
      },
      {
        title: '最终值',
        width: 100,
        dataIndex: 'final',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
        onCell: (record: EnergyMediumTableDataItem, index?: number) => ({
          rowSpan: getRowSpan(data, record, index),
          style: getExpandStyle(record.isHightLight),
        }),
      },
      {
        title: '操作',
        fixed: 'right' as 'right',
        width: 100,
        onCell: (record: EnergyMediumTableDataItem) => ({
          style: getCellStyle(changeButtonType, MeterProjectChangeButtonType.CHANGE_COPY_NUM, record.isHightLight),
        }),
        render: (v: any, record: EnergyMediumTableDataItem) => {
          return (
            <Space>
              {hasAdjust && (
                <Button
                  type="link"
                  onClick={() => {
                    setAdjustOpen(true);
                    setCurrentRow(record);
                  }}
                >
                  调整
                </Button>
              )}
            </Space>
          );
        },
      },
    ];

    // 编辑态过滤操作行
    if (!!changeButtonType) {
      columns = columns.filter(i => i.title !== '操作');
    }

    // 表格过滤隐藏列
    if (singleOrDetail === SingleOrDetail.SINGLE) {
      columns = columns.filter(i => !i.isHide);
    }

    return columns;
  }, [
    isElectricity,
    changeButtonType,
    singleOrDetail,
    data,
    copyNumEditItemIds,
    adjustEditItemIds,
    shareEditItemIds,
    hasAdjust,
  ]);

  return (
    <>
      {changeButtonType && (
        <Space size={8}>
          <Button
            type="primary"
            onClick={() => {
              if (changeButtonType === MeterProjectChangeButtonType.BATCH_ADJUST) {
                modalApi.confirm({
                  title: '确定保存批量调整？',
                  content: (
                    <Form form={batchAdjustForm}>
                      <Form.Item
                        name="batchAdjustReason"
                        rules={[{ max: 20, message: '调整原因最多20个字符' }]}
                        label="调整原因"
                      >
                        <Input.TextArea
                          autoSize={{
                            minRows: 3,
                          }}
                        />
                      </Form.Item>
                    </Form>
                  ),
                  okText: '保存',
                  onOk: () => {
                    let adjustList: AdjustBody[] = [];
                    const editData = data.filter(i => adjustEditItemIds.includes(i.itemId));
                    if (isElectricity) {
                      adjustList = editData.map(i => ({
                        itemId: i.itemId,
                        adjustType: 2,
                        adjustAmountSummit: i.summitAdjust,
                        adjustAmountPeak: i.peakAdjust,
                        adjustAmountPlain: i.plainAdjust,
                        adjustAmountValley: i.valleyAdjust,
                      }));
                    } else {
                      adjustList = editData.map(i => ({
                        itemId: i.itemId,
                        adjustType: 1,
                        adjustAmount: i.adjust,
                      }));
                    }
                    batchAdjustFunc({
                      list: adjustList,
                      adjustReason: batchAdjustForm.getFieldValue('batchAdjustReason'),
                    }).then(() => {
                      setChangeButtonType(undefined);
                      setBeforeEditData(data);
                      setAdjustEditItemIds([]);
                      updateFn();
                      return;
                    });
                  },
                });
              }
              if (changeButtonType === MeterProjectChangeButtonType.CHANGE_COPY_NUM) {
                modalApi.confirm({
                  title: '确认抄见数变更？',
                  content: '抄见数变更会同步到下一期抄表抄见数，影响最终数据。请确认变更？',
                  okText: '确认变更',
                  onOk: () => {
                    let editData = data.filter(i => copyNumEditItemIds.includes(i.itemId));
                    const body = editData.map(i => ({
                      itemId: i.itemId,
                      updateMeterReadingAmount: i.current,
                    }));
                    meterReadingAmountChangeFunc({ list: body }).then(() => {
                      setChangeButtonType(undefined);
                      setBeforeEditData(data);
                      setCopyNumEditItemIds([]);
                      updateFn();
                      return;
                    });
                  },
                });
              }
              if (changeButtonType === MeterProjectChangeButtonType.APPORTION_INPUT) {
                if (id && mediumId) {
                  const editData = data.filter(i => shareEditItemIds.includes(i.itemId));
                  let apportionList: ApportionListItem[] = [];
                  if (isElectricity) {
                    apportionList = editData.map(i => ({
                      taskId: id,
                      objectId: i.meterReadingObjectId,
                      energyMediumId: mediumId,
                      adjustType: 2,
                      apportionAmountSummit: i.summitShare,
                      apportionAmountPeak: i.peakShare,
                      apportionAmountPlain: i.plainShare,
                      apportionAmountValley: i.valleyShare,
                    }));
                  } else {
                    apportionList = editData.map(i => ({
                      taskId: id,
                      objectId: i.meterReadingObjectId,
                      energyMediumId: mediumId,
                      adjustType: 1,
                      apportionAmount: i.share,
                    }));
                  }
                  apportionFunc({ list: apportionList }).then(() => {
                    setChangeButtonType(undefined);
                    setShareEditItemIds([]);
                    setBeforeEditData(data);
                    updateFn();
                    return;
                  });
                }
              }
            }}
          >
            保存
          </Button>
          <Button
            onClick={() => {
              setChangeButtonType(undefined);
              setAdjustEditItemIds([]);
              setCopyNumEditItemIds([]);
              setShareEditItemIds([]);
              setData(beforeEditData);
            }}
          >
            取消
          </Button>
        </Space>
      )}

      <div className={styles.tableStyles}>
        <Table
          loading={loading}
          bordered
          columns={singleOrDetailColumns}
          rowKey="id"
          pagination={false}
          dataSource={data}
          sticky
          scroll={{ x: 1300 }}
          expandable={{
            onExpand: (isShow, record) => {
              if (isShow) {
                setExpandedRowKeys([...expandedRowKeys, record.id]);
                setExpandedTreeData(changeTreeData(expandedTreeData, record.id, true));
                setData(insertExpandToTreeData(expandedTreeData, data, record));
                setBeforeEditData(insertExpandToTreeData(expandedTreeData, beforeEditData, record));
              } else {
                setExpandedRowKeys(expandedRowKeys.filter(i => i !== record.id));
                setExpandedTreeData(changeTreeData(expandedTreeData, record.id, false));
                setData(deleteToTreeData(expandedTreeData, data, record));
                setBeforeEditData(deleteToTreeData(expandedTreeData, beforeEditData, record));
              }
            },
            expandedRowKeys,
          }}
        />
      </div>
      {adjustOpen && (
        <AdjustModal
          basicInfo={basicInfo}
          currentRow={currentRow}
          adjustOpen={adjustOpen}
          setAdjustOpen={setAdjustOpen}
          isElectricity={isElectricity}
          unitName={unitName}
          updateFn={updateFn}
        />
      )}
      {modalContextHolder}
    </>
  );
};
export default TotalEnergyMediumTable;

import { Empty, Modal } from '@maxtropy/components';
import { Checkbox, Divider, Row, Spin } from 'antd';
import styles from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import {
  V2WorkShiftCalendarConfigProcedureListPostResponse,
  apiV2WorkShiftCalendarConfigProcedureListPost,
} from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash';
import { WorkCenterItem } from '..';
import { useLocation, useParams } from 'react-router-dom';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

const CheckboxGroup = Checkbox.Group;

interface WorkProcedure {
  workProcedureId: number;
  workProcedureName: string;
}

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  workCenterInfo?: WorkCenterItem;
  updateData: (workProcedureList: WorkProcedure[], addProcedureIds?: number[], delProcedureIds?: number[]) => void;
  dateRange: { startDate?: string; endDate?: string };
}

export type ProcessItem = Exclude<V2WorkShiftCalendarConfigProcedureListPostResponse['list'], undefined>[number];

const SelectedProcessModal: React.FC<Props> = ({ open, setOpen, workCenterInfo, dateRange, updateData }) => {
  const [processOptions, setProcessOptions] = useState<ProcessItem[]>([]);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const checkAll = processOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < processOptions.length;
  const [prevCheckedList, setPrevCheckedList] = useState<number[]>([]);
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const workShiftCalendarId = urlSearchParams.get('workShiftCalendarId') || undefined;

  useEffect(() => {
    setPrevCheckedList((workCenterInfo?.workProcedureList ?? [])?.map(m => m.workProcedureId!));
  }, [workCenterInfo]);

  useEffect(() => {
    if (isNil(workCenterInfo?.workCenterId) || isNil(dateRange.startDate) || isNil(dateRange.endDate)) return;
    setLoading(true);
    apiV2WorkShiftCalendarConfigProcedureListPost({
      id: isNil(id) ? undefined : Number(id),
      workShiftCalendarId: Number(workShiftCalendarId),
      workCenterId: workCenterInfo?.workCenterId!,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    })
      .then(res => {
        setProcessOptions(res.list ?? []);
        setCheckedList((workCenterInfo?.workProcedureList ?? []).map(k => k.workProcedureId!));
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workCenterInfo, dateRange]);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list as number[]);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(processOptions.map(k => k.workProcedureId!));
  };

  const saveLink = () => {
    const options = processOptions.map(n => ({
      workProcedureId: n.workProcedureId!,
      workProcedureName: n.workProcedureName!,
    }));
    const addProcedureIds = options
      .filter(n => checkedList.filter(k => !prevCheckedList.includes(k)).includes(n.workProcedureId!))
      .map(m => m.workProcedureId);

    const delProcedureIds = options
      .filter(n => prevCheckedList.filter(k => !checkedList.includes(k)).includes(n.workProcedureId!))
      .map(m => m.workProcedureId);
    const workProcedureList = options.filter(k => checkedList.includes(k.workProcedureId!));

    updateData(workProcedureList, addProcedureIds, delProcedureIds);
    setOpen(false);
  };

  return (
    <Modal title="选择应用工序" open={open} onOk={saveLink} onCancel={() => setOpen(false)}>
      <Spin spinning={loading}>
        <div className={styles.topContent}>
          <div className={styles.headerRow}>
            工作中心：<span>{workCenterInfo?.workCenterName ?? '--'}</span>
          </div>
          <div className={styles.tipRow}>
            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} /> 不可选适用事件范围内已配置或无权限的工序
          </div>
        </div>
        {processOptions.length ? (
          <>
            <div className={styles.checkRow}>
              <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                全选
              </Checkbox>
              <div className={styles.selectedTip}>
                已选择 <span>{checkedList.length}</span> 个工序
              </div>
            </div>
            <Divider style={{ margin: '12px 0' }} />
            <div className={styles.bottomContent}>
              <CheckboxGroup
                style={{ width: '100%', gap: 8, flexDirection: 'column' }}
                value={checkedList}
                onChange={onChange}
              >
                {processOptions.map(k => (
                  <Row key={k.workProcedureId}>
                    <Checkbox disabled={!k.selectableFlag} value={k.workProcedureId}>
                      {k.workProcedureName}
                    </Checkbox>
                  </Row>
                ))}
              </CheckboxGroup>
            </div>
          </>
        ) : (
          <Empty style={{ padding: '100px 0' }} description="暂无数据" />
        )}
      </Spin>
    </Modal>
  );
};

export default SelectedProcessModal;

import * as echarts from 'echarts';
import { V2EnergyCarbonOverviewGreenFactoryPostResponse } from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import { keepTwoNull, keepTwoNullRid1000 } from '@/pages/ZYFDashboard/utils';

export const getChartOption = (data?: V2EnergyCarbonOverviewGreenFactoryPostResponse) => {
  // 光伏发电量
  let pvPowerData = (data?.list ?? []).map(item => [dayjs(item.time).valueOf(), keepTwoNullRid1000(item.pvPower)]);

  // 绿电降碳
  let greenCarbonReductionData = (data?.list ?? []).map(item => [
    dayjs(item.time).valueOf(),
    keepTwoNull(item.greenCarbonReduction),
  ]);

  const maxPvPowerData = Math.max(...pvPowerData.map(i => i[1] ?? 0));
  const maxGreenCarbonReductionData = Math.max(...greenCarbonReductionData.map(i => i[1] ?? 0));

  const minPvPowerData = Math.min(...pvPowerData.map(i => i[1] ?? 0));
  const minGreenCarbonReductionData = Math.min(...greenCarbonReductionData.map(i => i[1] ?? 0));

  let maxPvPower = Math.ceil(Math.max(maxPvPowerData) / 4) * 4;
  let maxGreenCarbonReduction = Math.ceil(Math.max(maxGreenCarbonReductionData) / 4) * 4;
  let minPvPower = Math.floor(Math.min(minPvPowerData) / 4) * 4;
  let minGreenCarbonReduction = Math.floor(Math.min(minGreenCarbonReductionData) / 4) * 4;

  // 间隔计算
  let pvPowerInterVal = Number(((maxPvPower - minPvPower) / 4).toFixed(0));
  let greenCarbonReductionInterVal = Number(((maxGreenCarbonReduction - minGreenCarbonReduction) / 4).toFixed(0));

  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format('YYYY-MM');
        let otherStr = '';
        let str = '';

        items.forEach((item: any) => {
          const { seriesName, data, marker } = item;
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;">${marker}</span>${seriesName} : &nbsp;${
            data[1] || data[1] === 0 ? data[1] : '-'
          }${seriesName === '光伏发电量' ? 'MWh' : 'tCO2'}</div>`;
          str = firstStr + otherStr;
        });

        return str;
      },
    },
    grid: {
      left: 10,
      right: 10,
      top: 50,
      bottom: 10,
      containLabel: true,
    },
    legend: [
      {
        right: 110,
        itemGap: 18,
        itemWidth: 12,
        itemHeight: 12,
        textStyle: {
          color: '#ffffff85',
        },
        inactiveColor: '#ffffff45',
        data: [
          {
            name: '光伏发电量',
            icon: 'rect',
          },
        ],
      },
      {
        right: 10,
        itemGap: 18,
        itemWidth: 12,
        itemHeight: 12,
        textStyle: {
          color: '#ffffff85',
        },
        inactiveColor: '#ffffff45',
        data: [
          {
            name: '绿电降碳量',
            icon: 'rect',
          },
        ],
      },
    ],
    xAxis: [
      {
        type: 'time',
        minInterval: 60 * 1000 * 60 * 24 * 30,
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: function (value: number) {
            const month = dayjs(value).format('YYYY-MM').split('-')[1]; // 从 '2024-01' 中提取月份
            return Number(month) + '月'; // 转换为数字，会自动去掉前导 0
          },
          fontSize: 10,
        },
        axisPointer: {
          type: 'shadow',
        },
        axisLine: {
          show: true, // 是否显示轴线
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'MWh',
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
      {
        type: 'value',
        name: 'tCO2',
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#66686A',
          },
        },
      },
    ],
    series: [
      {
        name: '光伏发电量',
        type: 'bar',
        data: pvPowerData,
        max: maxPvPower,
        min: minPvPower,
        interval: pvPowerInterVal,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1, // 渐变从上到下
            [
              { offset: 0, color: '#4AFF7F' }, // 起始颜色
              { offset: 0.65, color: 'rgba(74,255,127,0.7)' }, // 起始颜色
              { offset: 1, color: 'rgba(216,216,216,0)' }, // 结束颜色
            ]
          ),
        },
      },
      {
        name: '绿电降碳量',
        type: 'line',
        yAxisIndex: 1,
        data: greenCarbonReductionData,
        max: maxGreenCarbonReduction,
        min: minGreenCarbonReduction,
        interval: greenCarbonReductionInterVal,
        itemStyle: {
          color: '#FF8E2D', // 图例的颜色会与折线颜色保持一致
        },
        lineStyle: {
          color: '#FF8E2D', // 线条颜色
          width: 2, // 线条宽度
          type: 'solid', // 线条类型：'solid' | 'dashed' | 'dotted'
        },
        // itemStyle: {
        //   color: new echarts.graphic.LinearGradient(
        //     0,
        //     0,
        //     0,
        //     1, // 渐变从上到下
        //     [
        //       { offset: 0, color: 'rgba(0,173,255,0.9)' }, // 起始颜色
        //       { offset: 0.65, color: 'rgba(0,173,255,0.5)' }, // 起始颜色
        //       { offset: 1, color: 'rgba(0,173,255,0)' }, // 结束颜色
        //     ]
        //   ),
        // },
        // effect: {
        //   show: true, // 开启动态效果
        //   constantSpeed: 200, // 流动速度（越大越快）
        //   trailLength: 0.7, // 光点拖尾长度
        //   symbol: 'circle', // 光点形状
        //   symbolSize: 8, // 光点大小
        //   color: '#42A5F5',
        // },
        // animation: true,
        // animationDuration: 2000, // 动画时长
        // animationEasing: 'cubicOut', // 动画缓动效果
      },
    ],
  };
};

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Wrapper,
  useBreadcrumbRoutes,
  Button,
  useAsync,
  Form,
  Modal,
  Input,
  Select,
  SubContent,
  FormTitle,
  ShowInput,
} from '@maxtropy/components';
import { Row, Col, Space, Cascader } from 'antd';

import { AlarmType, IotProtocolType, IotProtocolTypeDisplay, AlarmChannel } from '@/shared/types';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getRulesListById, updateRuleGroup } from '../../../api/ruleGroup';
import { formatDeviceTypeToDataNode, sliceDeviceTypeName, useQuery } from '@/shared/utils/utils';
import { getProtocol } from '../../../api/protocol';
import { RulePageProps } from '../../../api/rule';
import { getDeviceTypeTreeWithoutScene } from '../../../api/deviceType';
import SelectAlarmRules from './SelectAlarmRules';
import styles from './index.module.scss';
import { isNil } from 'lodash-es';
import {
  V2RuleGroupInfoPostResponse,
  apiV2RuleGroupAddPost,
  apiV2RuleGroupInfoPost,
  apiV2RuleGetChannelPost,
  V2RuleGetChannelPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { getPhysicalModelList } from '@/api/device';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

export interface FormItems {
  name: string;
  rootMcid: string;
  channel: AlarmChannel;
  iotProtocol: string | IotProtocolType;
  physicalModelId?: number;
  rules?: RulePageProps[];
}

// const pageSize = 10000;

// const groupSize = 5;

const CreateAlarmRulesGroup: FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { search } = useLocation();

  const { id } = useParams<{ id: string }>();

  const [ruleGroup, setRuleGroup] = useState<V2RuleGroupInfoPostResponse>();
  const [rules, setRules] = useState<RulePageProps[]>([]);
  const [alarmChannelList, setAlarmChannelList] = useState<V2RuleGetChannelPostResponse['list']>();
  const [alarmChannel, setAlarmChannel] = useState<AlarmChannel>(AlarmChannel.MAXTROPY);

  // const [temporaryRules, setTemporaryRules] = useState<RulePageProps[]>();

  // const [totalCount, setTotalCount] = useState<number>();

  const [loading, setLoading] = useState<boolean>(false);

  const breadcrumbRoutes = useBreadcrumbRoutes();

  // 注释部分先不用动，可以问一下为啥要注释
  // useEffect(() => {
  //   if (id) {
  //     setLoading(true);
  //     getRulesPageById({id: Number(id), page: 1, size: pageSize}).then(res => {
  //       if (res) {
  //         setTotalCount(res.total ?? 0);
  //         if (res.total <= pageSize) {
  //           setRules(res.list)
  //         }
  //         setTemporaryRules(res.list)
  //       }
  //     })
  //   }
  // }, [id])

  // const remainingPageArray = useMemo(() => {
  //   if (totalCount !== undefined) {
  //     const pages = Math.ceil(totalCount / pageSize);
  //     if (pages > 1) {
  //       return new Array(pages-1).fill(0).map((_, i) => i+2)
  //     } else {
  //       setLoading(false);
  //     }
  //   }
  // }, [totalCount])

  // const getPagesData = (pages: number[]) => {
  //   return pages.map(i => getRulesPageById({id: Number(id), page: i, size: pageSize}).onError(e => {throw e}))
  // }

  // const getAllPagesData = async (data: number[]) => {
  //   const groupByData = chunk(data, groupSize)
  //   const allData: RulePageProps[] = []
  //   try {
  //     for (let v of groupByData) {
  //       await Promise.all(getPagesData(v)).then(res => {
  //         allData.push(...res.map(i => i.list).flat())
  //       })
  //     }
  //     setRules([...(temporaryRules ?? []), ...allData])
  //     setLoading(false);

  //   } catch(e) {
  //     setLoading(false)
  //     Modal.error({
  //       title: "未知错误，请联系管理员！"
  //     })
  //   }
  // }

  // useEffect(() => {
  //   if (id && Array.isArray(remainingPageArray) && Array.isArray(temporaryRules)) {
  //     getAllPagesData(remainingPageArray)
  //   }
  // }, [id, remainingPageArray, temporaryRules])

  useEffect(() => {
    if (id) {
      setLoading(true);
      getRulesListById(id).then(res => {
        setRules(res.list ?? []);
        setLoading(false);
      });
      apiV2RuleGroupInfoPost({ id: +id }).then(res => {
        setRuleGroup(res);
      });
    }
  }, [id]);

  // 获取渠道数据
  useEffect(() => {
    apiV2RuleGetChannelPost().then(res => {
      setAlarmChannelList(res.list ?? []);
    });
  }, []);

  useEffect(() => {
    if (ruleGroup) {
      setDeviceType(ruleGroup.deviceTypeId);
      setIotProtocolType(ruleGroup.iotProtocol as IotProtocolType);
      setPhysicalModelId(ruleGroup.physicalModelId);
      setAlarmChannel(ruleGroup.channel as AlarmChannel);
      form.setFieldsValue({
        name: ruleGroup.name ?? '--',
        channel: alarmChannelList?.find(i => i.code === ruleGroup.channel)?.desc ?? '--',
        iotProtocol: IotProtocolTypeDisplay[ruleGroup.iotProtocol!] ?? '--',
        deviceTypeId: ruleGroup.deviceTypeName ? sliceDeviceTypeName(ruleGroup.deviceTypeName) : '--',
        physicalModelId: ruleGroup.modelNo ?? '--',
      });
    }
  }, [ruleGroup, form, alarmChannelList]);

  useEffect(() => {
    if (rules) {
      form.setFieldsValue({
        rules: rules,
      });
    }
  }, [rules, form]);

  const iotProtocolData = useAsync(getProtocol);
  const [iotProtocolType, setIotProtocolType] = useState<IotProtocolType>();
  const [deviceType, setDeviceType] = useState<number>();
  const [physicalModelId, setPhysicalModelId] = useState<number>();

  const { data: deviceTypeTreeData } = useQuery(useCallback(() => getDeviceTypeTreeWithoutScene(), []));

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}规则组` }];
  }, [isEdit]);

  const goList = () => {
    navigate(`/device/rule/list${search}`);
  };

  const onCacel = () => {
    Modal.confirm({
      title: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: goList,
    });
  };

  const onFinish = (value: FormItems) => {
    if (isEdit) {
      const currentRulesId = (value.rules ?? []).map(i => i.id);
      const rulesId = (rules ?? []).map(i => i.id);
      const deleteRuleIds = currentRulesId.filter(i => !rulesId.includes(i));
      const addRuleIds = rulesId.filter(i => !currentRulesId.includes(i));
      updateRuleGroup({
        id: ruleGroup?.id!,
        name: value.name,
        channel: value.channel,
        deleteRuleIds: addRuleIds,
        addRuleIds: deleteRuleIds,
        deviceTypeId: deviceType,
      }).then(res => {
        if (res) {
          goList();
        }
      });
    } else {
      apiV2RuleGroupAddPost({
        name: value.name,
        // rootMcid: value.rootMcid,
        channel: value.channel,
        iotProtocol:
          alarmChannel === AlarmChannel.LONGSHINE || alarmChannel === AlarmChannel.THIRD
            ? IotProtocolType.LONGSHINE
            : (value.iotProtocol as IotProtocolType),
        ruleIds: (value.rules ?? []).map(i => i.id),
        deviceTypeId: deviceType,
        physicalModelId: value.physicalModelId,
      }).then(res => {
        if (res) {
          goList();
        }
      });
    }
  };

  const onIotProtocolChange = useCallback(
    (v: IotProtocolType) => {
      setIotProtocolType(v);
      form.setFieldsValue({
        rules: [],
      });
    },
    [form]
  );

  const onDeviceTypeIdChange = useCallback(
    (e: any) => {
      setDeviceType(e[1] as number | undefined);
      setPhysicalModelId(undefined);
      form.setFieldsValue({
        rules: [],
        physicalModelId: undefined,
      });
    },
    [form]
  );

  const { data: physicalModelOptions = [] } = useQuery(
    useCallback(async () => {
      if (!isNil(deviceType) && !isEdit) {
        const response = await getPhysicalModelList({ deviceTypeIds: [deviceType] });
        return response.list;
      }
      return [];
    }, [deviceType, isEdit])
  );

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
        <FormTitle title={routes[0].name} />
        <SubContent className="mb-8">
          <Row>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="name"
                label="规则组名称"
                rules={[
                  { required: true, message: '请输入规则组名称' },
                  { max: 20, message: '不超过20位' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <SubContent title="报警规则">
          <Row className={styles.info}>
            <Col span={8}>
              {isEdit ? (
                <>
                  <Form.Item name="channel" label="渠道">
                    <ShowInput />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    name="channel"
                    label="渠道"
                    initialValue={AlarmChannel.MAXTROPY}
                    rules={[{ required: true, message: '请选择渠道' }]}
                  >
                    <Select
                      onChange={setAlarmChannel}
                      options={(alarmChannelList ?? []).map(({ code, desc }) => ({
                        label: desc,
                        value: code,
                      }))}
                    />
                  </Form.Item>
                </>
              )}
            </Col>
            {alarmChannel === AlarmChannel.MAXTROPY ? (
              <>
                <Col span={8}>
                  <Form.Item
                    name="iotProtocol"
                    rules={[{ required: true, message: '请选择物联层协议' }]}
                    label="物联层协议"
                  >
                    {isEdit ? (
                      <ShowInput />
                    ) : (
                      <Select<IotProtocolType> onChange={onIotProtocolChange} placeholder="请选择">
                        {iotProtocolData?.list?.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {iotProtocolType === IotProtocolType.MOCKINGBIRD && (
                  <>
                    <Col span={8}>
                      <Form.Item
                        name="deviceTypeId"
                        label="适用设备类目"
                        rules={[{ required: true, message: '请选择适用设备类目' }]}
                      >
                        {isEdit ? (
                          <ShowInput />
                        ) : (
                          <Cascader
                            allowClear={false}
                            style={{ width: '100%' }}
                            options={formatDeviceTypeToDataNode(deviceTypeTreeData?.deviceTypes ?? [])}
                            fieldNames={{ label: 'name', value: 'id' }}
                            onChange={onDeviceTypeIdChange}
                            placeholder="请选择"
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={8} className={styles.col}>
                      <Form.Item name="physicalModelId" label="物模型型号">
                        {isEdit ? (
                          <ShowInput />
                        ) : (
                          <Select
                            placeholder="请选择"
                            allowClear
                            options={physicalModelOptions.map(item => ({
                              label: item.modelNo,
                              value: item.id,
                            }))}
                            onChange={v => {
                              setPhysicalModelId(v);
                              form.setFieldsValue({
                                rules: [],
                              });
                            }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            ) : (
              <>
                <Col span={8}>
                  <Form.Item
                    name="deviceTypeId"
                    label="适用设备类目"
                    rules={[{ required: true, message: '请选择适用设备类目' }]}
                  >
                    {isEdit ? (
                      <ShowInput />
                    ) : (
                      <Cascader
                        allowClear={false}
                        style={{ width: '100%' }}
                        options={formatDeviceTypeToDataNode(deviceTypeTreeData?.deviceTypes ?? [])}
                        fieldNames={{ label: 'name', value: 'id' }}
                        onChange={onDeviceTypeIdChange}
                        placeholder="请选择"
                      />
                    )}
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={24}>
              <Form.Item name="rules" wrapperCol={{ span: 24 }}>
                <SelectAlarmRules
                  loading={loading}
                  type={AlarmType.MARGIN}
                  iotProtocolType={iotProtocolType}
                  deviceTypeId={deviceType}
                  alarmChannel={alarmChannel as AlarmChannel}
                  physicalModelId={physicalModelId}
                />
              </Form.Item>
            </Col>
          </Row>
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button type="primary" htmlType="submit">
            保存
          </Button>
          <Button className={styles.button} onClick={onCacel}>
            取消
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateAlarmRulesGroup;

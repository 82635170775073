import { keepTwoNull, keepTwoNullMulti100 } from '@/pages/ZYFDashboard/utils';
import { V2EnergyCarbonOverviewConsumptionPostResponse } from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import * as echarts from 'echarts';

export const getChartOption = (data?: V2EnergyCarbonOverviewConsumptionPostResponse) => {
  const areaColors = [
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: 'rgba(0,173,255,0.15)' },
      { offset: 1, color: 'rgba(0,173,255,0.05)' },
    ]),
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: 'rgba(74,255,127,0.15)' },
      { offset: 1, color: 'rgba(74,255,127,0.05)' },
    ]),
    new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      { offset: 0, color: 'rgba(74,255,127,0.15)' },
      { offset: 1, color: 'rgba(74,255,127,0.05)' },
    ]),
  ];

  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.8)',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.85)',
      },
      formatter: (items: any) => {
        let { axisValue } = items[0];
        let firstStr = dayjs(axisValue).format('YYYY-MM');
        let otherStr = '';
        let str = '';

        items.forEach((item: any) => {
          const { seriesName, data } = item;
          otherStr += `<div style="margin-top:8px"><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
            item.color
          };"></span>${seriesName} : &nbsp;${data[1] || data[1] === 0 ? data[1] : '-'}%</div>`;
          str = firstStr + otherStr;
        });

        return str;
      },
    },
    grid: {
      left: 10,
      right: 10,
      top: 60,
      bottom: 10,
      containLabel: true,
    },
    color: ['#4AFF7F', '#FFE44A', '#00ADFF'],
    legend: {
      right: 10,
      textStyle: {
        color: 'rgba(255, 255, 255, 0.85)',
      },
      inactiveColor: 'rgba(255, 255, 255, 0.45)',
      itemWidth: 16,
      itemHeight: 4,
      data: data?.list?.map(item => ({
        name: item.name,
        icon: 'rect',
      })),
    },
    xAxis: [
      {
        type: 'time',
        minInterval: 60 * 1000 * 60 * 24 * 30,
        axisLabel: {
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
          formatter: function (value: number) {
            const month = dayjs(value).format('YYYY-MM').split('-')[1]; // 从 '2024-01' 中提取月份
            return Number(month) + '月'; // 转换为数字，会自动去掉前导 0
          },
          fontSize: 10,
        },
        axisPointer: {
          type: 'shadow',
        },
        axisLine: {
          show: true, // 是否显示轴线
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '%',
        axisLabel: {
          formatter: '{value}',
          textStyle: {
            color: 'rgba(255,255,255,0.85)', //坐标的字体颜色
          },
        },
        nameTextStyle: {
          color: 'rgba(255,255,255,0.85)',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
      },
    ],
    series: data?.list?.map((item, index) => ({
      name: item.name,
      type: 'line',
      data: item.aggrValueVos?.map(i => [dayjs(i.ts).valueOf(), keepTwoNullMulti100(i.uploadValue)]),
      connectNulls: true,
      smooth: true,
      symbol: 'circle',
      symbolSize: 2,
      showSymbol: false, // 只有在 tooltip hover 的时候显示symbol
      itemStyle: {
        // symbol样式
        normal: {
          borderColor: 'rgba(255, 255, 255, 0.2)', // symbol边框颜色
          borderWidth: 6, // symbol边框宽度
        },
      },
      lineStyle: {
        width: 2,
        type: 'solid', //'dotted'点型虚线 'solid'实线 'dashed'线性虚线
      },
      areaStyle: {
        color: areaColors[index % areaColors.length],
      },
    })),
  };
};

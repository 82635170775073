import React, { useContext, useMemo, useState } from 'react';
import spin from '../../imgs/bottom_spin.png';
import safe from '../../imgs/bottom_safe.png';
import styles from './index.module.scss';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { ShareDataContext } from '../..';
import {
  apiV2EnergyCarbonConfigConfigInfoPost,
  apiV2EnergyCarbonOverviewEdgeDeviceDataPost,
  apiV2EnergyCarbonOverviewMediumSceneNumberPost,
} from '@maxtropy/device-customer-apis-v2';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { EnergyMediumSceneEnum, EnergyMediumSceneEnumDisplay } from '../../utils';
import { Empty } from '@maxtropy/components';

const CenterBottomBox = () => {
  const [swiperIntance, setSwiperIntance] = useState<SwiperCore | undefined>();

  const { configId } = useContext(ShareDataContext);

  const { data: configDetails } = useRequest(
    async () => {
      const res = await apiV2EnergyCarbonConfigConfigInfoPost({
        id: Number(configId),
      });
      return res;
    },
    {
      ready: !!configId,
      refreshDeps: [configId],
    }
  );

  // 采集点数量
  const { data: edgeDevicePointData } = useRequest(
    async () => {
      const res = await apiV2EnergyCarbonOverviewEdgeDeviceDataPost({
        id: Number(configId),
      });
      return res;
    },
    {
      ready: !!configId,
      refreshDeps: [configId],
    }
  );

  // 获取租户所有场景下的输入输出数(总数)
  const { data: mediumData } = useRequest(
    async () => {
      const res = await apiV2EnergyCarbonOverviewMediumSceneNumberPost({
        id: Number(configId),
      });
      return res;
    },
    {
      ready: !!configId,
      refreshDeps: [configId],
    }
  );

  const slidesPerView = useMemo(() => {
    const filteredList = mediumData?.list ?? [];
    if (filteredList.length === 2) {
      return 2;
    } else if (filteredList.length >= 3) {
      return 3;
    }
    return 1;
  }, [mediumData?.list]);

  return (
    <div className={styles.bottom_wrapper}>
      <div className={styles.left_wrapper}>
        <div className={styles.inner}>
          <div className={styles.moving_element}></div>
          <div className={styles.background}></div>
          <div className={styles.number_box}>
            <div className={styles.label}>采集点数量</div>
            <div className={styles.value}>{edgeDevicePointData?.edgeDevicePointCount ?? '--'}</div>
          </div>
          <div className={styles.swipper}>
            {mediumData?.list?.length ? (
              <>
                <div
                  className={classNames(`swiper-button-prev`, styles.swiper_prev_btn)}
                  onClick={() => swiperIntance?.slidePrev()}
                >
                  <LeftOutlined />
                </div>
                <Swiper
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  spaceBetween={8}
                  slidesPerView={slidesPerView}
                  freeMode={true}
                  observer={true}
                  observeParents={true}
                  onSwiper={swiper => setSwiperIntance(swiper)}
                >
                  {(mediumData?.list ?? []).map((m, index) => {
                    return (
                      <SwiperSlide key={m.energyMediumSceneId}>
                        <div className={styles.cardStyles}>
                          <div className={styles.singleCard}>
                            <p>{m?.mediumSceneSum}</p>
                            <p>{EnergyMediumSceneEnumDisplay[m?.energyMediumSceneId as EnergyMediumSceneEnum]}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <div
                  className={classNames(`swiper-button-next`, styles.swiper_next_btn)}
                  onClick={() => swiperIntance?.slideNext()}
                >
                  <RightOutlined />
                </div>
              </>
            ) : (
              <Empty imageStyle={{ height: 60 }} />
            )}
          </div>
        </div>
      </div>
      <div className={styles.right_wrapper}>
        <div className={styles.inner}>
          <div className={styles.moving_element}></div>
          <div className={styles.background}></div>
          <div className={styles.img_wrapper}>
            <img src={spin} alt="" />
            <img src={safe} alt="" />
          </div>
          <div className={styles.right_day_box}>
            <div className={styles.label}>精益能碳治理行动已进行</div>
            <div className={styles.value}>
              {Math.abs(
                Number(
                  dayjs(dayjs(configDetails?.startMeasurementDate).valueOf()).diff(dayjs(dayjs().valueOf()), 'day')
                )
              )
                .toString()
                .split('')
                .map((item, index) => (
                  <div key={index} className={styles.each_number}>
                    {item}
                  </div>
                ))}
            </div>
            <div className={styles.day}>天</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CenterBottomBox;

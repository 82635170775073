import { Button, EllipsisSpan, Form, Input, Key, PopConfirm, ShowInput, Table } from '@maxtropy/components';
import { FormInstance, FormListFieldData, Space } from 'antd';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import CabintModal from './CabintModal';
import { cloneDeep, isNil } from 'lodash-es';
import { ShareDataContext } from '../../..';
import DeviceModal from './DeviceModal';

export interface IEachWorkshop {
  index: number;
}

const EachWorkshop: FC<IEachWorkshop> = props => {
  const { index } = props;
  const { form, isEdit } = useContext(ShareDataContext);
  const [cabintVisible, setCabintVisible] = useState<boolean>(false); // 机台弹窗
  const [deviceVisible, setDeviceVisible] = useState<boolean>(false); // 设备弹窗
  const [cabintRecord, setCabintRecord] = useState<any>(); // 保存每一行

  const columns = [
    {
      title: '机台',
      dataIndex: 'machineName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      fixed: 'right' as const,
      render: (record: any) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              disabled={!isEdit}
              onClick={() => {
                setCabintVisible(true);
                setCabintRecord(record);
              }}
            >
              编辑机台
            </Button>
            <Button
              type="link"
              disabled={!isEdit}
              onClick={() => {
                setDeviceVisible(true);
                setCabintRecord(record);
              }}
            >
              编辑设备
            </Button>
            <PopConfirm
              okText="继续"
              title={'确定删除'}
              onConfirm={() => {
                const list = form?.getFieldValue(['workShopList', index, 'machineList']) ?? [];
                const originalList = cloneDeep(list);
                const removeIndex = originalList.findIndex((i: any) => i.machineNumber === record.machineNumber);
                originalList.splice(removeIndex, 1);
                form?.setFieldValue(['workShopList', index, 'machineList'], originalList);
              }}
            >
              <Button disabled={!isEdit} type="link">
                删除
              </Button>
            </PopConfirm>
          </Space>
        );
      },
    },
  ];

  // 机台重置
  const onCabintReset = () => {
    setCabintVisible(false);
    setCabintRecord(undefined);
  };

  // 设备重置
  const onDeviceReset = () => {
    setDeviceVisible(false);
    setCabintRecord(undefined);
  };

  return (
    <>
      <Form.Item label={`车间名称`} name={[index, 'workShopName']} rules={[{ min: 1, max: 20 }]}>
        <Input style={{ width: '20%' }} disabled={!isEdit} placeholder="请输入" />
      </Form.Item>
      <div style={{ width: 90, marginBottom: 8 }}>
        <Button type="primary" disabled={!isEdit} onClick={() => setCabintVisible(true)}>
          新增机台
        </Button>
      </div>
      <Form.Item name={[index, 'machineList']} style={{ marginBottom: 0, width: '50%' }} valuePropName="dataSource">
        <Table rowKey={() => Math.random()} columns={columns} pagination={false} />
      </Form.Item>
      {/* 机台弹窗（新建+编辑） */}
      <CabintModal
        cabintVisible={cabintVisible}
        cabintRecord={cabintRecord}
        onCancel={() => {
          onCabintReset();
        }}
        onOk={val => {
          console.log('val', val);
          // 编辑
          if (!isNil(cabintRecord)) {
            const arr = form?.getFieldValue(['workShopList', index, 'machineList']) ?? [];
            const list = cloneDeep(arr);
            const findIndex = list?.findIndex((c: any) => c?.machineNumber === cabintRecord?.machineNumber);
            const findOriginValue = list?.find((c: any) => c?.machineNumber === cabintRecord?.machineNumber);
            console.log('findOriginValue', findOriginValue);
            if (findIndex !== -1) {
              form?.setFieldValue(['workShopList', index, 'machineList', findIndex], { ...findOriginValue, ...val });
            }
          } else {
            // 新增
            const arr = form?.getFieldValue(['workShopList', index, 'machineList']) ?? [];
            const list = cloneDeep(arr);
            list.push({ ...val, machineNumber: Math.random() });
            form?.setFieldValue(['workShopList', index, 'machineList'], list);
          }
          onCabintReset();
        }}
      />
      {/* 设备弹窗（编辑） */}
      <DeviceModal
        deviceVisible={deviceVisible}
        cabintRecord={cabintRecord}
        index={index}
        onCancel={() => {
          onDeviceReset();
        }}
        onOk={val => {
          const list = form?.getFieldValue(['workShopList', index, 'machineList']) ?? [];
          const findIndex = list?.findIndex((c: any) => c?.machineNumber === cabintRecord?.machineNumber);
          if (findIndex !== -1) {
            form?.setFieldValue(['workShopList', index, 'machineList', findIndex, 'deviceIds'], val);
          }

          onDeviceReset();
        }}
      />
    </>
  );
};

export default EachWorkshop;

import React, { useContext, useEffect, useMemo, useRef } from 'react';
import styles from './index.module.scss';
import CardBox from '../../../CardBox';
import ReactEcharts from 'echarts-for-react';
import { getChartOption } from './chart';
import { ShareDataContext } from '@/pages/ZYFDashboard';
import { useRequest } from 'ahooks';
import { apiV2EnergyCarbonOverviewConsumptionPost } from '@maxtropy/device-customer-apis-v2';
import { Empty } from '@maxtropy/components';
import { Spin } from 'antd';

const UnitConsCompRate = () => {
  const chartRef = useRef<ReactEcharts>();
  const { configId } = useContext(ShareDataContext);

  // 单耗达标率柱状图
  const { data: unitRateData, loading } = useRequest(
    async () => {
      const res = await apiV2EnergyCarbonOverviewConsumptionPost({
        id: Number(configId),
      });
      return res;
    },
    {
      ready: !!configId,
      refreshDeps: [configId],
    }
  );

  // 查询图表
  const chartOption = useMemo(() => getChartOption(unitRateData), [unitRateData]);

  const handleResize = () => {
    const echartsInstance = chartRef.current?.getEchartsInstance();
    if (echartsInstance) {
      echartsInstance.resize();
    }
  };

  const containerRef = useRef(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const echartsInstance = chartRef.current?.getEchartsInstance();
      if (echartsInstance) {
        echartsInstance.resize();
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CardBox title="单耗达标率" className={styles.cardBoxSty}>
      {loading ? (
        <Spin spinning={loading} />
      ) : unitRateData?.list?.length ? (
        <div className={styles.echarts_wrapper} ref={containerRef}>
          <ReactEcharts
            style={{ height: '100%' }}
            ref={d => {
              if (d) {
                chartRef.current = d;
              }
            }}
            option={chartOption}
            notMerge
            lazyUpdate={false}
          />
        </div>
      ) : (
        <Empty />
      )}
    </CardBox>
  );
};

export default UnitConsCompRate;

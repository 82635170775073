import { fetch, Key, PromiseWithErrorHandler } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { Operator, BatchDeviceTemplateStatus, IotProtocolType, DriveType } from '@/shared/types';
import { MockingbirdDeviceProps } from '@/shared/components/DataAcquisitionForm/types';

export interface BatchDeviceTemplate {
  id: number;
  code: string;
  templateStatus: BatchDeviceTemplateStatus;
  updateSource: Operator;
  updateByUserId: string;
  updateByUsername: string;
  typeId?: number;
  typeName?: string;
  modelId?: number;
  modelName?: string;
  updateTime: string;
  existAttribute: boolean;
  existEdgeTemplate: boolean;
}

export interface BatchDeviceTemplateListRequest extends PageRequest {
  code?: string;
  typeId?: Array<number>;
}

export interface BatchDeviceTemplateListResponse extends PageResponse<BatchDeviceTemplate> {}

export function getBatchDeviceTemplateList(
  params: BatchDeviceTemplateListRequest
): Promise<BatchDeviceTemplateListResponse> {
  return fetch(
    `/api/v2/batch/import/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface BatchDeviceRecord {
  id: number;
  serialNumber: string;
  templateId: number;
  templateCode: string;
  importTime: string;
  updateSource: Operator;
  updateByUserId?: string;
  updateByUsername?: string;
  typeId: number;
  typeName: string;
  modelId: number;
  modelName: string;
  // rootMcid: string;
  // rootFullName: string;
  tenantCode: string;
  errorCount: number;
  successCount: number;
  totalCount: number;
}

export function getBatchDeviceRecordList(
  params: BatchDeviceTemplateListRequest
): Promise<PageResponse<BatchDeviceRecord>> {
  return fetch(
    `/api/v2/batch/import/record/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface CreateBatchDeviceTemplatePropertyRequest {
  typeId?: number;
  modelId?: number;
  modelName?: string;
  pic?: string;
  // rootMcid: string;
  customerMcid: string;
  assetCode: string;
  ouIds?: Array<number>;
  addOuIds?: Array<number>;
  deleteOuIds?: Array<number>;
  tags?: string;
  physicalModelId?: Key;
  modelNo?: string;
}

export interface BatchDeviceTemplatePropertyResponse extends CreateBatchDeviceTemplatePropertyRequest {
  id: number;
  code: string;
  typeName?: string;
  // rootFullName: string;
  customerName: string;
  customerFullName: string;
  manufacturerName: string;
}

export function getBatchDeviceTemplateProperty(id: string): Promise<BatchDeviceTemplatePropertyResponse> {
  return fetch(
    `/api/v2/batch/import/info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function createBatchDeviceTemplateProperty(
  params: CreateBatchDeviceTemplatePropertyRequest
): Promise<BatchDeviceTemplatePropertyResponse> {
  return fetch(
    `/api/v2/batch/import/add`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface UpdateBatchDeviceTemplatePropertyRequest
  extends Omit<CreateBatchDeviceTemplatePropertyRequest, 'typeId' | 'modelId' | 'modeName' | 'rootMcid'> {
  id: number;
}

export function updateBatchDeviceTemplateProperty(
  params: UpdateBatchDeviceTemplatePropertyRequest
): Promise<BatchDeviceTemplatePropertyResponse> {
  return fetch(
    `/api/v2/batch/import/update`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface CopyBatchDeviceTemplatePropertyRequest extends CreateBatchDeviceTemplatePropertyRequest {
  copyId: number;
}

export function copyBatchDeviceTemplateProperty(
  params: CopyBatchDeviceTemplatePropertyRequest
): Promise<BatchDeviceTemplatePropertyResponse> {
  return fetch(
    `/api/v2/batch/import/copy`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface AttributeItem {
  fieldId: number;
  unitId?: number;
  value?: string;
}

export interface BatchDeviceTemplateAttributeResponse {
  batchTemplateId: number;
  templateId: number;
  templateVersion: number;
  list: AttributeItem[];
}

export function getBatchDeviceTemplateAttribute(id: string): Promise<BatchDeviceTemplateAttributeResponse> {
  return fetch(
    `/api/v2/batch/import/attribute`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function updateBatchDeviceTemplateAttribute(
  params: BatchDeviceTemplateAttributeResponse
): Promise<BatchDeviceTemplateAttributeResponse> {
  return fetch(
    `/api/v2/batch/import/attribute/upsert`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface BatchDeviceTemplateEdgeInfo {
  batchTemplateId: number;
  iotProtocol: IotProtocolType;
  templateVersionId: number;
  driveType?: DriveType;
  parameters?: Omit<MockingbirdDeviceProps, 'driveType'>;
}

export const getBatchDeviceTemplateEdgeInfo = (id: string) =>
  fetch<BatchDeviceTemplateEdgeInfo>(
    `/api/v2/batch/import/getEdge`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export const updateBatchDeviceTemplateEdge = (body: BatchDeviceTemplateEdgeInfo) => {
  return fetch<void>(
    `/api/v2/batch/import/edge/upsert`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};

export function deleteBatchDeviceTemplate(id: number): Promise<{ flag: boolean }> {
  return fetch(
    `/api/v2/batch/import/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function deleteBatchDeviceRecord(id: number): Promise<{ flag: boolean }> {
  return fetch(
    `/api/v2/batch/import/deleteRecord`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface BatchDevicePageRequest extends PageRequest {
  codeOrName?: string;
  tag?: string;
  deviceTypeId: number;
  templateId?: number;
  templateVersion?: number;
  operationType: number;
  physicalModelId?: number;
}

interface EdgeDevice {
  id: number;
  name: string;
  code: string;
  typeId: number;
  typeName: string;
  rootMcid: string;
  tenantCode: string;
  rootFullName: string;
  tags?: string[];
  customerFullName?: string;
}

export type BatchDevicePageResponse = PageResponse<EdgeDevice>;

export function getBatchDevicePage(params: BatchDevicePageRequest): Promise<BatchDevicePageResponse> {
  return fetch(
    `/api/v2/batch/device/template/attribute/device/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

interface BatchDeviceAttributeForm {
  deviceIds?: number[];
  templateId?: number;
  templateVersion?: number;
  list: AttributeItem[];
}

export function createBatchDeviceAttribute(params: BatchDeviceAttributeForm): PromiseWithErrorHandler<{ key: string }> {
  return fetch(
    `/api/v2/batch/device/template/attribute/insert`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export function updateBatchDeviceAttribute(params: BatchDeviceAttributeForm): PromiseWithErrorHandler<{ key: string }> {
  return fetch(
    `/api/v2/batch/device/template/attribute/update`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Modal, ShowInput } from '@maxtropy/components';
import { Space } from 'antd';
import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import { EnergyAnalysisOptimizePageRes } from '../..';

interface Props {
  modalOpen: boolean;
  setModalOpen: (v: boolean) => void;
  currentDetail?: EnergyAnalysisOptimizePageRes;
  setCurrentDetail: (v: EnergyAnalysisOptimizePageRes | undefined) => void;
}

const formLayout = {
  labelCol: { span: 5, offset: 1 },
  wrapperCol: { span: 16 },
};

const DATE_FORMAT = 'YYYY-MM-DD';

const DetailAnalysis: React.FC<Props> = ({ setCurrentDetail, currentDetail, modalOpen = false, setModalOpen }) => {
  const [modalForm] = Form.useForm();

  useEffect(() => {
    if (currentDetail) {
      modalForm.setFieldsValue({
        name: currentDetail.name,
        time: `${dayjs(currentDetail.optimizationStartTime).format(DATE_FORMAT)}至${dayjs(
          currentDetail.optimizationEndTime
        ).format(DATE_FORMAT)}`,
        beforeTime: `${dayjs(currentDetail.initialBaselineStartTime).format(DATE_FORMAT)}至${dayjs(
          currentDetail.initialBaselineEndTime
        ).format(DATE_FORMAT)}`,
        type: currentDetail.type === 1 ? '单元用能分析' : undefined,
        energyUnitGroupId: currentDetail.energyUnitGroupName,
        energyUnitIds: (currentDetail.energyUnits ?? []).map(i => i.name).join('、'),
        energyMediumIds: (currentDetail.energyMediums ?? []).map(i => i.name).join('、'),
        remark: currentDetail.remark ?? undefined,
      });
    }
  }, [currentDetail, modalForm]);

  const onClose = () => {
    setModalOpen(false);

    setCurrentDetail(undefined);
  };

  return (
    <Modal
      size="large"
      title={`查看优化项目`}
      contentClassName="modal-form-content"
      open={modalOpen}
      onOk={onClose}
      onCancel={onClose}
    >
      <Form
        form={modalForm}
        {...formLayout}
        labelAlign="left"
        initialValues={{
          type: 1,
        }}
      >
        <Form.Item label="优化项目" name="name">
          <ShowInput />
        </Form.Item>
        <Form.Item
          label="优化时间"
          name="time"
          extra={
            <Space>
              <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
              <span>优化分析将以开始时间前和结束时间后进行对比</span>
            </Space>
          }
        >
          <ShowInput />
        </Form.Item>
        <Form.Item
          label="优化前基准时间"
          name="beforeTime"
          extra={
            <Space>
              <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
              <span>优化前能耗基准将以该时段日均基准计算</span>
            </Space>
          }
        >
          <ShowInput />
        </Form.Item>
        <Form.Item label="选择类型" name="type">
          <ShowInput />
        </Form.Item>
        <Form.Item label="用能分析组" name="energyUnitGroupId">
          <ShowInput />
        </Form.Item>
        <Form.Item
          label="收益计量单元"
          name="energyUnitIds"
          extra={
            <Space>
              <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
              <span>单独计算单元用能成本加总计算收益</span>
            </Space>
          }
        >
          <ShowInput />
        </Form.Item>
        <Form.Item label="优化用能项" name="energyMediumIds">
          <ShowInput />
        </Form.Item>
        <Form.Item label="备注" name="remark">
          <ShowInput />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DetailAnalysis;

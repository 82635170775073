import React, { useContext, useEffect, useMemo, useRef } from 'react';
import CardBox from '../../../CardBox';
import ReactEcharts from 'echarts-for-react';
import { getChartOption } from './chart';
import styles from './index.module.scss';
import { ShareDataContext } from '@/pages/ZYFDashboard';
import { useRequest } from 'ahooks';
import { apiV2EnergyCarbonOverviewAiEnergyPost } from '@maxtropy/device-customer-apis-v2';
import { Spin } from 'antd';
import { keepTwoOrLine } from '@/pages/ZYFDashboard/utils';
import { isNil } from 'lodash-es';

const AIEnergyIndex = () => {
  const chartRef = useRef<ReactEcharts>();
  const { configId } = useContext(ShareDataContext);

  // 查询数据
  const { data, loading } = useRequest(
    async () => {
      const res = await apiV2EnergyCarbonOverviewAiEnergyPost({
        id: Number(configId),
      });
      return res;
    },
    {
      ready: !!configId,
      refreshDeps: [configId],
    }
  );

  useEffect(() => {
    if (
      [
        data?.electricitySafety,
        data?.efficiency,
        data?.peakValleyRatio,
        data?.loadRate,
        data?.voltageQualificationRate,
      ].filter(i => !isNil(i)).length === 0
    ) {
      const echartsObj = chartRef.current?.getEchartsInstance();
      // 如果数据为空，添加 graphic 提示
      echartsObj?.setOption({
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: 'middle',
            style: {
              text: '暂无数据',
              fontSize: 16,
              fill: '#999',
            },
          },
        ],
      });
    }
  }, [data]);

  // 查询图表
  const chartOption = useMemo(() => getChartOption(data), [data]);

  const handleResize = () => {
    const echartsInstance = chartRef.current?.getEchartsInstance();
    if (echartsInstance) {
      echartsInstance.resize();
    }
  };

  const containerRef = useRef(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const echartsInstance = chartRef.current?.getEchartsInstance();
      if (echartsInstance) {
        echartsInstance.resize();
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <CardBox title="AI能源指数" className={styles.cardBoxSty}>
      {loading ? (
        <Spin spinning={loading} />
      ) : (
        <div className={styles.card_bg}>
          <div className={styles.left_top}>
            {/* 旋转圈 */}
            <div className={styles.AI_box} />
            {/* 定位AI */}
            <div className={styles.AI_img} />
            {/* AI能源指数box */}
            <div className={styles.AIEnergyIndex_box}>
              <div className={styles.title}>AI能源指数</div>
              <div className={styles.value}>
                {keepTwoOrLine(
                  ((data?.efficiency ?? 0) +
                    (data?.electricitySafety ?? 0) +
                    (data?.loadRate ?? 0) +
                    (data?.peakValleyRatio ?? 0) +
                    (data?.voltageQualificationRate ?? 0)) /
                    5
                )}
              </div>
            </div>
          </div>
          <div className={styles.echarts_box} ref={containerRef}>
            <div className={styles.spin_bg_box}>
              <div className={styles.spin_bg} />
            </div>
            {/* <img className={styles.spin_bg} src={spin_bg} alt="" /> */}

            <ReactEcharts
              style={{ height: '100%', width: '100%' }}
              ref={d => {
                if (d) {
                  chartRef.current = d;
                }
              }}
              option={chartOption}
              notMerge
              lazyUpdate={false}
            />
          </div>
        </div>
      )}
    </CardBox>
  );
};

export default AIEnergyIndex;

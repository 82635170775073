import {
  V2EnergyUnitOverviewAggregatedKgcePostResponse,
  V2EnergyUnitOverviewAggregatedPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import React, { FC, useEffect, useState } from 'react';
import styles from './index.module.scss';
import tittleDecoration from '../../imgs/tittleDecoration.png';
import { Progress, Space, Spin } from 'antd';
import { keepPercentOrLine, keepPercentTwoNull, keepTwoOrLine } from '../../utils';
import { isNil } from 'lodash-es';
import { Button, Key, Tooltip } from '@maxtropy/components';
import { EnergyUnitContext } from '@/pages/EnergyUnitKanban';
import classnames from 'classnames/bind';

export interface IUnitRank {
  data?: V2EnergyUnitOverviewAggregatedKgcePostResponse | V2EnergyUnitOverviewAggregatedPostResponse;
  mediumId?: Key;
}

const cx = classnames.bind(styles);

const UnitRank: FC<IUnitRank> = props => {
  const { data, mediumId } = props;
  const { setUnitId, setShowDetail } = React.useContext(EnergyUnitContext);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <img src={tittleDecoration} alt="pic" className={styles.titleBorder} />
        <span className={styles.title}>下级用能单元能耗排名</span>
      </div>
      {loading ? (
        <Spin spinning />
      ) : (
        <div className={styles.content_box}>
          {data?.childList?.map((item, index) => (
            <Space size={8} key={item.energyUnitId} className={styles.itemRank}>
              <div className={cx('rankNumber', `rank${index + 1}`)}>{index + 1}</div>
              <Tooltip title={item.energyUnitName}>
                <Button
                  type="link"
                  className={styles.name}
                  onClick={() => {
                    setShowDetail?.(true);
                    console.log('item.energyUnitId', item.energyUnitId);
                    setUnitId?.(item.energyUnitId);
                  }}
                >
                  {item.energyUnitName}
                </Button>
              </Tooltip>
              <div className={styles.process}>
                <Progress
                  status="active"
                  percent={
                    !isNil(data.aggregatedValue)
                      ? keepPercentTwoNull((item?.uploadValue ?? 0) / data.aggregatedValue) ?? undefined
                      : undefined
                  }
                  strokeColor={{ '0%': '#51DBFF', '100%': '#51A1FF' }}
                  trailColor="#214172"
                  showInfo={false}
                />
              </div>
              <div className={styles.percent}>
                {!isNil(data.aggregatedValue) && !isNil(item?.uploadValue)
                  ? keepPercentOrLine((item?.uploadValue ?? 0) / data.aggregatedValue) + '%'
                  : '--'}
              </div>
              <div className={styles.value}>
                {keepTwoOrLine(item.uploadValue)}
                {mediumId === 'total' ? 'kgce' : (data as V2EnergyUnitOverviewAggregatedPostResponse).physicalUnitName}
              </div>
            </Space>
          ))}
        </div>
      )}
    </div>
  );
};

export default UnitRank;

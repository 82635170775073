import {
  Tag,
  Table,
  Form,
  SubContent,
  Button,
  Wrapper,
  useBreadcrumbRoutes,
  FormTitle,
  BaseInfoContent,
  ShowInput,
} from '@maxtropy/components';
import { Row, Col, Space } from 'antd';
import { isNil } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AlarmLevel, AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';
import styles from './index.module.scss';
import { apiV2EnergyUnitServerAlarmGetRulePost } from '@maxtropy/device-customer-apis-v2';
import { InfoCircleOutlined } from '@ant-design/icons';

const EnergyAlarmRuleDetail = () => {
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<any>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const routes = [
    {
      name: '查看单元报警规则',
    },
  ];

  const columns = [
    {
      title: '周期/能源类型',
      dataIndex: 'name',
      key: 'name',
      width: 180,
    },
    {
      title: '时',
      dataIndex: 'hour',
      key: 'hour',
    },
    {
      title: '日',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '周',
      dataIndex: 'week',
      key: 'week',
    },
    {
      title: '月',
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: '旬',
      dataIndex: 'tenDay',
      key: 'tenDay',
    },
  ];

  useEffect(() => {
    if (isNil(id)) return;
    apiV2EnergyUnitServerAlarmGetRulePost({ ruleId: id }).then(res => {
      form.setFieldsValue({
        ruleName: res?.ruleName,
        alarmLevel: (
          <Tag border="solid" color={AlarmLevelColorDisplay[res?.alarmLevel! as AlarmLevel]}>
            {AlarmLevelDisplay[res?.alarmLevel! as AlarmLevel]}
          </Tag>
        ),
        ouId: res?.ouId,
        ouName: res?.ouName,
        energyUnitGroupName: res?.energyUnitGroupName,
        meterTypes: (res?.meterTypes ?? []).map(m => (
          <Tag border="solid" color={AlarmLevelColorDisplay[2]}>
            {m.meterTypeName}
          </Tag>
        )),
        daysOfMonthForEarlyWarning: res?.daysOfMonthForEarlyWarning,
        openEarlyWarning: res?.openEarlyWarning,
        energyUnit: (res?.energyUnits ?? []).map(m => m.energyUnitName).join('、'),
      });

      const tableList = (res?.meterTypes ?? []).map(item => {
        const meterTypeUnit = res.hourUpperLimitValue?.find(
          m => m.energyMediumId === item.meterTypeId
        )?.physicalUnitName;
        return {
          key: item.meterTypeId,
          name: item.meterTypeName + `（${meterTypeUnit ?? '--'}）`,
          hour: res?.hourUpperLimitValue?.find(k => k.energyMediumId === item.meterTypeId)?.upperLimitValue,
          date: res?.dayUpperLimitValue?.find(k => k.energyMediumId === item.meterTypeId)?.upperLimitValue,
          week: res?.weekUpperLimitValue?.find(k => k.energyMediumId === item.meterTypeId)?.upperLimitValue,
          month: res?.monthUpperLimitValue?.find(k => k.energyMediumId === item.meterTypeId)?.upperLimitValue,
          tenDay: res?.tenDayUpperLimitValue?.find(k => k.energyMediumId === item.meterTypeId)?.upperLimitValue,
        };
      });

      setDataSource([...tableList]);
    });
  }, [id, form]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.content}>
      <Form form={form}>
        <FormTitle title="查看单元报警信息" />
        <SubContent>
          <BaseInfoContent title="规则基础信息" colNum={2} layout="horizontal">
            <Form.Item name="ruleName" label="规则名称">
              <ShowInput />
            </Form.Item>

            <Form.Item name="ouName" label="所属运营单元">
              <ShowInput />
            </Form.Item>

            <Form.Item name="alarmLevel" label="报警等级">
              <ShowInput />
            </Form.Item>
          </BaseInfoContent>
        </SubContent>

        <SubContent>
          <BaseInfoContent title="规则详情" colNum={2} layout="horizontal">
            <Form.Item name="energyUnitGroupName" label="用能分析组">
              <ShowInput />
            </Form.Item>
            <Form.Item name="energyUnit" label="用能单元">
              <ShowInput />
            </Form.Item>
            <Form.Item name="alarmType" label="报警逻辑">
              <div>用能上下限</div>
            </Form.Item>
            <Form.Item name="meterTypes" label="能源类型">
              <ShowInput />
            </Form.Item>
          </BaseInfoContent>
        </SubContent>
        <SubContent style={{ marginBottom: 0 }}>
          <Row>
            <Col span={16}>
              <Form.Item
                name="cycleSetting"
                label="上限额设定"
                tooltip={{
                  title: '不填写上限额时，视为周期内该类型不报警',
                  icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
                }}
              >
                <Table rowKey="key" dataSource={dataSource} bordered pagination={false} columns={columns} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="智能预警" dependencies={['daysOfMonthForEarlyWarning', 'openEarlyWarning']}>
                {({ getFieldValue }) => {
                  return (
                    <div>
                      {getFieldValue('openEarlyWarning')
                        ? `每月${(getFieldValue('daysOfMonthForEarlyWarning') ?? []).join(
                            '/'
                          )}日，根据当前用能趋势判断预警`
                        : '未配置'}
                    </div>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </SubContent>

        <Space className="sticky-footer" style={{ zIndex: 99 }}>
          <Button
            onClick={() => {
              navigate(`/energyUnits/Alarm/rules?${urlSearchParams.toString()}`);
            }}
          >
            返回
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};
export default EnergyAlarmRuleDetail;

import { EnergyMediumTableDataItem, TotalTimeSharingElectricityFinalTableItem } from '@/api/universeMeterQuery';
import { Key } from '@maxtropy/components';
import { MeterProjectChangeButtonType } from './components/MeterProjectDetail';
import { addNum, toFixNum } from './utils';
import { isNil } from 'lodash';

// 将后端数据改为所需数据结构
export function changeTreeData<T extends { id: Key; child?: T[] }>(data: T[], id: Key, expand: boolean): T[] {
  const res: T[] = data.map(i => {
    if (i.id === id) {
      return {
        ...i,
        expand,
      };
    }
    if (i.child) {
      return {
        ...i,
        child: changeTreeData(i.child, id, expand),
      };
    }
    return i;
  });
  return res;
}

// 遍历树，获取当前节点的子节点
export function findCurrentChild<T extends { id: Key; child?: T[] }>(data: T[], currentId: Key): T[] {
  const res: T[] = [];
  data.forEach(i => {
    if (i.id === currentId) {
      if (i.child) {
        res.push(...i.child);
      }
    }
    if (i.child) {
      res.push(...findCurrentChild(i.child, currentId));
    }
  });
  return res;
}

// 获取所有展开或者收起的子节点
export function getAllShowOrHideChild<T extends { id: Key; child?: T[]; expand?: boolean }>(child: T[], res: T[]): T[] {
  child.forEach(i => {
    res.push(i);
    if (i.expand && i.child) {
      getAllShowOrHideChild(i.child, res);
    }
  });
  return res;
}

// 排序，相同抄表对象的排在一起
export function sortData<T extends { meterReadingObjectId: Key }>(data: T[]): T[] {
  const res: T[] = [];
  const meterReadingObjectIdList = data.map(i => i.meterReadingObjectId);
  const meterReadingObjectIdSet = new Set(meterReadingObjectIdList);
  meterReadingObjectIdSet.forEach(i => {
    const sameList = data.filter(j => j.meterReadingObjectId === i);
    res.push(...sameList);
  });
  return res;
}

// 修改final树特定节点数据
export const changeFinalTreeData = (
  data: TotalTimeSharingElectricityFinalTableItem[],
  meterReadingObjectId: Key,
  expand: boolean
) => {
  const res: TotalTimeSharingElectricityFinalTableItem[] = data.map(i => {
    if (i.meterReadingObjectId === meterReadingObjectId) {
      return {
        ...i,
        expand,
      };
    }
    if (i.child) {
      return {
        ...i,
        child: changeFinalTreeData(i.child, meterReadingObjectId, expand),
      };
    }
    return i;
  });
  return res;
};

// 找到特定节点的final子节点
export const findFinalChild = (data: TotalTimeSharingElectricityFinalTableItem[], meterReadingObjectId: Key) => {
  const res: TotalTimeSharingElectricityFinalTableItem[] = [];
  data.forEach(i => {
    if (i.meterReadingObjectId === meterReadingObjectId) {
      if (i.child) {
        res.push(...i.child);
      }
    }
    if (i.child) {
      res.push(...findFinalChild(i.child, meterReadingObjectId));
    }
  });
  return res;
};

// 获取所有展开或者收起的final子节点
export const getAllShowOrHideFinalChild = (
  child: TotalTimeSharingElectricityFinalTableItem[],
  res: TotalTimeSharingElectricityFinalTableItem[]
) => {
  child.forEach(i => {
    res.push(i);
    if (i.expand && i.child) {
      getAllShowOrHideFinalChild(i.child, res);
    }
  });
  return res;
};

// format后端数据， 展开，高亮
export function formatDataToExpendHightLight<T extends { id: Key; meterReadingObjectId: Key }>(
  data: T[],
  meterReadingObjectId: Key,
  id: Key
): T[] {
  return data.map(i => {
    if (i.meterReadingObjectId === meterReadingObjectId) {
      if (i.id === id) {
        return {
          ...i,
          expand: true,
          isHightLight: true,
        };
      }
      return {
        ...i,
        isHightLight: true,
      };
    }
    return i;
  });
}

// 为treeData添加展开节点
export function insertExpandToTreeData<T extends { id: Key; meterReadingObjectId: Key }>(
  expandedTreeData: T[],
  table: T[],
  record: T
): T[] {
  const newExpandTree = changeTreeData(expandedTreeData, record.id, true);
  const currentData = formatDataToExpendHightLight(table, record.meterReadingObjectId, record.id);
  const currentNodeIndex = currentData.findIndex(i => i.id === record.id);
  const currentNodeLength = currentData.filter(i => i.meterReadingObjectId === record.meterReadingObjectId).length;
  const before = currentData.slice(0, currentNodeIndex + currentNodeLength);
  const after = currentData.slice(currentNodeIndex + currentNodeLength);
  const currentChild = findCurrentChild(newExpandTree, record.id);
  const insertChild = sortData(getAllShowOrHideChild(currentChild, [])).map(i => ({
    ...i,
    isHightLight: true,
  }));
  return [...before, ...insertChild, ...after];
}

// 收起节点
export function deleteToTreeData<T extends { id: Key; meterReadingObjectId: Key; meterReadingLevel?: Key }>(
  expandedTreeData: T[],
  table: T[],
  record: T
): T[] {
  const newExpandTree = changeTreeData(expandedTreeData, record.id, false);
  const currentData = table.map(i => {
    if (i.meterReadingObjectId === record.meterReadingObjectId) {
      if (i.meterReadingLevel === 1) {
        return {
          ...i,
          expand: false,
          isHightLight: false,
        };
      }
    }
    return i;
  });
  const currentNodeIndex = currentData.findIndex(i => i.id === record.id); // 当前行的索引
  const currentNodeLength = currentData.filter(i => i.meterReadingObjectId === record.meterReadingObjectId).length;
  const beforePreserve = currentData.slice(0, currentNodeIndex + currentNodeLength); // 保留的数据
  const currentChild = findCurrentChild(newExpandTree, record.id);
  const delChildLength = getAllShowOrHideChild(currentChild, []).length;
  const afterPreserve = currentData.slice(currentNodeIndex + currentNodeLength + delChildLength); // 保留的数据
  return [...beforePreserve, ...afterPreserve];
}

// 获取所有可以展开的行
export function getAllExpandedRow<T extends { child?: T[] }>(data: T[], expandedRow: T[]) {
  data.forEach(i => {
    if (i.child && i.child.length > 0) {
      expandedRow.push(i);
      getAllExpandedRow(i.child, expandedRow);
    }
  });
  return expandedRow;
}

// 合并单元格 获取纵向合并的单元格数
/**
 *
 * @param data table数据
 * @param record 当前行
 * @param index 当前行索引
 * @returns
 */
export function getRowSpan<T extends { meterReadingObjectId: Key }>(data: T[], record: T, index?: number) {
  const firstIndex = data.findIndex(i => i.meterReadingObjectId === record.meterReadingObjectId);
  const objectOccupyRows = data.filter(i => i.meterReadingObjectId === record.meterReadingObjectId).length;
  if (index === firstIndex) {
    return objectOccupyRows;
  }
  return 0;
}

// 获取单元格样式
/**
 * @param changeButtonType 当前点击的按钮类型
 * @param type 当前编辑的表格类型
 * @param isHightLight 当前单元格是否需要高亮
 * @returns style
 * */
export function getCellStyle(
  changeButtonType?: MeterProjectChangeButtonType,
  type?: MeterProjectChangeButtonType,
  isHightLight?: boolean
) {
  return changeButtonType === type
    ? {
        backgroundColor: 'var(--mx-tree-selected-bg-color)',
      }
    : isHightLight
    ? {
        backgroundColor: 'var(--mx-page-bg-color)',
      }
    : {};
}

// 工质，编辑本期抄见数
/**
 *
 * @param value 抄见数
 * @param isElectricity 工质是否为电能
 * @param record table本行数据
 * @param data table数据
 * @returns newData
 */
export function changeCurrentCopyNum(
  value: number | null,
  isElectricity: boolean,
  record: EnergyMediumTableDataItem,
  data: EnergyMediumTableDataItem[]
) {
  let allAfterAdjust: number | undefined | null; // 所有调整后值之和
  let afterAdjust: number | undefined | null; // 调整后值=实际抄表值+调整值
  let share: number | undefined | null; // 分摊值
  let newData: EnergyMediumTableDataItem[];

  if (value === null) {
    newData = data.map(i => {
      if (i.id === record.id) {
        return {
          ...i,
          current: null,
          fact: null,
        };
      }
      return i;
    });
  } else {
    newData = data.map(i => {
      if (i.id === record.id) {
        const current = value;
        let fact: number;
        if (record.deviceTypeName === '剔除设备') {
          fact = Number(toFixNum((i.last ?? 0) - (current ?? 0))?.toFixed(2));
        } else {
          fact = Number(toFixNum((current ?? 0) - (i.last ?? 0))?.toFixed(2));
        }
        return {
          ...i,
          current,
          fact,
        };
      }
      return i;
    });
  }

  const currentObject = newData.filter(i => i.meterReadingObjectId === record.meterReadingObjectId);
  const currentRow = currentObject.find(i => i.id === record.id);

  if (isElectricity) {
    afterAdjust = addNum(
      currentRow?.fact,
      currentRow?.summitAdjust,
      currentRow?.peakAdjust,
      currentRow?.plainAdjust,
      currentRow?.valleyAdjust
    );
    allAfterAdjust = addNum(
      ...currentObject.map(i => addNum(i.fact, i.summitAdjust, i.peakAdjust, i.plainAdjust, i.valleyAdjust))
    );
    share = addNum(...currentObject.map(i => addNum(i.summitShare, i.peakShare, i.plainShare, i.valleyShare)));
  } else {
    afterAdjust = addNum(currentRow?.fact, currentRow?.adjust);
    allAfterAdjust = addNum(...currentObject.map(i => addNum(i.fact, i.adjust)));
    share = newData.find(i => i.id === record.id)?.share;
  }

  const final = addNum(allAfterAdjust, share);

  newData.forEach(i => {
    if (i.meterReadingObjectId === record.meterReadingObjectId) {
      if (i.id === record.id) {
        i.afterAdjust = afterAdjust;
      }
      i.final = final;
    }
  });
  return newData;
}

// 工质，编辑调整值
/**
 *
 * @param value 调整值
 * @param record table本行数据
 * @param data table数据
 * @returns newData
 */
export function changeAdjust(
  value: number | null,
  record: EnergyMediumTableDataItem,
  data: EnergyMediumTableDataItem[]
) {
  let afterAdjust: number | undefined | null; // 调整后值=实际抄表值+调整值
  let allAfterAdjust: number | undefined | null; // 所有调整后值之和
  let share: number | undefined | null; // 分摊值
  let newData: EnergyMediumTableDataItem[];

  newData = data.map(i => {
    if (i.id === record.id) {
      return {
        ...i,
        adjust: value ?? 0,
      };
    }
    return i;
  });

  const currentObject = newData.filter(i => i.meterReadingObjectId === record.meterReadingObjectId);
  const currentRow = currentObject.find(i => i.id === record.id);
  afterAdjust = addNum(currentRow?.fact, currentRow?.adjust);
  allAfterAdjust = addNum(...currentObject.map(i => addNum(i.fact, i.adjust)));
  share = newData.find(i => i.id === record.id)?.share;

  const final = addNum(allAfterAdjust, share);

  newData.forEach(i => {
    if (i.meterReadingObjectId === record.meterReadingObjectId) {
      if (i.id === record.id) {
        i.afterAdjust = afterAdjust;
      }
      i.final = final;
    }
  });
  return newData;
}

// 工质，编辑分摊值
/**
 * @param value 分摊值
 * @param record table本行数据
 * @param data table数据
 * @returns newData
 * */
export function changeShare(
  value: number | null,
  record: EnergyMediumTableDataItem,
  data: EnergyMediumTableDataItem[]
) {
  const allAfterAdjust = addNum(
    ...data.filter(i => i.meterReadingObjectId === record.meterReadingObjectId).map(i => i.afterAdjust)
  );
  const newData = data.map(i => {
    if (record.meterReadingObjectId === i.meterReadingObjectId) {
      const share = value ?? 0;
      const final = addNum(allAfterAdjust, share);
      return {
        ...i,
        share,
        final,
      };
    }
    return i;
  });
  return newData;
}

export interface TimeShareSumItem {
  summit: number;
  peak: number;
  plain: number;
  valley: number;
  sum: number;
  fact: number;
  check?: number;
}

// 分时电量表格计算总计
export function getTimeShareTableSum(pageData: TimeShareSumItem[], hasCheck?: boolean) {
  let totalSummit: number | null | undefined;
  let totalPeak: number | null | undefined;
  let totalPlain: number | null | undefined;
  let totalValley: number | null | undefined;
  let totalSum: number | null | undefined; // 总尖峰平谷合计
  let totalFact: number | null | undefined; // 总实际抄表值
  let totalCheck: number | null | undefined; // 总核对差值

  pageData.forEach(i => {
    if (!isNil(i.summit)) {
      totalSummit = addNum(i.summit, totalSummit);
    }
    if (!isNil(i.peak)) {
      totalPeak = addNum(i.peak, totalPeak);
    }
    if (!isNil(i.plain)) {
      totalPlain = addNum(i.plain, totalPlain);
    }
    if (!isNil(i.valley)) {
      totalValley = addNum(i.valley, totalValley);
    }
    if (!isNil(i.sum)) {
      totalSum = addNum(i.sum, totalSum);
    }
    if (!isNil(i.fact)) {
      totalFact = addNum(i.fact, totalFact);
    }
  });

  if (hasCheck) {
    pageData.forEach(i => {
      if (isNil(i.check)) return;
      totalCheck = addNum(i.check, totalCheck);
    });
  }
  return {
    totalSummit,
    totalPeak,
    totalPlain,
    totalValley,
    totalSum,
    totalFact,
    totalCheck,
  };
}

import { CircuitDetailProps, CircuitProps, CircuitType, getCircuitDetail, getCircuitList } from '@/api/circuit';
import { UetInfoProps } from '@/api/uet';
import { Button, FormContent, Empty, Form } from '@maxtropy/components';
import { Layout } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BasicInfoForm from './components/BasicInfoForm';
import CircuitRelationForm from './components/CircuitRelationForm';
import CircuitTree from './components/CircuitTree';
import DeviceListForm from './components/DeviceListForm';
import styles from './index.module.scss';
import { Resizable } from 're-resizable';

interface MicrogridPowerTopoProps {
  info?: UetInfoProps;
  isDetail?: boolean;
}

const { Sider, Content } = Layout;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const MicrogridPowerTopoTab: FC<MicrogridPowerTopoProps> = () => {
  const [circuitDetailInfoId, setCircuitDetailInfoId] = useState<any>();
  const [data, setData] = useState<CircuitDetailProps>();
  const { id } = useParams<{ id: string }>();
  const [circuitList, setCircuitList] = useState<CircuitProps[]>([]);

  // 请求电网计费回路接口
  useEffect(() => {
    getCircuitList({ type: CircuitType.GRID_BILLING_CIRCUIT, uetId: id ? Number(id) : undefined }).then(res => {
      if (res && res?.list?.length) {
        setCircuitList(res.list ?? []);
        setCircuitDetailInfoId(res.list?.[0].id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTreeSelect = (value: React.Key[], info: any) => {
    setCircuitDetailInfoId(info.circuitId);
  };

  useEffect(() => {
    if (circuitDetailInfoId) {
      getCircuitDetail(String(circuitDetailInfoId)).then(setData);
    }
  }, [circuitDetailInfoId]);

  return (
    <Layout>
      {!circuitList.length ? (
        <Empty style={{ margin: 0, padding: '60px 0' }} className={styles.header} description={'暂无微网回路拓扑'} />
      ) : (
        <>
          <Resizable
            style={{ backgroundColor: 'var(--mx-module-bg-color)', overflowY: 'auto', overflowX: 'hidden' }}
            defaultSize={{ width: 270, height: '100vh - 100px' }}
            minWidth={120}
            maxWidth={600}
            minHeight={'100vh - 100px'}
            maxHeight={'100vh - 100px'}
          >
            <Sider width={260} style={{ backgroundColor: 'var(--mx-module-bg-color)' }}>
              <CircuitTree style={{ minWidth: 260 }} circuitList={circuitList} changeTreeSelect={changeTreeSelect} />
            </Sider>
          </Resizable>
          {/* <Sider width={260}>
            <CircuitTree style={{ minWidth: 260 }} circuitList={circuitList} changeTreeSelect={changeTreeSelect} />
          </Sider> */}
          <Content className={styles.header} style={{ zIndex: 999 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 24px 0 24px' }}>
              <h2>回路详情</h2>
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    window.open(`/dc/circuit/circuitManage/edit/${circuitDetailInfoId}`);
                  }}
                >
                  编辑
                </Button>
              </div>
            </div>
            <Form layout="vertical" {...formLayout}>
              <FormContent title="基本信息">
                <BasicInfoForm data={data} />
              </FormContent>
              <FormContent title="设备绑定">
                <Form.Item name="devicesList" wrapperCol={{ span: 24 }}>
                  <DeviceListForm data={data} />
                </Form.Item>
              </FormContent>
              <FormContent title="回路关系关联">
                <CircuitRelationForm data={data} />
              </FormContent>
            </Form>
          </Content>
        </>
      )}
    </Layout>
  );
};

export default MicrogridPowerTopoTab;

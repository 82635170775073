import React, { useMemo, useState } from 'react';
import {
  Wrapper,
  useBreadcrumbRoutes,
  Empty,
  Button,
  getRealUrl,
  Radio,
  Tabs,
  DatePicker,
  Input,
  Modal,
  Tree,
} from '@maxtropy/components';
import styles from './index.module.scss';
import { Layout, Space, Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import qs from 'qs';
import {
  BoardChartUnitData,
  BoardChartData,
  BoardChartDataQuery,
  BoardChartQuarterStatus,
  BoardChartQuarterStatusListQuery,
  ConsumptionAttribute,
  ConsumptionAttributeDisplay,
  ConsumptionAttributeUnitDisplay,
  ConsumptionDimension,
  ConsumptionDimensionDisplay,
  ConsumptionStatusData,
  ConsumptionStatusDataListQuery,
  EnergyUnitMedium,
  ExceedingFlag,
  MediumQuery,
  StatusColorDisplay,
  StatusDisplay,
  TimeGranularity,
  TimeGranularityDisplay,
  TimeGranularityInitialValue,
  TimeGranularityRequest,
  TimeGranularityUnit,
  TimeGranularityUnitDisplay,
  getBoardChartQuarterStatusList,
  getConsumptionStatusDataList,
  getMediumById,
  BoardChartResponse,
  UnitData,
  Status,
} from '@/api/productionConsumptionBoard';
import type { DataNode } from 'antd/es/tree';
import dayjs, { Dayjs } from 'dayjs';
import CardForData from './CardForData';
import DragResize from '@/components/DragResize';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
  TitleComponent,
  TimelineComponent,
  MarkAreaComponent,
  LegendComponent,
  DataZoomComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import useEchartsTheme from '@/shared/hooks/useEchartsTheme';
import {
  MarkAreaLegend,
  EnergyMediumSceneIdsColorDisplay,
  EnergyMediumIconDisplay,
  EnergyMediumSceneIds,
  TimeGranularityFormat,
  chartDefaultOptions,
  StackLegend,
} from './chartConfigs';
import productionBaseIcon from './images/productionBase.png';
import energyUnit from './images/energyConsumingUnit.png';
import root from './images/workCenter.png';
import workProcedure from './images/productionProcesse.png';
import workStation from './images/workStation.png';
import generalActive from './images/generalActive.png';
import generalPassive from './images/generalPassive.png';
import CompareModal from './CompareModal';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import AnalysisDrawer from './components/AnalysisDrawer';
import { DiagnosisLevel, levelColors, linearColors, scoreRange } from '@/api/energyConsumption';
import { isNil } from 'lodash-es';
import ResizeObserver from 'rc-resize-observer';
import { useLocation } from 'react-router-dom';
import {
  apiV2ProductionBaseCompleteTreePost,
  apiV2ProductionEnergyConsumptionScorePost,
  V2ProductionEnergyConsumptionScorePostResponse,
} from '@maxtropy/device-customer-apis-v2';

// Register the required components
echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  TimelineComponent,
  MarkAreaComponent,
  LegendComponent,
  DataZoomComponent,
]);

interface EnergyUnit {
  energyUnitId: number;
  energyUnitName: string;
  workCenterId: number;
  centerProcedureLinkId: number;
  procedureStationLinkId: number;
  procedureName?: string;
  stationId?: number;
  procedureId?: number;
  stationName?: string;
}

interface Station {
  stationId: number;
  stationName: string;
  unitTree: EnergyUnit[];
  procedureStationLinkId: number;
  workCenterId: number;
  centerProcedureLinkId: number;
  energyUnitName?: string;
  procedureId?: number;
  energyUnitId: number;
  procedureName?: string;
}

interface Procedure {
  procedureId: number;
  procedureName: string;
  procedureOrder: number;
  stationTree: Station[];
  centerProcedureLinkId: number;
  workCenterId: number;
  energyUnitName?: string;
  procedureStationLinkId?: number;
  stationId?: number;
  energyUnitId: number;
  stationName?: string;
}

interface WorkCenter {
  id?: number;
  name?: string;
  procedureTree: Procedure[];
  sequence?: number;
  iconKey?: string;
  productionBaseId?: number;
  code?: string;
}

interface ProductionBaseType {
  id?: number;
  name?: string;
  sequence?: number;
  iconKey?: string;
  workCenterTree: WorkCenter[];
  code?: string;
}
type CompleteTree = ProductionBaseType[];

export const getFlattenTreeData = (
  data: TreeDataNode[]
): { title: any; originalName?: string; key: React.Key; children: any }[] => {
  return data.flatMap(({ title, originalName, key, children }) => [
    { title, originalName, key, children },
    ...getFlattenTreeData(children || []),
  ]);
};

const getParentKey = (key: React.Key, tree: TreeDataNode[]): React.Key => {
  let parentKey: React.Key;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey!;
};

type TreeDataNode = DataNode & { originalName?: string; img?: React.ReactNode };

type RangeValue = [Dayjs | null, Dayjs | null] | null;

// 综合能耗 id 为 1, 类似比如工质, 电是 2001, 新水是 2003(虚拟)
const GENERAL_CONSUMPTION = 1;
// 综合能耗场景 id 为 1, 类似比如电是 0, 给排水用水是 500
const GENERAL_CONSUMPTION_SCENE = 1;
// 综合能耗柱状图颜色
const GENERAL_CONSUMPTION_COLOR = '#477BFF';

export default function ProductionEnergyConsumptionBoard() {
  const echartsTheme = useEchartsTheme();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [openSider, setOpenSider] = useState(true); // 左侧树形toggle
  // 初始值
  const [siderWidth, setSiderWidth] = useState(260);
  // 拖拽状态
  const [dragStatus, setDragStatus] = useState(false);
  // 左侧树
  const [tree, setTree] = useState<TreeDataNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const flattenedTree = useMemo(() => {
    return getFlattenTreeData(tree);
  }, [tree]);
  const [searchValue, setSearchValue] = useState('');
  // 记录选中的 key
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  // 记录选中的名称
  const [selectedName, setSelectedName] = useState<string | undefined>(undefined);
  // 工质列表, 根据左侧 id 获取  获取的每一次切换工作中心拿到的所有能源介质
  const [mediums, setMediums] = useState<EnergyUnitMedium[]>([]);
  // 顶部选择综合能耗还是能源介质, 综合能耗的 id 为 1, 其他能源介质为其 id
  const [selectedConsumption, setSelectedConsumption] = useState<number>(GENERAL_CONSUMPTION);
  // 顶部选择综合能耗还是能源介质, 综合能耗的 id 为 1, 其他能源介质为其 id
  const [selectedBasePhysicalQuantityId, setSelectedBasePhysicalQuantityId] = useState<number | undefined>();
  // 顶部选择综合能耗还是能源介质的场景 id, 综合能耗场景为 1, 电为 0, 其余照后端给的, 例如给排水用水的为 500
  const [selectedConsumptionScene, setSelectedConsumptionScene] = useState<number>(GENERAL_CONSUMPTION_SCENE);
  // 时间颗粒度上面的选项, 例如综合能耗下的发热量和标准煤, 电下的有功电能
  const [selectedTabItem, setSelectedTabItem] = useState<ConsumptionAttribute>(ConsumptionAttribute.HEAT);
  // 选择的生产维度, 是生产能耗还是生产单耗
  const [selectedConsumptionDimension, setSelectedConsumptionDimension] = useState<ConsumptionDimension>(
    ConsumptionDimension.TOTAL
  );
  // 时间颗粒度, 15 分钟/天/月
  const [timeGranularity, setTimeGranularity] = useState<TimeGranularity | TimeGranularityUnit>(
    TimeGranularity.FIFTEEN_MINUTES
  );
  // 时间范围, dates 为临时时间, value 为用户选择的状态
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(TimeGranularityInitialValue[TimeGranularity.FIFTEEN_MINUTES]);

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [analysisData, setAnalysisData] = React.useState<V2ProductionEnergyConsumptionScorePostResponse>();

  // 图表所有数据
  const [chartData, setChartData] = useState<BoardChartData[]>([]);
  const [chartUnitData, setChartUnitData] = useState<BoardChartUnitData[]>([]);
  const [chartStatusList, setChartStatusList] = useState<BoardChartQuarterStatus[]>([]);
  const [totalValue, setTotalValue] = useState<number>();
  // 单位
  const [generalName, setGeneralName] = useState<string>('--');
  const [loading, setLoading] = useState(false);
  // 不同状态用量卡片
  const [consumptionStatusDataList, setConsumptionStatusDataList] = useState<ConsumptionStatusData[]>([]);
  // 对比弹框
  const [showCompareModal, setShowCompareModal] = useState(false);
  // 对比权限
  const hasComparePermission = useHasPermission(PermissionsType.B_COMPARISON_PRODUCTION_ENERGY_CONSUMPTION_KANBAN);
  const [width, setWidth] = useState<number>(0); //容器宽度
  //是否显示导出
  const [showExport, setShowExport] = useState<boolean>(false);
  const [completeTree, setCompleteTree] = useState<any>([]);
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let currentDateType = urlSearchParams.get('currentDateType') || undefined;
  let prevId = urlSearchParams.get('id') || undefined;

  const [isOverTenThousand, setIsOverTenThousand] = useState<boolean>(false);

  // 累计成本
  const [totalCost, setTotalCost] = useState<number>();

  function findNodeByProcedureId(
    tree: CompleteTree,
    targetProcedureId: number
  ): Procedure | Station | EnergyUnit | null {
    for (const productionBaseTree of tree) {
      for (const workCenter of productionBaseTree.workCenterTree) {
        for (const procedure of workCenter.procedureTree) {
          if (procedure.procedureId === targetProcedureId) {
            return procedure;
          }
          const foundInSubtree = findProcedureInStationTree(procedure.stationTree as Station[], targetProcedureId);
          if (foundInSubtree) {
            return foundInSubtree;
          }
        }
      }
    }
    return null;
  }

  function findProcedureInStationTree(stationTree: Station[], targetProcedureId: number): Station | EnergyUnit | null {
    for (const station of stationTree) {
      console.log(station, 'station');
      if (station.stationId === targetProcedureId) {
        return station;
      }
      const foundInSubtree = findEnergyUnitInTree(station.unitTree as EnergyUnit[], targetProcedureId);
      if (foundInSubtree) {
        return foundInSubtree;
      }
    }
    return null;
  }

  function findEnergyUnitInTree(unitTree: EnergyUnit[], targetProcedureId: number): Station | EnergyUnit | null {
    for (const energyUnit of unitTree) {
      if (energyUnit.energyUnitId === targetProcedureId) {
        return energyUnit;
      }
    }

    return null;
  }
  useEffect(() => {
    if (!isNil(prevId) && completeTree.length > 0) {
      const prevName = prevId.split('-')[0];
      const prevPageId = Number(prevId.split('-')[1]);
      let nodeById;
      setSelectedConsumptionScene(GENERAL_CONSUMPTION_SCENE);
      if (prevName === 'workCenter') {
        // nodeById = completeTree.find((node: any) => node.id === prevPageId);
        setSelectedKeys([`workCenter-${prevPageId}`]);
      } else if (prevName === 'workProcedure') {
        nodeById = findNodeByProcedureId(completeTree, prevPageId);
        if (isNil(nodeById)) return;
        setSelectedKeys([
          `workProcedure-${nodeById.procedureId}-${nodeById.centerProcedureLinkId}-${nodeById.workCenterId}`,
        ]);
        setSelectedName(nodeById.procedureName);
      } else if (prevName === 'workStation') {
        nodeById = findNodeByProcedureId(completeTree, prevPageId);
        if (isNil(nodeById)) return;
        setSelectedKeys([
          `workStation-${nodeById.stationId}-${nodeById.procedureStationLinkId}-${nodeById.centerProcedureLinkId}-${nodeById.workCenterId}`,
        ]);
        setSelectedName(nodeById.stationName);
      } else if (prevName === 'energyUnit') {
        nodeById = findNodeByProcedureId(completeTree, prevPageId);
        if (isNil(nodeById)) return;

        setSelectedKeys([
          `energyUnit-${nodeById.energyUnitId}-${nodeById.procedureStationLinkId}-${nodeById.centerProcedureLinkId}-${nodeById.workCenterId}`,
        ]);

        setSelectedName(nodeById.energyUnitName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeTree, prevId]);

  useEffect(() => {
    if (currentDateType === 'M') {
      setTimeGranularity(TimeGranularity.DAY);
      setValue(TimeGranularityInitialValue[TimeGranularity.DAY]);
    } else if (currentDateType === 'D') {
      setTimeGranularity(TimeGranularity.FIFTEEN_MINUTES);
      setValue(TimeGranularityInitialValue[TimeGranularity.FIFTEEN_MINUTES]);
    } else if (currentDateType === 'Y') {
      setTimeGranularity(TimeGranularity.MONTH);
      setValue(TimeGranularityInitialValue[TimeGranularity.MONTH]);
    }
  }, [currentDateType]);

  // 根据选择的工质或者综合能耗显示不同的颜色
  const consumptionColor = useMemo(() => {
    return {
      ...EnergyMediumSceneIdsColorDisplay,
      [GENERAL_CONSUMPTION_SCENE]: GENERAL_CONSUMPTION_COLOR,
    }[selectedConsumptionScene];
  }, [selectedConsumptionScene]);

  const chartDataByTabItem = useMemo(() => {
    return chartData.map(data => ({
      ...data,
      consumption: data.value,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, selectedTabItem]);

  // 工作中心, 工站, 工序, 用能单元查询参数
  const generalQuery = useMemo(() => {
    console.log(selectedKeys);
    const key = (selectedKeys[0] as string) ?? '';

    return {
      // 用能单元 id
      energyUnitId: key.includes('energyUnit') ? key.split('-')[1] : undefined,
      // 工序工站关联id
      procedureStationLinkId:
        key.includes('workStation') || key.includes('energyUnit') ? key.split('-').at(-3) : undefined,
      // 工作中心和工序关联id
      centerProcedureLinkId: !key.includes('workCenter') ? key.split('-').at(-2) : undefined,
      // 必传工作中心 id
      workCenterId: key.split('-').at(-1)!,
    };
  }, [selectedKeys]);

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    let tooLate = null;
    let tooEarly = null;
    const afterToday = current && current > dayjs().endOf('day');
    // 月颗粒度下最多选择 36 月
    if (TimeGranularityUnit.HOUR === timeGranularity) {
      tooLate = dates[0] && current.diff(dates[0], 'days') >= 7;
      tooEarly = dates[1] && dates[1].diff(current, 'days') >= 7;
    } else if (TimeGranularity.MONTH === timeGranularity) {
      tooLate = dates[0] && current.diff(dates[0], 'months') >= 35;
      tooEarly = dates[1] && dates[1].diff(current, 'months') >= 35;
    } else {
      // 15 分钟颗粒度下最多选择 7 天, 日颗粒度下最多选择 31 天
      const limitDays = timeGranularity === TimeGranularity.FIFTEEN_MINUTES ? 7 : 31;
      tooLate = dates[0] && current.diff(dates[0], 'days') >= limitDays;
      tooEarly = dates[1] && dates[1].diff(current, 'days') >= limitDays;
    }
    return !!tooEarly || !!tooLate || !!afterToday;
  };

  // 完整左侧树, 包括 icon, 高亮
  const treeData = useMemo(() => {
    const loop = (data: TreeDataNode[]): TreeDataNode[] =>
      data.map(item => {
        const strTitle = item.title as string;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);

        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className={styles.treeSearchValue}>{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{strTitle}</span>
          );
        if (item.children) {
          return {
            title,
            icon: item.icon,
            key: item.key,
            // @ts-ignore
            display: item.display,
            children: loop(item.children),
          };
        }

        return {
          icon: item.icon,
          title,
          // @ts-ignore
          display: item.display,
          key: item.key,
        };
      });

    return loop(tree);
  }, [searchValue, tree]);

  // tabs 项目, 发热量/标准煤/有功电能
  const tabsItems = useMemo(() => {
    if (selectedConsumption === GENERAL_CONSUMPTION) {
      return [
        {
          key: ConsumptionAttribute.HEAT.toString(),
          label: (
            <div className={styles.tab}>
              <div
                style={{
                  color: selectedTabItem === ConsumptionAttribute.HEAT ? '#4a90e2' : 'rgba(255, 255, 255, 0.65)',
                }}
              >
                {ConsumptionAttributeDisplay[ConsumptionAttribute.HEAT]}
              </div>
            </div>
          ),
        },
        {
          key: ConsumptionAttribute.COAL.toString(),
          label: (
            <div className={styles.tab}>
              <div
                style={{
                  color: selectedTabItem === ConsumptionAttribute.COAL ? '#4a90e2' : 'rgba(255, 255, 255, 0.65)',
                }}
              >
                {ConsumptionAttributeDisplay[ConsumptionAttribute.COAL]}
              </div>
            </div>
          ),
        },
      ];
    } else {
      return [
        {
          key: ConsumptionAttribute.MEDIUM.toString(),
          label: (
            <div className={styles.tab}>
              <div
                style={{
                  color: selectedTabItem === ConsumptionAttribute.MEDIUM ? '#4a90e2' : 'rgba(255, 255, 255, 0.65)',
                }}
              >
                {ConsumptionAttributeDisplay[ConsumptionAttribute.MEDIUM]}
              </div>
            </div>
          ),
        },
      ];
    }
  }, [selectedConsumption, selectedTabItem]);

  // 根据选中的 tabItem 提取当前是 发热量/标准煤/工质 下对应的能耗/单耗
  const consumptionStatusByTabItem = useMemo(
    () =>
      consumptionStatusDataList.map(data => {
        return {
          ...data,
          consumption: data.totalValue,
          unitConsumption: data.avgValue,
          cost: isNil(data.avgCost) || isNil(data.duration) ? null : (data.avgCost * data.duration).toFixed(2),
        };
      }),
    [consumptionStatusDataList]
  );

  // 能耗图表数据
  const consumptionSeries = useMemo(() => {
    return [
      {
        z: 1,
        name: '基本值',
        type: 'bar',
        stack: 'add',
        data: chartDataByTabItem?.map(d => [
          d.ts,
          // 如果是正常, 就显示正常数值, 超出, 底部应该显示阈值上限(能耗高于上限), 否则显示能耗(能耗低于下限)
          d.exceedingFlag === ExceedingFlag.NORMAL
            ? d.consumption
            : d.exceedingFlag === ExceedingFlag.HIGH
            ? d.upperLimit
            : d.consumption,
          // 相关数据放进来
          {
            ...d,
          },
        ]),
        // 柱状图颜色, 综合能耗 + 工质, 通过 sceneId 匹配
        itemStyle: {
          color: consumptionColor,
        },
        // 只有 15 分钟的时候才展示 markArea
        markArea:
          timeGranularity === TimeGranularity.FIFTEEN_MINUTES
            ? {
                data: chartStatusList.map(sl => {
                  return [
                    {
                      xAxis: dayjs(sl.startTime).valueOf(),
                      label: {
                        show: false,
                        width: 120 - 24,
                        height: 60 - 16,
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        padding: [8, 12],

                        formatter: [
                          `{status|${StatusDisplay[sl.status]}}状态`,
                          `{range|${dayjs(sl.startTime).format('HH:mm')} - ${dayjs(sl.endTime).format('HH:mm')}}`,
                        ].join('\n\n'),

                        rich: {
                          status: {
                            color: 'rgba(255,255,255,0.65)',
                            lineHeight: 17,
                          },
                          range: {
                            color: 'rgba(255,255,255,0.85)',
                            lineHeight: 17,
                            fonWeight: 500,
                          },
                        },
                      },
                      emphasis: {
                        label: {
                          show: true,
                          position: ['0%', '-600%'],
                        },
                      },
                      blur: {
                        label: {
                          show: false,
                        },
                      },
                      // name: 'hello\n\n你好',
                      itemStyle: {
                        color: StatusColorDisplay[sl.status],
                      },
                      y: '84%',
                    },
                    {
                      xAxis: dayjs(sl.endTime).valueOf(),
                      y: '86%',
                    },
                  ];
                }),
              }
            : {},
      },
      {
        name: '超出值',
        type: 'bar',
        stack: 'add',
        data: chartDataByTabItem?.map(d => {
          return {
            // 超出部分的值
            value: [d.ts, d.exceedingLimit === ExceedingFlag.NORMAL ? 0 : d.exceedingLimit],
            itemStyle: {
              color: d.exceedingFlag === ExceedingFlag.HIGH ? 'rgb(255, 77, 79, 0.4)' : 'rgb(211, 211, 211, 0.4)',
              decal: {
                color: d.exceedingFlag === ExceedingFlag.HIGH ? 'rgb(255, 77, 79)' : 'rgb(211, 211, 211)',
                dashArrayX: [1, 0],
                dashArrayY: [4, 3],
                rotation: -Math.PI / 4,
              },
            },
          };
        }),
      },
      {
        name: isOverTenThousand ? '万元' : '元',
        type: 'line',
        smooth: true,
        yAxisIndex: 1,
        data: chartDataByTabItem?.map(d => [
          d.ts,
          isOverTenThousand ? (isNil(d.cost) ? d.cost : Number((d.cost / 10000).toFixed(2))) : d.cost,
        ]),
        itemStyle: {
          color: '#FFCB47',
        },
        emphasis: {
          focus: 'series',
        },
        showSymbol: false,
      },
    ];
  }, [chartDataByTabItem, chartStatusList, consumptionColor, isOverTenThousand, timeGranularity]);

  // 单耗的图表数据
  const unitConsumptionSeries = useMemo(() => {
    return chartUnitData.reduce<any>((result, unit) => {
      if (unit?.data?.length === 0) return result;
      result.push(
        // 堆叠图底部基本值
        {
          name: `${unit.productSheetCode}-${unit.outputProductName}`,
          type: 'bar',
          stack: unit.productSheetCode,
          data: unit?.data?.map(d => [
            d.ts,
            // 如果是正常, 就显示正常数值, 超出, 底部应该显示阈值上限(能耗高于上限), 否则显示能耗(能耗低于下限)
            d.exceedingFlag === ExceedingFlag.NORMAL
              ? d.unitConsumption
              : d.exceedingFlag === ExceedingFlag.HIGH
              ? d.upperLimit
              : d.unitConsumption,
            // 相关数据放进来
            d,
          ]),
          itemStyle: {
            // TODO: 颜色待定
          },
        },
        // 堆叠图上层值
        {
          name: `${unit.productSheetCode}-${unit.outputProductName}-exceeding`,
          type: 'bar',
          stack: unit.productSheetCode,
          data: unit?.data?.map(d => {
            return {
              // 超出部分的值
              value: [d.ts, d.exceedingLimit === ExceedingFlag.NORMAL ? 0 : d.exceedingLimit, d],
              itemStyle: {
                color: d.exceedingFlag === ExceedingFlag.HIGH ? 'rgb(255, 77, 79, 0.4)' : 'rgb(211, 211, 211, 0.4)',
                decal: {
                  color: d.exceedingFlag === ExceedingFlag.HIGH ? 'rgb(255, 77, 79)' : 'rgb(211, 211, 211)',
                  dashArrayX: [1, 0],
                  dashArrayY: [4, 3],
                  rotation: -Math.PI / 4,
                },
              },
            };
          }),
        }
      );
      return result;
    }, []);
  }, [chartUnitData]);

  // 能耗图表格式化
  const consumptionTooltip = useMemo(() => {
    return {
      ...chartDefaultOptions.tooltip,
      axisPointer: {
        type: 'line',
      },
      formatter: (params: any) => {
        const [barParams] = params;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [time, barValue, rawData] = barParams.value as [any, any, (typeof chartDataByTabItem)[number]];
        let formattedTime;
        formattedTime = dayjs(time).format(TimeGranularityFormat[timeGranularity]);
        return `
          <div>
            <div>
              ${formattedTime}
            </div>
            
            <div style="display: flex; width: 250px; justify-content :space-between;">
              <span>${ConsumptionAttributeDisplay[selectedTabItem]}: </span>
              <strong>${rawData.consumption?.toFixed(2) ?? '--'}${
          selectedConsumption === GENERAL_CONSUMPTION ? ConsumptionAttributeUnitDisplay[selectedTabItem] : generalName
        }</strong>
            </div>
            
          <div style="display: flex; width: 250px; justify-content :space-between;">
            <span>当前单耗: </span>
            <strong>${rawData?.unitConsumption?.toFixed(2) ?? '--'}${
          selectedConsumption === GENERAL_CONSUMPTION ? ConsumptionAttributeUnitDisplay[selectedTabItem] : generalName
        }</strong>
          </div>

          <div style="display: flex; width: 250px; justify-content :space-between;">
            <span>${[Status.LINE_STOP, Status.WORK].includes(rawData.status!) ? '阈值上限' : '能耗上限'}: </span>
            <strong>${rawData?.upperLimit?.toFixed(2) ?? '--'}${
          selectedConsumption === GENERAL_CONSUMPTION ? ConsumptionAttributeUnitDisplay[selectedTabItem] : generalName
        }</strong>
        </div>

        <div style="display: flex; width: 250px; justify-content :space-between;">
          <span>${[Status.LINE_STOP, Status.WORK].includes(rawData.status!) ? '阈值下限' : '能耗下限'}: </span>
          <strong>${rawData?.lowerLimit?.toFixed(2) ?? '--'}${
          selectedConsumption === GENERAL_CONSUMPTION ? ConsumptionAttributeUnitDisplay[selectedTabItem] : generalName
        }</strong>
        </div>

        <div style="display: flex; width: 250px; justify-content :space-between;">
          <span>能耗成本: </span>
          <strong>${
            isOverTenThousand
              ? isNil(rawData?.cost)
                ? '--'
                : (rawData?.cost / 10000).toFixed(2)
              : isNil(rawData?.cost)
              ? '--'
              : rawData?.cost?.toFixed(2)
          }${isOverTenThousand ? '万元 ' : '元'}</strong>
        </div> 

        <div style="display: flex; width: 250px; justify-content :space-between;">
          <span>单耗成本: </span>
          <strong>${rawData?.unitConsumptionCost?.toFixed(2) ?? '--'}元</strong>
        </div>

        <div style="display: flex; width: 250px; justify-content :space-between;">
          <span>投入数量: </span>
          <strong>${rawData?.inputNum?.toFixed(2) ?? '--'}</strong>
        </div>

        <div style="display: flex; width: 250px; justify-content :space-between;">
          <span>产出数量: </span>
          <strong>${rawData?.outputNum?.toFixed(2) ?? '--'}</strong>
          </div>

        </div>
        `;
      },
    };
  }, [generalName, isOverTenThousand, selectedConsumption, selectedTabItem, timeGranularity]);

  // 单耗图表格式化
  const unitConsumptionTooltip = useMemo(() => {
    return {
      ...chartDefaultOptions.tooltip,
      // 以柱子的形式进行 tooltip 展示
      trigger: 'item',
      formatter: (params: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [time, barValue, rawData] = params.value as [any, any, UnitData];
        const startFormattedTime = dayjs(rawData.startTime).format('HH:mm');
        const endFormattedTime = dayjs(rawData.endTime).format('HH:mm');

        return `
          <div>
            ${startFormattedTime} ~ ${endFormattedTime}
          </div>

          <div style="display: flex; width: 250px; justify-content :space-between;">
            <strong>${params.seriesName.split('-').slice(0, 2).join('-')}</strong>
          </div>

          <div style="display: flex; width: 250px; justify-content :space-between;">
            <span>生产单耗: </span>
            <strong>${rawData.unitConsumption?.toFixed(2) ?? '--'}${
          selectedConsumption === GENERAL_CONSUMPTION ? ConsumptionAttributeUnitDisplay[selectedTabItem] : generalName
        }</strong>
          </div>
          <div style="display: flex; width: 250px; justify-content :space-between;">
          <span>单耗成本: </span>
          <strong>${rawData.unitConsumptionCost?.toFixed(2) ?? '--'}元
          </strong>
        </div>

          <div style="display: flex; width: 250px; justify-content :space-between;">
            <span>单耗区间: </span>
            <strong>${rawData.lowerLimit?.toFixed(2) ?? '--'}-${rawData.upperLimit?.toFixed(2) ?? '--'}${
          selectedConsumption === GENERAL_CONSUMPTION ? ConsumptionAttributeUnitDisplay[selectedTabItem] : generalName
        }
        </strong>
          </div>
        `;
      },
    };
  }, [generalName, selectedConsumption, selectedTabItem]);

  const consumptionGraphic = useMemo(() => {
    const seriesGraphics = [
      {
        right: 200,
        text: ConsumptionAttributeDisplay[selectedTabItem],
        legendColor: consumptionColor,
        vertical: true,
      },
    ].map(({ right, legendColor, text, vertical }) => ({
      type: 'rect',
      top: vertical ? 0 : 5,
      right: right,
      shape: {
        // r: 4,
        width: vertical ? 8 : 16,
        height: vertical ? 16 : 8,
      },
      textContent: {
        type: 'text',
        style: {
          text,
        },
      },
      textConfig: {
        position: 'right',
      },
      style: {
        fill: legendColor,
      },
    }));

    const stackGraphics = StackLegend.map(({ right, text, image, width, height, vertical }) => ({
      type: 'image',
      // top: vertical ? 0 : 5,
      right: right,
      textContent: {
        type: 'text',
        style: {
          text,
        },
      },
      textConfig: {
        position: 'right',
      },
      style: {
        image,
        width,
        height,
      },
    }));

    const markAreaGraphics = MarkAreaLegend.map(({ right, legendColor, text, vertical }) => ({
      type: 'rect',
      top: vertical ? 0 : 5,
      right: right,
      shape: {
        // r: 4,
        width: vertical ? 8 : 16,
        height: vertical ? 16 : 8,
      },
      textContent: {
        type: 'text',
        style: {
          text,
        },
      },
      textConfig: {
        position: 'right',
      },
      style: {
        fill: legendColor,
      },
    }));

    const costGraphics = [
      {
        right: 130,
        text: '成本',
        legendColor: '#FFCB47',
        vertical: true,
      },
    ].map(({ right, legendColor, text, vertical }) => ({
      type: 'rect',
      top: 6,
      right: right,
      shape: {
        // r: 4,
        width: 16,
        height: 4,
      },
      textContent: {
        type: 'text',
        style: {
          text,
        },
      },
      textConfig: {
        position: 'right',
      },
      style: {
        fill: legendColor,
      },
    }));

    return timeGranularity === TimeGranularity.FIFTEEN_MINUTES
      ? [...markAreaGraphics, ...stackGraphics, ...seriesGraphics, ...costGraphics]
      : [...stackGraphics, ...seriesGraphics, ...costGraphics];
  }, [consumptionColor, selectedTabItem, timeGranularity]);

  const unitConsumptionGraphic = useMemo(() => {
    return [...StackLegend].map(({ right, text, image, width, height }) => ({
      type: 'image',
      top: 5,
      right: right - 70,
      textContent: {
        type: 'text',
        style: {
          text,
        },
      },
      textConfig: {
        position: 'right',
      },
      style: {
        image,
        width,
        height,
      },
    }));
  }, []);

  const options = useMemo(() => {
    return {
      ...chartDefaultOptions,
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          // 单耗的时候给一下 bottom
          ...(timeGranularity === TimeGranularityUnit.HOUR && { bottom: 4 }),
          start: 0,
          end: 100,
        },
      ],
      // 坐标轴左右间距
      grid: {
        left: 80,
        right: 80,
        // top: 5,
        ...(timeGranularity === TimeGranularity.FIFTEEN_MINUTES && { bottom: 100 }),
      },
      graphic:
        selectedConsumptionDimension === ConsumptionDimension.TOTAL ? consumptionGraphic : unitConsumptionGraphic,
      legend: {
        // 单耗的时候展示 legend, 否则不展示
        show: selectedConsumptionDimension === ConsumptionDimension.TOTAL ? false : true,
        type: 'scroll',
        itemWidth: 8,
        itemHeight: 16,
        icon: 'rect',
        // 取消选中效果
        selectedMode: false,
        // 只显示堆叠图基础值, 不显示堆叠图累加值
        data: chartUnitData?.map(d => `${d.productSheetCode}-${d.outputProductName}`),
        pageButtonPosition: 'start',
        right: 360,
        itemGap: 20,
      },
      tooltip:
        selectedConsumptionDimension === ConsumptionDimension.TOTAL ? consumptionTooltip : unitConsumptionTooltip,
      xAxis: {
        offset: timeGranularity === TimeGranularity.FIFTEEN_MINUTES ? 18 : 0,
        min: 'dataMin',
        max: 'dataMax',
        type: 'time',
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.3)',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          formatter: (v: number) => {
            return dayjs(v).format(TimeGranularityFormat[timeGranularity]);
          },
        },
      },
      yAxis:
        selectedConsumptionDimension === ConsumptionDimension.TOTAL
          ? [
              {
                type: 'value',
                // y 轴显示单位
                name:
                  selectedConsumption === GENERAL_CONSUMPTION
                    ? ConsumptionAttributeUnitDisplay[selectedTabItem]
                    : generalName,
                position: 'left',
                // 和右边的 y 轴对齐
                alignTicks: true,
                axisLine: {
                  show: false,
                },
              },
              {
                type: 'value',
                name: isOverTenThousand ? '万元' : '元',
                position: 'right',
              },
            ]
          : [
              {
                type: 'value',
                // y 轴显示单位
                name:
                  selectedConsumption === GENERAL_CONSUMPTION
                    ? ConsumptionAttributeUnitDisplay[selectedTabItem]
                    : generalName,
                position: 'left',
                // 和右边的 y 轴对齐
                alignTicks: true,
                axisLine: {
                  show: false,
                },
              },
            ],
      series: selectedConsumptionDimension === ConsumptionDimension.TOTAL ? consumptionSeries : unitConsumptionSeries,
    };
  }, [
    chartUnitData,
    consumptionGraphic,
    consumptionSeries,
    consumptionTooltip,
    generalName,
    isOverTenThousand,
    selectedConsumption,
    selectedConsumptionDimension,
    selectedTabItem,
    timeGranularity,
    unitConsumptionGraphic,
    unitConsumptionSeries,
    unitConsumptionTooltip,
  ]);

  const dragChange = (width: number) => {
    if (openSider) {
      setSiderWidth(width);
    }
  };

  // 获取左侧树
  useEffect(() => {
    apiV2ProductionBaseCompleteTreePost({}).then(res => {
      setCompleteTree(res.list ?? []);
      const formattedTree: TreeDataNode[] = (res.list ?? []).map(pd => {
        return {
          title: pd.name,
          disabled: true,
          icon: (
            <img
              src={getRealUrl(pd?.iconKey) ?? productionBaseIcon}
              width={16}
              height={16}
              alt=""
              style={{ marginBottom: -6 }}
            />
          ),
          // 记录名称, 以 工作中心-工序-工站-用能单元为样式
          display: pd.name,
          key: `productionBase-${pd.id}`,
          children: pd?.workCenterTree?.map(wc => ({
            title: wc.name,
            icon: (
              <img src={getRealUrl(wc?.iconKey) ?? root} width={16} height={16} alt="" style={{ marginBottom: -6 }} />
            ),
            // 记录名称, 以 工作中心-工序-工站-用能单元为样式
            display: wc.name,
            key: `workCenter-${wc.id}`,
            children: wc?.procedureTree?.map(wp => ({
              title: wp.procedureName,
              icon: (
                <img
                  src={getRealUrl(wp?.iconKey) ?? workProcedure}
                  width={16}
                  height={16}
                  alt=""
                  style={{ marginBottom: -6 }}
                />
              ),
              display: `${wc.name}-${wp.procedureName}`,
              key: `workProcedure-${wp.procedureId}-${wp.centerProcedureLinkId}-${wp.workCenterId}`,
              children: wp?.stationTree?.map(ws => ({
                title: ws.stationName,
                icon: (
                  <img
                    src={getRealUrl(ws?.iconKey) ?? workStation}
                    width={16}
                    height={16}
                    alt=""
                    style={{ marginBottom: -6 }}
                  />
                ),
                display: `${wc.name}-${wp.procedureName}-${ws.stationName}`,
                key: `workStation-${ws.stationId}-${ws.procedureStationLinkId}-${ws.centerProcedureLinkId}-${ws.workCenterId}`,
                children: ws?.unitTree?.map(ut => ({
                  title: ut.energyUnitName,
                  icon: <img src={energyUnit} width={16} height={16} alt="" style={{ marginBottom: -6 }} />,
                  display: `${wc.name}-${wp.procedureName}-${ws.stationName}-${ut.energyUnitName}`,
                  key: `energyUnit-${ut.energyUnitId}-${ut.procedureStationLinkId}-${ut.centerProcedureLinkId}-${ut.workCenterId}`,
                })),
              })),
            })),
          })),
        };
      });
      console.log(formattedTree, 'formattedTree');
      setTree(formattedTree);
    });
  }, []);

  useEffect(() => {
    // 默认选中第一个工作中心
    if (tree.length > 0 && isNil(prevId) && tree[0].children && tree[0].children.length > 0) {
      setSelectedName(tree?.[0]?.children?.[0]?.title as string);
      setSelectedKeys([tree?.[0]?.children?.[0]?.key as string]);
      setShowExport(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree]);

  useEffect(() => {
    // 左侧树默认全部展开
    if (flattenedTree.length) {
      setExpandedKeys(flattenedTree.map(data => data.key));
    }
  }, [flattenedTree]);

  useEffect(() => {
    setDates(value);
  }, [value]);

  // 获取图表上用量的卡片
  useEffect(() => {
    if (selectedKeys.length && value?.[0] && value?.[1]) {
      const query: ConsumptionStatusDataListQuery = {
        ...generalQuery,
        // 如果是选择的综合能耗, 那么 energyMediumId 传发热量(200)或者标准煤(100), 否则就传工质 id
        energyMediumId: selectedConsumption !== GENERAL_CONSUMPTION ? selectedConsumption : selectedTabItem,
        isTotal: selectedConsumption === GENERAL_CONSUMPTION,
        basePhysicalQuantityId: selectedBasePhysicalQuantityId,
        startDate: value?.[0]?.format('YYYY-MM-DD'),
        endDate: value?.[1]?.format('YYYY-MM-DD'),
      };

      getConsumptionStatusDataList(query).then(res => {
        setConsumptionStatusDataList(res?.list ?? []);
      });
    }
  }, [generalQuery, selectedConsumption, selectedKeys, selectedTabItem, value, selectedBasePhysicalQuantityId]);

  // 根据选择的工作中心/工序/工站/用能单元 id 获取顶部工质
  useEffect(() => {
    if (selectedKeys.length) {
      const query: MediumQuery = {
        ...generalQuery,
      };

      getMediumById(query).then(res => {
        // 切换工作中心时，判断这个工作中心上有没有现在的能源介质，有保留，没有回到综合能耗
        const found = res.list?.some(item => item.id === selectedConsumption);

        if (found) {
          setSelectedConsumption(selectedConsumption);
        } else {
          setSelectedConsumption(GENERAL_CONSUMPTION);
          setSelectedTabItem(ConsumptionAttribute.HEAT);
        }
        if (selectedConsumption === GENERAL_CONSUMPTION) {
          // 如果等于综合能耗的话  判断是发热量还是标准煤 或者是用电
          if (selectedTabItem === ConsumptionAttribute.HEAT) {
            setSelectedTabItem(ConsumptionAttribute.HEAT);
          } else if (selectedTabItem === ConsumptionAttribute.COAL) {
            setSelectedTabItem(ConsumptionAttribute.COAL);
          } else if (selectedTabItem === ConsumptionAttribute.MEDIUM) {
            setSelectedTabItem(ConsumptionAttribute.MEDIUM);
          }
        }

        setMediums(res.list || []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalQuery, selectedKeys]);

  // 如果已经选中的工质不在新获取到的工质里面, 跳回选中综合能耗, 否则保持之前选中的工质状态
  useEffect(() => {
    if (selectedKeys.length && mediums.length) {
      if (!mediums.map(r => r.energyMediumId).includes(selectedConsumption)) {
        setSelectedConsumption(GENERAL_CONSUMPTION);
        setSelectedConsumptionScene(GENERAL_CONSUMPTION_SCENE);
      }
    }
  }, [mediums, selectedConsumption, selectedKeys]);

  // 根据时间颗粒度, 选择的时间以及树节点获取所有请求
  useEffect(() => {
    // 清空之前的数据
    // setChartData([]);
    if (selectedKeys.length && value?.[0] && value?.[1]) {
      setLoading(true);

      const query: BoardChartDataQuery = {
        ...generalQuery,
        // 如果是选择的综合能耗, 那么 energyMediumId 传发热量(200)或者标准煤(100), 否则就传工质 id
        energyMediumId: selectedConsumption !== GENERAL_CONSUMPTION ? selectedConsumption : selectedTabItem,
        basePhysicalQuantityId: selectedBasePhysicalQuantityId,
        isTotal: selectedConsumption === GENERAL_CONSUMPTION,
        startDate: value?.[0]?.format('YYYY-MM-DD'),
        endDate: value?.[1]?.format('YYYY-MM-DD'),
      };

      TimeGranularityRequest[timeGranularity](query)
        .onError(err => {
          Modal.error({
            title: err.cause.errorMessage ?? '未知错误！ 请联系管理员。',
          });
          setChartData([]);
          setTotalValue(undefined);
          setChartUnitData([]);
          setGeneralName('--');
          setIsOverTenThousand(false);
          setTotalCost(undefined);
          throw err;
        })
        .then(res => {
          // 根据当前是单耗还是能耗做类型判断
          if (selectedConsumptionDimension === ConsumptionDimension.TOTAL) {
            const resTotal = res as BoardChartResponse;
            (resTotal.data ?? []).filter(item => (item.cost ?? 0) > 10000).length > 0
              ? setIsOverTenThousand(true)
              : setIsOverTenThousand(false);
            setChartData(resTotal.data ?? []);
            setGeneralName(resTotal.generalName ?? '--');
            setTotalValue(resTotal?.totalValue);
            setTotalCost(resTotal?.totalCost);
          } else {
            const resUnit = res as { list: BoardChartUnitData[] };
            setChartUnitData(resUnit.list ?? []);
            setGeneralName(resUnit?.list?.[0]?.generalName ?? '--');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setChartData([]);
      setTotalValue(undefined);
      setChartUnitData([]);
      setTotalCost(undefined);
    }
    // 这里原则上只需要依赖 value, 虽然也依赖了 timeGranularity,
    // 但是当 timeGranularity 变化时 value 也变化, 会导致发两次请求, 因此只要依赖 value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    generalQuery,
    selectedConsumption,
    selectedKeys,
    selectedTabItem,
    selectedConsumptionDimension,
    value,
    selectedBasePhysicalQuantityId,
  ]);

  // 获取工作状态, 对应图里的 markArea
  useEffect(() => {
    if (selectedKeys.length && value?.[0] && value?.[1]) {
      const key = selectedKeys[0] as string;
      const query: BoardChartQuarterStatusListQuery = {
        // 必传工作中心 id
        workCenterId: key.split('-').at(-1)!,
        startDate: value?.[0]?.format('YYYY-MM-DD'),
        endDate: value?.[1]?.format('YYYY-MM-DD'),
      };

      getBoardChartQuarterStatusList(query).then(res => {
        setChartStatusList(res.list);
      });
    }
  }, [selectedKeys, value]);

  // 智能诊断
  useEffect(() => {
    if (!selectedKeys.length) return;
    const key = selectedKeys[0] as string;
    const workCenterId = key.split('-').at(-1)!;

    apiV2ProductionEnergyConsumptionScorePost({
      workCenterId: Number(workCenterId),
      date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    }).then(res => {
      setAnalysisData(res);
      if (isNil(res.score)) {
        setShowDrawer(false);
      }
    });
  }, [selectedKeys]);

  const currentLevel = useMemo(() => {
    if (!analysisData || isNil(analysisData?.score)) return DiagnosisLevel.EXCELLENT;
    const level = scoreRange.find(item => item.min <= analysisData?.score! && item.max > analysisData?.score!)?.level;
    return level ?? DiagnosisLevel.EXCELLENT;
  }, [analysisData]);

  return (
    <div style={{ position: 'relative' }}>
      <Wrapper routes={breadcrumbRoutes?.routes ?? []} className={styles.wrapper}>
        <Layout className={styles.layout}>
          <Layout.Sider
            theme="light"
            className={styles.sider}
            width={openSider ? siderWidth : 0}
            style={{ transition: dragStatus ? 'none' : 'all 0.2s', marginRight: openSider ? 10 : 0 }}
          >
            <DragResize init={260} dragChange={dragChange} dragStatus={setDragStatus} />
            <div className={styles.treeArea}>
              <div className={styles.searchArea}>
                <Input.Search
                  placeholder="请输入"
                  allowClear
                  onSearch={value => {
                    const newExpandedKeys = flattenedTree
                      .map(item => {
                        if (item.title.indexOf(value)! > -1) {
                          return getParentKey(item.key, treeData);
                        }
                        return null;
                      })
                      .filter((item, i, self) => item && self.indexOf(item) === i);
                    setExpandedKeys(newExpandedKeys as React.Key[]);
                    setAutoExpandParent(true);
                    setSearchValue(value);
                  }}
                />
              </div>

              <div className={styles.treeBox}>
                {tree.length ? (
                  <Tree
                    showIcon
                    blockNode
                    onExpand={newExpandedKeys => {
                      setExpandedKeys(newExpandedKeys);
                      setAutoExpandParent(false);
                    }}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    style={{ height: 'calc(100vh - 180px)' }}
                    treeData={treeData}
                    // defaultExpandAll
                    selectedKeys={selectedKeys}
                    onSelect={(selectedKeys, info) => {
                      console.log(selectedKeys, info);

                      if (typeof selectedKeys[0] === 'string') {
                        if (selectedKeys[0].split('-')[0] === 'productionBase') {
                          return;
                        }
                      }
                      if (typeof selectedKeys[0] === 'string') {
                        if (selectedKeys[0].split('-').length === 4 || selectedKeys[0].split('-').length === 2) {
                          setShowExport(true);
                        } else {
                          setShowExport(false);
                        }
                      }
                      console.log(selectedKeys, info);
                      setSelectedKeys(selectedKeys);
                      // 切换工作中心  把能源介质切换为综合能耗
                      // setSelectedConsumption(GENERAL_CONSUMPTION);
                      setSelectedBasePhysicalQuantityId(undefined);

                      setSelectedConsumptionScene(GENERAL_CONSUMPTION_SCENE);
                      if (info.selected) {
                        // @ts-ignore
                        setSelectedName(info.node.display);
                      } else {
                        setSelectedName(undefined);
                      }
                    }}
                  />
                ) : (
                  <Empty />
                )}
              </div>
            </div>
          </Layout.Sider>
          <Layout.Content className={styles.content}>
            <div>
              <div
                className={styles.toggleBtn}
                style={{ left: openSider ? -30 : 0 }}
                onClick={() => setOpenSider(!openSider)}
              >
                {openSider ? <LeftOutlined /> : <RightOutlined />}
              </div>

              <Space style={{ padding: '20px 12px 12px 20px' }} size="small" wrap>
                <Button
                  style={{ paddingLeft: 12, paddingRight: 16 }}
                  onClick={() => {
                    // 综合能耗 id 为 0
                    setSelectedConsumption(GENERAL_CONSUMPTION);
                    setSelectedBasePhysicalQuantityId(undefined);
                    setSelectedConsumptionScene(GENERAL_CONSUMPTION_SCENE);
                    setSelectedTabItem(ConsumptionAttribute.HEAT);
                  }}
                  className={
                    selectedConsumption === GENERAL_CONSUMPTION
                      ? styles.titleButtonChecked
                      : styles.titleButtonUnChecked
                  }
                >
                  <Space size="small" align="center">
                    <img
                      width={24}
                      src={selectedConsumption === GENERAL_CONSUMPTION ? generalActive : generalPassive}
                      alt=""
                    />
                    <span>综合能耗</span>
                  </Space>
                </Button>

                {mediums.map(medium => (
                  <Button
                    style={{ paddingLeft: 12, paddingRight: 16 }}
                    onClick={() => {
                      setSelectedConsumption(medium.energyMediumId);
                      setSelectedBasePhysicalQuantityId(medium.basePhysicalQuantityId);
                      setSelectedConsumptionScene(medium.sceneId!);
                      setSelectedTabItem(ConsumptionAttribute.MEDIUM);
                    }}
                    className={
                      `${selectedBasePhysicalQuantityId}-${selectedConsumption}` ===
                      `${medium.basePhysicalQuantityId}-${medium.energyMediumId}`
                        ? styles.titleButtonChecked
                        : styles.titleButtonUnChecked
                    }
                  >
                    <Space size="small" align="center">
                      <img
                        width={24}
                        src={
                          selectedConsumption === medium.energyMediumId
                            ? EnergyMediumIconDisplay[medium.sceneId as EnergyMediumSceneIds].active
                            : EnergyMediumIconDisplay[medium.sceneId as EnergyMediumSceneIds].passive
                        }
                        alt=""
                      />
                      <span>
                        {medium.energyMediumName}-{medium.generalName}
                      </span>
                    </Space>
                  </Button>
                ))}
              </Space>
              <Tabs
                activeKey={selectedTabItem.toString()}
                // tabPosition="bottom"
                items={tabsItems}
                onChange={activeKey => {
                  console.log(activeKey);

                  setSelectedTabItem(Number(activeKey));
                }}
              />
              <div className={styles.tabContent}>
                <div className={styles.filter}>
                  <div className={styles.dateSwitch}>
                    <div className={styles.switchDimension}>
                      <Radio.Group
                        options={Object.entries(ConsumptionDimensionDisplay).map(([k, v]) => ({
                          label: v,
                          value: k,
                        }))}
                        value={selectedConsumptionDimension}
                        onChange={e => {
                          setSelectedConsumptionDimension(e.target.value);
                          // 切换的时候能耗默认选中 15 分钟, 单耗默认选中小时
                          if (e.target.value === ConsumptionDimension.TOTAL) {
                            setTimeGranularity(TimeGranularity.FIFTEEN_MINUTES);
                          } else {
                            if (timeGranularity !== TimeGranularity.FIFTEEN_MINUTES) {
                              // 如果上一个选中的时间维度是 15 分钟, 做记忆, 否则重置为小时的默认时间
                              setValue(TimeGranularityInitialValue[TimeGranularityUnit.HOUR]);
                            }
                            setTimeGranularity(TimeGranularityUnit.HOUR);
                          }
                        }}
                        optionType="button"
                        buttonStyle="solid"
                      />
                    </div>

                    <div className={styles.switchBtn}>
                      <Radio.Group
                        options={
                          selectedConsumptionDimension === ConsumptionDimension.TOTAL
                            ? Object.entries(TimeGranularityDisplay).map(([k, v]) => ({
                                label: v,
                                value: k,
                              }))
                            : Object.entries(TimeGranularityUnitDisplay).map(([k, v]) => ({
                                label: v,
                                value: k,
                              }))
                        }
                        onChange={e => {
                          // 设置时间颗粒度和时间
                          setTimeGranularity(e.target.value);
                          setValue(TimeGranularityInitialValue[e.target.value]);
                        }}
                        value={timeGranularity}
                        optionType="button"
                        buttonStyle="solid"
                      />
                    </div>
                    <div className={styles.datePickerArea}>
                      <DatePicker.RangePicker
                        allowClear={false}
                        picker={timeGranularity === TimeGranularity.MONTH ? 'month' : 'date'}
                        value={dates || value}
                        onOpenChange={open => {
                          if (open) {
                            setDates([null, null]);
                          }
                        }}
                        disabledDate={disabledDate}
                        onCalendarChange={val => setDates([val[0], null])}
                        onChange={val => setValue(val)}
                      />
                    </div>
                    <div className={styles.operation}>
                      <Space size={8}>
                        {hasComparePermission &&
                          showExport &&
                          (timeGranularity === TimeGranularity.DAY || timeGranularity === TimeGranularity.MONTH) &&
                          selectedConsumption === GENERAL_CONSUMPTION &&
                          (selectedTabItem === ConsumptionAttribute.COAL ||
                            selectedTabItem === ConsumptionAttribute.HEAT) && (
                            <Button
                              type="primary"
                              onClick={() => {
                                if (selectedKeys.length && value?.[0] && value?.[1]) {
                                  console.log(selectedKeys);
                                  const key = selectedKeys[0] as string;
                                  const resolutionType: any = {
                                    month: 'M',
                                    day: 'D',
                                  };
                                  let query = {};
                                  if (key.split('-').length === 4) {
                                    query = {
                                      centerProcedureLinkId: key.split('-').at(-2),
                                      workCenterId: key.split('-').at(-1)!,
                                      startDate: value?.[0]?.format('YYYY-MM-DD'),
                                      endDate: value?.[1]?.format('YYYY-MM-DD'),
                                      resolution: resolutionType[timeGranularity],
                                    };
                                  } else {
                                    query = {
                                      centerProcedureLinkId: null,
                                      workCenterId: key.split('-').at(-1)!,
                                      startDate: value?.[0]?.format('YYYY-MM-DD'),
                                      endDate: value?.[1]?.format('YYYY-MM-DD'),
                                      resolution: resolutionType[timeGranularity],
                                    };
                                  }
                                  console.log(query);

                                  window.open(`/api/v2/production/energy/consumption/export?${qs.stringify(query)}`);
                                }
                              }}
                            >
                              导出
                            </Button>
                          )}
                        {hasComparePermission && (
                          <Button
                            type="primary"
                            onClick={() => {
                              setShowCompareModal(true);
                            }}
                          >
                            对比
                          </Button>
                        )}
                        {/* prettier-ignore */}
                        {analysisData?.score && selectedKeys.length ? (
                          <Button
                            type="primary"
                            style={{ background: linearColors[currentLevel] }}
                            onClick={() => setShowDrawer(true)}
                          >
                            <div className={styles.btnContent}>
                              <div className={styles.scoreBox} style={{ background: levelColors[currentLevel] }}>
                                {analysisData?.score ? analysisData?.score.toFixed(2) : '--'}
                              </div>
                              <div className={styles.labelTxt}>智能分析诊断</div>
                            </div>
                          </Button>
                        ) : null}
                      </Space>
                    </div>
                  </div>
                </div>

                <div className={styles.cardContainer}>
                  {/* 综合能耗/发热量的卡片显示只有在能耗下才展示 */}
                  {selectedConsumptionDimension === ConsumptionDimension.TOTAL && (
                    <div className={styles.cardItem}>
                      <CardForData
                        title={
                          selectedConsumption === GENERAL_CONSUMPTION
                            ? `综合能耗（${ConsumptionAttributeUnitDisplay[selectedTabItem]}）`
                            : `累计用量（${generalName}）`
                        }
                        value={isNil(totalValue) ? null : totalValue}
                      />
                    </div>
                  )}
                  {/* 累计成本和累计电度电费只有在能耗下展示 */}
                  {selectedConsumptionDimension === ConsumptionDimension.TOTAL && (
                    <div className={styles.cardItem}>
                      <CardForData
                        title={
                          selectedConsumption === 2030
                            ? `累计电度电费（${isOverTenThousand ? '万元' : '元'}）`
                            : `累计成本（${isOverTenThousand ? '万元' : '元'}）`
                        }
                        value={
                          isNil(totalCost)
                            ? null
                            : isOverTenThousand
                            ? Number((totalCost / 10000).toFixed(2))
                            : totalCost
                        }
                      />
                    </div>
                  )}

                  {/* 状态卡片只有在 15 分钟下的能耗或者生产单耗下才展示 */}
                  {selectedKeys.length &&
                  (timeGranularity === TimeGranularity.FIFTEEN_MINUTES || timeGranularity === TimeGranularityUnit.HOUR)
                    ? consumptionStatusByTabItem.map((data, index) => (
                        <div key={index} className={styles.cardItem}>
                          <CardForData
                            titleTop={`${StatusDisplay[data?.status] ?? ''}时长（h）`}
                            valueTop={data.duration}
                            titleBottom={`${
                              selectedConsumptionDimension === ConsumptionDimension.TOTAL
                                ? selectedConsumption === GENERAL_CONSUMPTION
                                  ? `综合能耗（${ConsumptionAttributeUnitDisplay[selectedTabItem]}）`
                                  : `累计用量（${generalName}）`
                                : `每小时能耗（${
                                    selectedConsumption === GENERAL_CONSUMPTION
                                      ? `${ConsumptionAttributeUnitDisplay[selectedTabItem]}/h`
                                      : generalName
                                  }）`
                            }`}
                            valueBottom={
                              selectedConsumptionDimension === ConsumptionDimension.TOTAL
                                ? data.consumption
                                : data.unitConsumption
                            }
                            costTitle={
                              selectedConsumptionDimension === ConsumptionDimension.TOTAL
                                ? `累计成本（${isOverTenThousand ? '万元' : '元'} ）`
                                : '每小时成本（元）'
                            }
                            cost={
                              selectedConsumptionDimension === ConsumptionDimension.TOTAL
                                ? isNil(data.totalCost)
                                  ? data.totalCost
                                  : isOverTenThousand
                                  ? Number((data.totalCost / 10000).toFixed(2))
                                  : data.totalCost
                                : data.avgCost
                            }
                          />
                        </div>
                      ))
                    : null}
                </div>
              </div>

              <Spin spinning={loading}>
                <ResizeObserver onResize={({ width }) => setWidth(width)}>
                  <div className={styles.chartContainer}>
                    {selectedKeys.length &&
                    ((selectedConsumptionDimension === ConsumptionDimension.TOTAL && chartData?.length) ||
                      (selectedConsumptionDimension === ConsumptionDimension.UNIT && chartUnitData?.length)) ? (
                      <ReactEChartsCore
                        notMerge
                        // showLoading={loading}
                        lazyUpdate={false}
                        style={{ height: '100%', width }}
                        option={options}
                        echarts={echarts}
                        theme={echartsTheme}
                      />
                    ) : (
                      <Empty />
                    )}
                  </div>
                </ResizeObserver>
              </Spin>
            </div>
          </Layout.Content>

          {showCompareModal && (
            <CompareModal
              selectedConsumptionNode={selectedKeys?.[0]}
              selectedConsumptionNodeName={selectedName}
              selectedConsumption={selectedConsumption}
              selectedMonitorType={selectedTabItem}
              open={showCompareModal}
              closeModal={() => setShowCompareModal(false)}
            />
          )}
        </Layout>
      </Wrapper>
      {showDrawer && selectedKeys.length && (
        <AnalysisDrawer analysisData={analysisData} show={showDrawer} close={() => setShowDrawer(false)} />
      )}
    </div>
  );
}

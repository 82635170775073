import { FormContent } from '@maxtropy/components';
import { Col, Form, FormInstance, Input, InputNumber, Radio, Row, Space } from 'antd';
import styles from './index.module.scss';
import {
  ElectricitySaleContractSettlement,
  ElectricitySaleContractSettlementMeterType,
  ElectricitySaleContractSettlementType,
  HasPreferentialAmount,
  HasPreferentialAmountType,
} from '../../../../../api/electricitySettlementRules';
import { FC, useEffect, useState } from 'react';
import UnitarySystem from '../../../../SettlementRules/CreateSettlementRules/components/UnitarySystem';
import DistributedNewEnergy from '../../../../SettlementRules/CreateSettlementRules/components/DistributedNewEnergy';
import DistributedNewEnergyFull from '../../../../SettlementRules/CreateSettlementRules/components/DistributedNewEnergyFull';
import TwoPartSystemAndNewEnergy from '../../../../SettlementRules/CreateSettlementRules/components/TwoPartSystemAndNewEnergy';
import TwoPartSystem from '../../../../SettlementRules/CreateSettlementRules/components/TwoPartSystem';
import EffectiveMeterForm from '../../../../SettlementRules/CreateSettlementRules/components/EffectiveMeterForm';
import { InfoCircleOutlined } from '@ant-design/icons';

interface ICusSettlementRule {
  isEdit?: boolean;
  form: FormInstance<any>;
  contractId?: any[];
  isSelectContract?: boolean;
}

const CusSettlementRule: FC<ICusSettlementRule> = props => {
  const { isEdit, form, contractId, isSelectContract } = props;
  const [propertyManagementConfigRange, setPropertyManagementConfigRange] = useState<string>('1,2'); // 当选择物业的时候带出物业范围，用于结算规则类型
  const [propertyManagementConfigId] = useState<string>('1'); // 选择物业的时候保存物业id，用于合同查找
  const [deductPhotovoltaicMetersData] = useState<any[]>([]);
  const [photovoltaicMetersData] = useState<any[]>([]);
  const [electricityMetersDatas] = useState<any[]>([]);
  const [subMetersDatas] = useState<any[]>([]);
  const [demandMetersDatas] = useState<any[]>([]);
  const [saleContractId, setSaleContractId] = useState<string>();

  useEffect(() => {
    if (isSelectContract && contractId) {
      console.log(contractId);
      setSaleContractId(String(contractId[0].id));
      setPropertyManagementConfigRange(contractId[0].propertyManagementConfigBusinessScope ?? '1,2');
    } else {
      setSaleContractId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectContract]);

  // 结算类型切换时，时段选择全选
  const onSettlementTypeChange = () => {
    form.setFieldsValue({ timeOfDayRateChecked: [1, 2, 3, 4] });
  };

  return (
    <>
      {!saleContractId ? (
        <Row style={{ paddingLeft: '15px' }}>
          <Col span={24}>
            <Form.Item
              label="结算类型"
              rules={[{ required: true, message: '请选择结算类型' }]}
              extra={<span style={{ color: 'red' }}>请先选择合同</span>}
            ></Form.Item>
          </Col>
        </Row>
      ) : (
        <div style={{ paddingLeft: '15px' }}>
          <Row>
            <Col span={24}>
              <Form.Item name="settlementType" label="结算类型" rules={[{ required: true, message: '请选择结算类型' }]}>
                {propertyManagementConfigRange.length > 1 ? (
                  <Radio.Group onChange={onSettlementTypeChange} disabled={isEdit || !propertyManagementConfigId}>
                    <Radio value={ElectricitySaleContractSettlementType.UNITARYSYSTEM}>
                      {ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.UNITARYSYSTEM]}
                    </Radio>
                    <Radio value={ElectricitySaleContractSettlementType.TWOPARTSYSTEM}>
                      {ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.TWOPARTSYSTEM]}
                    </Radio>
                    <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST}>
                      {
                        ElectricitySaleContractSettlement[
                          ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST
                        ]
                      }
                    </Radio>
                    <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL}>
                      {
                        ElectricitySaleContractSettlement[
                          ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL
                        ]
                      }
                    </Radio>
                    <Radio value={ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY}>
                      {
                        ElectricitySaleContractSettlement[
                          ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
                        ]
                      }
                    </Radio>
                  </Radio.Group>
                ) : propertyManagementConfigRange === '1' ? (
                  <Radio.Group onChange={onSettlementTypeChange} disabled={isEdit || !propertyManagementConfigId}>
                    <Radio value={ElectricitySaleContractSettlementType.UNITARYSYSTEM}>
                      {ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.UNITARYSYSTEM]}
                    </Radio>
                    <Radio value={ElectricitySaleContractSettlementType.TWOPARTSYSTEM}>
                      {ElectricitySaleContractSettlement[ElectricitySaleContractSettlementType.TWOPARTSYSTEM]}
                    </Radio>
                    <Radio value={ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY}>
                      {
                        ElectricitySaleContractSettlement[
                          ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY
                        ]
                      }
                    </Radio>
                  </Radio.Group>
                ) : propertyManagementConfigRange === '2' ? (
                  <Radio.Group onChange={onSettlementTypeChange} disabled={isEdit || !propertyManagementConfigId}>
                    <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST}>
                      {
                        ElectricitySaleContractSettlement[
                          ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST
                        ]
                      }
                    </Radio>
                    <Radio value={ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL}>
                      {
                        ElectricitySaleContractSettlement[
                          ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL
                        ]
                      }
                    </Radio>
                  </Radio.Group>
                ) : (
                  <></>
                )}
              </Form.Item>
            </Col>
          </Row>
          <UnitarySystem />
          <TwoPartSystem form={form} />
          <DistributedNewEnergy />
          <DistributedNewEnergyFull />
          <TwoPartSystemAndNewEnergy />

          <Row>
            <Form.Item noStyle dependencies={['settlementType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                    <Col span={8} className={styles.col} style={{ marginTop: '20px' }}>
                      <Form.Item
                        name="hasCapitalContributionRate"
                        label="是否有公摊系数"
                        extra={
                          <Space size={4} style={{ width: 380 }}>
                            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
                            注：公摊电量需要在核查联生成后，手动在核查联中录入
                          </Space>
                        }
                        rules={[{ required: true, message: '请选择是否有公摊系数' }]}
                      >
                        <Radio.Group>
                          <Radio value={true}>是</Radio>
                          <Radio value={false}>否</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  )
                );
              }}
            </Form.Item>
          </Row>

          {/* 是否有优惠金额 */}
          <Col span={8} className={styles.col} style={{ marginTop: '20px' }}>
            <Form.Item
              name="hasDiscountedRate"
              label="是否有优惠金额"
              rules={[{ required: true, message: '请选择是否有优惠金额' }]}
            >
              <Radio.Group>
                <Radio value={true}>是</Radio>
                <Radio value={false}>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Form.Item noStyle dependencies={['hasDiscountedRate']}>
            {({ getFieldValue }) => {
              return (
                getFieldValue('hasDiscountedRate') === true && (
                  <Row style={{ backgroundColor: 'rgba(var(--base-text-color), 0.04)', padding: '20px' }}>
                    <Form.Item noStyle dependencies={['hasDiscountedRate']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('hasDiscountedRate') === true && (
                            <>
                              <Col span={24}>
                                <Form.Item
                                  name="discountedType"
                                  label="优惠方式"
                                  rules={[{ required: true, message: '请选择优惠方式' }]}
                                >
                                  <Radio.Group style={{ width: 850 }}>
                                    <Radio value={HasPreferentialAmountType.TOTALDISCOUNTRATIO}>
                                      {HasPreferentialAmount[HasPreferentialAmountType.TOTALDISCOUNTRATIO]}
                                    </Radio>
                                    <Radio value={HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT}>
                                      {HasPreferentialAmount[HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT]}
                                    </Radio>
                                    <Radio value={HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT}>
                                      {HasPreferentialAmount[HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT]}
                                    </Radio>
                                    {/* <Radio value={HasPreferentialAmountType.TIMESHARINGDISCOUNTRATIO}>{HasPreferentialAmount[HasPreferentialAmountType.TIMESHARINGDISCOUNTRATIO]}</Radio> */}
                                    {/* <Radio value={HasPreferentialAmountType.TIMESHARINGPREFERENTIALAMOUNT}>{HasPreferentialAmount[HasPreferentialAmountType.TIMESHARINGPREFERENTIALAMOUNT]}</Radio> */}
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                            </>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item noStyle dependencies={['hasDiscountedRate', 'discountedType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('hasDiscountedRate') === true &&
                          getFieldValue('discountedType') === HasPreferentialAmountType.TOTALDISCOUNTRATIO && (
                            <>
                              <Col span={8} className={styles.col}>
                                <Form.Item
                                  name="totalRateDiscountedRatio"
                                  label={HasPreferentialAmount[HasPreferentialAmountType.TOTALDISCOUNTRATIO]}
                                  extra={<>例：输80%，等于打8折</>}
                                  rules={[{ required: true, message: '请输入' }]}
                                >
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    placeholder="请输入"
                                    max={99.99}
                                    min={0}
                                    addonAfter="%"
                                    step="0.01"
                                    precision={2}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} className={styles.col}></Col>
                            </>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item noStyle dependencies={['hasDiscountedRate', 'discountedType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('hasDiscountedRate') === true &&
                          getFieldValue('discountedType') === HasPreferentialAmountType.TOTALPREFERENTIALAMOUNT && (
                            <>
                              <Col span={8}>
                                <Form.Item
                                  name="totalRateDiscountedQuantity"
                                  label="优惠电度数"
                                  rules={[{ required: true, message: '请输入' }]}
                                >
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    placeholder="请输入"
                                    max={99999999}
                                    min={0}
                                    addonAfter="kWh"
                                    step="0.01"
                                    precision={2}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={16}></Col>
                              <Col span={8} className={styles.col}>
                                <Form.Item
                                  name="totalRateDiscountedRate"
                                  label="优惠金额"
                                  rules={[{ required: true, message: '请输入' }]}
                                >
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    placeholder="请输入"
                                    max={99999999.99999999}
                                    min={0}
                                    addonAfter="元"
                                    step="0.00000001"
                                    precision={8}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item noStyle dependencies={['hasDiscountedRate', 'discountedType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('hasDiscountedRate') === true &&
                          getFieldValue('discountedType') === HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT && (
                            <>
                              <Col span={8} className={styles.col}>
                                <Form.Item
                                  name="unitRateDiscountedRate"
                                  label={HasPreferentialAmount[HasPreferentialAmountType.UNITPRICEPREFERENTIALAMOUNT]}
                                  rules={[{ required: true, message: '请输入' }]}
                                >
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    placeholder="请输入"
                                    max={100}
                                    min={0}
                                    addonAfter="分"
                                    addonBefore="每度减"
                                    step="1"
                                    precision={0}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8} className={styles.col}></Col>
                            </>
                          )
                        );
                      }}
                    </Form.Item>
                  </Row>
                )
              );
            }}
          </Form.Item>

          <Form.Item noStyle dependencies={['settlementType']}>
            {({ getFieldValue }) => {
              return (
                getFieldValue('settlementType') && (
                  <FormContent title={'生效表计'} style={{ paddingLeft: '0px' }}>
                    <Form.Item noStyle dependencies={['settlementType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('settlementType') === ElectricitySaleContractSettlementType.UNITARYSYSTEM && (
                            <Row>
                              <Col span={24}>
                                <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                                  <EffectiveMeterForm
                                    saleElectricityContractId={saleContractId}
                                    addType={'电量-'}
                                    meterType={ElectricitySaleContractSettlementMeterType.ELECTRICITY}
                                    isEdit={isEdit}
                                    meterDatas={electricityMetersDatas}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item noStyle dependencies={['settlementType', 'hasSubMeterRateDeduct']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('settlementType') === ElectricitySaleContractSettlementType.TWOPARTSYSTEM && (
                            <>
                              <Row>
                                <Col span={24}>
                                  <Form.Item name="demandMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleContractId}
                                      addType={'需量-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.DEMAND}
                                      isEdit={isEdit}
                                      meterDatas={demandMetersDatas}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={24}>
                                  <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleContractId}
                                      addType={'电量-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.ELECTRICITY}
                                      isEdit={isEdit}
                                      meterDatas={electricityMetersDatas}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              {getFieldValue('hasSubMeterRateDeduct') === true && (
                                <Row>
                                  <Col span={24}>
                                    <Form.Item name="subMeters" label="" wrapperCol={{ span: 24 }}>
                                      <EffectiveMeterForm
                                        saleElectricityContractId={saleContractId}
                                        addType={'子表-'}
                                        meterType={ElectricitySaleContractSettlementMeterType.SUBTABLE}
                                        isEdit={isEdit}
                                        meterDatas={subMetersDatas}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              )}
                            </>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item noStyle dependencies={['settlementType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('settlementType') ===
                            ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_REST && (
                            <>
                              <Row>
                                <Col span={24}>
                                  <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleContractId}
                                      addType={'电量-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.ELECTRICITY}
                                      isEdit={isEdit}
                                      meterDatas={electricityMetersDatas}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={24}>
                                  <Form.Item name="photovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleContractId}
                                      addType={'不扣除反向电量-光伏'}
                                      meterType={ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC}
                                      isEdit={isEdit}
                                      meterDatas={photovoltaicMetersData}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item noStyle dependencies={['settlementType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('settlementType') ===
                            ElectricitySaleContractSettlementType.DISTRIBUTEDNEWENERGY_FULL && (
                            <>
                              <Row>
                                <Col span={24}>
                                  <Form.Item name="deductPhotovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleContractId}
                                      addType={'扣除反向电量-光伏'}
                                      meterType={ElectricitySaleContractSettlementMeterType.DEDUCTPHOTOVOLTAIC}
                                      isEdit={isEdit}
                                      meterDatas={deductPhotovoltaicMetersData}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={24}>
                                  <Form.Item name="photovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleContractId}
                                      addType={'不扣除反向电量-光伏'}
                                      meterType={ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC}
                                      isEdit={isEdit}
                                      meterDatas={photovoltaicMetersData}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item noStyle dependencies={['settlementType']}>
                      {({ getFieldValue }) => {
                        return (
                          getFieldValue('settlementType') ===
                            ElectricitySaleContractSettlementType.TWOPARTCOMMERCIALPOWER_AND_NEWENERGY && (
                            <>
                              <Row>
                                <Col span={24}>
                                  <Form.Item name="demandMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleContractId}
                                      addType={'需量-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.DEMAND}
                                      isEdit={isEdit}
                                      meterDatas={demandMetersDatas}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={24}>
                                  <Form.Item name="electricityMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleContractId}
                                      addType={'电量-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.ELECTRICITY}
                                      isEdit={isEdit}
                                      meterDatas={electricityMetersDatas}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col span={24}>
                                  <Form.Item name="photovoltaicMeters" label="" wrapperCol={{ span: 24 }}>
                                    <EffectiveMeterForm
                                      saleElectricityContractId={saleContractId}
                                      addType={'光伏-'}
                                      meterType={ElectricitySaleContractSettlementMeterType.PHOTOVOLTAIC}
                                      isEdit={isEdit}
                                      meterDatas={photovoltaicMetersData}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )
                        );
                      }}
                    </Form.Item>
                  </FormContent>
                )
              );
            }}
          </Form.Item>

          <Row>
            <Col span={12} className={styles.col}>
              <Form.Item
                name="description"
                label="备注"
                rules={[
                  // { required: true, message: '请输入备注' },
                  { max: 100, message: '最多输入100个字符' },
                ]}
              >
                <Input maxLength={100} placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default CusSettlementRule;

import { Button, Wrapper, useBreadcrumbRoutes } from '@maxtropy/components';
import { Form, Input } from 'antd';
import styles from './index.module.scss';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { ElectricityPurQuery } from '@/api/elePurRecordQuery';
import { getSaleClientByCode } from '@/api/sale';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export interface EleSearchParams extends Omit<ElectricityPurQuery, 'page' | 'size'> {}

const ElectricityPurchaseRecordQuery: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();

  const saleClientCode = Form.useWatch('saleClientCode', form);
  const navigate = useNavigate();
  const [isCodeRight, setIsCodeRight] = useState<boolean>(true);

  const onFinish = () => {
    if (saleClientCode) {
      getSaleClientByCode(saleClientCode).then(res => {
        if (res) {
          navigate(
            `/assetsOperation/elePurEnergyQuery/elePurEleRecordQuery/list?saleClientId=${res.id}&saleClientCode=${saleClientCode}&saleClientName=${res.name}`
          );
        } else {
          setIsCodeRight(false);
        }
      });
    }
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
      <p className={styles.pStyle}>用电记录查询</p>
      <Form form={form} className={styles.formStyle} onFinish={onFinish}>
        <Form.Item className={styles.formItemStyle} name="saleClientCode" label="请输入客户编号">
          <Input
            placeholder="请输入"
            suffix={
              <SearchOutlined
                style={{
                  width: 13,
                  height: 13,
                }}
              />
            }
            onChange={() => {
              setIsCodeRight(true);
            }}
          />
        </Form.Item>
      </Form>
      {!isCodeRight && <p className={styles.infoStyle}>客户编号错误，请重新输入。</p>}
      <div className={styles.btnStyle}>
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          查询
        </Button>
      </div>
      <p className={styles.hintP}>
        <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', paddingRight: 4 }} />{' '}
        如果不知道客户编号，请联系能源提供方。
      </p>
    </Wrapper>
  );
};

export default ElectricityPurchaseRecordQuery;

import { useBreadcrumbRoutes, Wrapper, Button, Form, FormTitle, SubContent, Input, Select } from '@maxtropy/components';
import { Space, TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { getOrganization } from '../../../api/customer-user';
import { getOuListByMcid, OuInfoProps } from '../../../api/ou';
import { checkUnique, createUet, CreateUetReq } from '../../../api/uet';
import { formatTreeData } from '../utils';
import { Link, useNavigate } from 'react-router-dom';

// const routes = [
//   {name: '全域能源管理'},
//   {name: '基础设置'},
//   {name: '全域能源拓扑管理'},
// ];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 8 },
};

const UETCreate: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [organization, setOrganization] = useState<DefaultOptionType[]>([]);
  const [ouList, setOuList] = useState<OuInfoProps[]>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();

  useEffect(() => {
    getOrganization().then(organization => {
      if (organization) {
        setOrganization(formatTreeData([organization]));
      } else {
        setOrganization([]);
      }
    });
  }, []);

  const handleMcidChange = (value: string) => {
    getOuListByMcid(value).then(res => {
      setOuList(res);
    });
  };

  const onFinish = async (v: CreateUetReq) => {
    const r = await checkUnique(v.name);
    if (r) {
      form.setFields([
        {
          name: 'name',
          errors: ['拓扑名称重复！'],
        },
      ]);
    } else {
      createUet(v).then(res => {
        if (res.id) {
          navigate(`/energy/basic/uet/edit/${res.id}`);
        }
      });
    }
  };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className="page_wrapper">
      <div className={styles.container}>
        <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
          <FormTitle title="新建全域能源拓扑" />
          <SubContent>
            <Form.Item
              label="拓扑名称"
              name="name"
              rules={[
                { required: true, message: '请输入拓扑名称' },
                { max: 16, message: '长度不能超过16个字符' },
              ]}
            >
              <Input placeholder="请输入" />
            </Form.Item>
            <Form.Item label="组织" name="mcid" rules={[{ required: true, message: '请选择组织' }]}>
              <TreeSelect treeData={organization} placeholder="请选择" onChange={handleMcidChange} />
            </Form.Item>
            <Form.Item
              label="全域能源拓扑包含运营单元"
              name="ouIds"
              rules={[{ required: true, message: '请选择全域能源拓扑包含运营单元' }]}
            >
              <Select placeholder="请选择" mode="multiple" optionFilterProp="children">
                {ouList.map(i => (
                  <Select.Option value={i.id} key={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </SubContent>

          <Space size={8} className="sticky-footer">
            <Button htmlType="submit" type="primary">
              生成全域能源拓扑
            </Button>
            <Button>
              <Link to={`/energy/basic/uet`}>取消</Link>
            </Button>
          </Space>
        </Form>
      </div>
    </Wrapper>
  );
};

export default UETCreate;
